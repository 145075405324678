import { Col, Form, Input, Row, notification } from "antd"
import { FormDrawer, Table } from "components"
import { apis } from "../../../../../constants"
import { common_post, formatCurrency } from "helpers"
import { forwardRef, useImperativeHandle, useState } from "react"
import i18n, { languageKeys } from "../../../../../i18n"
import style from "./style.module.less"
import { useDispatch, useSelector } from "react-redux"
import { TiepDonActions, tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice"
import { useEventListener } from "hooks"
import moment from "moment"

const DrawerHuyPhieu = ({ renderExpandIcon, setLichKhamInfo, setDS_DV_GOI, setBundleReceipts }, ref) => {

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [selectedRow, setSelectedRow] = useState([])

  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { dsGoiDvBenhNhan } = useSelector(tiepDonState);


  useImperativeHandle(ref, () => ({
    open: (LIST_GOI_ID, LICH_KHAM_ID) => {
      layDsPhieuDaTtDvGoiChiDinh(LIST_GOI_ID, LICH_KHAM_ID)
      setVisible(true)
    }
  }))


  const layDsPhieuDaTtDvGoiChiDinh = async (LIST_GOI_ID, LICH_KHAM_ID) => {
    setLoading(true)
    try {
      const response = await common_post(apis.layDsPhieuDaTtDvGoiChiDinh, { LIST_GOI_ID, LICH_KHAM_ID });
      if (response.status === 'OK') {
        const data = response.result?.map(item => ({
          ...item,
          TEN_DICHVU: `${item.SO_PHIEU} - ${item.TEN_GOI_DV}`,
          TONG_TIEN: item.TONG_CHI_PHI,
          children: item.list_dv.map(i => ({ ...i, HOA_DON_ID: `${i.DV_ID}${item.HOA_DON_ID}` }))
        }))
        setDataSource(data)
        setSelectedRow(data.filter(i => moment().isSame(moment(i.NGAY), 'day')))
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }


  const huyNhieuPhieuThuDichVu = async (value) => {
    setLoading(true)
    try {
      const response = await common_post(apis.huyNhieuPhieuThuDichVu, {
        LIST_HOA_DON_ID: selectedRow.flatMap(i => i.HOA_DON_ID),
        NGUOI_HUY: userProfile.NHANSU_ID,
        ...value,
      });
      if (response.status === 'OK') {

        setLichKhamInfo((obj) => ({ ...obj, runReload: true }))

        //
        const arrBundle = selectedRow.flatMap(i => i.list_dv).map(item => ({
          GOI_ID: item.GOI_ID,
          DV_ID: item.DV_ID
        }))

        // Update ds gói đã chọn
        setDS_DV_GOI((arr) => arr.map(item => {
          const found = arrBundle.find(i => i.DV_ID === item.DV_ID || i.DV_ID === item.DICH_VU_ID)
          if (found) {
            return {
              ...item,
              THANH_TOAN: found.GOI_ID === item.GOI_ID ? 0 : item.THANH_TOAN
            }
          } else {
            return item
          }
        }))

        // Update ds gói
        dispatch(TiepDonActions.setDsGoiDvBenhNhan(
          dsGoiDvBenhNhan.map(item => ({
            ...item,
            DS_DV: item?.DS_DV?.map(dv => {
              if (dv?.DV_KEM_THEO) {
                return {
                  ...dv,
                  DV_KEM_THEO: dv.DV_KEM_THEO.map((dvkt => {
                    const found = arrBundle.find(i => i.DV_ID === dvkt.DV_ID || i.DV_ID === dvkt.DICH_VU_ID)
                    if (found) {
                      return {
                        ...dvkt,
                        THANH_TOAN: found.GOI_ID === item.GOI_DV_ID ? 0 : item.THANH_TOAN
                      }
                    } else {
                      return dvkt
                    }
                  }))
                }
              } else {
                const found = arrBundle.find(i => i.DV_ID === dv.DV_ID || i.DV_ID === dv.DICH_VU_ID)
                if (found) {
                  return {
                    ...dv,
                    THANH_TOAN: found.GOI_ID === item.GOI_DV_ID ? 0 : item.THANH_TOAN
                  }
                } else {
                  return dv
                }
              }
            })
          }))
        ))

        setBundleReceipts([])

        notification.success({ message: i18n.t(languageKeys.noti_huy_phieu_thanh_cong), placement: 'bottomLeft' })
        handleClose()

      } else {
        notification.error({ message: i18n.t(languageKeys.noti_huy_phieu_that_bai), placement: 'bottomLeft' })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setVisible(false)
    setDataSource([])
    setSelectedRow([])
    form.resetFields()
  }


  const keydownEvent = (e) => {
    if (e.ctrlKey && e.key === "d") {
      e.stopPropagation();
      e.preventDefault();
      form.submit()
    }
  }
  useEventListener("keydown", keydownEvent, window, visible);


  return (
    <FormDrawer
      visible={visible}
      title={i18n.t(languageKeys.title_huy_phieu_thu_dich_vu)}
      width={900}
      onCancel={handleClose}
      loading={loading}
      buttonsAtBottomOfContent
      disableAddBtn={false}
      footerClass={style.footer}
      okText={i18n.t(languageKeys.huy_phieu) + " (Ctrl + D)"}
      onOk={() => form.submit()}
      disableOkBtn={!selectedRow.length}
    >
      <div className={style.wrapper}>

        <Table
          columns={columnsHuy}
          dataSource={dataSource}
          rowKey={'HOA_DON_ID'}
          expandable={{
            expandIcon: renderExpandIcon,
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          loading={loading}
          className={style.table}
          selectedRows={selectedRow}
          onSelectRows={(keys) => setSelectedRow(keys.filter(i => i.SO_PHIEU))}
          rowPropsConfig={(record) => {
            if (record.HOA_DON_ID && !moment().isSame(moment(record.NGAY), 'day')) {
              return {
                disabled: true,
              };
            }
          }}
        />

        <Form form={form} onFinish={huyNhieuPhieuThuDichVu}>
          <Row className={style.reason}>
            <Col span={4}>
              <label>{i18n.t(languageKeys.label_ly_do_huy_phieu)}</label>
            </Col>
            <Col span={20}>
              <Form.Item name="LY_DO_HUY" rules={[{ required: true, message: "" }]} style={{ margin: 0 }}>
                <Input
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  className={style.input}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </div>
    </FormDrawer>
  )
}

export default forwardRef(DrawerHuyPhieu)


const columnsHuy = [
  {
    title: i18n.t(languageKeys.field_Dich_vu),
    dataIndex: "TEN_DICHVU",
    key: "TEN_DICHVU",
    width: '49%',
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_tong_tien),
    dataIndex: "TONG_TIEN",
    key: "TONG_TIEN",
    width: '17%',
    render: (MONEY) => formatCurrency(MONEY, "", true)
  },
  {
    title: i18n.t(languageKeys.field_doi_tuong_mien_giam),
    dataIndex: "MIEN_GIAM",
    key: "MIEN_GIAM",
    width: '17%',
    render: (MONEY) => formatCurrency(MONEY, "", true)
  },
  {
    title: i18n.t(languageKeys.vien_phi_thanh_tien),
    dataIndex: "THANH_TIEN",
    key: "THANH_TIEN",
    width: '17%',
    render: (MONEY) => formatCurrency(MONEY, "", true)
  },
]