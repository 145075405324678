import React, { useEffect, useState, createContext } from "react";
import { Layout } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { resetTitle, updateTitle } from "../../../ducks/slices/titlePageSlice";
import Sider, { tabItemsKey } from "./components/Sider";
import styles from "./style.module.less";
import DanhSachDv from "./components/DanhSachDv";
import i18n, { languageKeys } from "../../../i18n";

export const TraKQContext = createContext();

const TraKetQuaCdha = () => {
  const userProfile = useSelector((state) => state.auth.user);
  const [selectedPhieu, setSelectedPhieu] = useState(null);
  const [tabActive, setTabActive] = useState(tabItemsKey.CHO_THUC_HIEN);
  const [selectedPhong, setSelectedPhong] = useState(null);
  const [reloadAfterThucHien, setReloadAfterThucHien] = useState(false);

  const values = {
    selectedPhieu,
    setSelectedPhieu,
    tabActive,
    setTabActive,
    setSelectedPhong,
    selectedPhong,
    setReloadAfterThucHien,
    reloadAfterThucHien,
    selectedPhong,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(updateTitle(i18n.t(languageKeys.title_page_tra_kq_cdhd)));
    return () => {
      // dispatch(resetTitle());
      setSelectedPhieu(null);
      setSelectedPhong(null);
    };
  }, []);

  return (
    <TraKQContext.Provider value={values}>
      <Layout className={styles.container}>
        <Sider />
        <DanhSachDv />
      </Layout>
    </TraKQContext.Provider>
  );
};

export default TraKetQuaCdha;

export const trang_thai_tra = {
  CHO_KET_QUA: "CHO_KET_QUA",
  DA_TRA_KET_QUA: "DA_TRA_KET_QUA",
  HOAN_THANH: "HOAN_THANH",
};

export const trang_thai_th = {
  CHO_THUC_HIEN: "CHO_THUC_HIEN",
  DA_THUC_HIEN: "DA_THUC_HIEN",
  HOAN_THANH: "HOAN_THANH",
  DANG_THUC_HIEN: "DANG_THUC_HIEN",
};
