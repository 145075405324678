export const keyFieldKh = {
  "BENH_NHAN_ID": "35a0ad32d4",
  "LUOT_DIEU_TRI_ID": "LDT_17c950489664156bf2c6ca5ff1d61707122197307",
  "NGAY_TAI_KHAM": null,
  "LICH_KHAM_ID": "LK_326f3b553c327bac84146aed9919e1707122197241",
  "NGAY_TIEP_DON": "20240205",
  "GIO_TIEP_DON": "15:36",
  "THOI_GIAN_TAO": 1707122241302,
  "ID": "35a0ad32d4",
  "MA_BENH_NHAN": "0579017193",
  "MAT_KHAU": "123",
  "HO": "ĐỨC",
  "TEN": "ĐỨC HUY",
  "ANH_DAI_DIEN": null,
  "SO_DIEN_THOAI": "0974859905",
  "NGAY_SINH": "1998",
  "EMAIL": "",
  "GIOI_TINH": "nam",
  "MA_TINH_THANH": "",
  "TEN_TINH_THANH": "",
  "MA_QUAN_HUYEN": "",
  "TEN_QUAN_HUYEN": "",
  "MA_PHUONG_XA": "",
  "TEN_PHUONG_XA": "",
  "MA_KHU_VUC": "",
  "DIA_CHI_CHI_TIET": "",
  "MA_NGHE_NGHIEP": "",
  "NGHE_NGHIEP": "",
  "MA_DAN_TOC": 1,
  "DAN_TOC": "Kinh (Việt)",
  "MA_QUOC_TICH": "QG-4208398",
  "QUOC_TICH": "Viet Nam",
  "MA_BHXH": "",
  "CMTND": "",
  "CCCD": "",
  "HO_CHIEU": "",
  "BANG_LAI_XE": "",
  "MA_VAN_HOA": "",
  "VAN_HOA": "",
  "MA_TON_GIAO": "",
  "TON_GIAO": "",
  "NOI_CONG_TAC": "",
  "TIEN_SU_CA_NHAN": "",
  "TIEN_SU_GIA_DINH": "",
  "DI_UNG": "",
  "BENH_VIEN_ID": "H-KTM83kt3RJ",
  "PARTNER_CODE": "noiquoctuan5",
  "NOI_BAT": 0,
  "NGAY_KHOI_TAO": 1707122196788,
  "CHIA_SE_HO_SO": 0,
  "NGUON_KHACH_ID": null,
  "DA_DONG_BO": 1,
  "GID": "ĐỨCHUY19980974859905",
  "LID": "c9e9aba41f",
  "CONG_TY_ID": null,
  "MA_NHAN_VIEN_KHAM_DOAN": null,
  "NGAY_BAT_DAU_LAM_VIEC": null,
  "BO_PHAN": null,
  "CHUC_VU": null,
  "TEN_NGUOI_THAN": null,
  "SDT_NGUOI_THAN": null,
  "QUAN_HE_GIA_DINH": null,
  "DIA_CHI_NGUOI_THAN": null,
  "NAM_SINH": null,
  "MA_CUOC_KHAM": "24733704",
  "NOI_BAT_BENH_NHAN": 0,
  "NHAN_VIEN_TU_VAN_ID": null,
  "TEN_NHAN_VIEN_TU_VAN": null,
  "key": "15c5f"
}

export const keyFieldGoi =  {
  "NGAY_TAO": "03/03/2024",
  "NGUOI_TAO": "Deepcare Việt Nam",
  "TEN_GOI_DV": "lieu trinh",
  "LOAI_GOI_DV_ID": 9,
  "NGAY_HET_HAN": "20240330",
  "IS_ACTIVE": true,
  "NGAY_HIEU_LUC": "20240303",
  "NGAY_SU_DUNG": 28,
  "GIA_GOI_DV": 140000,
  "TONG_THANH_TIEN": 160000,
  "TONG_TIEN_PHAN_BO": 140000,
  "TONG_TIEN": 160000,
  "NHOM_GOI": "LIEU_TRINH",
  "SO_LUONG": 1,
  "GOI_DV_ID": "",
  "MO_TA": "",
  "GHI_CHU": "",
  "MA_GOI_DV": "",
  "ID": "",
  "SO_BUOI_SU_DUNG": 69,
  "GHI_CHU_GOI": "ahihi"
}