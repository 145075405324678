import { Checkbox, Col, Form, Input, Row, Spin } from "antd";
import style from "./ttbn.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { layThongTinBenhNhan } from "../../apisKhamBenh/apisKhamBenh";
import { convertDateToValue, HLog } from "../../../../helpers";
import { useSelector } from "react-redux";
import { danhSachDoiTuong } from "../../../../constants/data";
import moment from "moment";
import cn from "classnames"
import KhaiThacThongTinKB from "./components/KhaiThacThongTinKB";

const ThongTinBenhNhan = ({ patientInfo, setMoTa = () => {}, isVisible }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [loadingView, setLoadingView] = useState(false);

  useEffect(() => {
    if (!!patientInfo && !!isVisible) {
      layChiTietBenhNhan();
    }
    if (isVisible && !patientInfo) {
      // nếu k có bệnh nhân thì resetForm
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, isVisible]);

  // lấy thông tin bệnh nhân
  async function layChiTietBenhNhan() {
    if (!!patientInfo.LUOT_DIEU_TRI_ID) {
      setLoadingView(true);

      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
      };
      const response = await layThongTinBenhNhan(dataRequest);

      if (response) {
        setMoTa(response.result.MO_TA);

        HLog("Data patient response", response);
        setDataToForm(response.result);
      }

      setLoadingView(false);
    } else {
      HLog("Chưa có LUOT_DIEU_TRI_ID");
    }
  }

  // điền data vào form
  function setDataToForm(data) {
    let doi_tuong = danhSachDoiTuong.find(
      (item) => item.key === data.DOI_TUONG_BENH_NHAN
    );
    let dataList = Object.values(fieldBenhNhan).map((key) => {
      // if (key === fieldBenhNhan.DOI_TUONG_BENH_NHAN) {
      //   return {
      //     name: key,
      //     value: doi_tuong ? doi_tuong.name : "",
      //   };
      // }

      if (key === fieldBenhNhan.HO) {
        return {
          name: key,
          value: data.TEN,
        };
      }
      if (key === fieldBenhNhan.NGAY_SINH) {
        return {
          name: key,
          value: !!data.NGAY_SINH ? (data.NGAY_SINH.length > 4 ? moment(data.NGAY_SINH, "YYYYMMDD").format("DD/MM/YYYY") : data.NGAY_SINH) : "",
        };
      }
      if (key === fieldBenhNhan.GIOI_TINH) {
        return {
          name: key,
          value: data.GIOI_TINH === "nam" ? i18n.t(languageKeys.gioi_tinh_Nam) : i18n.t(languageKeys.gioi_tinh_Nu),
        };
      }

      return {
        name: key,
        value: data[key],
      };
    });

    form.setFields(dataList);
  }

  const [form] = useForm();
  
  return (
    <Spin spinning={loadingView}>
      <Form layout="vertical" form={form} style={{ padding: 15 }}>
        <div className={style["wrapper"]}>
          <Row gutter={10} align="bottom">
            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_doi_tuong_bn)}
                name={fieldBenhNhan.TEN_DOI_TUONG}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.nguon_khach)}
                  name={fieldBenhNhan.TEN_NGUON_KHACH}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_Ma_benh_nhan)}
                  name={fieldBenhNhan.MA_BENH_NHAN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_Ten_benh_nhan)}
                  name={fieldBenhNhan.HO}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_CMND_CCCD)}
                  name={fieldBenhNhan.CCCD}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            {/*<Col>*/}
            {/*  <Form.Item name={fieldBenhNhan.UU_TIEN} valuePropName="checked">*/}
            {/*    <Checkbox disabled>*/}
            {/*      {i18n.t(languageKeys.field_Uu_tien)}*/}
            {/*    </Checkbox>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
          </Row>

          <Row gutter={10}>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_So_dien_thoai)}
                  name={fieldBenhNhan.SO_DIEN_THOAI}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_Email)}
                  name={fieldBenhNhan.EMAIL}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.field_Gioi_tinh)}
                  name={fieldBenhNhan.GIOI_TINH}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Ngay_sinh)}
                name={fieldBenhNhan.NGAY_SINH}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_THX)}
                name={fieldBenhNhan.MA_KHU_VUC}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Tinh_thanh)}
                name={fieldBenhNhan.TEN_TINH_THANH}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Quan_huyen)}
                name={fieldBenhNhan.TEN_QUAN_HUYEN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Xa_phuong)}
                name={fieldBenhNhan.TEN_PHUONG_XA}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"40%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)}
                name={fieldBenhNhan.DIA_CHI_CHI_TIET}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Nghe_nghiep)}
                name={fieldBenhNhan.NGHE_NGHIEP}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Dan_toc)}
                name={fieldBenhNhan.DAN_TOC}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.field_Quoc_gia)}
                name={fieldBenhNhan.QUOC_TICH}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col flex={"20%"}>
              <Form.Item
                label={i18n.t(languageKeys.hang_bang_lx)}
                name={fieldBenhNhan.BANG_LAI_XE}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={cn(style["wrapper"], style["nguoi-than"])}>
          <Row gutter={10}>
            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.ten_nguoi_than)}
                  name={fieldBenhNhan.TEN_NGUOI_THAN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.sdt_nguoi_than)}
                  name={fieldBenhNhan.SDT_NGUOI_THAN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col flex={"20%"}>
              <Form.Item
                  label={i18n.t(languageKeys.quan_he_gia_dinh)}
                  name={fieldBenhNhan.QUAN_HE_NGUOI_THAN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col flex={"40%"}>
              <Form.Item
                  label={i18n.t(languageKeys.dia_chi_nguoi_than)}
                  name={fieldBenhNhan.DIA_CHI_NGUOI_THAN}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <KhaiThacThongTinKB patientInfo = {patientInfo}/>

        {/* <h3>{i18n.t(languageKeys.field_Thong_tin_bhyt)}</h3>

        <div className={style["wrapper"]}>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item>
                <Checkbox disabled>
                  {i18n.t(languageKeys.field_TE_co_the_bhyt)}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label={i18n.t(languageKeys.field_The_bhyt)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={i18n.t(languageKeys.field_Ma_cskcb)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label={i18n.t(languageKeys.field_Ten_cskcb)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label={i18n.t(languageKeys.field_Tuyen)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item label={i18n.t(languageKeys.field_Noi_song)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label={i18n.t(languageKeys.field_dia_chi_the)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={i18n.t(languageKeys.field_Han_the_tu)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={i18n.t(languageKeys.field_Han_the_den)}>
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item
                label={i18n.t(languageKeys.field_Ngay_mien_cung_chi_tra)}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item
                label={i18n.t(languageKeys.field_Ngay_dong_han_du_5_nam)}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        </div> */}
      </Form>
    </Spin>
  );
};

export default ThongTinBenhNhan;

const fieldBenhNhan = {
  DOI_TUONG_BENH_NHAN: "DOI_TUONG_BENH_NHAN",
  UU_TIEN: "UU_TIEN",
  MA_BENH_NHAN: "MA_BENH_NHAN",

  HO: "HO",
  CCCD: "CCCD",
  SO_DIEN_THOAI: "SO_DIEN_THOAI",

  EMAIL: "EMAIL",
  GIOI_TINH: "GIOI_TINH",
  NGAY_SINH: "NGAY_SINH",

  MA_KHU_VUC: "MA_KHU_VUC",
  TEN_TINH_THANH: "TEN_TINH_THANH",
  TEN_QUAN_HUYEN: "TEN_QUAN_HUYEN",
  TEN_PHUONG_XA: "TEN_PHUONG_XA",

  DIA_CHI_CHI_TIET: "DIA_CHI_CHI_TIET",
  NGHE_NGHIEP: "NGHE_NGHIEP",
  DAN_TOC: "DAN_TOC",
  QUOC_TICH: "QUOC_TICH",
  TEN_NGUOI_THAN:"TEN_NGUOI_THAN",
  SDT_NGUOI_THAN : "SDT_NGUOI_THAN",
  QUAN_HE_NGUOI_THAN : "QUAN_HE_NGUOI_THAN",
  DIA_CHI_NGUOI_THAN : "DIA_CHI_NGUOI_THAN",
  TEN_DOI_TUONG : "TEN_DOI_TUONG",
  TEN_NGUON_KHACH : "TEN_NGUON_KHACH",
  BANG_LAI_XE: "BANG_LAI_XE"
};
