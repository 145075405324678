import { InputNumber, Tooltip } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { formPattern } from "helpers";

const InputQuantity = ({ value, record, readOnly, onChangeInputNumber, index, planMode, maximum, selectedTab }) => {

  const [error, setError] = useState(!value)
  const inputRef = useRef()
  // const max = record.TON_CUOI || record.TON_KHO;
  const { planData, supplyPlanList } = useSelector(getLieuTrinhData);

  // useEffect(() => {
  //   !record.old && inputRef.current?.focus()
  // }, [])

  useEffect(() => {
    setError(!value)
  }, [value])


  const DSVT = planData
    .filter((_, num) => num !== index)
    .flatMap(i => i.DANH_SACH_VAT_TU)
    .filter(i => i.SO_LUONG)
    .reduce((acc, cur) => {
      const found = acc.find(val => val.VAT_TU_TRONG_KHO_ID === cur.VAT_TU_TRONG_KHO_ID)
      if (found) {
        found.SO_LUONG += Number(cur.SO_LUONG)
      }
      else {
        acc.push({ ...cur, SO_LUONG: Number(cur.SO_LUONG) })
      }
      return acc
    }, [])


  const max = planMode
    ? supplyPlanList
      ?.filter(i => i.VAT_TU_TRONG_KHO_ID)
      .find(i => i.VAT_TU_TRONG_KHO_ID === record.VAT_TU_TRONG_KHO_ID)?.SO_LUONG - (
      DSVT?.find(i => i.VAT_TU_TRONG_KHO_ID === record.VAT_TU_TRONG_KHO_ID)?.SO_LUONG ?? 0
    )
    : maximum


  const validateInput = (value, doUpdate) => {
    if (readOnly) return;
    if (!max && !planMode) {
      if (formPattern.numberOnly.test(value) && Number(value) > 0) {
        onChangeInputNumber(value, record.STT - 1, "SO_LUONG")
      } else {
        onChangeInputNumber(1, record.STT - 1, "SO_LUONG")
      }
      return;
    }
    if (!value || (!!value && Number(value) <= 0)) {
      if (doUpdate) onChangeInputNumber(1, record.STT - 1, "SO_LUONG")
      return setError(true);
    }
    if (!value || Number(value) <= Number(max)) {
      if (doUpdate) onChangeInputNumber(value, record.STT - 1, "SO_LUONG")
      return setError(false);
    }
    if (doUpdate) onChangeInputNumber(max, record.STT - 1, "SO_LUONG")
    return setError(true);
  }


  return (
    <Tooltip
      title={
        readOnly || !max
          ? undefined
          : `${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0 ${i18n.t(languageKeys.va_nho_hon_hoac_bang)} ${max}`
      }
      trigger={readOnly ? "hover" : "focus"}
      placement="left"
    >
      <InputNumber
        readOnly={readOnly}
        onChange={max ? validateInput : () => {}}
        addonAfter={record.TEN_DVT_QD || record.TEN_DVT}
        status={(error && selectedTab !== "PHAC_DO_SU_DUNG") ? "error" : ""}
        placeholder={i18n.t(languageKeys.common_Nhap)}
        onBlur={(e) => validateInput(e.target.value, true)}
        value={value}
        ref={inputRef}
        controls={false}
      />
    </Tooltip>
  )
}

export default InputQuantity;