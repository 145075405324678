import { Row, Col } from "antd";
import i18n, { languageKeys } from "../../../../../../i18n";
import style from "./style.module.less";

export default function CustomTitleTable({
  onCheckAllChange = () => {},
  currentSubclinicalList,
  disabled = false
}) {
  return (
    <div className={style["row-title"]}>
      <Row align="middle" justify="space-between" gutter={8} wrap={false}>
        {/*<Col>*/}
        {/*  <Checkbox*/}
        {/*    disabled={disabled}*/}
        {/*    indeterminate={*/}
        {/*      currentSubclinicalList.filter((item) => item.checked === true)*/}
        {/*        .length < currentSubclinicalList.length &&*/}
        {/*      currentSubclinicalList.filter((item) => item.checked === true)*/}
        {/*        .length > 0*/}
        {/*    }*/}
        {/*    checked={*/}
        {/*      currentSubclinicalList.filter((item) => item.checked === true)*/}
        {/*        .length === currentSubclinicalList.length &&*/}
        {/*      currentSubclinicalList.filter((item) => item.checked === true)*/}
        {/*        .length > 0*/}
        {/*    }*/}
        {/*    onChange={onCheckAllChange}*/}
        {/*  />*/}
        {/*</Col>*/}
        {/* <Col span={2}>
          <span>{i18n.t(languageKeys.field_Ma_dich_vu)}</span>
        </Col> */}
        <Col flex="200px">
          <span>{i18n.t(languageKeys.field_Ten_dich_vu)}</span>
        </Col>
        <Col flex="200px">
          <span>{i18n.t(languageKeys.field_Phong_thuc_hien)}</span>
        </Col>
        <Col flex="110px">
          <span>{i18n.t(languageKeys.field_don_gia)}</span>
        </Col>
        <Col
          flex="65px"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span>{i18n.t(languageKeys.sl)}</span>
        </Col>
        <Col flex="110px">
          <span>{i18n.t(languageKeys.thanh_tien)}</span>
        </Col>
        <Col flex="135px">
          <span>{i18n.t(languageKeys.cate_doi_tuong)}</span>
        </Col>
        <Col
          flex="60px"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span>{i18n.t(languageKeys.status_Da_thanh_toan)}</span>
        </Col>
        <Col flex="170px">
          <span>{i18n.t(languageKeys.noi_thuc_hien)}</span>
        </Col>
        <Col flex="200px">
          <span>{i18n.t(languageKeys.field_Ghi_chu)}</span>
        </Col>
        <div className={style["fixed"]}>
          <div style={{ height: "100%" }}></div>
        </div>
      </Row>
    </div>
  );
}
