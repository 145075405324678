import { Col, Form, Row, Select } from "antd"
import { SmallPlus, Trash } from "../../../assets/svg"
import i18n, { languageKeys } from '../../../i18n'
import styles from './styles.module.less' 
import { useEffect, useImperativeHandle, useRef, useState } from "react"
import ModalExemptions from "./ModalExemptions"
import { useDispatch, useSelector } from "react-redux"
import { deleteCommissionLocal, getCustomerSourceList, getServicePackages } from "ducks/slices/GoiDichVu/DSGoiDichVu/DSGoiDichVuSlice"
import { MIEN_GIAM_GOI } from "./FormGoiDichVu"
import { ActionType, keyNhomDoiTuongNguoiDung } from "./constant"
import { ConfirmModal } from "components"
import { forwardRef } from "react"

export const DM_NHOM_NGUON_KHACH_ID = 'DM_NHOM_NGUON_KHACH_ID'

const Exemption = forwardRef(({formExemptionModal, field, remove, detail, visible, nhomDoiTuongNguoiDung, actionType, total}, ref) => {
    const modalRef = useRef()
    const deleteRef = useRef()
    const dispatch = useDispatch()

    const {commissionListLocal, customerSourceList} = useSelector(getServicePackages)

    const [isCommissionLocal, setIsCommissionLocal] = useState(false);
    const [isAddCommission, setIsAddCommission] = useState(false);
    const [enableAdd, setEnableAdd] = useState(false);

    useEffect(() => {
        if (!!detail) {
            const { MIEN_GIAM } = detail;
            if (MIEN_GIAM?.length > 0) {
                // const detailExemption = MIEN_GIAM[field.name]?.MIEN_GIAM.map(item => ({
                //     customer: item.TEN_NGUON_KHACH,
                //     checked: item.MIEN_GIAM > 0,
                //     exemption: item.MIEN_GIAM,
                //     id: item.NGUON_KHACH_ID,
                // }))
                // console.log(detailExemption, 'detailExemption');

                // formExemptionModal.setFieldValue(["MIEN_GIAM", field.name, "MIEN_GIAM_DATA"], detailExemption);
                if (MIEN_GIAM[field.name]?.MIEN_GIAM?.length > 0) {
                    setIsAddCommission(true);
                } else {
                    setIsAddCommission(false);
                }
            }
        } else {
            setIsAddCommission(false);
        }
    }, [detail, field.name, visible]);

    useEffect(() => {
        if(actionType === ActionType.create) {
            setIsAddCommission(false)
        }
    }, [actionType])

    const formatOptionNhomDoiTuongNguoiDung = (list = []) => {
        return list.map(item => ({
            label: item.TEN_DM_NHOM_NGUON_KHACH,
            value: item.ID
        }))
    }

    const handleOpenModal = async () => {
       await formExemptionModal.validateFields([[MIEN_GIAM_GOI, field?.name, DM_NHOM_NGUON_KHACH_ID]])
       const hasCommissionExist = Object.keys(commissionListLocal).find((item) => item === `mienGiamGoi${field.key}`);
       if (!hasCommissionExist) {
            const valueIdNguonKhach = formExemptionModal.getFieldValue([MIEN_GIAM_GOI, field?.name, DM_NHOM_NGUON_KHACH_ID])
            dispatch(getCustomerSourceList({nguonKhachId: valueIdNguonKhach}))
            setIsCommissionLocal(false);
        } else {
            setIsCommissionLocal(true);
            setIsAddCommission(true);
        }

        const value = formExemptionModal.getFieldValue(["MIEN_GIAM", field.name, DM_NHOM_NGUON_KHACH_ID]);
        setEnableAdd(false);
        const findObjNhomNguonKhachSelect = nhomDoiTuongNguoiDung.find((x) => x.ID === value);
        if (
            findObjNhomNguonKhachSelect?.MA_DM_NHOM_NGUON_KHACH === keyNhomDoiTuongNguoiDung.khach_hang ||
            findObjNhomNguonKhachSelect?.MA_DM_NHOM_NGUON_KHACH === keyNhomDoiTuongNguoiDung.CTV ||
            findObjNhomNguonKhachSelect?.MA_DM_NHOM_NGUON_KHACH === keyNhomDoiTuongNguoiDung.nhan_vien ||
            findObjNhomNguonKhachSelect?.MA_DM_NHOM_NGUON_KHACH === keyNhomDoiTuongNguoiDung.sales ||
            findObjNhomNguonKhachSelect?.MA_DM_NHOM_NGUON_KHACH === keyNhomDoiTuongNguoiDung.bacsi_ctv
        ) {
            setEnableAdd(true);
        }
        modalRef?.current?.showModal()
    }

    const validateDuplicateValue = async (nameItem) => {
        const { MIEN_GIAM } = formExemptionModal.getFieldsValue();
        const formatFormList = MIEN_GIAM.map((item) => ({
          DM_NHOM_NGUON_KHACH_ID: item.DM_NHOM_NGUON_KHACH_ID,
        }));
        const value = formExemptionModal.getFieldValue(['MIEN_GIAM', nameItem]);
        const index = formatFormList.findIndex(
          (item, index) =>
            item.DM_NHOM_NGUON_KHACH_ID === value.DM_NHOM_NGUON_KHACH_ID &&
            item.DM_NHOM_NGUON_KHACH_ID !== undefined &&
            value.DM_NHOM_NGUON_KHACH_ID !== undefined &&
            index !== nameItem,
        );
        if (index !== -1) return Promise.reject(i18n.t(languageKeys.already_duplicated));
      };

    const handleClear = () => {
        const commissionExistKey = Object.keys(commissionListLocal).find((item) => item === `mienGiamGoi${field.key}`);
        if (commissionExistKey) {
            dispatch(deleteCommissionLocal(commissionExistKey));
        }
    };

    const handleRemoveExemption = () => {
        deleteRef.current.open()
    }

    const handleConfirmDelete = () => {
        dispatch(deleteCommissionLocal(`mienGiamGoi${field.key}`));
        remove(field.name)
    }

    return (
        <>
        <Col span={12} key={field.key}>
            <div className={styles['exemption-form-item']}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name={[field.name, DM_NHOM_NGUON_KHACH_ID]} label={i18n.t(languageKeys.nhom_doi_tuong_nguoi_dung)} rules={[{required: true, message: i18n.t(languageKeys.please_chon)}, {validator: () => validateDuplicateValue(field.name)}]}>
                            <Select allowClear options={formatOptionNhomDoiTuongNguoiDung(nhomDoiTuongNguoiDung)} placeholder={i18n.t(languageKeys.common_Chon)} onChange={handleClear} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item name={[field.name, 'MIEN_GIAM_DATA']}  label={i18n.t(languageKeys.field_Mien_giam)} rules={[{required: true, message: ''}]}>
                            <div className={styles['item-exemption']}  >
                                {!isAddCommission ?
                                <span style={{display:'flex', alignItem:'center', color: '#6576FF'}} onClick={() => handleOpenModal({name: field.name, key: field.key})}>
                                    <SmallPlus />                              
                                    <span style={{marginLeft: 6, cursor:'pointer'}}>{i18n.t(languageKeys.them_co_che_mien_giam)}</span> 
                                </span> : <span style={{color: '#6576FF'}} onClick={() => handleOpenModal({name: field.name, key: field.key})}>
                                    {i18n.t(languageKeys.exemption_mechanism)}
                                </span>
                                }
                                <div className={styles['item-icon-trash']} onClick={handleRemoveExemption}>
                                    <Trash />
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Col>
        <ModalExemptions 
            field={field} 
            ref={modalRef} 
            formExemptionModal={formExemptionModal} 
            commissionList={isCommissionLocal ? commissionListLocal : customerSourceList}
            isCommissionLocal={isCommissionLocal}
            detail={detail}
            setIsAddCommission={setIsAddCommission}
            // isOpen={modalRef?.current?.isOpen}
            enableAdd={enableAdd}
            total={total}
        />
        <ConfirmModal
            ref={deleteRef}
            title={i18n.t(languageKeys.common_Canh_bao)}
            okText={i18n.t(languageKeys.answer_co)}
            cancelText={i18n.t(languageKeys.answer_khong)}
            content={i18n.t(languageKeys.noti_xoa_mien_giam_goi)}
            onOk={handleConfirmDelete}
            danger
        />
        </>
    )
})

export default Exemption
