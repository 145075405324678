import { Button, Space, notification } from "antd";
import { FormDrawer } from "components";
import i18n, { languageKeys } from "../../../../i18n";
import ChiDinhVatTu from "pages/QuanLyGoiDichVu/GoiLieuTrinh/ChiDinhVatTu";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { common_post } from "helpers";
import { apis } from "../../../../constants";
import { useDispatch } from "react-redux";
import { resetGoiVatTuSlice, setSupplyPlanList } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { useEventListener } from "hooks";

const QlyVatTuRIS = forwardRef(({ phieu = {}, currService = {} }, ref) => {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [supplyList, setSupplyList] = useState([{}])

  useEffect(() => {
    return () => {
      dispatch(resetGoiVatTuSlice())
    }
  }, [])

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      layVatTuRIS()
    }
  }));


  const handleClose = () => {
    setOpen(false)
    setSupplyList([[{}]])
    dispatch(resetGoiVatTuSlice())
  }


  const layVatTuRIS = async () => {
    try {
      const resSD = await common_post(apis.layVatTuSuDungCls, { PHONG_DIEU_TRI_ID: currService?.PDT_ID });
      if (resSD.status === "OK") {
        const resVT = await common_post(apis.layVatTuTrongDichVuVaPhong, { 
          DICH_VU_ID: currService?.DICH_VU_ID,
          PHONG_ID: [currService?.PHONG_ID],
          LOAI_KHO: 'KHO_TU_TRUC',
        });
        if (resVT.status === "OK") {
          dispatch(setSupplyPlanList(resVT.result.filter(i => !i.VAT_TU_MAC_DINH_DICH_VU)))
          //
          const dsSD = resSD.result || []
          const dsVT = resVT.result || []
          if (dsSD[0]) {
            setSupplyList([
              ...dsSD.map(i => ({
                ...i,
                TON_KHO: dsVT.find(c => c.VAT_TU_TRONG_KHO_ID === i.VAT_TU_TRONG_KHO_ID)?.SO_LUONG
              })),
              {}
            ])
          } else {
            setSupplyList([
              ...dsVT.filter(i => i.VAT_TU_MAC_DINH_DICH_VU).map(i => ({
                ...i,
                TON_KHO: dsVT.find(c => c.VAT_TU_TRONG_KHO_ID === i.VAT_TU_TRONG_KHO_ID)?.SO_LUONG
              })),
              {}
            ])
          }
        }
      }
    } catch (error) {
      notification.error({
        message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
        placement: "bottomLeft",
      });
    }
  }


  const luuVatTuSuDungCls = async () => {
    try {
      const res = await common_post(apis.luuVatTuSuDungCls, {
        PHONG_DIEU_TRI_ID: currService?.PDT_ID,
        DANH_SACH_VAT_TU: supplyList.filter(i => i.SO_LUONG)
      });
      if (res.status === "OK") {
        notification.success({
          message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: "bottomLeft",
        });
        handleClose()
      }
    } catch (error) {
      notification.error({
        message: i18n.t(languageKeys.cap_nhat_that_bai),
        placement: "bottomLeft",
      });
    }
  }


  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "s") {
      event.stopPropagation();
      event.preventDefault();
      luuVatTuSuDungCls()
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);


  const FormFooter = (
    <Space style={{ float: 'right' }}>
      <Button type="primary" ghost onClick={handleClose}>
        {i18n.t(languageKeys.common_Thoat)} (ESC)
      </Button>
      <Button type="primary" onClick={luuVatTuSuDungCls}>
        {i18n.t(languageKeys.common_Luu)} (Ctrl+S)
      </Button>
    </Space>
  )

  
  return (
    <FormDrawer
      open={open}
      width={'calc(100vw - 60px)'}
      title={i18n.t(languageKeys.quan_ly_vat_tu_hoa_chat)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
      footer={FormFooter}
    >
      <div style={{ margin: 10, padding: 10, background: "#F5F6FA", borderRadius: 10 }}>
        <ChiDinhVatTu
          planMode
          supplyList={supplyList}
          setSupplyList={setSupplyList}
          isRIS
        />
      </div>
    </FormDrawer>
  )
})

export default QlyVatTuRIS;