import { Button, Form, Input, Layout, Space, Spin, Tabs, Row, notification, Col, Radio, Empty, Collapse, Tooltip } from "antd";
import { useImperativeHandle, useRef, useCallback, useState, forwardRef, memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal, FormDrawer, ModalPDF } from "../../../components";
import LichSuKhamBenhComponent from "../../../components/LichSuKhamBenh/LichSuKhamBenhComponent";
import { apis, paths } from "../../../constants";
import { HLog, blurAll, hasDuplicateValues, isEmptyObject, rid } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import { fieldTiepDon } from "../fieldsTiepDon";
import ThongTinBenhNhan from "../components/ThongTinBenhNhan/ThongTinBenhNhan";
import DrawerActions from "../ModalTiepDon/layouts/DrawerActions";
import style from "./modalTiepDon.module.less";
import { checkEditThongTinHanhChinh, showSaveBtns } from "./helpers";
import { useEventListener } from "../../../hooks";
import { useConfigTiepDon, useModalTiepDon } from "./modalTiepDon.hook";
import { TRANG_THAI_THANH_TOAN, widthDrawerKeys } from "../../../constants/keys";
import { useThongTinBn } from "../components/ThongTinBenhNhan/ttbn.hook";
import KhaiThacLichSuBenh from "../components/KhaiThacLichSuBenh";
import { GoiDichVu, TabKhamDichVu } from "./Tabs";
import classNames from "classnames";
import { ArrowDownBold } from "assets/svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import { TiepDonActions, tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import { doFetchServiceTypes, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { getExemptionSuccess } from "ducks/slices/ListData/Exemption.slice";
import ModalTTBN from "components_v2/ModalTTBN";
import { updateResetSelectTab } from "ducks/slices/dataSlice";
import { chatAiActions } from "ducks/slices/chatAiSlice";
import ChatAi from "features/ChatAi/ModalChatAi";
import RenderOptionSpecialist from "./components/RenderOptionSpecialist";
import _ from "lodash";
import FastSwitchButtons from "components_v2/FastSwitchButtons";
import ModalThanhToan from "./components/ModalThanhToan/ModalThanhToan";
import { useHistory } from "react-router-dom";
import ModalChonSo from "./components/ModalChonSo";
import NewWarningModal from "./components/ModalCheckDuplicate";
import { PrinterOutlined } from "@ant-design/icons";
import ModalBarcode from "./components/ModalBarcode/ModalBarcode";
import ModalBarcodeOngMau from "./components/ModalBarcode/ModalBarcodeOngMau";

const initOffset = 180;

export const TAB_KHAM_BENH = i18n.t(languageKeys.field_Kham_benh) + "\n (Ctrl + K)";
export const TAB_DICH_VU = i18n.t(languageKeys.dich_vu) + "\n (Ctrl + E)";

export const TAB_THONG_TIN_KB = "1";
export const TAB_LICH_SU_KB = "2";

export const tabs = [TAB_KHAM_BENH, TAB_DICH_VU];

const examTabTypes = {
  TAB_KHAM_DICH_VU: "TAB_KHAM_DICH_VU",
  TAB_KHAM_BHYT: "TAB_KHAM_BHYT",
  TAB_GOI_DICH_VU: "TAB_GOI_DICH_VU",
};

let ModalTiepDon = ({ onSuccess = () => {}, checkPermisson, printReceipt }, ref) => {

  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.user);
  const useAi = useSelector(s => s.auth.useAi);
  const { loaiDichVu, kham_benh } = useSelector((state) => state.data);
  const { todayDoctors, customerTarget } = useSelector(getSharedData);
  const { visibleSubActions, dsGoiDvBenhNhan, duplicateService } = useSelector(tiepDonState);
  const { NGUOI_TIEP_DON } = useSelector((state) => state.snapShot.temp_data);

  const duplicateInfoRef = useRef();
  const pdfRef = useRef();
  const ttbnRef = useRef();
  const formRef = useRef();
  const siderDvRef = useRef();
  const refLichSuKB = useRef();
  const wrapTableRef = useRef();
  const thanhToanRef = useRef();
  const beforeSubmitRef = useRef();
  const overlimitRef = useRef();
  const modalHuySID = useRef();

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [lichKhamInfo, setLichKhamInfo] = useState();
  const [examType, setExamType] = useState(examTabTypes.TAB_KHAM_DICH_VU);
  const [disabledClick, setDisabledClick] = useState(false)
  //
  const [bundleReceipts, setBundleReceipts] = useState([])
  const [bookId, setBookId] = useState()
  const [bundleOverlimit, setBundleOverlimit] = useState([])
  const [loadingChecking, setLoadingChecking] = useState(false);
  const [totalExp, setTotalExp] = useState(0);

  const { dsDoiTuong, dsNguonKhach, getNguonKhachHieuLuc, loadingNguonKhach } = useThongTinBn({ visible });
  const { doCreateAppointment, doUpdateAppointment, loadingSubmit, setLoadingSubmit, formatTempDataPrint } = useConfigTiepDon({ duplicateInfoRef, form, totalExp });
  const optionsPrintSID = useRef()
  const optionsPrintSIDOngMau = useRef()
  const [formSID] = Form.useForm();
  const history = useHistory();

  const scrollToview = () => {
    try {
      if (wrapTableRef.current) {
        wrapTableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    } catch (error) {
      HLog(error);
    }
  };

  const {
    selectedPatient,
    dsDvKhamBenhDaChon,
    dsDvClsDaChon,
    checkedContinueAdding,
    currentTab,
    checkPrint,
    editData,
    widthTiepDon,
    currentTabView,
    hideDrawer,
    batBuoc,
    selectedNguonKhach,
    openTTBN,
    openKhaiThac,
    hiddenDetailInfo,
    offsetHeight,
    txtNote,
    // selectedDoiTuong,
    luuMau,
    //================================================
    setLuuMau,
    handleSetRequire,
    handleHideParent,
    getAppointmentInfo,
    setDsDvKhamBenhDaChon,
    setSelectedPatient,
    // setSelectedDoiTuong,
    setDsDvClsDaChon,
    setCheckedContinueAdding,
    setCurrentTab,
    setCheckPrint,
    setEditData,
    setWidthTiepDon,
    setCurrentTabView,
    setHideDrawer,
    setBatBuoc,
    setSelectedNguonKhach,
    setOpenTTBN,
    setOpenKhaiThac,
    setHiddenDetailInfo,
    setTxtNote,
    handleSelectPatient,
    handleClearPatient,
    readOnly,
    allowAddNewServices,
    layDsIdDvDaChon,
    getPacketList,
    layBacSiThucHienTheoNgay,
    //====================================
    selectedGoiService,
    setGoiSelectedService,

    setBarcodeQuanLy,
    barcodeQuanLy,
    setStatusDuyetSID,
    statusDuyetSID,
    onHuyBarcode,
    handleHuyBarcode,
    getSIDApi,
    handleSelectNguonkhach,
    loadingInit

  } = useModalTiepDon({
    dsNhanSu: NGUOI_TIEP_DON,
    dsDoiTuong,
    dsNguonKhach,
    userProfile,
    pdfRef,
    ttbnRef,
    refLichSuKB,
    siderDvRef,
    formRef,
    wrapTableRef,
    form,
    visible,
    lichKhamInfo,
    initOffset,
    //================================================
    getNguonKhachHieuLuc,
    setLoadingSubmit,
    setLichKhamInfo,
    scrollToview,
    setVisible,
    setExamType,
    setBundleReceipts,
    modalHuySID,
    setTotalExp
  });

  // reset store
  useEffect(() => dispatch(TiepDonActions.resetState()), []);

  const configBarcode = useMemo(() => {
    // return false
    try {
      return userProfile.config.IN_BARCODE_NANG_CAO
    } catch (error) {
      return false;
    }
  }, [userProfile])

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    visible,
  }));

  const layDsLoaiDichVu = async () => {
    try {
      dispatch(doFetchServiceTypes());
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (data) => {
    // console.log("LỊCH KHÁM INFFOOOOO : ", data);
    (!loaiDichVu?.length && useAi) && layDsLoaiDichVu();
    !todayDoctors && layBacSiThucHienTheoNgay();
    setVisible(true);
    setLichKhamInfo(data);
    dispatch(TiepDonActions.setSelectedLichKham(data));
    if (data) {
      setHiddenDetailInfo(true);
      setEditData(true);
    } else {
      setEditData(false);
    }
    if (data) {
      if (!!data.GOI_DV_ID) {
        setExamType(examTabTypes.TAB_GOI_DICH_VU);
        // setTimeout(() => {
        //   getAppointmentInfo(data, true);
        // }, 100);
      } else {
        // setTimeout(() => {
        //   getAppointmentInfo(data, true);
        // }, 100);
      }
      getAppointmentInfo(data, true)
      // layTongTinThanhToan({ BENH_NHAN_ID: data?.BENH_NHAN_ID });
    }
    setTimeout(() => {
      ttbnRef.current?.init();
    }, 100);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onGetListNguon = useCallback(() => {
    return getNguonKhachHieuLuc();
  }, [useThongTinBn]);

  // reset state khi đóng giao diện hoặc lưu xong (luôn luôn thực thi)
  const resetState = () => {
    ttbnRef.current?.reset();
    siderDvRef.current?.reset();
    setCurrentTabView(TAB_THONG_TIN_KB);
    setCurrentTab(tabs[0]);
    setOpenTTBN(true);
    setOpenKhaiThac(false);
    setTxtNote("");
    setLichKhamInfo();
    setSelectedPatient();
    setLuuMau(false);
    setDsDvKhamBenhDaChon([]);
    setDsDvClsDaChon([]);
    setCheckPrint(false);
    setHideDrawer(false);
    setBatBuoc(false);
    setExamType(examTabTypes.TAB_KHAM_DICH_VU);
    dispatch(TiepDonActions.resetState());
    setGoiSelectedService([])
    dispatch(getExemptionSuccess([]));
    setSelectedNguonKhach();
    setBundleReceipts([])
    setBookId()
    setBundleOverlimit([])
    setTotalExp(0)
  };

  // reset giao diện khi nhấn lưu với tùy chọn nhập liên tục
  const handleCheckContinuously = () => {

    form.resetFields();
    dispatch(updateResetSelectTab(false))
    // if (!!dsNguonKhach.length && dsNguonKhach.length < 2) {
    //   let firstNguonKhach = dsNguonKhach[0];
    //   firstNguonKhach = { ID: firstNguonKhach.ID, TEN_NGUON_KHACH: firstNguonKhach.TEN_NGUON_KHACH };
    //   form.setFields([{ name: fieldTiepDon.DM_NGUON_KHACH_ID, value: firstNguonKhach.TEN_NGUON_KHACH }]);

    //   !selectedNguonKhach &&
    //     setSelectedNguonKhach(() => {
    //       return JSON.stringify(firstNguonKhach);
    //     });
    // }

    ttbnRef.current?.fillQuocGiaVaDanToc();
  };

  // nhấn nút thoát (ESC);
  const handleClose = (doClose = true) => {
    setBarcodeQuanLy(null)
    setStatusDuyetSID(false)
    dispatch(updateResetSelectTab(true))

    doClose && setVisible(false);
    dispatch(chatAiActions.setOpen(false));
    dispatch(chatAiActions.setData({}));
    resetState();
    setTimeout(() => {
      if (!doClose) {
        handleCheckContinuously();
      } else {
        form.resetFields();
        dispatch(updateResetSelectTab(false))
      }
    }, 300);
    setEditData(false)
    if (lichKhamInfo?.runReload) onSuccess();
  };

  const handlePrint = (id, list_DV_ID = []) => {
    if (lichKhamInfo) {
      let LICH_KHAM_ID = null;
      if (!!id) {
        LICH_KHAM_ID = id;
      } else if (!!lichKhamInfo && !isEmptyObject(lichKhamInfo)) {
        LICH_KHAM_ID = lichKhamInfo.LICH_KHAM_ID;
      }
      if (!!LICH_KHAM_ID) {
        const dataRequest = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          LICH_KHAM_ID,
          list_DV_ID,
        };
        const api = apis.lay_data_in_phieu_tiep_don_v2;
        pdfRef.current.openModal(dataRequest, api, { isTiepDon: 1 });
      } else {
        HLog("Chua co lich kham ID");
      }
    } else if (id) {
      const dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LICH_KHAM_ID: id,
      };
      const api = apis.lay_data_in_phieu_tiep_don_v2;
      pdfRef.current.openModal(dataRequest, api, { isTiepDon: 1 });
    }
    else {
      if (dsDvKhamBenhDaChon.concat(dsDvClsDaChon).every(i => i?.PHONG_THUC_HIEN_ID)) {
        pdfRef.current?.openModalWithData(formatTempDataPrint(dsDvKhamBenhDaChon.concat(dsDvClsDaChon)), "phieuTiepDonMoi")
      } else {
        notification.warning({
          message: i18n.t(languageKeys.noti_Chua_chon_phong_thuc_hien),
          placement: 'bottomLeft'
        })
      }
    }
  };

  const isRemainDup = () => {
    const needCheckField = [fieldTiepDon.ten_benh_nhan, fieldTiepDon.ngay_sinh, fieldTiepDon.so_dien_thoai]
    const errorArr = form.getFieldsError(needCheckField);
    const errorField = errorArr?.find(err => err?.errors?.length);
    if (errorField) {
      notification.error({
        message: i18n.t(languageKeys.trung_thong_tin),
        placement: 'bottomLeft'
      })
      return true;
    }
  }

  // hàm xử lý phím tắt
  function keydownEvent(event) {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    if (event.key === "Escape" && !visibleSubActions) {
      stopDefaultAction(event);
      if (refLichSuKB.current?.visibleDetailLuotDieuTri) {
        return;
      }
      handleClose();
    }

    // if (showSaveBtns(lichKhamInfo) && !hideDrawer) {
    //   if (event.ctrlKey && event.key === "i" && !!lichKhamInfo) {
    //     stopDefaultAction(event);
    //     handlePrint(lichKhamInfo.LICH_KHAM_ID, layDsIdDvDaChon());
    //   } else if (event.ctrlKey && event.key === "s") {
    //     stopDefaultAction(event);
    //     form.submit();
    //   } else if (event.ctrlKey && event.key === "l") {
    //     stopDefaultAction(event);
    //     handelSaveAndPrint();
    //   }
    // }
  }

  useEventListener("keydown", keydownEvent, window, visible);

  const setBirthPatient = (date, customFormat = 'YYYYMMDD') => {
    ttbnRef.current?.setDate(date, customFormat);
  }

  /**
   * @description chọn bệnh nhân và lấy danh sách gói theo bệnh nhân
   * @param {Object} patient 
   */
  const onSelectPatientAndFetchPack = (patient) => {
    setGoiSelectedService([])
    handleSelectPatient(patient);
    getPacketList(patient);
    // layTongTinThanhToan({ BENH_NHAN_ID: patient.BENH_NHAN_ID });
  }

  const handleChooseOldPatient = (patient) => {
    onSelectPatientAndFetchPack(patient);
    duplicateInfoRef?.current?.setVisible(false);
  }

  const handleFormChange = (values) => {
    const needCheckField = [fieldTiepDon.ten_benh_nhan, fieldTiepDon.ngay_sinh, fieldTiepDon.so_dien_thoai]
    if (needCheckField.includes(Object?.keys(values)[0])) {
      const errorArr = form.getFieldsError(needCheckField);
      errorArr.forEach(err => {
        if (err?.errors?.includes(i18n.t(languageKeys.trung_thong_tin))) {
          form.validateFields([err?.name[0]])
        }
      })
    }
  }

  //================================================================================================
  const Navbar = () => {
    return (
      <Row style={{ padding: 15 }} justify={"center"}>
        <Col>
          <Radio.Group onChange={(e) => setExamType(e.target.value)} value={examType} className={style["radio-tabs"]}>
            <Radio.Button
              // disabled={disableTabs(examTabTypes.TAB_KHAM_DICH_VU)}
              key={"asdsa-1"}
              className={"radio-tabs-btn"}
              value={examTabTypes.TAB_KHAM_DICH_VU}
            >
              {i18n.t(languageKeys.kham_dich_vu)}
            </Radio.Button>
            {/* <Radio.Button key={'asdshba-2'} className={'radio-tabs-btn'} value={examTabTypes.TAB_KHAM_BHYT}>{i18n.t(languageKeys.kham_bhyt)}</Radio.Button> */}
            <Radio.Button
              key={"asds-3"}
              className={"radio-tabs-btn"}
              value={examTabTypes.TAB_GOI_DICH_VU}
            >
              {i18n.t(languageKeys.kham_goi_lieu_trinh)}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    );
  };

  const maxlengthSID = useMemo(() => {
    if (userProfile?.config?.BARCODE_XET_NGHIEM != "BARCODE_VINH_VIEN") {
      return 5;
    }
    return userProfile?.config?.BARCODE_LENGTH || null;
  }, [userProfile]);
  //===============================================renderĐanhSachChonDichVu================================================

  const groupByBenhBenh = () => {
    let dichVuXetNghiem = []
    let arrayData = dsDvClsDaChon?.filter(obj => obj.PHAN_LOAI == "XET_NGHIEM" || obj.PHAN_LOAI == "COMBO_DV")

    let selectedGoiServiceCv = selectedGoiService?.filter(objFt => (objFt.PHAN_LOAI == "XET_NGHIEM" || objFt.PHAN_LOAI == "COMBO_DV" || objFt.MA_NHOM == "XET_NGHIEM"))
    if (selectedGoiServiceCv?.length > 0) {
      arrayData = arrayData.concat(selectedGoiServiceCv)
    }

    if (arrayData?.length > 0) {
      arrayData.forEach(e => {
        if (e.PHAN_LOAI == "XET_NGHIEM" || !e?.DV_KEM_THEO) {
          dichVuXetNghiem?.push({ PHAN_LOAI: e.PHAN_LOAI, TEN_DICHVU: e?.TEN_DICHVU || e?.TEN_DV, TEN_LOAI_BP: e?.TEN_LOAI_BP })
        } else {
          let dvKemTheo = (e?.DV_KEM_THEO || [])?.filter(obj => obj.PHAN_LOAI == "XET_NGHIEM")?.map(e => ({ PHAN_LOAI: e.PHAN_LOAI, TEN_DICHVU: e?.TEN_DICHVU || e?.TEN_DV || e?.TEN_DICH_VU_KEM_THEO, TEN_LOAI_BP: e?.TEN_LOAI_BP }))
          dichVuXetNghiem = dichVuXetNghiem?.concat(dvKemTheo)
        }

      });
    }

    let arrayOngMau = _.chain(dichVuXetNghiem)
      .groupBy(item => (item.TEN_LOAI_BP == null || item.TEN_LOAI_BP == "undefined") ? null : item.TEN_LOAI_BP)
      .map((value, key) => ({
        TEN_LOAI_BP: (key == "null" || key == "undefined") ? null : key, array: value, SO_LUONG: value?.length, TEN_KHACH_HANG: lichKhamInfo?.TEN_BENH_NHAN,
        GIOI_TINH: lichKhamInfo?.GIOI_TINH,
        NGAY_SINH: lichKhamInfo?.NGAY_SINH
      }))
      .value()

    return arrayOngMau || []

  }

  const handlePrintSID = async ({ data }) => {
    const value = await formSID.getFieldsValue(true);
    const sid = form.getFieldsValue(['BARCODE_XET_NGHIEM']);

    let dataSource = []

    try {
      optionsPrintSID?.current?.close?.();
      optionsPrintSIDOngMau?.current?.close?.();
    } catch (error) {
      console.log(error);
    }
    try {
      // if(configBarcode) {
      //   dataSource = groupByBenhBenh() || []
      //   // dataSource = refDsDichVu.current?.onGetDataOngMau?.();
      // } else {
      dataSource = data;
      // }
    } catch (error) {
      dataSource = [];
    }

    pdfRef.current?.openModalWithData({
      hospital: userProfile.benh_vien,
      sid: sid?.BARCODE_XET_NGHIEM,
      quality: value?.THUONG_NUMBER,
      dataSource
    }, "IN_BARCODE" + `${userProfile?.config?.BARCODE_PAGE_SIZE || 4}`)

    // dispatch(
    //   onPrint({
    //     template: "IN_BARCODE" + `${userProfile?.config?.BARCODE_PAGE_SIZE || 4}`,
    //     data: {
    //       hospital: userProfile.benh_vien,
    //       sid : sid?.BARCODE_XET_NGHIEM,
    //       quality: value?.THUONG_NUMBER,
    //       dataSource
    //     },
    //   }),
    // );
  };


  const handleModalPrint = async () => {

    try {
      const values = await form.getFieldsValue();
      let { BARCODE_XET_NGHIEM } = values;
      let barcode = BARCODE_XET_NGHIEM?.toString()?.trim();
      if (!barcode || barcode?.length === 0) {
        return;
      }

      if (configBarcode) {
        let dataSource = groupByBenhBenh() || []
        // console.log("dataSource",dataSource)
        optionsPrintSIDOngMau?.current?.openModal(dataSource)
        // handlePrintSID({});
        return
      } else {
        // optionsPrintSID?.current?.openModal?.();
        if (userProfile?.partner_code === 'greenlabuh') {
          formSID?.setFieldsValue({
            OPTION_IN: ["THUONG", "GLUCOSE"],
            THUONG_NUMBER: 3,
            GLUCOSE_NUMBER: 1
          })
        }
        optionsPrintSID?.current?.open?.();
      }

    } catch (error) {
      console.log("PRINT THONG TIN KQ XN :: ", error);
    }
  }

  const Sid = () => {

    return <div style={{ display: "flex", gap: 10, alignItems: "center", color: "#2c3782" }}><span>SID</span><Form.Item
      style={{ marginBottom: 0 }}
      name={"BARCODE_XET_NGHIEM"}
      rules={[
        {
          pattern: /^[0-9]*$/,
          message: i18n.t(languageKeys.nhap_dung_dinh_dang_so),
        },
      ]}
    >
      <Input
        readOnly={statusDuyetSID || barcodeQuanLy}
        maxLength={maxlengthSID}
        suffix={<Tooltip title="In mã SID">
          <Button size="small" type="primary" icon={<PrinterOutlined />} onClick={() => {
            handleModalPrint()
          }} />
        </Tooltip>}
      />
    </Form.Item>
      <Form.Item
        style={{ marginBottom: 0, display: "none" }}
        name={"BARCODE_QUAN_LY"}
      >
        <Input />
      </Form.Item>
      {!barcodeQuanLy && userProfile?.config?.BARCODE_XET_NGHIEM == "BARCODE_VINH_VIEN" && (
        <Space align="center" direction="vertical">
          <Button type="primary"
            onClick={() => getSIDApi()}
          >
            {i18n.t(languageKeys.cap)} SID
          </Button>
        </Space>
      )}
      {!statusDuyetSID && barcodeQuanLy && (
        <Space align="center" direction="vertical">
          <Button
            type="primary"
            onClick={() => onHuyBarcode()}
          >
            {i18n.t(languageKeys.common_Huy)} SID
          </Button>
        </Space>
      )}

    </div>
  }

  const renderTabs = () => {
    switch (examType) {
      case examTabTypes.TAB_KHAM_DICH_VU:
        return (
          <TabKhamDichVu
            //Biến
            form={form}
            tabs={tabs}
            luuMau={luuMau}
            txtNote={txtNote}
            visible={visible}
            readOnly={readOnly}
            openTTBN={openTTBN}
            hideDrawer={hideDrawer}
            siderDvRef={siderDvRef}
            currentTab={currentTab}
            openKhaiThac={openKhaiThac}
            lichKhamInfo={lichKhamInfo}
            dsDvClsDaChon={dsDvClsDaChon}
            dsDvKhamBenhDaChon={dsDvKhamBenhDaChon}
            allowAddNewServices={allowAddNewServices}
            checkedContinueAdding={checkedContinueAdding}
            setLoadingSubmit={setLoadingSubmit}
            //hàm
            onFinish={onFinish}
            onClose={handleClose}
            setLuuMau={setLuuMau}
            onPrint={handlePrint}
            setTxtNote={setTxtNote}
            setOpenTTBN={setOpenTTBN}
            showSaveBtns={showSaveBtns}
            setCurrentTab={setCurrentTab}
            setOpenKhaiThac={setOpenKhaiThac}
            layDsIdDvDaChon={layDsIdDvDaChon}
            setDsDvClsDaChon={setDsDvClsDaChon}
            setDsDvKhamBenhDaChon={setDsDvKhamBenhDaChon}
            setCheckedContinueAdding={setCheckedContinueAdding}
            //
            disableIn={!checkPermisson?.IN_PDF}
            disableDichVu={!checkPermisson?.SUA_DICH_VU}
            disableThemMoi={!checkPermisson?.THEM_MOI}
            disableBenhNhan={!checkPermisson?.SUA_BENH_NHAN}
            selectedGoiService={selectedGoiService}
            //
            disabledClick={disabledClick}
            //
            hightlighter={duplicateService?.hightLighter}
            checkShowModal={checkShowModal}
            duplicateSerArr={duplicateService?.list}
            // LIS
            sid={Sid}
            totalExp={totalExp}
            setTotalExp={setTotalExp}
          // setBarcodeQuanLy={setBarcodeQuanLy}
          // barcodeQuanLy={barcodeQuanLy}
          // statusDuyetSID={statusDuyetSID}
          // onHuyBarcode={onHuyBarcode}
          // maxlengthSID={maxlengthSID}
          // getSIDApi={getSIDApi}

          />
        );
      case examTabTypes.TAB_GOI_DICH_VU:
        return (
          <GoiDichVu
            form={form}
            hightlighter={duplicateService?.hightLighter}
            visible={visible}
            readOnly={readOnly}
            onFinish={onFinish}
            openTTBN={openTTBN}
            onClose={handleClose}
            onPrint={handlePrint}
            disableIn={!checkPermisson?.IN_PDF}
            hideDrawer={hideDrawer}
            currentTab={currentTab}
            setOpenTTBN={setOpenTTBN}
            openKhaiThac={openKhaiThac}
            disableDichVu={!checkPermisson?.SUA_DICH_VU}
            disableThemMoi={!checkPermisson?.THEM_MOI}
            disableBenhNhan={!checkPermisson?.SUA_BENH_NHAN}
            selectedPatient={selectedPatient}
            layDsIdDvDaChon={layDsIdDvDaChon}
            setOpenKhaiThac={setOpenKhaiThac}
            allowAddNewServices={allowAddNewServices}
            checkedContinueAdding={checkedContinueAdding}
            setCheckedContinueAdding={setCheckedContinueAdding}
            doUpdatePacketAppointment={doUpdateAppointment} //doUpdatePacketAppointment
            doCreatePacketAppointment={doCreateAppointment} //doCreatePacketAppointment
            doUpdateAppointment={doUpdateAppointment}
            selectedService={selectedGoiService}
            setSelectedService={setGoiSelectedService}
            dsDvClsDaChon={dsDvClsDaChon}
            dsDvKhamBenhDaChon={dsDvKhamBenhDaChon}
            txtNote={txtNote}
            setTxtNote={setTxtNote}
            onSelectPatient={handleSelectPatient}
            dsNguonKhach={dsNguonKhach}
            onClearPatient={handleClearPatient}
            setBirthPatient={setBirthPatient}
            //
            disabledClick={disabledClick}
            setExamType={setExamType}
            duplicateInfoRef={duplicateInfoRef}
            //
            bundleReceipts={bundleReceipts}
            setBundleReceipts={setBundleReceipts}
            checkShowModal={checkShowModal}
            lichKhamInfo={lichKhamInfo}
            setLichKhamInfo={setLichKhamInfo}
            checkPermisson={checkPermisson}

            // LIS

            sid={Sid}
            setBarcodeQuanLy={setBarcodeQuanLy}
            barcodeQuanLy={barcodeQuanLy}
            statusDuyetSID={statusDuyetSID}
            onHuyBarcode={onHuyBarcode}
            maxlengthSID={maxlengthSID}
            getSIDApi={getSIDApi}

          />
        );
      default:
        return <Empty />;
    }
  };
  //==========================================(MAIN)================================================================================================
  const handleSetList = (listID) => {
    try {
      let dsKb = [];
      for (const id of listID) {
        let item = kham_benh.find((e) => e.DICH_VU_ID === id);
        if (item) {
          item = {
            ...item,
            SO_LUONG: item.SO_LUONG || 1,
            _id: rid(7),
            key: rid(),
            GIA_DICH_VU: item.GIA_THU_PHI,
            NGUOI_TAO: userProfile,
            THANH_TIEN: item.THANH_TIEN,
            STT: undefined,
            ID: undefined,
            DM_DOI_TUONG_ID: customerTarget?.[0]?.ID

          };
          dsKb.push(item);
        }
      }

      const checkUnique = (arr) => {
        let result = [];

        result = _.uniqBy(arr, "DICH_VU_ID");
        return result;
      };
      setDsDvKhamBenhDaChon((prev) => {
        return checkUnique([...prev, ...dsKb]);
      });
      setTimeout(() => {
        dispatch(chatAiActions.setOpen(false));
      })
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 
   * @returns {{hasDuplicated: boolean, duplicatedItems: any[], uniqueValues: any[]}}
   */
  const handleCheckedDuplicated = () => {
    try {
      let checkDouble = checkDuplicateService([...dsDvKhamBenhDaChon, ...dsDvClsDaChon, ...selectedGoiService]);
      return checkDouble
    } catch (error) {
      return {
        hasDuplicated: false, duplicatedItems: [], uniqueValues: []
      };
    }
  }

  // nut lưu
  const onFinish = async (print, showModal, showNotiLimit = true, updateBundleReceipt = false, afterSubmit) => {
    try {
      let rsDuplicated = handleCheckedDuplicated();

      if (rsDuplicated.hasDuplicated) {
        // let arrTextDv = rsDuplicated.duplicatedItems.map((i) => i.TEN_DICHVU || i.TEN_DICH_VU_KEM_THEO).join(", ");
        dispatch(
          TiepDonActions.setDuplicateService({
            openWarnModal: true,
            list: rsDuplicated.duplicatedItems,
            finishProps: {
              print,
              showModal,
              showNotiLimit,
              updateBundleReceipt,
              afterSubmit,
            },
            contentWarning: (
              <div className="navy-txt">
                <span className="navy-txt">{i18n.t(languageKeys.warn_khach_hang_co_dich_vu_da_duoc_chi_dinh)}:</span>{" "}
                <ul style={{ textAlign: "left", marginBottom: 6, listStyleType: "unset" }}>
                  {rsDuplicated.duplicatedItems.map((i, ind) => (
                    <li key={ind} className="red-txt" style={{ textTransform: "uppercase", padding: 2 }}>
                      {i.TEN_DICHVU || i.TEN_DICH_VU_KEM_THEO || i.TEN_DV}
                    </li>
                  ))}
                </ul>{" "}
                {i18n.t(languageKeys.warn_ban_co_muon_tiep_tuc_chi_dinh_dich_vu)} ?
              </div>
            ),
          })
        );
        // duplicatedRef.current.open();
      } else {
        await handleFinish(print, showModal, showNotiLimit = true, updateBundleReceipt = false, afterSubmit);
      }
    } catch (error) {
      HLog(error);
    }

  };

  const checkDuplicateService = (listDv) => {
    try {
      const getFlat = () => {
        let rs = []
        for (const item of listDv) {
          rs.push(item);
          if (item.children?.[0]) {
            let childrens = item.children.map(i => ({ ...i, DV_ID: i.DV_KEM_THEO_ID }));
            rs.push(...childrens);
          }
        }
        return rs.map(i => ({ ...i, DV_ID: i.DV_ID || i.DICH_VU_ID }))
      }

      let cp_list = getFlat();

      let check1 = hasDuplicateValues(cp_list, 'DV_ID');
      return check1
    } catch (error) {
      console.log(error);
      throw new Error('Lỗi code');
    }
  }

  const handleFinish = async (print, showModal, showNotiLimit = true, updateBundleReceipt = false, afterSubmit) => {
    await form.validateFields();

    // Check miễn giảm
    if (totalExp > dsDvKhamBenhDaChon.concat(dsDvClsDaChon).reduce((sum, item) => sum + (item.SO_LUONG * item.GIA_DICH_VU), 0)) {
      return notification.error({
        message: i18n.t(languageKeys.error_Gia_tri_mien_giam_ko_hop_le),
        placement: "bottomLeft",
      })
    }

    // Check trùng
    if (isRemainDup()) return;

    // Check quá số nợ gói
    if (selectedGoiService.length && lichKhamInfo && showNotiLimit) {
      const grouped = Object.values(selectedGoiService.reduce((acc, item) => {
        acc[item.GOI_ID] = [...(acc[item.GOI_ID] || []), item];
        return acc;
      }, {}))
      let arrOverlimit = []
      grouped.forEach(arr => {
        const bundle = dsGoiDvBenhNhan.find(i => i.GOI_DV_ID === arr[0].GOI_ID)
        const debtLimit = bundle?.TONG_TIEN_PHAN_BO / 100 * userProfile.config?.PHAN_TRAM_NO_GOI_CHO_PHEP
        // Check đã TT và điều kiện nợ
        if (bundle?.IS_NO && bundle?.BENH_NHAN_DUA < debtLimit) {
          let total = arr.reduce((cur, obj) => cur + obj.DON_GIA_PHAN_BO, 0)
          if (total > debtLimit) {
            arrOverlimit.push(bundle.TEN_GOI_DV)
          }
        }
      })
      if (arrOverlimit.length) {
        setBundleOverlimit(arrOverlimit)
        overlimitRef.current?.open({ print, afterSubmit })
        return;
      }
    }

    // Noti tạo phiếu
    if (showModal) {
      beforeSubmitRef.current?.open({ print, afterSubmit })
      return;
    }

    // Lưu
    try {
      let res = await onSubmit(afterSubmit, updateBundleReceipt);
      if (afterSubmit) return;
      if (print) handlePrint(res.LICH_KHAM_ID);

      if (!lichKhamInfo) {
        if (!!res) {
          handleClose(!checkedContinueAdding);
          if (checkPrint) handlePrint(res.LICH_KHAM_ID);
        } else return
      } else {
        if (!!res) {
          handleClose();
          if (checkPrint) handlePrint();
        } else return
      }
      onSuccess();
    } catch (e) {
      HLog("Luuwwwwwwwww", e);
      setLoadingChecking(false);
    } finally {
      setLoadingChecking(false);
    }
  }

  const onConfirmDuplicatedWarn = () => {
    setLoadingChecking(true);
    try {
      setTimeout(() => {
        dispatch(TiepDonActions.setDuplicateService({
          list: [],
          openWarnModal: false,
          finishProps: {},
          contentWarning: '',
          hightLighter: false,
        }))

        let { finishProps } = duplicateService;
        let { print, showModal, showNotiLimit, updateBundleReceipt, afterSubmit } = finishProps;
        handleFinish(print, showModal, showNotiLimit, updateBundleReceipt, afterSubmit);
      }, 400)
    } catch (error) {
      HLog(error)
      setLoadingChecking(false);
    }
  }
  //================================================================NEW FEATURE==========================================================================

  const onSubmit = async (afterSubmit, updateBundleReceipt) => {
    setDisabledClick(true)
    try {
      let value = await form.validateFields();
      if (isRemainDup()) return;

      let list_dv = updateBundleReceipt ? selectedGoiService : bundleReceipts
      list_dv = list_dv
        .filter(i => !i.THANH_TOAN)
        .map(item => ({
          PDT_ID: item.PDT_ID || null,
          DV_ID: item.DV_ID || item.DICH_VU_ID,
          GOI_ID: item.GOI_ID,
          SO_LUONG: 1,
          DON_GIA: item.DON_GIA_PHAN_BO,
          PHAN_LOAI: item.PHAN_LOAI,
          IS_GOI_DV: 1,
          TONG_TIEN: item.DON_GIA_PHAN_BO,
          // RLS_BN_GOI_DV_ID: item.RLS_BN_GOI_DV_DVCON_ID ? item.RLS_BN_GOI_DV_ID : null, // cái cũ
          RLS_BN_GOI_DV_ID: item?.RLS_BN_GOI_DV_ID,
          RLS_BN_GOI_DV_DVCON_ID: item?.RLS_BN_GOI_DV_DVCON_ID,
          MIEN_GIAM: item.MIEN_GIAM,
        }))

      let res;
      value.DM_NGUON_KHACH_ID = selectedNguonKhach;
      if (!lichKhamInfo) {
        if (selectedPatient) {
          delete selectedPatient.LICH_KHAM_ID;
        }
        res = await doCreateAppointment(
          {
            ...selectedPatient,
            ...value,
            GHI_CHU: txtNote,
            IS_MAU: luuMau,
            BARCODE_XET_NGHIEM: value.BARCODE_XET_NGHIEM
          },
          dsDvKhamBenhDaChon,
          dsDvClsDaChon,
          selectedGoiService,
          {
            SO_HOADON_ID: bookId,
            list_dv,
          }
        );
      } else {
        res = await doUpdateAppointment(
          {
            ...lichKhamInfo,
            ...selectedPatient,
            ...value,
            GHI_CHU: txtNote,
            IS_MAU: luuMau,
            BARCODE_XET_NGHIEM: value.BARCODE_XET_NGHIEM
          },
          dsDvKhamBenhDaChon,
          dsDvClsDaChon,
          selectedGoiService,
          {
            SO_HOADON_ID: bookId,
            list_dv,
          }
        );
      }

      if (res) {
        if (afterSubmit === 'openPayment') {
          thanhToanRef.current?.onOpen(lichKhamInfo
            ? { ...res, LICH_KHAM_ID: lichKhamInfo.LICH_KHAM_ID, BENH_NHAN_ID: lichKhamInfo.BENH_NHAN_ID, PHONG_ID: dsDvKhamBenhDaChon?.[0]?.PHONG_THUC_HIEN_ID }
            : { ...res, PHONG_ID: dsDvKhamBenhDaChon?.[0]?.PHONG_THUC_HIEN_ID })
        }
        if (afterSubmit === 'goToExam') {
          let phongID = dsDvKhamBenhDaChon?.[0]?.PHONG_THUC_HIEN_ID || selectedGoiService.filter(i => i.PHAN_LOAI === 'KHAM_BENH')?.[0].PHONG_THUC_HIEN_ID
          history.push(`${paths.quan_ly_kham_benh}?ma_benh_nhan=${lichKhamInfo ? lichKhamInfo.BENH_NHAN_ID : res.BENH_NHAN_ID}&phong=${phongID}&trang_thai=${languageKeys.status_Tat_ca}`)
        }
        if (afterSubmit === 'goToSubclinical') {
          history.push(`${paths.quan_ly_dv_chi_dinh}?ma_benh_nhan=${lichKhamInfo ? lichKhamInfo.BENH_NHAN_ID : res.BENH_NHAN_ID}&trang_thai=${languageKeys.status_Tat_ca}}`)
        }
      }
      return res;
    } catch (error) {
      console.log(error)
    } finally {
      setDisabledClick(false)
    }
  };

  const checkDisabledPayment = () => {
    if (lichKhamInfo) {
      if (lichKhamInfo?.GOI_TT > 0) {
        return false;
      }
      else if (lichKhamInfo.TRANG_THAI_THANH_TOAN === "CHUA_THANH_TOAN") {
        return false
      } else {
        return !(dsDvKhamBenhDaChon.filter(i => !i.old).length > 0 || dsDvClsDaChon.filter(i => !i.old).length > 0 || selectedGoiService.filter(i => !i.THANH_TOAN).length > 0)
      }
    } else {
      return (dsDvKhamBenhDaChon.length < 1 && dsDvClsDaChon.length < 1 && selectedGoiService.length < 1)
    }
  }

  const checkDisabledExam = () => {
    if (dsDvKhamBenhDaChon.length < 1 && !selectedGoiService.flatMap(i => i.PHAN_LOAI).includes('KHAM_BENH')) {
      return true
    } else {
      if (lichKhamInfo) {
        if (dsDvKhamBenhDaChon.some(i => !i.THANH_TOAN) || selectedGoiService.filter(i => i.PHAN_LOAI === 'KHAM_BENH').some(i => !i.THANH_TOAN)) {
          return userProfile.config?.THANH_TOAN_TRUOC_KHAM
            ? !bundleReceipts.some(i => i.PHAN_LOAI === 'KHAM_BENH')
            : false
        } else {
          return false
        }
      } else {
        return userProfile.config?.THANH_TOAN_TRUOC_KHAM
          ? !bundleReceipts.some(i => i.PHAN_LOAI === 'KHAM_BENH')
          : false
      }
    }
  }

  const checkDisabledSubclinical = () => {
    if (dsDvClsDaChon.length < 1 && selectedGoiService.filter(i => i.PHAN_LOAI !== 'KHAM_BENH').length < 1) {
      return true
    } else {
      if (lichKhamInfo) {
        if (dsDvClsDaChon.some(i => !i.THANH_TOAN) || selectedGoiService.filter(i => i.PHAN_LOAI !== 'KHAM_BENH').some(i => !i.THANH_TOAN)) {
          return userProfile.config?.THANH_TOAN_TRUOC_KHAM
            ? !bundleReceipts.filter(i => i.PHAN_LOAI !== 'KHAM_BENH').length > 0
            : false
        } else {
          return false
        }
      } else {
        return userProfile.config?.THANH_TOAN_TRUOC_KHAM
          ? !bundleReceipts.filter(i => i.PHAN_LOAI !== 'KHAM_BENH').length > 0
          : false
      }
    }
  }

  const checkShowModal = selectedGoiService.length > 0
    ? selectedGoiService.every(i => i.THANH_TOAN)
      ? false
      : userProfile.config?.THANH_TOAN_TRUOC_KHAM
        ? true
        : bundleReceipts.length > 0
    : false;

  //===================================================================================================================================================

  return (
    <>
      <FormDrawer
        bodyStyle={{ overflowX: "hidden" }}
        back={true}
        newBackIcon={true}
        width={widthDrawerKeys.large}
        visible={visible}
        // title={editData ? 'Chi tiết' : 'Tiếp đón'}
        title={editData ? i18n.t(languageKeys.chi_tiet_tiep_don_kham_chua_benh) : i18n.t(languageKeys.label_Tiep_don_kham_chua_benh)}
        // extraTitle={
        //   selectedPatient?.MA_BENH_NHAN && thongTinThanhToan && (
        //     <div style={{ width: "calc(100vw - 800px)", display: "flex", justifyContent: "center" }}>
        //       <Spin spinning={loadingTT}>
        //         <ThongTinThanhToanBenhNhan data={{ ...thongTinThanhToan, BENH_NHAN_ID: selectedPatient?.ID || lichKhamInfo?.BENH_NHAN_ID }} />
        //       </Spin>
        //     </div>
        //   )
        // }
        onCancel={handleClose}
        hiddenTopAction={true}
        className={classNames(widthTiepDon ? style["visibility"] : style["nothing"], style["wrapper-formDrawer"])}
        headerClass={style["header"]}
        keyboard={false}
        footer={
          currentTabView === TAB_LICH_SU_KB ? (
            <Row justify="end">
              <Button type="primary" onClick={handleClose} style={{ width: 120 }} ghost>
                {i18n.t(languageKeys.common_Thoat)} (ESC)
              </Button>
            </Row>
          ) : (
            false
          )
        }
        extra={!(lichKhamInfo?.TRANG_THAI_HIEN_TAI === 'HUY' || lichKhamInfo?.TRANG_THAI_HIEN_TAI === 'HOAN_THANH') && (
          <FastSwitchButtons
            handlePayment={() => onFinish(false, checkShowModal, true, false, 'openPayment')}
            loading={disabledClick}
            showPayment
            showExam
            handleGoToExam={() => onFinish(false, checkShowModal, true, false, 'goToExam')}
            handleGoToSubclinical={() => onFinish(false, checkShowModal, true, false, 'goToSubclinical')}
            disabledPayment={checkDisabledPayment()}
            disabledExam={checkDisabledExam()}
            disabledSubclinical={checkDisabledSubclinical()}
          />
        )}
      >
        <Tabs
          activeKey={currentTabView}
          onChange={setCurrentTabView}
          className={style["tab-ctn"]}
          tabBarExtraContent={!lichKhamInfo && (
            <DrawerActions
              onSelectPatient={onSelectPatientAndFetchPack}
              showInsuranceBtns={false}
              disbleButtonDefault={true}
              visible={visible}
              focusNameInput={() => ttbnRef.current?.init()}
              clearInfo={() => {
                dispatch(updateResetSelectTab(true));
                setTimeout(() => {
                  setSelectedPatient();
                  // dispatch(TiepDonActions.setSelectedPacket());
                  dispatch(TiepDonActions.setDsGoiDvBenhNhan([]));
                  setCurrentTabView(TAB_THONG_TIN_KB);
                  form.resetFields();
                  blurAll()
                  dispatch(updateResetSelectTab(false));
                  setGoiSelectedService([])
                }, 300);
              }}
            />
          )}
        >
          <Tabs.TabPane tab={i18n.t(languageKeys.thong_tin_kham_benh)} key={TAB_THONG_TIN_KB}>
            <Spin spinning={loadingSubmit || loadingInit}>

              <div
                style={{ minHeight: "calc(100vh - 89px)" }}
                className={style["wrapper-tabs"]}
              >
                <Form
                  layout="vertical"
                  form={form}
                  className={style["wrapper"]}
                  ref={formRef}
                  onFinishFailed={({ _, errorFields, __ }) => {
                    !openTTBN && setOpenTTBN(true);
                    notification.error({
                      message: errorFields[0].errors[0],
                      placement: "bottomLeft",
                    });
                  }}
                  disabled={!checkPermisson?.SUA_BENH_NHAN}
                  onValuesChange={handleFormChange}
                >
                  <div className={style["wrapper-collapse"]}>
                    <Collapse
                      activeKey={openTTBN ? [`${openTTBN}`] : []}
                      ghost
                      onChange={(e) => (e.length ? setOpenTTBN(true) : setOpenTTBN(false))}
                      className={style["collapse-ttbn"]}
                      expandIcon={({ isActive }) => <Icon component={ArrowDownBold} rotate={isActive ? 0 : -90} style={{ fontSize: 8 }} />}
                    >
                      <Collapse.Panel
                        key={"true"}
                        header={i18n.t(languageKeys.thong_tin_benh_nhan)}
                        forceRender
                        extra={selectedPatient && (
                          <Space>
                            <span className="navy-txt">{i18n.t(languageKeys.field_Ma_benh_nhan)}:</span>
                            <Input readOnly value={selectedPatient.MA_BENH_NHAN} className={style["panel-input"]} />
                          </Space>
                        )}
                      >
                        <ThongTinBenhNhan
                          dividerProps={{
                            className: style["expand-icon"],
                          }}
                          ref={ttbnRef}
                          form={form}
                          extraClassName={style["wrapper-ttbn"]}
                          setSelectedNguonKhach={handleSelectNguonkhach}
                          // readOnly={!showSaveBtns(lichKhamInfo) || readOnly || !checkDangKhamHoacKhamXong(lichKhamInfo)}
                          readOnly={readOnly || !checkEditThongTinHanhChinh(lichKhamInfo)}
                          horizontal
                          isCreateNew={!lichKhamInfo}
                          visible={visible}
                          ObjectList={dsDoiTuong}
                          dsNguonKhach={dsNguonKhach}
                          onHideParent={handleHideParent}
                          onGetListNguon={onGetListNguon}
                          batBuoc={batBuoc}
                          onBatBuoc={handleSetRequire}
                          handleErrorOpenForm={() => {}}
                          hidePatientInfo={hiddenDetailInfo}
                          onHidePatientInfo={(bool) => setHiddenDetailInfo(bool)}
                          showHideDetail
                          triggerOnHidden={scrollToview}
                          disableBenhNhan={!checkPermisson?.SUA_BENH_NHAN}
                          onSelectPatient={!lichKhamInfo ? onSelectPatientAndFetchPack : undefined}
                          onClearPatient={!lichKhamInfo ? handleClearPatient : () => {}}
                          disableThemMoi={!checkPermisson?.THEM_MOI}
                          getNguonKhachHieuLuc={getNguonKhachHieuLuc}
                          loadingNguonKhach={loadingNguonKhach}
                          showAi
                        />
                      </Collapse.Panel>
                    </Collapse>

                    {visible && (
                      <ChatAi
                        key={"tiep-don"}
                        title={i18n.t(languageKeys.de_xuat_dich_vu)}
                        component={({ data }) => <RenderOptionSpecialist data={data} onSetList={handleSetList} />}
                      />
                    )}
                    <Collapse
                      activeKey={openKhaiThac ? [`${openKhaiThac}-2`] : []}
                      ghost
                      onChange={(e) => (e.length ? setOpenKhaiThac(true) : setOpenKhaiThac(false))}
                      className={style["collapse-ttbn"]}
                      expandIcon={({ isActive }) => <Icon component={ArrowDownBold} rotate={isActive ? 0 : -90} style={{ fontSize: 8 }} />}
                    >
                      <Collapse.Panel key={"true-2"} header={i18n.t(languageKeys.khai_thac_benh_su)} forceRender>
                        <div style={!openKhaiThac ? { visibility: "hidden", opacity: 0, position: "fixed", paddingLeft: 10, paddingRight: 10 } : { paddingLeft: 10, paddingRight: 10 }}>
                          <KhaiThacLichSuBenh form={form} readOnlyInput={readOnly || checkDisableInput(lichKhamInfo, true)} />
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </div>

                  <Layout
                    style={{ minHeight: `calc(100vh - ${offsetHeight}px)`, margin: "0 8px", borderRadius: 10 }}
                    ref={wrapTableRef}
                  >
                    <Layout.Header style={{ background: "transparent" }}><Navbar /></Layout.Header>
                    {loadingInit ? <></> : <Layout>{renderTabs()}</Layout>}
                  </Layout>
                </Form>
              </div>
            </Spin>
          </Tabs.TabPane>

          {(selectedPatient || lichKhamInfo) && (
            <Tabs.TabPane
              tab={i18n.t(languageKeys.Lich_su_kham_benh)}
              key={TAB_LICH_SU_KB}
              style={{ backgroundColor: "#F5F7FB", height: "calc(100vh - 140px)" }}
            >
              <LichSuKhamBenhComponent
                BENH_NHAN_ID={selectedPatient?.ID || lichKhamInfo?.BENH_NHAN_ID}
                styleContainer={{ padding: "10px 25px" }}
                lichSuDrawerVisible={setWidthTiepDon}
                ref={refLichSuKB}
                patientInfo={selectedPatient}
                isVisible={currentTabView === TAB_LICH_SU_KB}
              />
            </Tabs.TabPane>
          )}
        </Tabs>

        <ModalPDF ref={pdfRef} configCheck="NHIEU_PHIEU_CHIDINH_CLS" />
        <ModalTTBN
          ref={duplicateInfoRef}
          onOk={handleChooseOldPatient}
          onCancel={() => form.setFields([
            {
              name: fieldTiepDon.ten_benh_nhan,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
            {
              name: fieldTiepDon.so_dien_thoai,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
            {
              name: fieldTiepDon.ngay_sinh,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
          ])}
        />

        <ModalThanhToan
          ref={thanhToanRef}
          onClose={(patient) => patient ? getAppointmentInfo(patient, true, true) : handleClose(false)}
          onPrint={printReceipt}
          showExtra
          disabledExam={dsDvKhamBenhDaChon.length < 1 && !selectedGoiService.flatMap(i => i.PHAN_LOAI).includes('KHAM_BENH')}
          disabledSubclinical={dsDvClsDaChon.length < 1 && selectedGoiService.filter(i => i.PHAN_LOAI !== 'KHAM_BENH').length < 1}
        />

        <ModalChonSo
          ref={beforeSubmitRef}
          bookId={bookId}
          setBookId={setBookId}
          danger={
            userProfile.config?.THANH_TOAN_TRUOC_KHAM &&
            selectedGoiService.length > 0 &&
            bundleReceipts.length !== selectedGoiService.filter(i => !i.THANH_TOAN).length
          }
          onFinish={handleFinish}
        />

        <ConfirmModal
          ref={overlimitRef}
          title={i18n.t(languageKeys.common_Canh_bao)}
          content={""}
          cancelText={i18n.t(languageKeys.huy)}
          onOk={({ print, afterSubmit }) => {
            onFinish(print, checkShowModal, false, false, afterSubmit)
            overlimitRef.current?.setVisible(false)
          }}
        >
          <div className={style.debtLimit}>
            <p>{i18n.t(languageKeys.so_tien_no_cua_kh)} <span>{selectedPatient?.TEN}</span> {i18n.t(languageKeys.da_vuot_han_muc_no)} </p>
            <Space direction="vertical">
              {bundleOverlimit.map(item => <span>• {item}</span>)}
            </Space>
            <p>{i18n.t(languageKeys.chac_chan_muon_tiep_tuc_chi_dinh_dv)}</p>
          </div>
        </ConfirmModal>


        <ConfirmModal
          ref={modalHuySID}
          // title={i18n.t(languageKeys.xac_nha)}
          title={i18n.t(languageKeys.title_Xac_nhan)}
          content={""}
          cancelText={i18n.t(languageKeys.huy)}
          danger
          onOk={() => {

            handleHuyBarcode()
          }}
        >
          <div className={style.debtLimit}>
            <p>{i18n.t(languageKeys.ban_co_chac_chan_muon_huy_sid)}</p>
          </div>
        </ConfirmModal>

        <NewWarningModal
          onClose={() => {
            try {
              dispatch(TiepDonActions.setDuplicateService({
                ...duplicateService,
                hightLighter: true,
                openWarnModal: false
              }))
              setLoadingChecking(false);
            } catch (error) {
              console.log(error);
              setLoadingChecking(false);
            }
          }}
          onOk={async () => { onConfirmDuplicatedWarn() }}
          loading={loadingChecking}
        />



      </FormDrawer>
      {
        configBarcode ? (<ModalBarcodeOngMau
          ref={optionsPrintSIDOngMau}
          handlePrint={handlePrintSID}
          formSID={formSID}
          cuocKham={lichKhamInfo}
        />)
          : (
            <ModalBarcode
              ref={optionsPrintSID}
              handlePrint={handlePrintSID}
              formSID={formSID}
              cuocKham={lichKhamInfo}
            />
          )
      }</>
  );
};

ModalTiepDon = forwardRef(ModalTiepDon);
ModalTiepDon = memo(ModalTiepDon);

export default ModalTiepDon;


//
const checkDisableInput = (lichkham,skipThanhToan) => {
  if (!lichkham) return false;
  if (!skipThanhToan &&( lichkham.TRANG_THAI_THANH_TOAN === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN)) {
    return true;
  } else {
    if (lichkham.TRANG_THAI_HIEN_TAI !== "CHO_KHAM" && lichkham.TRANG_THAI_HIEN_TAI !== "CHO_XAC_NHAN") {
      return true;
    }
  }
  return false;
};
