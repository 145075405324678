import { CameraOutlined, CloseOutlined, CheckCircleFilled , CheckOutlined} from "@ant-design/icons";
import { Image } from "antd";
import classNames from "classnames";
import React, { memo, useState } from "react";
import style from "./dichvu.module.less";
const ImagePreview = ({
  source = null,
  uid = null,
  note = "đây là ảnh",
  onRemove = () => {},
  width = 100,
  height = 100,
  removeIcon = false,
  className,
  onClickImg, 
  isAdded = false,
  data,
}) => {
  return source ? (
    <div className={classNames(style["emty-img"], className)} style={{ position: "relative" }}>
      {!removeIcon ? (
        <div className={style["item-add"]} style={{ zIndex: 5 }} onClick={() => onRemove(uid)}>
          <CloseOutlined style={{ fontSize: 8, transform: "translateY(-5px)" }} />
        </div>
      ) : <></>}
        <CheckCircleFilled style={{ color: "green",transform: "scale(1.5)", opacity :1, margin: "auto" , position : "absolute" , top : 50, left :50 }} />
      <img id={uid} width={"100%"} style={{borderRadius : 6, opacity : isAdded ? 0.4 : 1, zIndex: 2}} src={source} onClick={() => onClickImg(source)}/>
    </div>
  ) : (
    <div className={style["emty-img"]} style={{ zIndex: 0 }}>
      <div className={style["icon-wrapper"]}>
        <CameraOutlined style={{ color: "#999", transform: "scale(1.2)", margin: "auto" }} />
      </div>
    </div>
  );
};

export default memo(ImagePreview);
