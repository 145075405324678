import { Badge, Button, Col, Drawer, Row, Form, Input, Layout, Divider, notification, Tooltip, message } from "antd";
import moment from "moment";
import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FormDrawer, ModalPDF, Topbar } from "../../../../components";
import { apis } from "../../../../constants";
import { common_get, common_post, convertDateToValue, getPrivateImageLink, handleCheckPermissions, HLog, rid } from "../../../../helpers";
import { useEventListener } from "../../../../hooks";
import i18n, { languageKeys } from "../../../../i18n";
import { TextEditor } from "../../components";
import style from "../formStyle.module.less";
import { apiPhieuTra } from "../services";
import { trang_thai_tra } from "../TraKetQuaCdha";
import ImagePreview from "./ImagePreview";
import { permissionActionKey, widthDrawerKeys } from "constants/keys";
import { CopyFilled } from "@ant-design/icons";
import { Attachment, CopyIcon, FileCheck, NewBackIcon } from "assets/svg";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";

const { Item } = Form;
const init_arr = [null, null, null, null];

const DvDaTraKQ = forwardRef((
  {
    patientInfo = {},
    ticket = {},
    doAfterDestroy = () => {},
    footerAction = true,
    setCheckClose = () => {},
    setIsModalActive = () => {},
    disabledPrint = false,
    tipPrint = i18n.t(languageKeys.field_In_phieu),
    hideTopbar = false,
  },
  ref
) => {
  const userProfile = useSelector((state) => state.auth.user);
  const pdfRef = useRef();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [currentService, setCurrentService] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [ketLuan, setKetLuan] = useState("");
  const [arrImgPrev, setArrImgPrev] = useState([]);
  const dataLocal = useSelector((state) => state.data);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFile] = useState();
  const [visible2, setVisible2] = useState(false);


  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    visible,
  }));
  const keydownEvent = (event) => {
    if (visible) {

      if (ticket.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA) {
        if (currentService.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
          if (event.ctrlKey && event.key === "d") {
            if (footerAction) {
              event.stopPropagation();
              event.preventDefault();
              handleHuyTra();
            } else {
              return;
            }
          }
        } else {
          return
        }
      }
      if (currentService.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
        if (event.ctrlKey && event.key === "i") {
          event.stopPropagation();
          event.preventDefault();
          handlePrint([currentService.PHIEU_TRA_ID]);
        }
      } else {
        return
      }
    }
  };

  useEventListener("keydown", keydownEvent, window.document, visible);

  // Khoi tao du lieu san co
  useEffect(() => {
    if (visible && currentService) {
      if (footerAction) {
        let fieldData = Object.keys(fieldKetQua).map((item) => {
          if (item.includes("NGAY")) {
            return {
              name: item,
              value: convertDateToValue(ticket[item]),
            };
          }
          if (item.includes(fieldKetQua.THOI_GIAN_Y_LENH)) {
            return {
              name: item,
              value: moment(Number(ticket[fieldKetQua.THOI_GIAN_Y_LENH])).format("HH:mm DD/MM/YYYY"),
            };
          }
          return {
            name: item,
            value: ticket[item],
          };
        });
        form.setFields(fieldData);
      } else {
        form.setFields([
          {
            name: fieldKetQua.MA_PHIEU,
            value: ticket.MA_PHIEU || currentService.MA_PHIEU,
          }
        ]);
      }
      if (currentService.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA) {
        getServiceInfo(currentService);
      }
    }

    if (!visible) {
      setCurrentService();
      setData();
      setDescription("");
      setKetLuan("");
      setArrImgPrev([])
      form.resetFields();
    }
  }, [visible]);

  const getServiceInfo = async (service) => {
    setLoading(true);
    try {
      let body = {
        BENH_VIEN_ID: service?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
        partner_code: service?.BENH_VIEN_BRAND || userProfile.partner_code,
        PHIEU_TRA_ID: service.PHIEU_TRA_ID,
      };
      let res = await common_post(apis.ris_lay_chi_tiet_phieu_tra, body, false);
      if (res && res.status === "OK") {
        let { result } = res;
        setImageUrl(result[0]?.LINK_VIEW);
        setFile(result[0]?.FILE || [])
        if (footerAction) {
          form.setFields([
            {
              name: fieldKetQua.NGAY_TRA_KQ,
              value: convertDateToValue(result[0]["NGAY_TRA_KET_QUA"]),
            },
            {
              name: fieldKetQua.NGUOI_TRA_KQ,
              value: result[0].HO_TEN_NGUOI_TRA,
            },
            {
              name: fieldKetQua.MAY_THUC_HIEN,
              value: result[0].TEN_MAY,
            },
            // {
            //   name:fieldKetQua.CHAN_DOAN,
            //   value:result[0].CHAN_DOAN_XAC_DINH
            // }
          ]);
        } else {
          form.setFields([
            {
              name: fieldKetQua.NGAY_CHI_DINH,
              value: `${result[0].GIO_CHI_DINH ? result[0].GIO_CHI_DINH : ""} ${convertDateToValue(result[0].NGAY_CHI_DINH)}`,
            },
            {
              name: fieldKetQua.NGAY_TRA_KQ,
              value: convertDateToValue(result[0].NGAY_TRA_KET_QUA),
            },
            {
              name: fieldKetQua.THOI_GIAN_Y_LENH,
              value: result[0].GIO_Y_LENH + " - " + convertDateToValue(result[0].NGAY_Y_LENH),
            },
            {
              name: fieldKetQua.NGUOI_THUC_HIEN,
              value: result[0].HO_TEN_NGUOI_THUC_HIEN,
            },
            {
              name: fieldKetQua.NOI_CHI_DINH,
              value: ticket.PHONG_CHI_DINH || result[0].PHONG_CHI_DINH,
            },
            {
              name: fieldKetQua.NOI_THUC_HIEN,
              value: currentService.TEN_KHOA_PHONG || result[0].PHONG_THUC_HIEN,
            },
            {
              name: fieldKetQua.NGUOI_TRA_KQ,
              value: result[0].HO_TEN_NGUOI_TRA,
            },
            {
              name: fieldKetQua.MAY_THUC_HIEN,
              value: result[0].TEN_MAY,
            },
            {
              name: fieldKetQua.CHAN_DOAN,
              value: !!result[0].CHAN_DOAN_LAM_SANG ? result[0].CHAN_DOAN_LAM_SANG : ticket.CHAN_DOAN_LAM_SANG
            }
          ]);
        }

        if (result[0].ANH_ID && result[0].ANH_ID.length) {
          setArrImgPrev(() => {
            let arrImg = JSON.parse(result[0]["ANH_ID"]).map((item) => {
              return {
                key: rid(),
                id: item,
                thumbnail: getPrivateImageLink(item),
              };
            });
            return arrImg;
          });
        } else {
          setArrImgPrev([]);
        }
        result[0].MO_TA ? setDescription(result[0].MO_TA) : setDescription("");
        result[0].MO_TA ? setKetLuan(result[0].KET_LUAN) : setKetLuan();
      }

    } catch (error) {
      HLog(error);
    } finally {
      setLoading(false);
    };
  };

  const handleOpen = (record) => {
    if (record) {
      setIsModalActive(true);
      setCurrentService(record);
      setVisible(true);
    }
  };

  const handleClose = () => {
    setVisible(false);
    setImageUrl();
    setTimeout(() => {
      setIsModalActive(false);
    }, 100);
  };

  const handleAfterSubmit = () => {
    handleClose();
  };

  const handleHuyTra = async () => {
    try {
      let body = {
        NHANSU_ID: userProfile.NHANSU_ID,
        partner_code: userProfile.partner_code,
        CHITIET_PHIEU_TRA_ID: currentService.PHIEU_TRA_ID,
        PHONG_DIEU_TRI_ID: currentService?.PDT_ID,
        DICH_VU_ID: currentService?.DICH_VU_ID,
      };
      let res = await common_post(apis.ris_huy_dich_vu_phieu_tra_kq, body, false);
      if (res && res.status === "OK") {
        doAfterDestroy(currentService.DICH_VU_ID);
        handleAfterSubmit();
        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
          placement: "bottomLeft",
        });
      } else {
        setArrImgPrev([])
        notification.error({
          message: i18n.t(languageKeys.thao_tac_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (error) {}
  };

  // in cả phiếu
  async function handlePrint(array_id) {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_ID: array_id,
    };
    let response = await apiPhieuTra(req);
    if (response) {
      let array = response.result;
      let data_print = array.map((item) => {
        let data = {
          ...item,
          maumota: item.MO_TA,
          ket_luan: item.KET_LUAN,
          arr_anh_ID: item.ANH_MO_TA,
          MA_PHIEU: ticket.MA_PHIEU || currentService.MA_PHIEU,
        };
        return data;
      });

      const template = dataLocal?.template_pdf?.PHIEU_TRA_KQ_RIS || "PhieuTraKq";
      if (!template) {
        notification.error({
          message: "Không có mẫu in!",
          placement: "bottomLeft",
        })
        return
      }
      pdfRef.current.openModalWithData(data_print, template);
    }
  }
  const handleCloseDrawFile = () => {
    setVisible2(false)
  }

  const handleClickFile = async (file) => {
    try {

      const response = await common_get(`${apis.layFileMoi}?ID=${file.FILE_ID || file.ID}`, {
        ID: file.FILE_ID || file.ID
      });

      const createObjectURLFromBase64 = (base64, contentType) => {
        console.log("base64", base64);
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        let url = window.URL.createObjectURL(blob);

        window.open(url, "_blank");
      };

      createObjectURLFromBase64(response, "application/pdf");

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      visible={visible}
      width="90vw"
      closable={false}
      onClose={handleClose}
      className={style["form-container"]}
      // closeIcon={<NewBackIcon />}
      loading={loading}
      title={
        <Row className={style["header-title"]} justify="space-between" >
          <Col className={style["title-drawer"]} style={{ fontSize: 22 }}><NewBackIcon onClick={handleClose} style={{ cursor: "pointer" }} />{i18n.t(languageKeys.title_page_tra_kq_cdhd)}</Col>
          <Col>
            <Row justify="end">
              <Col className={style["form__top--action"]}>
                <Row className={style["patient-title"]} justify="space-evenly">
                  <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ten_benh_nhan)} :</Col>{" "}
                  <Col className="blue-txt" style={{ display: "inline" }}>
                    {patientInfo?.TEN}
                  </Col>
                </Row>
                <Row className={style["patient-title"]} justify="space-evenly">
                  <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_BN)}: </Col>
                  <Col>
                    <span>{patientInfo?.MA_BENH_NHAN}</span>
                  </Col>
                </Row>
                <Row className={style["patient-title"]}>
                  <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_CK)}: </Col>
                  <Col>
                    <span>{patientInfo?.MA_CUOC_KHAM}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      footer={
        <Row gutter={10} justify="end">
          <Col>
            <Button type="primary" ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Thoat)} (ESC)
            </Button>
          </Col>
          {currentService && currentService.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA && (
            <>
              {footerAction && (
                <Col>
                  <Button type="danger" ghost onClick={handleHuyTra} disabled={ticket.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA}>
                    {i18n.t(languageKeys.huy_tra)} (Ctrl + D)
                  </Button>
                </Col>
              )}
              <Col>
                <Tooltip title={tipPrint}>
                  <Button
                    type="primary"
                    onClick={() => {
                      handlePrint([currentService.PHIEU_TRA_ID]);
                    }}
                    disabled={disabledPrint}
                  >
                    {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                  </Button>
                </Tooltip>
              </Col>
            </>
          )}
        </Row>
      }
    >
      {!hideTopbar ? (
        <Topbar
          title={
            <Row>
              <Col span={24} style={{ display: "flex", gap: 4 }}>
                <Badge status={currentService && (currentService.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA ? "success" : "warning")} />
                <h2 className={style["sub-header-title"]}>
                  {currentService?.TEN_DICHVU} - {ticket?.MA_PHIEU}
                </h2>
              </Col>
            </Row>
          }
          className={style["sub-header"]}
        />
      ) : (
        <></>
      )}
      <Form form={form} layout="vertical" style={{ height: "fit-content" }} onFinish={() => {}}>
        <div className={style["form__header--thongtin"]}>
          <Row gutter={10} className={style["form__wrap--chitiet"]}>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.ma_phieu)} name={fieldKetQua.MA_PHIEU}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.thoi_gian_chi_dinh)} name={fieldKetQua.NGAY_CHI_DINH}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.noi_chi_dinh)} name={fieldKetQua.NOI_CHI_DINH}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>

            {/* <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.ngay_y_lenh)} name={fieldKetQua.THOI_GIAN_Y_LENH}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col> */}
            <Col flex={"40%"}>
              <Item label={i18n.t(languageKeys.chan_doan)} name={fieldKetQua.CHAN_DOAN}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
          </Row>

          <Row gutter={10} className={style["form__wrap--chitiet"]}>

            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.noi_thuc_hien)} name={fieldKetQua.NOI_THUC_HIEN}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.nguoi_thuc_hien)} name={fieldKetQua.NGUOI_THUC_HIEN}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.nguoi_tra_kq)} name={fieldKetQua.NGUOI_TRA_KQ}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.ngay_tra_kq)} name={fieldKetQua.NGAY_TRA_KQ}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col>
            {/* <Col flex={"20%"}>
              <Item label={i18n.t(languageKeys.may_thuc_hien)} name={fieldKetQua.MAY_THUC_HIEN}>
                <Input readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Item>
            </Col> */}

          </Row>
        </div>
      </Form>
      <Layout>
        <div className={style["container-content"]} style={{ height: "100%" }}>
          <div style={{ height: "70%" }} className={style['desc-container']}>
            <h2 style={{ paddingInline: "18px" }}>{i18n.t(languageKeys.mo_ta)}</h2>
            <div className={`${style["iframe-mota"]} ${style['style-scroll-bar']}`}>
              <TextEditor className={style["editor-disabled"]} disabled value={description} toolbar={[]} />
            </div>
            <Row style={{ height: "10%", paddingInline: 18 }} align={"middle"}>
              <Col span={24} style={{ marginRight: 8 }}> <span className="bold-txt navy-txt">{i18n.t(languageKeys.link_hinh_anh)}</span></Col>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <Input
                    readOnly
                    style={{ width: "50%" }}
                    className={style['suffix-input']}
                    onClick={(e) => e.target.value && window.open(e.target.value, "_blank")}
                    type="link"
                    suffix={<CopyIcon value={imageUrl} style={{ cursor: "pointer" }} onClick={(e) => {
                      navigator.clipboard.writeText(imageUrl);
                      message.success(i18n.t(languageKeys.da_sao_chep));
                    }} />}
                    value={imageUrl}
                  />

                  <p className={classNames(style["file-dinh-kem"])} onClick={() => setVisible2(true)} ><Attachment />{i18n.t(languageKeys.file_dinh_kem)}</p>
                </div>
              </Col>
            </Row>
          </div>
          <Divider style={{ color: "#EDEDED", margin: 0 }} />
          <div className={style["ket-luan-wrap"]} >
            <Row style={{ paddingInline: "18px" }}>
              <h2>{i18n.t(languageKeys.ket_luan)}</h2>
            </Row>
            <Row style={{ padding: "6px 24px" }}>
              {/* <div style={{height:100, width:"100%"}} >
              <TextEditor  className={style["editor-disabled"]} disabled value={ketLuan} menubar={false} toolbar={false} />

              </div> */}

              <div dangerouslySetInnerHTML={{ __html: ketLuan }} className={style["ket-luan-content"]} />

              {/* <Input.TextArea  className={classNames(style["boldTextArea"])} value={ketLuan} readOnly /> */}
            </Row>
          </div>
        </div>
        {!!arrImgPrev.length && (
          <Layout.Sider width={240} theme="light">
            <div className={style["form__sider--right"]}>
              {arrImgPrev.map((img, index) => {
                return <ImagePreview key={img.key} source={img?.thumbnail} uid={img.key} removeIcon />;
              })}
            </div>
            <div className={style["sider__right--background"]}>
              {init_arr.map((img, index) => {
                return <ImagePreview key={index} source={img} />;
              })}
            </div>
          </Layout.Sider>
        )}
      </Layout>
      <ModalPDF ref={pdfRef} />
      <FormDrawer
        visible={visible2}
        width={widthDrawerKeys.small}
        closable
        onClose={handleCloseDrawFile}
        onCancel={handleCloseDrawFile}
        className={style["form-container"]}
        closeIcon={false}
        maskClosable={false}
        title={i18n.t(languageKeys.file_dinh_kem)}
        back={true}
        newBackIcon={true}
        hiddenTopAction={true}
        footer={
          <>
            <Row justify="end" gutter={[16, 16]}>
              <Col>
                <Button type="primary" ghost onClick={handleCloseDrawFile}>
                  {i18n.t(languageKeys.common_Thoat)}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ padding: "15px 20px" }}>
          <div className={style["form-draw"]}>
            <h2 className={style["form-header"]}>{i18n.t(languageKeys.thong_tin_file_dinh_kem)}</h2>
            <div className={style["form-content"]}>
              <Row gutter={10} justify="end">
                <Col span={24}>
                  <Row gutter={[10, 10]} align="middle">
                    {fileList?.map((file, index) => {
                      return (
                        <Col span={24} key={nanoid(5)} className={style["item-file"]} style={{ cursor: "pointer" }} onClick={() => handleClickFile(file)}>
                          <div
                            className={style["item-file-1"]}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                            onClick={() => handleClickFile(file)}
                          >
                            <FileCheck />
                          </div>
                          <span className={style["item-file-2"]}>{file.TEN_FILE || file.ORIGINAL_NAME}</span>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </FormDrawer>
    </Drawer>
  );
});
export default memo(DvDaTraKQ);

const fieldKetQua = {
  MA_PHIEU: "MA_PHIEU",
  NGAY_CHI_DINH: "NGAY_CHI_DINH",
  NGAY_TRA_KQ: "NGAY_TRA_KQ",
  THOI_GIAN_Y_LENH: "THOI_GIAN_Y_LENH",
  GIO_Y_LENH: "GIO_Y_LENH",
  NGUOI_THUC_HIEN: "NGUOI_THUC_HIEN",
  NOI_CHI_DINH: "NOI_CHI_DINH",
  NOI_THUC_HIEN: "NOI_THUC_HIEN",
  NGUOI_TRA_KQ: "NGUOI_TRA_KQ",
  CHAN_DOAN: "CHAN_DOAN",
  TRANG_THAI_PHIEU: "TRANG_THAI_PHIEU",
  MAY_THUC_HIEN: "MAY_THUC_HIEN",
  TEN_MAY_THUC_HIEN: "TEN_MAY_THUC_HIEN",
};

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  str = str.replaceAll(/(<([^>]+)>)/gi, "");
  return str.replaceAll("&nbsp;", " ");
}
