import { Tooltip } from "antd";
import { dataLoaiPhieu } from "../../../constants/data";
import { convertDateToValue, formatNumberToPrice } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import style from "./banThuoc.module.less";

// ellipsis chữ (độ ... đằng sau nếu quá dài)
const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};
// không có đơn giá và thành tiền, hiển thị thêm trường hàm lượng và nồng độ.

export const columnsThuoc = [
  {
    title: i18n.t(languageKeys.field_ma_thuoc),
    width: 100,
    key: "Thuoc_Code",
    dataIndex: "Thuoc_Code",
    render: (Thuoc_Code, record) => (!!Thuoc_Code ? Thuoc_Code : record.MA_THUOC),
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ten_thuoc),
    width: 200,
    key: "TEN_THUOC",
    dataIndex: "TEN_THUOC",
    ...ellipsisProps,
    // render: (Thuoc_Ten, record) => <p className={style['elipsis-column']}>{!!Thuoc_Ten ? Thuoc_Ten : record.TEN_THUOC  } </p>,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_lo_thuoc),
    width: 100,
    //align: 'right',
    key: "MA_LO",
    dataIndex: "MA_LO",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_So_luong),
    width: 100,
    //align: 'right',
    key: "SO_LUONG",
    dataIndex: "SO_LUONG",
    //...ellipsisProps,
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_ham_luong),
    width: 100,
    //align: 'right',
    key: "HAM_LUONG",
    dataIndex: "HAM_LUONG",
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_nong_do),
    width: 100,
    //align: 'right',
    key: "NONG_DO",
    dataIndex: "NONG_DO",
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_don_gia),
    width: 100,
    //align: 'right',
    key: "GIA_BAN",
    dataIndex: "GIA_BAN",
    render: (GIA_BAN) => formatNumberToPrice(GIA_BAN),
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.vien_phi_thanh_tien),
    width: 100,
    //align: 'right',
    key: "THANH_TIEN",
    dataIndex: "THANH_TIEN",
    render: (THANH_TIEN, record) => formatNumberToPrice(Number(record.THANH_TIEN)),
    unViewer: false,
  },
];

export const columnsDonThuoc = [
  {
    title: i18n.t(languageKeys.field_Stt),
    width: "60px",
    key: "STT",
    dataIndex: "STT",
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_ma_thuoc),
    width: "100px",
    key: "Thuoc_Code",
    dataIndex: "Thuoc_Code",
    render: (Thuoc_Code, record) => (!!Thuoc_Code ? Thuoc_Code : record.MA_THUOC),
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ten_thuoc),
    width: "200px",
    key: "Thuoc_Ten",
    dataIndex: "Thuoc_Ten",
    ...ellipsisProps,
    render: (Thuoc_Ten, record) => <p className={style["elipsis-column"]}>{!!Thuoc_Ten ? Thuoc_Ten : record.TEN_THUOC} </p>,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    width: "200px",
    key: "Thuoc_HoatChat",
    dataIndex: "Thuoc_HoatChat",
    ...ellipsisProps,
    // render: (MA_LO, record) => <p>{!!Thuoc_Ten ? Thuoc_Ten : record.TEN_THUOC + " " + record.HAM_LUONG || record.NONG_DO } </p>,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_Don_vi),
    width: "70px",
    key: "Thuoc_Dvt",
    dataIndex: "Thuoc_Dvt",
    ...ellipsisProps,
    unViewer: false,
  },

  {
    title: i18n.t(languageKeys.field_So_luong),
    width: "60px",
    //align: 'right',
    key: "Thuoc_SL",
    dataIndex: "Thuoc_SL",
    //...ellipsisProps,
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_Cach_dung),
    width: "225px",
    key: "Chidan",
    dataIndex: "Chidan",
    // render: (CACH_DUNG) => formatNumberToPrice(GIA_BAN),
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_Ghi_chu),
    width: "200px",
    key: "GHI_CHU",
    dataIndex: "GHI_CHU",
    // render: (CACH_DUNG) => formatNumberToPrice(GIA_BAN),
    unViewer: false,
    ellipsis: true,
  },
];

export const columnsChuaDuyet = [
  {
    title: i18n.t(languageKeys.field_Stt),
    width: 50,
    key: "STT",
    dataIndex: "STT",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },

  {
    title: i18n.t(languageKeys.field_ma_thuoc),
    width: 70,
    key: "Thuoc_Code",
    dataIndex: "Thuoc_Code",
    render: (Thuoc_Code, record) => (!!Thuoc_Code ? Thuoc_Code : record.MA_THUOC),
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ten_thuoc),
    width: 190,
    key: "TEN_THUOC",
    dataIndex: "TEN_THUOC",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    width: 120,
    //align: 'right',
    key: "TEN_HOAT_CHAT",
    dataIndex: "TEN_HOAT_CHAT",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ham_luong),
    width: 100,
    //align: 'right',
    key: "HAM_LUONG",
    dataIndex: "HAM_LUONG",
    //...ellipsisProps,
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_nong_do),
    width: 100,
    //align: 'right',
    key: "NONG_DO",
    dataIndex: "NONG_DO",
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_So_luong),
    width: 80,
    //align: 'right',
    key: "SO_LUONG",
    dataIndex: "SO_LUONG",
    unViewer: false,
  },
];

export const columnsDaDuyet = [
  {
    title: i18n.t(languageKeys.field_Stt),
    width: 70,
    key: "STT",
    dataIndex: "STT",
  },
  {
    title: i18n.t(languageKeys.field_ma_thuoc),
    width: 100,
    key: "Thuoc_Code",
    dataIndex: "Thuoc_Code",
    render: (Thuoc_Code, record) => (!!Thuoc_Code ? Thuoc_Code : record.MA_THUOC),
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ten_thuoc),
    width: 200,
    key: "TEN_THUOC",
    dataIndex: "TEN_THUOC",
    ...ellipsisProps,
    // render: (Thuoc_Ten, record) => <p className={style['elipsis-column']}>{!!Thuoc_Ten ? Thuoc_Ten : record.TEN_THUOC  } </p>,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_ma_lo),
    width: 100,
    //align: 'right',
    key: "MA_LO",
    dataIndex: "MA_LO",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    width: 100,
    key: "TEN_DON_VI_TINH",
    dataIndex: "TEN_DON_VI_TINH",
    ...ellipsisProps,
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.field_don_gia),
    width: 100,
    //align: 'right',
    key: "GIA_BAN",
    dataIndex: "GIA_BAN",
    //...ellipsisProps,
    unViewer: false,
    render: (GIA_BAN) => formatNumberToPrice(GIA_BAN),
  },
  {
    title: "VAT",
    width: 100,
    //align: 'right',
    key: "VAT_PHAN_TRAM",
    dataIndex: "VAT_PHAN_TRAM",
    //...ellipsisProps,
    unViewer: false,
    render: (VAT_PHAN_TRAM) => (VAT_PHAN_TRAM ? VAT_PHAN_TRAM + "%" : "0%"),
  },
  {
    title: i18n.t(languageKeys.sl),
    width: 120,
    //align: 'right',
    key: "SO_LUONG",
    dataIndex: "SO_LUONG",
    unViewer: false,
    ellipsis: true,
  },
  {
    title: i18n.t(languageKeys.vien_phi_thanh_tien),
    width: 100,
    //align: 'right',
    key: "THANH_TIEN",
    dataIndex: "THANH_TIEN",
    render: (THANH_TIEN, record) => formatNumberToPrice(Number(record.THANH_TIEN)),
    unViewer: false,
  },
];
