import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { keys } from "../../../../constants";
import { HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import {
  apiLayDsDanToc,
  apiLayDsHuyen,
  apiLayDsNgheNghiep,
  apiLayDsQuocGiaCoHieuLuc,
  apiLayDsTinh,
  apiLayDsXaPhuong,
} from "../../../QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDanhMuc";
import { fieldDantoc, fieldHuyen, fieldNgheNghiep, fieldTinh, fieldXa } from "../../../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { apiLayDsDoiTuongBenhNhan } from "../../apisTiepDon";
import { dataPartnerCode } from "constants/keys";

export const useDTBN = (active = true) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [dsDTBN, setDsDTBN] = useState([]);
  const [loadingDTBN, setLoadingDTBN] = useState(false);

  useEffect(() => {
    if (active) layDsDTBN(userProfile.config);
  }, [active, userProfile.config]);

  const layDsDTBN = async (config) => {
    setLoadingDTBN(true);

    const res = await apiLayDsDoiTuongBenhNhan();

    if (!!res) {
      let listDT = res.map((item) => {
        switch (item) {
          case keys.BHYT:
            return {
              name: i18n.t(languageKeys.field_doi_tuong_BHYT),
              MA_DOI_TUONG: item,
            };
          case keys.YEU_CAU:
            return {
              name: i18n.t(languageKeys.field_doi_tuong_yeu_cau),
              MA_DOI_TUONG: item,
            };
          case keys.VIEN_PHI:
            return {
              name: i18n.t(languageKeys.field_doi_tuong_vien_phi),
              MA_DOI_TUONG: item,
            };
          case keys.NUOC_NGOAI:
            return {
              name: i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai),
              MA_DOI_TUONG: item,
            };
          case keys.MIEN_GIAM:
            return {
              name: i18n.t(languageKeys.field_doi_tuong_mien_giam),
              MA_DOI_TUONG: item,
            };
          default:
            return {};
        }
      });

      listDT = listDT.filter((item) => {
        // if (item.MA_DOI_TUONG === keys.BHYT) return false;

        // if (item.MA_DOI_TUONG === keys.NUOC_NGOAI) return false;

        // if (
        //   !!config.DM_DICHVU_KYTHUAT &&
        //   !!config.DM_DICHVU_KYTHUAT.isCo &&
        //   Array.isArray(config.DM_DICHVU_KYTHUAT.TRUONG_BO) &&
        //   item.MA_DOI_TUONG === keys.YEU_CAU
        // ) {
        //   return !config.DM_DICHVU_KYTHUAT.TRUONG_BO.some(
        //     (item) => item === "GIA_YEU_CAU"
        //   );
        // }

        if (item.MA_DOI_TUONG === keys.VIEN_PHI) return true;

        return false;
      });

      setDsDTBN(listDT);
    }

    setLoadingDTBN(false);
  };

  return {
    dsDTBN,
    loadingDTBN,
  };
};

export const useProvince = () => {
  const [provinceList, setProvinceList] = useState([]);
  const [loadingProvince, setLoadingProvince] = useState(false);
  const data = useSelector((state) => state.data);


  const getProvinceList = async (value = "") => {
    setLoadingProvince(true);

    let res = {};
    res.result = data.tinh.filter((obj) => obj?.MA_TINH_THANH?.toLowerCase().includes(value?.toLowerCase()?.trim()) || obj?.TEN_TINH_THANH?.toLowerCase()?.includes(value?.toLowerCase()?.trim()));
    res.result= res?.result?.map(obj=>({...obj,ID:obj?.MA_TINH_THANH,MA_HANH_CHINH:obj?.MA_TINH_THANH  }))

    // const res = await apiLayDsTinh({
    //   limit: 100,
    //   search_string: value,
    // });

   

    if (!!res) {
      const ma_tinh_thanh = fieldTinh.id_tinh;
      const ten_tinh_thanh = fieldTinh.ten_tinh;

      const data = res.result.map((item) => ({
        [ma_tinh_thanh]: item[fieldTinh.ma_tinh_byt],
        [ten_tinh_thanh]: item[ten_tinh_thanh],
      }));

      setProvinceList(data);
    }

    setLoadingProvince(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchProvince = useCallback(debounce(getProvinceList, 1000), []);

  return {
    provinceList,
    loadingProvince,
    searchProvince,
    setProvinceList,
  };
};

export const useDistrict = () => {
  const [districtList, setDistrictList] = useState([]);
  const [loadingDistrict, setLoadingDistrict] = useState(false);
  const data = useSelector((state) => state.data);


  const getDistrictList = async (MA_TINH_THANH = "", value = "") => {
    if (!!MA_TINH_THANH) {
      setLoadingDistrict(true);
      let res = {};
      res.result = data.huyen[MA_TINH_THANH].filter(
        (obj) =>
          obj?.TEN_QUAN_HUYEN?.toLowerCase().includes(value?.toLowerCase()?.trim()) ||
          obj?.MA_QUAN_HUYEN?.toLowerCase()?.includes(value?.toLowerCase()?.trim())
      );
      res.result = res?.result?.map((obj) => ({ ...obj, ID: obj?.TEN_QUAN_HUYEN, MA_QUAN_HUYEN: obj?.MA_QUAN_HUYEN }));
      // const res = await apiLayDsHuyen({
      //   limit: 1000000,
      //   search_string: value,
      //   MA_TINH_THANH,
      // });

      if (!!res) {
        const ma_quan_huyen = fieldHuyen.id_huyen;
        const ten_quan_huyen = fieldHuyen.ten_huyen;

        const data = res.result.map((item) => ({
          [ma_quan_huyen]: item[fieldHuyen.ma_huyen_byt],
          [ten_quan_huyen]: item[ten_quan_huyen],
        }));

        setDistrictList(data);
      }

      setLoadingDistrict(false);
    } else {
      HLog("Chua chon tinh thanh");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDistrict = useCallback(debounce(getDistrictList, 1000), []);

  return {
    districtList,
    loadingDistrict,
    searchDistrict,
    setDistrictList,
  };
};

export const useWard = () => {
  const [wardList, setWardList] = useState([]);
  const [loadingWard, setLoadingWard] = useState(false);
  const data = useSelector((state) => state.data);

  const getWardList = async (MA_QUAN_HUYEN = "", value = "") => {
    if (!!MA_QUAN_HUYEN) {
      setLoadingWard(true);



      let ma = String(MA_QUAN_HUYEN).padStart(3, "0");
      let res = {};

      res.result = data.xa[ma].filter(
        (obj) =>
          obj?.TEN_PHUONG_XA?.toLowerCase().includes(value?.toLowerCase()?.trim()) ||
          obj?.MA_PHUONG_XA?.toLowerCase()?.includes(value?.toLowerCase()?.trim())
      );
      res.result = res?.result?.map((obj) => ({ ...obj, ID: obj?.TEN_PHUONG_XA, MA_PHUONG_XA: obj?.MA_PHUONG_XA }));
      
      // const res = await apiLayDsXaPhuong({
      //   limit: 1000000,
      //   search_string: value,
      //   MA_QUAN_HUYEN,
      // });

      if (!!res) {
        const ma_xa_phuong = fieldXa.id_xa;
        const ten_xa_phuong = fieldXa.ten_xa;
        const code_thx = fieldXa.code_thx;

        const data = res.result.map((item) => ({
          [ma_xa_phuong]: item[fieldXa.ma_xa_byt],
          [ten_xa_phuong]: item[ten_xa_phuong],
          [code_thx]: item[code_thx],
        }));

        setWardList(data);
      }

      setLoadingWard(false);
    } else {
      HLog("Chua chon quan huyen");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchWard = useCallback(debounce(getWardList, 1000), []);

  return { wardList, loadingWard, searchWard, setWardList };
};

export const useJob = () => {
  const [jobList, setJobList] = useState([]);
  const [loadingJob, setLoadingJob] = useState(false);

  useEffect(() => {
    getJobList();
  }, []);

  const getJobList = async () => {
    try {
      setLoadingJob(true);

      const res = await apiLayDsNgheNghiep();

      if (!!res) {
        const ma_nghe_nghiep = fieldNgheNghiep.ma_nghe_nghiep;
        const ten_nghe_nghiep = fieldNgheNghiep.ten_nghe_nghiep;

        const data = res.result.map((item) => ({
          [ma_nghe_nghiep]: item[ma_nghe_nghiep],
          [ten_nghe_nghiep]: item[ten_nghe_nghiep],
        }));

        setJobList(data);
      }

      setLoadingJob(false);
    } catch (error) {
      HLog("aaa", error);
    }
  };

  return {
    jobList,
    loadingJob,
  };
};

export const useNation = () => {
  const [nationList, setNationList] = useState([]);
  const [loadingNation, setLoadingNation] = useState(false);

  const getNationList = async (value = "") => {
    setLoadingNation(true);

    const res = await apiLayDsQuocGiaCoHieuLuc({
      limit: keys.limit,
      search_string: value,
    });

    if (!!res) setNationList(res.result);

    setLoadingNation(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchNation = useCallback(debounce(getNationList, 1000), []);

  return {
    nationList,
    loadingNation,
    searchNation,
  };
};

export const useEthnic = (active = false) => {
  const [ethnicList, setEthnicList] = useState([]);
  const [loadingEthnic, setLoadingEthnic] = useState(false);

  useEffect(() => {
    !!active && getEthnicList();
  }, [active]);

  const getEthnicList = async () => {
    setLoadingEthnic(true);

    const res = await apiLayDsDanToc({});

    if (!!res) {
      const ma_dan_toc = fieldDantoc.ma_dan_toc;
      const ten_dan_toc = fieldDantoc.ten_dan_toc;

      const data = res.result.map((item) => ({
        [ma_dan_toc]: item[ma_dan_toc],
        [ten_dan_toc]: item[ten_dan_toc],
      }));

      setEthnicList(data);
    }

    setLoadingEthnic(false);
  };

  return {
    ethnicList,
    loadingEthnic,
  };
};

export const useCheckingRequiredByPartner = (userProfile) => {

  if(!userProfile) return {
    ruleNguonKhach: [],
    ruleDiaChiChiTiet: [],
    listNguonKhachRequiredPartner: [],
    listRequireDcctPartner: [],
    listRequiredNgaySinh: [],
    requiredNguonKhach: false,
  };
  const partnerCode = userProfile?.partner_code;

  //==========================================================================
  const listNguonKhachRequiredPartner = [dataPartnerCode.dth];

  const requiredNguonKhach = !!userProfile.config?.BAT_BUOC_NGUON_KHACH || listNguonKhachRequiredPartner.includes(partnerCode);

  const ruleNguonKhach = [{
    required: requiredNguonKhach,
    message: i18n.t(languageKeys.please_chon),
  }]
  //==========================================================================
  const listRequireDcctPartner = [dataPartnerCode.dth];

  const ruleDiaChiChiTiet = [
    {
      required: listRequireDcctPartner.includes(partnerCode),
      message: i18n.t(languageKeys.vui_long_nhap)
    }
  ];

  const listRequiredNgaySinh = [dataPartnerCode.dth];

  return {
    ruleNguonKhach,
    ruleDiaChiChiTiet,
    listNguonKhachRequiredPartner,
    listRequireDcctPartner,
    listRequiredNgaySinh,
    requiredNguonKhach,
  }
}