import React from "react";
import { Col, Row, Divider, Modal, Button } from "antd";
import {  useSelector } from "react-redux";
import {  tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import { useEventListener } from "hooks";
import i18n, { languageKeys } from "../../../../i18n";
const NewWarningModal = ({ onClose = () => {},onOk = () => {}, loading }) => {
  const { duplicateService } = useSelector(tiepDonState);

    // hàm xử lý phím tắt
    function keydownEvent(event) {
      if (!duplicateService?.openWarnModal) {
        return;
      }

      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        onOk();
      }
    }

    useEventListener("keydown", keydownEvent, window, duplicateService?.openWarnModal, 100);

  return (
    <Modal width={400} open={duplicateService?.openWarnModal} footer={null} onCancel={onClose} closeIcon={<></>}>
      <h1 style={{textAlign:"center",marginTop:12}}>{i18n.t(languageKeys.common_Canh_bao)}</h1>
      <div style={{textAlign:"center",overflow:"auto"}}>{duplicateService?.contentWarning}</div>
      <Divider />
      <Row justify={"center"} gutter={20}>
        <Col>
          <Button type="primary" ghost danger onClick={onClose}>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={loading} onClick={onOk}>{i18n.t(languageKeys.title_Xac_nhan)} (Enter)</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default NewWarningModal;
