import React, { useEffect } from "react";
import { Row, Button, Col, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { chatAiActions, chatAiState } from "ducks/slices/chatAiSlice";
import i18n, { languageKeys } from "../../../../i18n";
import style from "./styles/style.module.less";
const RenderOptionSpecialist = ({ data, onSetList = () => {} }) => {
  const { loaiDichVu, kham_benh } = useSelector((state) => state.data);
  const { open } = useSelector(chatAiState);
  const [checkList, setCheckList] = React.useState([]);
  const [dataKhamBenh, setDataKhamBenh] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    return handleRefresh();
    
  },[open])

const handleRefresh = () => { setCheckList([]);}
  React.useEffect(() => {
    try {
      let arr_ten_loai = [];

      for (const loai of loaiDichVu) {
        if (data?.answer?.includes(loai.TEN_LOAI)) {
          arr_ten_loai.push({ ID: loai.ID, TEN_LOAI: loai.TEN_LOAI });
        }
      }
      let arr_dv_kb = kham_benh.filter((dv) => arr_ten_loai.map((i) => i.TEN_LOAI).includes(dv.TEN_LOAI_DICH_VU));

      setDataKhamBenh(arr_dv_kb);
    } catch (error) {console.log(error)}
  },[data?.answer,loaiDichVu]);

  return (
    <div style={{marginTop:6}}>
      <div hidden={!dataKhamBenh?.length}>Khách hàng có thể khám một trong các dịch vụ sau:</div>
      <div>
        {!!open && dataKhamBenh.map((dv) => (
          <div key={dv.DICH_VU_ID} className={style['align-left-option']}>
            <Checkbox
              name={dv.TEN_DICHVU}
              onChange={(e) =>
                e.target.checked ? setCheckList((prev) => [...prev, dv.DICH_VU_ID]) : setCheckList((prev) => prev.filter((i) => i === dv.DICH_VU_ID))
              }
            >
              {dv.TEN_DICHVU}
            </Checkbox>
          </div>
        ))}
      </div>
      <Row hidden={!dataKhamBenh?.length} justify={"center"} gutter={8} className={style['flow-container-bottom-btn']}>
        <Col span={6}>
          <Button
            block
            type="primary"
            ghost
            onClick={() => {
              dispatch(chatAiActions.setOpen(false));
              dispatch(chatAiActions.setData({}));
            }}
          >
            {i18n.t(languageKeys.common_Huy)}
          </Button>
        </Col>
        <Col span={6}>
          <Button block type="primary" onClick={() => onSetList(checkList)}>
            {i18n.t(languageKeys.title_Xac_nhan)}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RenderOptionSpecialist;
