import { SearchOutlined } from "@ant-design/icons";
import { Badge, Col, Row, Layout, Tooltip, Input } from "antd";
import classNames from "classnames";
import { memo, useRef } from "react";
import { useSelector } from "react-redux";
import { ConfirmModal } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import style from "../formStyle.module.less";
const { Sider } = Layout;

const SiderFormNhap = ({ listDichVu = [], currService, setCurrService = () => {}, phieu = {}, onFilter = () => {} }) => {
  const confirmRef = useRef();
  const isChange = useSelector((state) => state.snapShot.isChange);

  const handleOpenConfirm = (dichvu) => {
    if (isChange) {
      confirmRef.current && confirmRef.current.open(dichvu);
    } else {
      setCurrService(dichvu);
    }
  };

  return (
    <Sider width={240} theme="light" className={style["form__sider--left"]}>
      <Row className={style["sider-items"]}>
        <Input
          placeholder={i18n.t(languageKeys.common_Tim_kiem)}
          className={style["input"]}
          allowClear
          onChange={(e) => {
            if (e.target.value === "") {
              onFilter(e.target.value);
            }
          }}
          onPressEnter={(e) => onFilter(e.target.value)}
        />
        <SearchOutlined style={{ color: "#4263eb" }} className={style["search-icon"]} />
      </Row>
      {/* <div className={style["list__dv--wrap"]}> */}
      <div className={style["list__dv--wrap-2"]}>
        {listDichVu.map((dichvu, index) => {
          return (
            <Row
              key={dichvu.key}
              gutter={5}
              className={classNames(
                style["service__row"],
                currService && currService.DICH_VU_ID === dichvu.DICH_VU_ID && style["service__row--active"]
              )}
              onClick={() => handleOpenConfirm(dichvu)}
            >
              <Col span={2}>
                <Tooltip title={convertTxt(dichvu.TRANG_THAI_KQ)}>
                  <Badge status={dichvu.TRANG_THAI_KQ === fieldTrangThai.CHO_KET_QUA ? "warning" : "success"} />
                </Tooltip>
              </Col>
              <Col span={22}>
                <Tooltip title={dichvu.TEN_DICHVU} placement="rightTop">
                  <div className={style["txt-overflow"]}>{dichvu.TEN_DICHVU}</div>
                </Tooltip>
              </Col>
              {/* <Col span={9} style={{ textAlign: "right" }}>
                <Tooltip title={dichvu.TEN_DICHVU} placement="rightTop">
                  <div className={style["txt-overflow"]}>{phieu?.MA_PHIEU}</div>
                </Tooltip>
              </Col> */}
            </Row>
          );
        })}
      </div>
      <ConfirmModal
        ref={confirmRef}
        onOk={(data) => {
          confirmRef.current.close();
          setCurrService(data);
        }}
        danger
        content={i18n.t(languageKeys.noti_chua_hoan_thanh_dich_vu)}
      />
    </Sider>
  );
};
export default memo(SiderFormNhap);

const fieldTrangThai = {
  CHO_KET_QUA: "CHO_KET_QUA",
  DA_TRA_KET_QUA: "DA_TRA_KET_QUA",
};
const convertTxt = (key) => {
  switch (key) {
    case fieldTrangThai.CHO_KET_QUA:
      return i18n.t(languageKeys.status_Cho_ket_qua);
    case fieldTrangThai.DA_TRA_KET_QUA:
      return i18n.t(languageKeys.da_tra_kq);
    default:
      return "";
  }
};
