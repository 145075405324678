import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Collapse, DatePicker, Form, Input, InputNumber, Row } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";
import ChiDinhVatTu from "../ChiDinhVatTu";
import { Select } from "components";
import ChiDinhDvGoi from "../ChiDinhDvGoi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { calcTotal, getFullName } from "helpers";
import { getLieuTrinhData, setServiceUsageList, setSupplyUsageList } from "ducks/slices/GoiDichVu/GoiVatTu.slice";

const PhacDoSuDung = ({ form, readOnly }) => {

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { servicePackTypeList } = useSelector((state) => state.servicePacket.ServicePackType);
  const { serviceUsageList, supplyUsageList } = useSelector(getLieuTrinhData);

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      defaultActiveKey={["DICH_VU", "THONG_TIN"]}
    >

      <Collapse.Panel header={i18n.t(languageKeys.dv_sd_trong_goi)} key="DICH_VU">
        <ChiDinhDvGoi
          selectedTab={"PHAC_DO_SU_DUNG"}
          dataSource={serviceUsageList}
          setDataSource={(data) => dispatch(setServiceUsageList(data))}
          readOnly={readOnly}
          formBundle={form}
        />
        <h4>{i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)}</h4>
        <ChiDinhVatTu
          supplyList={supplyUsageList}
          setSupplyList={(data) => dispatch(setSupplyUsageList(data))}
          readOnly={readOnly}
          selectedTab={"PHAC_DO_SU_DUNG"}
        />
      </Collapse.Panel>

      <Collapse.Panel header={i18n.t(languageKeys.thong_tin_goi_lieu_trinh)} key="THONG_TIN">
        <Form form={form} layout="vertical">
          <div className={styles["wrapper"]}>
            <Row gutter={[10, 0]}>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.ma_goi)} name={"MA_GOI_DV"}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"40%"}>
                <Form.Item
                  name={"TEN_GOI_DV"}
                  label={i18n.t(languageKeys.ten_goi_lieu_trinh)}
                  rules={[{
                    required: true,
                    whitespace: true,
                    message: "",
                  }]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item
                  name={"LOAI_GOI_DV_ID"}
                  label={i18n.t(languageKeys.loai_goi)}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    dataSource={servicePackTypeList.filter(i => !i.KHOA)}
                    valueKey="ID"
                    titleKey="TEN_LOAI_GOI"
                    readOnly
                  />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <label style={{ color: "#2c3782" }}>{i18n.t(languageKeys.gia_goi_dich_vu)}</label>
                <InputNumber
                  value={calcTotal(serviceUsageList, "TONG_TIEN")}
                  style={{ marginTop: 8 }}
                  readOnly
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Col>

              <Col flex={"20%"}>
                <Form.Item
                  name={"NGAY_HET_HAN"}
                  label={i18n.t(languageKeys.field_han_su_dung)}
                  rules={[{ required: true, message: "" }]}
                >
                  <DatePicker
                    className={styles["datepicker"]}
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current && current < moment().endOf('day')}
                    inputReadOnly
                    disabled={readOnly}
                    onChange={(date) => form.setFieldValue(
                      "NGAY_SU_DUNG",
                      date.diff(moment(form.getFieldValue("NGAY_HIEU_LUC"), 'DD/MM/YYYY'), 'days') + 1
                    )}
                  />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.so_ngay_su_dung)} name={"NGAY_SU_DUNG"}>
                  <InputNumber readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.ngay_kich_hoat)} name={"NGAY_HIEU_LUC"}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item name={"NGAY_TAO"} label={i18n.t(languageKeys.ngay_tao)} initialValue={moment().format("DD/MM/YYYY")}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item name={"TEN_NGUOI_TAO"} label={i18n.t(languageKeys.nguoi_tao)} initialValue={getFullName(userProfile)}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={"MO_TA"} label={i18n.t(languageKeys.mo_ta)}>
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={"GHI_CHU"} label={i18n.t(languageKeys.ghi_chu)}>
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                </Form.Item>
              </Col>

            </Row>
          </div>
        </Form>
      </Collapse.Panel>

    </Collapse>
  )
}

export default PhacDoSuDung;