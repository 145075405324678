import { Layout, Row, Col, Button, Tooltip, Space, Tag } from "antd";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCheckPermissions, HLog, isEmptyObject } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import SiderBar from "./components/SiderBar";
import style from "./style.module.less";
import ModalPDF from "../../../components/Modal/ModalPDF";
import cn from "classnames";
import { apis } from "../../../constants";
import PhieuThuDaTao from "./PhieuThuDaTao/PhieuThuDaTao";
import { TRANG_THAI_THANH_TOAN, featureKeys } from "../../../constants/keys";
import ModalSoHoaDon from "../SoHoaDon/ModalSoHoaDon/ModalSoHoaDon";
import DichvuDaThanhToan from "./layouts/DichvuDaThanhToan/DichvuDaThanhToan";
// import { resetTitle, updateTitle } from "../../../ducks/slices/titlePageSlice";
import DichVuChuaThanhToan from "./layouts/DichVuChuaThanhToan";
import TongDichVu from "./layouts/TongDichvu";
import { setLoading } from "../../../ducks/slices/snapshotSlice";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";
import { ConfirmModal } from "components";
import ModalDebtList from "./layouts/ModalDebtList";

export const keyViews = {
  CHUA_THANH_TOAN: "CHO_THANH_TOAN",
  DA_THANH_TOAN: "DA_THANH_TOAN",
  TAT_CA: "TAT_CA",
};

const DichVuCanThanhToan = () => {

  const snapshot = useSelector(state => state.snapShot)

  const ref = useRef();
  const configRef = useRef();
  const createRef = useRef();
  const daThanhToanRef = useRef();
  const chuaThanhToanRef = useRef();
  const modalDebtList = useRef()
  const [visibleDrawerSoHoaDon, setVisibleDrawerSoHoaDon] = useState(false);

  const loading = useSelector((state) => state.snapShot.loading);
  const userProfile = useSelector((state) => state.auth.user);

  const [selectedPatient, setSelectedPatient] = useState(); // bệnh nhân đã chọn
  const [cancelItem, setCancelItem] = useState();
  const pdfRef = useRef();
  const [tabView, setTabView] = useState(keyViews.CHUA_THANH_TOAN);
  const [soCongNoId, setSoCongNoId] = useState()
  const [isRefundDisable, setIsRefundDisable] = useState(false);
  const [filterType, setFilterType] = useState('CHO_THANH_TOAN'); // trạng thái filter đã thanh toán hoặc chưa thanh toán
  const [disabledCongNo, setDisabledCongNo] = useState(false);
  // const [thongTinTT, setThongTinTT] = useState(null);

  const dispatch = useDispatch();

  const checkRoleArr = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan)
  }, [userProfile])

  const checkRoleSoTH = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_so_hoa_don);
  }, [userProfile])

  useEffect(() => {
    // dispatch(updateTitle(i18n.t(languageKeys.vien_phi_dich_vu_can_thanh_toan)));
    // dispatch(doFetchPaymentMethod())

    return () => {
      // dispatch(resetTitle());
      dispatch(setLoading(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // useEffect(() => {
  //   if (selectedPatient) {
  //     handleGetThongTinTT(selectedPatient)
  //   } else {
  //     setThongTinTT(null)
  //   }
  // }, [selectedPatient])


  // hàm xử lý khi chọn bệnh nhân
  function onSelectPatient(item) {
    if (!!item && item.LOAI !== "THANH_TOAN_MUA_GOI") {
      if (item.TRANG_THAI_THANH_TOAN === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN) {
        tabView !== keyViews.DA_THANH_TOAN && setTabView(keyViews.DA_THANH_TOAN);
      } else {
        tabView !== keyViews.CHUA_THANH_TOAN && setTabView(keyViews.CHUA_THANH_TOAN);
      }
    }
    setSelectedPatient(item);
    // if (!isUpdate) {
    //   layDsDichvu(item.BENH_NHAN_ID, filterDate, item.LICH_KHAM_ID);
    // }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearCurrentPatient = useCallback(() => {
    setSelectedPatient();
  }, []);

  const reloadSider = useCallback(() => {
    ref.current.reloadList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const printReceipt = (HOA_DON_ID, isMUAGOI) => {
    const dataRequest = {
      HOA_DON_ID,
      partner_code: userProfile.partner_code,
      arr_HOA_DON_ID: isMUAGOI ? HOA_DON_ID : [HOA_DON_ID],
      BENH_NHAN_ID: selectedPatient.BENH_NHAN_ID,
      LICH_KHAM_ID: selectedPatient.LICH_KHAM_ID,
    };
    const api = isMUAGOI ? apis.in_phieu_dtt_mua_goi_dv : apis.in_cac_phieu_dich_vu_da_thanh_toan;
    pdfRef.current.openModal(dataRequest, api);
  };

  const manyPrints = (arrHoaDon = [], printAll = false) => {
    if (selectedPatient && !isEmptyObject(selectedPatient)) {
      const dataRequest = {
        arr_HOA_DON_ID: arrHoaDon,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        BENH_NHAN_ID: selectedPatient.BENH_NHAN_ID,
        LICH_KHAM_ID: selectedPatient.LICH_KHAM_ID,
        printAll: printAll,
      };
      const api = selectedPatient.LICH_KHAM_ID ? apis.in_cac_phieu_dich_vu_da_thanh_toan : apis.in_phieu_dtt_mua_goi_dv;
      pdfRef.current.openModal(dataRequest, api);
    }
  };

  const onChangeFilterType = (status) => {
    try {
      ref.current.onSetFilterType(status);
    } catch (error) {
      HLog(error)
    }
  }

  // const reloadPaidPatient = () => {
  //   try {
  //     handleGetThongTinTT(selectedPatient);
  //   } catch (error) {
  //     HLog(error);
  //   }
  // }

  const renderTabs = (tab) => {
    switch (tab) {
      case keyViews.CHUA_THANH_TOAN:
        return (
          <DichVuChuaThanhToan
            reloadSiderBar={reloadSider}
            onThemSo={onThemSo}
            currentPatient={selectedPatient}
            setCancelItem={setCancelItem}
            cancelItem={cancelItem}
            ref={chuaThanhToanRef}
            onPrint={printReceipt}
            onManyPrints={manyPrints}
            tabView={tabView}
            onAfterSubmit={onChangeFilterType}
            setSelectedPatient={setSelectedPatient}
            setTabView={setTabView}
            visibleDrawerSoHoaDon={visibleDrawerSoHoaDon}
            isPayAllowed={checkRoleArr?.THANH_TOAN}
            isDeleteAllowed={checkRoleArr?.XOA_DICH_VU}
            isAddRevenueBookAllowed={checkRoleSoTH?.THEM_MOI}
            soCongNoId={soCongNoId}
            handleOpenModalDebtList={handleOpenModalDebtList}
            scrollY={305}
            setDisabledCongNo={setDisabledCongNo}
          // onReloadPaid={reloadPaidPatient}
          />
        );
      case keyViews.DA_THANH_TOAN:
        return (
          <DichvuDaThanhToan
            onThemSo={onThemSo}
            currentPatient={selectedPatient}
            ref={daThanhToanRef}
            patientInfo={selectedPatient}
            onPrint={printReceipt}
            onManyPrints={manyPrints}
            onAfterSubmit={clearCurrentPatient}
            tabView={tabView}
            setTabView={setTabView}
            isPayBackAllowed={checkRoleArr?.HOAN_TIEN}
            isAddRefundBookAllowed={checkRoleSoTH?.THEM_MOI}
            isCancelAllowed={userProfile?.NHOM_NHAN_SU == "QUAN_TRI" ? true : checkRoleArr?.HUY}
            onAfterCancel={onChangeFilterType}
            setIsRefundDisable={setIsRefundDisable}
            isPayAllowed={checkRoleArr?.THANH_TOAN}
          />
        );
      case keyViews.TAT_CA:
        return <TongDichVu patientInfo={selectedPatient} checkRoleArr />;
      default:
        return <></>;
    }
  };

  const onThemSo = (type = "SO_THU_TIEN") => {
    if (createRef.current) {
      createRef.current.open(null, type);
      setVisibleDrawerSoHoaDon(true);
    }
  };

  const handleSubmit = (print = false) => {
    if (tabView === keyViews.CHUA_THANH_TOAN && chuaThanhToanRef.current.onSubmit) chuaThanhToanRef.current.onSubmit(print);
  };

  const reloadSoHoaDon = () => {
    setTimeout(() => {
      setVisibleDrawerSoHoaDon(false);
    }, 500);

    if (tabView === keyViews.DA_THANH_TOAN) {
      HLog("reloadSoDaThanhToan", keyViews.DA_THANH_TOAN);
      daThanhToanRef.current?.reloadSo();
    } else if (tabView === keyViews.CHUA_THANH_TOAN) {
      HLog("reloadSoChuaThanhToan", keyViews.CHUA_THANH_TOAN);
      chuaThanhToanRef.current?.reloadSo();
    } else {
      return;
    }
  };

  const onClickTab = (tabKey) => {
    // if(tabKey == keyViews.CHUA_THANH_TOAN) {
    //  ref.current?.onChangeListPatient('CHUA_THANH_TOAN');
    // } else if (tabKey == keyViews.DA_THANH_TOAN) {
    //  ref.current?.onChangeListPatient('DA_THANH_TOAN');
    // }
    // setSelectedPatient();
    setTabView(tabKey);
  }

  const handleOpenModalDebtList = () => {
    chuaThanhToanRef?.current?.resetFieldPaymentMethod()
    modalDebtList?.current?.open()
  }

  const handleOnOk = () => {
    chuaThanhToanRef?.current?.handleSaveDebt()
    if (soCongNoId) {
      modalDebtList?.current?.close()
    }
  }

  // const handleGetThongTinTT = async (patient) => {
  //   try {
  //     const res = await common_post('api/partner/public/benhNhan/layThongTinThanhToanBenhNhan', {
  //       BENH_NHAN_ID : patient.BENH_NHAN_ID
  //     });
  //     if (res && res.status === "OK") {
  //       setThongTinTT({...res, BENH_NHAN_ID : patient.BENH_NHAN_ID})
  //     }
  //   }catch(error) {

  //   }
  // }

  return (
    <Layout>
      <SiderBar
        ref={ref}
        onSelectPatient={onSelectPatient}
        onSetTab={onClickTab}
        setSelectedPatient={setSelectedPatient}
        filterType={filterType}
        setFilterType={setFilterType}
      />

      {!selectedPatient && filterType === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN
        ? (
          <Layout className={style.empty}>
            {i18n.t(languageKeys.chua_chon_kh_da_tt)}
          </Layout>
        )
        : (
          <Layout style={{ paddingRight: 5 }}>
            <Layout>
              <Layout.Header
                style={{
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.03)",
                  zIndex: "2",
                }}
              >
                <Row gutter={10} style={{ padding: "0 12px" }}>
                  <Col>
                    <Button
                      onClick={() => onClickTab(keyViews.CHUA_THANH_TOAN)}
                      className={cn(style["button-status"], tabView === keyViews.CHUA_THANH_TOAN && style["button-status-active"])}
                    >
                      <span>{i18n.t(languageKeys.vienphi_chua_thanh_toan)}</span>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => onClickTab(keyViews.DA_THANH_TOAN)}
                      className={cn(style["button-status"], tabView === keyViews.DA_THANH_TOAN && style["button-status-active"])}
                    >
                      <Space align="baseline">
                        <span>{i18n.t(languageKeys.vienphi_da_tt)}</span>
                        <Tag
                          color={tabView === keyViews.DA_THANH_TOAN ? "#6576ff" : "white"}
                          style={{ margin: 0, fontSize: 12 }}
                        >
                          {selectedPatient?.TOTAL_DA_THANH_TOAN}
                        </Tag>
                      </Space>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => onClickTab(keyViews.TAT_CA)}
                      className={cn(style["button-status"], tabView === keyViews.TAT_CA && style["button-status-active"])}
                    >
                      <span>{i18n.t(languageKeys.status_Tat_ca)}</span>
                    </Button>
                  </Col>

                  <Col flex="auto" style={{ textAlign: "end" }}>
                    <Button
                      className={style["add-btn"]}
                      type="primary"
                      style={{ marginRight: 8, fontWeight: 700 }}
                      disabled={!selectedPatient || !selectedPatient.BENH_NHAN_ID}
                      onClick={() => {
                        configRef.current.open(selectedPatient);
                      }}
                    >
                      {i18n.t(languageKeys.title_Phieu_thu_da_tao)}
                    </Button>

                  </Col>
                </Row>
              </Layout.Header>

              <Layout.Content className={style["container"]}>
                {/*=================================================== render tabviews ===================================================== */}

                <Layout style={{ height: 'calc(100vh - 189px)' }}>
                  {renderTabs(tabView)}
                </Layout>

                {/*========================================================================================================================= */}
              </Layout.Content>
            </Layout>
            {/* tab đã thanh toán và tab chưa thanh toán */}
            {tabView !== keyViews.TAT_CA && (
              <Layout.Footer
                style={{
                  backgroundColor: "#fff",
                  padding: "18px 12px",
                  borderTop: "1px solid #eee",
                }}
              >

                <Row align="middle" justify="end" gutter={8}>
                  {/* <Col flex="auto">
                  {thongTinTT && <ThongTinThanhToanBenhNhan data = {thongTinTT} isOnclickTamUng={false} isOnclickChoTT={false}/>}
                </Col> */}

                  {tabView === keyViews.DA_THANH_TOAN && (
                    <Col>
                      <CheckRoleTooltip hasTooltip={false} isAllowed={checkRoleArr?.THANH_TOAN}>
                        <Button type="primary"
                          style={{ width: "100%" }}
                          onClick={() => daThanhToanRef.current.printAll()}
                          disabled={!checkRoleArr?.IN_PDF || snapshot.disabledVienphi}
                        >
                          {i18n.t(languageKeys.print_all)}
                        </Button>
                      </CheckRoleTooltip>
                    </Col>
                  )}

                  {tabView === keyViews.CHUA_THANH_TOAN && (
                    <Col>
                      <CheckRoleTooltip hasTooltip={false} isAllowed={checkRoleArr?.THANH_TOAN}>
                        <Button type="primary"
                          style={{ width: "100%" }}
                          onClick={handleOpenModalDebtList}
                          disabled={!checkRoleArr?.IN_PDF || snapshot.disabledVienphi}
                        >
                          {i18n.t(languageKeys.luu_cong_no)}{" "}(CTRL + B)
                        </Button>
                      </CheckRoleTooltip>
                    </Col>
                  )}
                  <Col>
                    {/* tab chưa thanh toán thì ra btn thanh toán đã thanh toán ra hoàn tiền */}
                    {tabView === keyViews.CHUA_THANH_TOAN
                      ? (
                        <CheckRoleTooltip hasTooltip={false} isAllowed={checkRoleArr?.THANH_TOAN && checkRoleArr?.IN_PDF}>
                          <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => handleSubmit(true)}
                            loading={loading}
                            disabled={(!checkRoleArr?.THANH_TOAN || !checkRoleArr?.IN_PDF) || snapshot.disabledVienphi}
                          >
                            {tabView === keyViews.CHUA_THANH_TOAN
                              ? i18n.t(languageKeys.vien_phi_thanh_toan_va_in)
                              : i18n.t(languageKeys.vien_phi_Hoan_tien_va_in)}{" "}
                            (CTRL + L)
                          </Button>
                        </CheckRoleTooltip>
                      ) : (
                        <Tooltip title={!checkRoleArr?.IN_PDF ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : ""}>
                          <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => daThanhToanRef.current.print()}
                            disabled={!checkRoleArr?.IN_PDF || snapshot.disabledVienphi}
                          >
                            {i18n.t(languageKeys.common_In)} (Ctrl + I)
                          </Button>
                        </Tooltip>
                      )}
                  </Col>
                  <Col>
                    {/* chức năng nút theo tabview */}
                    <CheckRoleTooltip hasTooltip={false} isAllowed={checkRoleArr?.THANH_TOAN}>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        // disabled={
                        //   selectedPatient &&
                        //   (tabView === keyViews.CHUA_THANH_TOAN
                        //     ? (chuaThanhToanRef.current?.valueMienGiam &&chuaThanhToanRef.current?.tongTien) && (formatPriceToNumber(
                        //         chuaThanhToanRef.current?.valueMienGiam
                        //       ) >
                        //       formatPriceToNumber(
                        //         chuaThanhToanRef.current?.tongTien
                        //       ))
                        //     : daThanhToanRef.current?.checkDisable && daThanhToanRef.current?.checkDisable())
                        // }
                        loading={loading}
                        onClick={() => {
                          if (tabView === keyViews.CHUA_THANH_TOAN) {
                            chuaThanhToanRef.current?.onSubmit();
                          } else {
                            daThanhToanRef.current?.onOpenRefundModal();
                          }
                        }}
                        disabled={
                          (tabView === keyViews.CHUA_THANH_TOAN ? !checkRoleArr?.THANH_TOAN : !checkRoleArr?.HOAN_TIEN)
                          || snapshot.disabledVienphi
                          || (tabView === keyViews.DA_THANH_TOAN && isRefundDisable)
                        }
                      >
                        {tabView === keyViews.CHUA_THANH_TOAN
                          ? i18n.t(languageKeys.txt_thanh_toan) + " (CTRL + S)"
                          : i18n.t(languageKeys.vien_phi_Hoan_tien)}
                      </Button>
                    </CheckRoleTooltip>
                  </Col>
                </Row>
              </Layout.Footer>
            )}
          </Layout>
        )
      }

      <PhieuThuDaTao
        ref={configRef}
        onAfterCancel={onChangeFilterType}
        isPrintAllowed={checkRoleArr?.IN_PDF}
        isCancelAllowed={checkRoleArr?.HUY}
        setTabView={setTabView}
      />

      <ModalSoHoaDon ref={createRef} doAfterSubmit={reloadSoHoaDon} setVisibleDrawerSoHoaDon={setVisibleDrawerSoHoaDon} />

      <ModalPDF ref={pdfRef} />

      <ConfirmModal
        isCloseWhenOk={false}
        ref={modalDebtList}
        content=''
        title={i18n.t(languageKeys.so_cong_no)}
        className={style['modal-content']}
        danger
        onOk={handleOnOk}
      >
        <ModalDebtList setSoCongNoId={setSoCongNoId} soCongNoId={soCongNoId} />
      </ConfirmModal>

    </Layout >
  );
};

export default DichVuCanThanhToan