import { Button, Checkbox, Col, Form, Input, InputNumber, Layout, PageHeader, Row, Space, Spin, Tooltip } from "antd";
import SiderDvTiepDon from "../SiderDvTiepDon/SiderDvTiepDon";
import i18n, { languageKeys } from "../../../../i18n";
import { PrinterOutlined } from "@ant-design/icons";
import style from "../modalTiepDon.module.less";
import { UarrowCircle } from "assets/svg";
import React, { useMemo, useState } from "react";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useEventListener } from "hooks";
import { TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI } from "constants/keys";
import TableKhamDv from "../components/TableKhamDv/TableKhamDv";
import DanhsachMauDichvu from "pages/QuanLyTiepDon/components/DsMauDichvu";
import { formatNumberToPrice } from "helpers";

export const TabKhamDichVu = ({
  form,
  hideDrawer,
  // selectedDoiTuong,
  dsDvClsDaChon,
  dsDvKhamBenhDaChon,
  setDsDvClsDaChon,
  setDsDvKhamBenhDaChon,
  visible,
  siderDvRef,
  currentTab,
  setCurrentTab,
  tabs,
  disableDichVu,
  showSaveBtns,
  lichKhamInfo,
  allowAddNewServices,
  openTTBN,
  setOpenTTBN,
  openKhaiThac,
  setOpenKhaiThac,
  readOnly,
  disableIn,
  checkedContinueAdding,
  disableBenhNhan,
  disableThemMoi,
  layDsIdDvDaChon,
  txtNote,
  luuMau,
  hightlighter,
  duplicateSerArr = [],
  setCheckedContinueAdding = () => {},
  onPrint = () => {},
  setTxtNote = () => {},
  setLuuMau = () => {},
  onClose = () => {},
  onFinish = () => {},
  selectedGoiService,
  //
  disabledClick,
  checkShowModal,
  // setBarcodeQuanLy,
  // barcodeQuanLy,
  // statusDuyetSID,
  // onHuyBarcode,
  // maxlengthSID,
  // getSIDApi,
  sid,
  totalExp,
  setTotalExp = () => {},
}) => {

  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState([]);
  // const [disabledClick, setDisabledClick] = useState(false)

  // const onSubmit = async () => {
  //   setDisabledClick(true)
  //   try {
  //     let value = await form.validateFields();
  //     if (isRemainDup()) return;
  //     let parsedValue;
  //     if (selectedPacket) {
  //       if (typeof selectedPacket === "string") {
  //         parsedValue = JSON.parse(selectedPacket);
  //       } else {
  //         parsedValue = selectedPacket;
  //       }
  //     }
  //     // if (!parsedValue) throw new Error("Không có gói được chọn");

  //     let res;
  //     // value.DOI_TUONG_BENH_NHAN = selectedDoiTuong;
  //     value.DM_NGUON_KHACH_ID = selectedNguonKhach;
  //     if (!lichKhamInfo) {
  //       if (selectedPatient) {
  //         delete selectedPatient.LICH_KHAM_ID;
  //       }

  //       // let checkGioKhamBenh = checkGioLamViec(dsDvKhamBenhDaChon);
  //       // let checkGioCLS = checkGioLamViec(dsDvClsDaChon);
  //       // if (checkGioKhamBenh.length > 0 || checkGioCLS.length > 0) {
  //       //   let arrPhong = checkGioKhamBenh.concat(checkGioCLS);
  //       //   let message = `Phòng ${arrPhong.join("; ")} đã hết giờ làm việc`;
  //       //   notification.error({
  //       //     placement: "bottomLeft",
  //       //     message: message,
  //       //   });
  //       //   return;
  //       // }
  //       res = await doCreateAppointment(
  //         {
  //           ...selectedPatient,
  //           ...value,
  //           GHI_CHU: txtNote,
  //           IS_MAU: luuMau,
  //         },
  //         dsDvKhamBenhDaChon,
  //         dsDvClsDaChon,
  //         parsedValue?.GOI_DV_ID,
  //         selectedGoiService
  //       );
  //     } else {
  //       res = await doUpdateAppointment(
  //         {
  //           ...lichKhamInfo,
  //           ...selectedPatient,
  //           ...value,
  //           GHI_CHU: txtNote,
  //           IS_MAU: luuMau,
  //         },
  //         dsDvKhamBenhDaChon,
  //         dsDvClsDaChon,
  //         parsedValue?.GOI_DV_ID,
  //         selectedGoiService
  //       );
  //     }

  //     return res;
  //   } catch (error) {

  //   } finally {
  //     setDisabledClick(false)
  //   }

  // };

  const disableSaveCondition =
    (lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ||
    (dsDvKhamBenhDaChon.length === 0 && dsDvClsDaChon.length === 0 && selectedGoiService.length === 0) ||
    !allowAddNewServices;


  // hàm xử lý phím tắt
  function keydownEvent(event) {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (showSaveBtns(lichKhamInfo) && !hideDrawer) {
      if (event.ctrlKey && event.key === "i" && !!lichKhamInfo) {
        stopDefaultAction(event);
        onPrint(lichKhamInfo.LICH_KHAM_ID, layDsIdDvDaChon());
      } else if (event.ctrlKey && event.key === "s" && !disableSaveCondition) {
        stopDefaultAction(event);
        !disabledClick && onFinish(false, checkShowModal);
      } else if (event.ctrlKey && event.key === "l" && !disableSaveCondition) {
        stopDefaultAction(event);
        !disabledClick && onFinish(true, checkShowModal);
      }
    }
  }
  useEventListener("keydown", keydownEvent, window, visible);


  const checkDisabledModel = useMemo(() => {
    if (Array.isArray(dsDvClsDaChon) && Array.isArray(dsDvKhamBenhDaChon)) {
      let checkDisabled = [...dsDvClsDaChon, ...dsDvKhamBenhDaChon].some(c => {
        if (c.THANH_TOAN === 1) return true;
        if (c.THANH_TOAN === 1 && c.THANH_TIEN === 0) return true;

        if (
          c.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
          c.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
          c.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH ||
          c.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN"
        ) {
          return true;
        }

        return false
      });
      return checkDisabled
    } else {
      return true;
    }
  }, [dsDvClsDaChon, dsDvKhamBenhDaChon]);

  const handleSelectCombo = (dv) => {
    try {
      setTimeout(() => {
        siderDvRef.current.onTreeClsSelect({ value: { ...dv, MA_NHOM_BHYT: "COMBO_DV" }, selectable: true, checked: false });
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }
  const checkDisabledService = !lichKhamInfo ? false : disableDichVu || !showSaveBtns(lichKhamInfo) || !allowAddNewServices;


  const dataSource = dsDvKhamBenhDaChon.concat(dsDvClsDaChon)
  //
  const count = dataSource
    .reduce((sum, item) => sum + (item.SO_LUONG ? item.SO_LUONG : 0), 0);
  //
  const totalAll = dataSource
    .reduce((sum, item) => sum + (
      item.SO_LUONG && item.GIA_DICH_VU
        ? item.SO_LUONG * item.GIA_DICH_VU
        : 0
    ), 0)
  //
  const totalExemp = dataSource.reduce((sum, item) => sum + (
    item.MIEN_GIAM_TIEP_DON
      ? (item.MIEN_GIAM_TIEP_DON > 100
        ? item.MIEN_GIAM_TIEP_DON
        : (item.GIA_DICH_VU * item.SO_LUONG / 100 * item.MIEN_GIAM_TIEP_DON))
      : 0
  ), 0)
  //
  const totalBillExp = totalExemp + (
    totalExp > 100
      ? totalExp
      : (totalAll - totalExemp) / 100 * totalExp
  )


  return (
    <React.Fragment>
      <SiderDvTiepDon
        dsDvClsDaChon={dsDvClsDaChon}
        dsDvKhamBenhDaChon={dsDvKhamBenhDaChon}
        setDsDvClsDaChon={setDsDvClsDaChon}
        setDsDvKhamBenhDaChon={setDsDvKhamBenhDaChon}
        visible={visible}
        ref={siderDvRef}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabs={tabs}
        disabled={checkDisabledService}
        openTTBN={openTTBN}
        setExpandedRow={setExpandedRow}
        expandedRow={expandedRow}
      />
      <Layout>
        <PageHeader className={style["contentHeader"]}>
          <Row gutter={10} justify={"space-between"}>
            <Col>
              <h2
                onClick={() => {
                  setOpenTTBN(!openTTBN);
                  openKhaiThac && setOpenKhaiThac(!openKhaiThac);
                }}
                style={{ cursor: "pointer" }}
              >
                {i18n.t(languageKeys.thong_tin_kham_dv)}
                <Icon component={UarrowCircle} style={{ fontSize: 16, paddingInline: 8, rotate: openTTBN ? "" : "180deg", transition: "all 0.5s" }} />
              </h2>
            </Col>
            <Col>
              <DanhsachMauDichvu
                open={visible}
                setDsDvKhamBenhDaChon={setDsDvKhamBenhDaChon}
                setDsDvClsDaChon={setDsDvClsDaChon}
                loadingDv={loading}
                setLoadingDv={setLoading}
                disabled={checkDisabledModel}
                onSelectCombo={handleSelectCombo}
              />
            </Col>
          </Row>
        </PageHeader>

        <Layout.Content style={{ padding: 10 }}>
          <div className={style["spin"]}>
            <Spin spinning={loading} style={{ height: 'calc(100% - 60px)' }}  >
              <TableKhamDv
                dsDvClsDaChon={dsDvClsDaChon}
                setDsDvClsDaChon={setDsDvClsDaChon}
                dsDvKhamBenhDaChon={dsDvKhamBenhDaChon}
                setDsDvKhamBenhDaChon={setDsDvKhamBenhDaChon}
                readOnly={!showSaveBtns(lichKhamInfo) || readOnly}
                isCreateNew={!lichKhamInfo}
                onPrint={onPrint}
                scheduleInfo={lichKhamInfo}
                disableDichVu={disableDichVu}
                disableIn={disableIn}
                form={form}
                hightlighter={hightlighter}
                duplicateSerArr={duplicateSerArr}
                setExpandedRow={setExpandedRow}
                expandedRow={expandedRow}
              />
            </Spin>
          </div>

          <Layout style={{ marginTop: 10 }}>
            <Layout.Content className={style["note-container"]}>
              <span>{i18n.t(languageKeys.field_Ghi_chu)}</span>
              <Input.TextArea
                // autoSize
                className={style["note"]}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                value={txtNote}
                onChange={(e) => setTxtNote(e.target.value)}
                readOnly={readOnly}
              />
            </Layout.Content>
            <Layout.Sider
              width={320}
              theme="light"
              className={style.summary}
            >
              <Space direction="vertical" className={style['money_container']} style={{ marginBottom: 5 }}>
                <Space>
                  <span>{i18n.t(languageKeys.tong_tien)}:</span>
                  <b>{formatNumberToPrice(totalAll)}</b>
                </Space>
                <Space>
                  <span>{i18n.t(languageKeys.txt_So_luong)}:</span>
                  <b>{count}</b>
                </Space>
                <Space>
                  <span>{i18n.t(languageKeys.mien_giam_hoa_don)}:</span>
                  <InputNumber
                    onChange={setTotalExp}
                    value={totalExp}
                    className={style.exemption}
                    placeholder={i18n.t(languageKeys.common_Nhap)}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={totalExp > 100 ? "đ" : "%"}
                    size="middle"
                    readOnly={readOnly || dataSource.some(i => !!i.THANH_TOAN)}
                    min={0}
                    max={totalAll}
                    controls={false}
                  />
                </Space>
                <Space>
                  <span>{i18n.t(languageKeys.vienphi_tong_mien_giam)}:</span>
                  <b>{formatNumberToPrice(totalBillExp)}</b>
                </Space>
                <Space>
                  <span>{i18n.t(languageKeys.tong_thanh_toan)}:</span>
                  <b>{formatNumberToPrice(totalAll - totalBillExp)}</b>
                </Space>
              </Space>
            </Layout.Sider>
          </Layout>

        </Layout.Content>

        <Layout.Footer className={style["footer"]}>
          <div>
            <div style={{ display: "flex" }}>
              <Form.Item>
                <Checkbox
                  checked={luuMau}
                  onChange={(e) => {
                    let { checked } = e.target;
                    setLuuMau(+checked);
                    if (!checked) {
                      form.resetFields(["TEN_MAU"]);
                    }
                  }}
                >
                  <span className="navy-txt">{i18n.t(languageKeys.luu_mau_chi_dinh_dich_vu)}</span>
                </Checkbox>
              </Form.Item>
              <Form.Item hidden={!luuMau} name={"TEN_MAU"}>
                <Input style={{ width: 200 }} placeholder={i18n.t(languageKeys.nhap_ten_mau)} />
              </Form.Item>
            </div>
            <div style={{ paddingBottom: 8, display: "flex", gap: 10, alignItems: "center" }}>
              {!lichKhamInfo ? (
                <Checkbox checked={checkedContinueAdding} onChange={(e) => setCheckedContinueAdding(e.target.checked)}>
                  <span className="navy-txt">{i18n.t(languageKeys.tiepdon_nhap_lien_tuc)}</span>
                </Checkbox>
              ) : (
                <div></div>
              )}
              {/* <div style={{ display: "flex", gap: 10, alignItems:"center", color:"#2c3782" }}><span>SID</span><Form.Item
                style={{ marginBottom: 0 }}
                name={"BARCODE_XET_NGHIEM"}
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: i18n.t(languageKeys.nhap_dung_dinh_dang_so),
                  },
                ]}
              >
                <Input
                  readOnly={statusDuyetSID || barcodeQuanLy}
                  maxLength={maxlengthSID}
                  // suffix={renderPrintBarcodeButton()}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, display:"none" }}
                name={"BARCODE_QUAN_LY"}
              >
                <Input
                />
              </Form.Item>
              { !barcodeQuanLy && userProfile?.config?.BARCODE_XET_NGHIEM == "BARCODE_VINH_VIEN" && (
                  <Space align="center" direction="vertical">
                    <Button type="primary" 
                    onClick={() => getSIDApi()}
                     >
                      {i18n.t(languageKeys.cap)} SID
                    </Button>
                  </Space>
                )}
              {barcodeQuanLy && (
                <Space align="center" direction="vertical">
                  <Button
                    type="primary"
                    onClick={() => onHuyBarcode()}
                  >
                    {i18n.t(languageKeys.common_Huy)} SID
                  </Button>
                </Space>
              )}
              
              </div> */}
              {sid && sid()}
            </div>
          </div>

          <Space>
            <Button type="primary" ghost onClick={onClose}>
              {i18n.t(languageKeys.common_Thoat)} (ESC)
            </Button>

            {showSaveBtns(lichKhamInfo) && (
              <>
                {/* {lichKhamInfo && (
                  <Button
                    type="primary"
                    icon={<PrinterOutlined />}
                    onClick={() => onPrint(undefined, layDsIdDvDaChon())}
                  // disabled={!checkPermisson.IN_PDF}
                  >
                    {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                  </Button>
                )} */}
                <Button
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={() => onPrint(undefined, layDsIdDvDaChon())}
                // disabled={!checkPermisson.IN_PDF}
                >
                  {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                </Button>
                <Tooltip title={(lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    type="primary"
                    onClick={() => onFinish(true, checkShowModal)}
                    disabled={disableSaveCondition || disabledClick}
                  >
                    {i18n.t(languageKeys.luu_va_in)} (Ctrl + L)
                  </Button>
                </Tooltip>
                <Tooltip title={(lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    type="primary"
                    onClick={() => onFinish(false, checkShowModal)}
                    disabled={disableSaveCondition || disabledClick}
                  >
                    {i18n.t(languageKeys.common_Luu)} (Ctrl + S)
                  </Button>
                </Tooltip>
              </>
            )}
          </Space>
        </Layout.Footer>

      </Layout>
    </React.Fragment>
  );
};