import { AutoComplete, Input, Spin } from "antd";
import { useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";

const SelectHDSD = ({ readOnly = false, onSelect = () => {}, value, rowIndex, dataSource, loading }) => {

  const [open, setOpen] = useState(false)

  const handleChange = (val) => {
    onSelect(val, rowIndex, "Chidan")
    setOpen(false)
  }

  return readOnly ? <Input readOnly value={value} />
    : (
      <Spin spinning={loading}>
        <AutoComplete
          open={open}
          options={dataSource.map(i => ({ value: i.HDSD }))}
          disabled={readOnly}
          defaultValue={value}
          placeholder={i18n.t(languageKeys.common_Nhap)}
          allowClear
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={handleChange}
          onBlur={(e) => handleChange(e.target.value)}
          onFocus={() => setTimeout(() => setOpen(true), 100)}
          onClick={() => setOpen(!open)}
        />
      </Spin>
    )
};

export default SelectHDSD;