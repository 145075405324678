import { Select } from "components_v2";
import i18n, { languageKeys } from "../../../../i18n";
import { checkHasAvailableRoom } from "pages/QuanLyTiepDon/ModalTiepDon.new/helpers";
import { useSelector } from "react-redux";
import { getRoomWithServiceId } from "helpers";
import { useEffect } from "react";

const SelectPhongThHien = ({ value, record, onSelect, readOnly }) => {

  const { PHONG } = useSelector((state) => state.data);

  useEffect(() => {
    if (record.LIST_PHONG_ID) {
      if (!record.LIST_PHONG_ID?.includes(value)) onSelect({}, record)
    } else {
      if (record.PHONG_THUC_HIEN_ID !== value) onSelect({}, record)
    }
  },[])

  const layDsPhongTheoDichVuLocal = (dichvu) => {
    let result = [];
    if (dichvu?.DV_KEM_THEO_ID || dichvu?.DICH_VU_GOC || dichvu?.IS_GOI_DV) {
      if (dichvu.PHONG_THUC_HIEN_ID) result = getRoomWithServiceId(PHONG, [dichvu.PHONG_THUC_HIEN_ID])
    } else {
      if (dichvu.LIST_PHONG_ID) result = getRoomWithServiceId(PHONG, dichvu.LIST_PHONG_ID)
    }
    return result;
  }

  return (
    <Select
      value={value}
      dataSource={layDsPhongTheoDichVuLocal(record)}
      onChange={(data) => onSelect(data, record)}
      labelKey={"TEN_KHOA_PHONG"}
      valueKey={"ID"}
      allowClear={false}
      tableProps={{ visible: true, columns }}
      filterOption={false}
      status={value ? "" : "error"}
      readOnly={readOnly}
      style={{ width: '100%' }}
      showSearch={false}
    />
  )
}

export default SelectPhongThHien;


const columns = [
  {
    title: i18n.t(languageKeys.field_Ten_phong),
    dataIndex: 'TEN_KHOA_PHONG',
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_So_phong),
    dataIndex: 'MA_PHONG',
    width: 90
  },
  {
    title: i18n.t(languageKeys.label_Thoi_gian),
    dataIndex: 'GIO_BAT_DAU',
    width: 100,
    render: (_, record) => `${!!record?.GIO_BAT_DAU ? record?.GIO_BAT_DAU + " " : ""}-${!!record?.GIO_KET_THUC ? " " + record?.GIO_KET_THUC : ""}`,
  },
  {
    title: i18n.t(languageKeys.field_Trang_thai),
    dataIndex: 'GIO_KET_THUC',
    width: 130,
    render: (_, record) => {
      if (!checkHasAvailableRoom(record)) {
        return <div className="red-txt">{i18n.t(languageKeys.da_nghi)}</div>;
      }
      return <div className="green-txt">{i18n.t(languageKeys.dang_hoat_dong)}</div>;
    },
  },
]