import { Form, Input, notification, Tooltip, Collapse } from "antd";
import { useImperativeHandle, memo, forwardRef, useState, useMemo } from "react";
import { TextEditor } from "../../../components";
import { removeTags, validFragment } from "../../../../../helpers";
import { Select, TreeSelect } from "../../../../../components_v2";
import style from "./formMoTa.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { UploadFileWord } from "./UploadFileWord";
import { useDichVuCoHieuLuc } from "../../mauMoTaCdha.hook";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { DownOutlined } from "@ant-design/icons";

const key_word_KET_QUA = "kết luận";
const key_word_KET_QUA_2_cham = "kết luận:";
const key_word_KQ = "kl";
const key_word_KQ_2_cham = "kl:";

let FormMoTa = ({ onSave = () => {}, selectedSample, onClickEscape = () => {}, isEditAllowed = false, isAddAllowed = false }, ref) => {
  const [form] = Form.useForm();
  const [htmlSample, setHtmlSample] = useState(""); // html trong field mô tả
  const [selectedNhomDV, setSelectedNhomDV] = useState(); // nhóm DV đã chọn
  const [selectedLoaiDV, setSelectedLoaiDV] = useState(); // loại DV đã chọn
  const [selectedListDV, setSelectedListDV] = useState([]); // danh sách DV đã chọn
  const {
    getListNhomDV,
    listNhomDV,
    loadingListNhomDV,
    setListNhomDV,
    getListLoaiDV,
    listLoaiDV,
    loadingListLoaiDV,
    setListLoaiDV,
    getListDV,
    listDV,
    loadingListDV,
    setListDV,
  } = useDichVuCoHieuLuc();
  const userProfile = useSelector((state) => state.auth.user);
  const [searchValueDichVu, setSearchValueDichVu] = useState();

  // const isAllowTo = useMemo(() => {
  //   let editItem = false;
  //   let createItem = false;
  //   if (userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri) {
  //     editItem = true;
  //     createItem = true;
  //   } else if (!!userProfile.QUYEN) {
  //     let dsQuyen = [];

  //     if (isJsonString(userProfile.QUYEN)) {
  //       dsQuyen = JSON.parse(userProfile.QUYEN);
  //     } else {
  //       dsQuyen = userProfile.QUYEN;
  //     }
  //     editItem = dsQuyen.some((quyen) => quyen.includes(featureKeys.sua_mau_mo_ta_cdha));
  //     createItem = dsQuyen.some((quyen) => quyen.includes(featureKeys.tao_mau_mo_ta_cdha));
  //   }

  //   return { editItem, createItem };
  // }, [userProfile]);

  const readOnly = !!selectedSample ? !isEditAllowed && !!selectedSample : !isAddAllowed;

  const formattedListDV = useMemo(() => {
    return listDV.map((dich_vu) => ({
      key: dich_vu?.ID,
      title: dich_vu?.TEN_DICHVU,
      value: dich_vu?.ID,
      fullValue: dich_vu,
    }));
  }, [listDV]);

  useImperativeHandle(ref, () => ({
    initForm,
    submitForm,
    resetForm,
  }));

  /**
   * Khởi tạo form mẫu mô tả
   * @param {object} sample mẫu mô tả vừa chọn
   */
  const initForm = (sample) => {
    setHtmlSample(sample?.MO_TA);
    form?.setFields(
      Object.keys(sample).map((name) => {
        let value = sample[name];

        if (name === "DS_DV") value = sample[name]?.map((dich_vu) => dich_vu.DV_ID);

        return { name, value, errors: [] };
      })
    );
    setSelectedListDV(sample?.DS_DV);
    setSelectedLoaiDV({
      LOAI_DV_ID: sample?.LOAI_DV_ID,
      TEN_LOAI: sample?.TEN_LOAI,
    });
    setSelectedNhomDV({
      NHOM_DV_ID: sample?.NHOM_DV_ID,
      TEN_NHOM: sample?.TEN_NHOM,
    });
  };

  /**
   * Lưu thông tin form mô tả
   * @returns {Promise<object>}
   */
  const submitForm = async () => {
    console.log(form.getFieldsValue());
    try {
      let values = await form.validateFields();
      validateMoTaKetLuan(values);
      values.MO_TA = formatMoTa(htmlSample);
      values.DS_DV_ID = selectedListDV.filter((item) => typeof item === "object").map((dich_vu) => dich_vu.DV_ID);
      values.NHOM_DV_ID = selectedNhomDV.NHOM_DV_ID;
      values.LOAI_DV_ID = selectedLoaiDV.LOAI_DV_ID;
      values.LOAI_DV = selectedLoaiDV;

      values = (await validFragment([values], formStandard))[0];

      return values;
    } catch (error) {
      if (!error.errorFields) throw error;

      const formattedError = error.errorFields.map((field) => `${field.errors} ${field.name}`).join(" ; ");

      throw Error(JSON.stringify(formattedError));
    }
  };

  const validateMoTaKetLuan = (values) => {
    let errors = [];

    if (!htmlSample) {
      errors.push(i18n.t(languageKeys.noti_chua_nhap_mo_ta));
    }

    if (!values.KET_LUAN) {
      errors.push(i18n.t(languageKeys.noti_chua_nhap_ket_luan));
    }

    if (errors.length > 0) {
      errors.forEach((message) => notification.error({ message }));
      throw Error(errors);
    }
  };

  const formatMoTa = (html = "") => {
    html = html.replaceAll("page-break-before: always;", "");
    return html;
  };

  // reset toàn bộ data form mô tả về mặc định ban đầu (trống toàn bộ)
  const resetForm = () => {
    form.resetFields();
    setHtmlSample("");
    setSelectedNhomDV();
    setSelectedLoaiDV();
    setSelectedListDV([]);
    setListNhomDV([]);
    setListLoaiDV([]);
    setListDV([]);
  };

  const handleOpenDropdownNhomDV = (visible) => {
    if (visible && listNhomDV.length === 0) {
      getListNhomDV();
    }
  };

  const handleSelectNhomDV = (nhom_dv) => {
    setSelectedNhomDV(nhom_dv);
    form.setFieldValue("TEN_NHOM", nhom_dv.TEN_NHOM);

    if (!!selectedLoaiDV) setSelectedLoaiDV();
    if (selectedListDV.length > 0) setSelectedListDV([]);
    if (listLoaiDV.length > 0) setListLoaiDV([]);
    if (listDV.length > 0) setListDV([]);
    form.resetFields(["TEN_LOAI", "DS_DV"]);
  };

  const handleOpenDropdownLoaiDV = (visible) => {
    if (visible && listLoaiDV.length === 0 && !!selectedNhomDV) {
      const NHOM_DV_ID = selectedNhomDV?.NHOM_DV_ID;

      getListLoaiDV("", { NHOM_DV_ID });
    }
  };

  const handleSelectLoaiDV = (loai_dv) => {
    setSelectedLoaiDV(loai_dv);
    form.setFieldValue("TEN_LOAI", loai_dv.TEN_LOAI);

    if (selectedListDV.length > 0) setSelectedListDV([]);
    if (listDV.length > 0) setListDV([]);
    form.resetFields(["DS_DV"]);
  };

  const handleOpenDropdownListDV = (visible) => {
    if (visible && listDV.length === 0 && !!selectedLoaiDV) {
      const LOAI_DV_ID = selectedLoaiDV?.LOAI_DV_ID;

      getListDV("", { LOAI_DV_ID });
    }
  };

  /**
   * Xử lý chọn DV trong danh sách DV
   * @param {string} ten_dv
   * @param {object} dich_vu
   */
  const handleSelectDV = (ten_dv, dich_vu) => {
    if (ten_dv === "Tất cả") {
      setSelectedListDV(listDV);
    }
    setSelectedListDV((arr) => [...arr, dich_vu]);
  };

  const handleDeSelectDV = (ten_dv) => {
    if (ten_dv === "Tất cả") {
      setSelectedListDV([]);
    }
    setSelectedListDV((arr) => arr.filter((dich_vu) => dich_vu?.DV_ID !== ten_dv));
  };

  const handleSetMoTaKetLuan = (html = "") => {
    const bodyHtml = getBodyHtml(html);
    const [ketLuan, keyWordKQ] = getTextKetLuan(bodyHtml);
    const moTa = getHtmlMoTa(bodyHtml, keyWordKQ);

    setHtmlSample(moTa);
    form.setFields([{ name: "KET_LUAN", value: ketLuan.trim() || "" }]);
  };

  const getBodyHtml = (html) => {
    const bodyTags = html.match(/<\/*body[^>]*>/gim);
    const indexOfFirstBodyTag = html.indexOf(bodyTags[0]);
    const indexOfLastBodyTag = html.indexOf(bodyTags[1]);
    return html.slice(indexOfFirstBodyTag + bodyTags[0].length, indexOfLastBodyTag - 1);
  };

  const getTextKetLuan = (html) => {
    const noTagPlainText = removeTags(html);
    const plainText = noTagPlainText.replaceAll(/\s\s+/g, " ").replaceAll(/\s/g, " ").trim();

    console.log("[getTextKetLuan] plain text", plainText);

    const indexPlainKetQua = plainText.toLowerCase().indexOf(key_word_KET_QUA);
    const indexPlainKetQua_2cham = plainText.toLowerCase().indexOf(key_word_KET_QUA_2_cham);
    const indexPlainKQ = plainText.toLowerCase().indexOf(key_word_KQ);
    const indexPlainKQ_2cham = plainText.toLowerCase().indexOf(key_word_KQ_2_cham);

    let keyWordLength = -1;
    let selectedKeyWord = "";

    if (indexPlainKetQua_2cham > -1) {
      keyWordLength = indexPlainKetQua_2cham + key_word_KET_QUA_2_cham.length;
      selectedKeyWord = key_word_KET_QUA_2_cham;
    } else if (indexPlainKQ_2cham > -1) {
      keyWordLength = indexPlainKQ_2cham + key_word_KQ_2_cham.length;
      selectedKeyWord = key_word_KQ_2_cham;
    } else if (indexPlainKetQua > -1) {
      keyWordLength = indexPlainKetQua + key_word_KET_QUA.length;
      selectedKeyWord = key_word_KET_QUA;
    } else if (indexPlainKQ > -1) {
      keyWordLength = indexPlainKQ + key_word_KQ.length;
      selectedKeyWord = key_word_KQ;
    }

    let ketLuan = "";

    if (keyWordLength > -1) {
      ketLuan = plainText.slice(keyWordLength, plainText.length);
    }

    return [ketLuan, selectedKeyWord];
  };

  const getHtmlMoTa = (html = "", keyWordKQ) => {
    try {
      if (!keyWordKQ) return html;

      var tempDivElement = document.createElement("div");

      tempDivElement.innerHTML = html.replaceAll(/\s\s+/g, " ").replaceAll(/\s/g, " ");

      html = tempDivElement.outerHTML;

      const index = html.toLowerCase().indexOf(keyWordKQ);

      return html.slice(0, index);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDownTextEditor = (e) => {
    if (e.key === "s" && e.ctrlKey && isEditAllowed) {
      e.stopPropagation();
      e.preventDefault();
      onSave();
    }

    // if (e.key === "Escape" && !!selectedSample) {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      onClickEscape();
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{
        required: i18n.t(languageKeys.vui_long_nhap),
      }}
      className={style["container"]}
      disabled={!!selectedSample ? !isEditAllowed : !isAddAllowed}
    >
      <div className={style["form-wrap-row"]}>
        <Collapse
          bordered={false}
          // expandIcon={({ isActive }) => {
          //   return isFromThongTinTTBenhNhan ? "" : <CaretRightOutlined rotate={isActive ? 90 : 0} />
          // }}
          defaultActiveKey={["TTKH"]}
        >
          <Collapse.Panel header={<span className={style["thong-tin"]}>{i18n.t(languageKeys.thong_tin)}</span>} key="TTKH">
            <div className={style["form-row"]}>
              <Form.Item label={i18n.t(languageKeys.field_ma_kq_dv)} name="MA_MAU" className={style["form-col"]}>
                <Input readOnly placeholder={i18n.t(languageKeys.tu_dong)} />
              </Form.Item>

              <Form.Item label={i18n.t(languageKeys.field_ten_kq_dv)} name="TEN_MAU" rules={[{ required: true }]} className={style["form-col"]}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
              </Form.Item>

              <Form.Item label={i18n.t(languageKeys.chon_nhom_dv)} name="TEN_NHOM" rules={[{ required: true }]} className={style["form-col"]}>
                {readOnly ? (
                  <Input readOnly />
                ) : (
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    allowClear={false}
                    dataSource={listNhomDV}
                    loading={loadingListNhomDV}
                    onDropdownVisibleChange={handleOpenDropdownNhomDV}
                    labelKey="TEN_NHOM"
                    valueKey="TEN_NHOM"
                    onSelect={handleSelectNhomDV}
                  />
                )}
              </Form.Item>

              <Tooltip title={!selectedNhomDV && i18n.t(languageKeys.warning_chon_nhom_dv)}>
                <Form.Item label={i18n.t(languageKeys.chon_loai_dv)} name="TEN_LOAI" rules={[{ required: true }]} className={style["form-col"]}>
                  {readOnly || !selectedNhomDV ? (
                    <Input
                      readOnly
                      placeholder={!readOnly && i18n.t(languageKeys.common_Chon)}
                      suffix={<DownOutlined style={{ color: "#cecece" }} />}
                    />
                  ) : (
                    <Select
                      placeholder={i18n.t(languageKeys.common_Chon)}
                      allowClear={false}
                      dataSource={listLoaiDV}
                      loading={loadingListLoaiDV}
                      onDropdownVisibleChange={handleOpenDropdownLoaiDV}
                      labelKey="TEN_LOAI"
                      valueKey="TEN_LOAI"
                      onSelect={handleSelectLoaiDV}
                    />
                  )}
                </Form.Item>
              </Tooltip>

              <Tooltip title={!selectedLoaiDV && i18n.t(languageKeys.warning_chon_loai_dv)}>
                <Form.Item label={i18n.t(languageKeys.chon_dv)} name="DS_DV" className={style["form-col"]}>
                  {!selectedLoaiDV ? (
                    <Input readOnly placeholder={!readOnly && i18n.t(languageKeys.common_Chon)} />
                  ) : (
                    <TreeSelect
                      placeholder={i18n.t(languageKeys.common_Chon)}
                      allowClear={false}
                      dataSource={[
                        {
                          key: "Tất cả",
                          title: i18n.t(languageKeys.tat_ca),
                          value: "Tất cả",
                          fullValue: "Tất cả",
                          children: formattedListDV,
                        },
                      ]}
                      loading={loadingListDV}
                      onDropdownVisibleChange={handleOpenDropdownListDV}
                      maxTagCount={0}
                      onSelect={handleSelectDV}
                      onDeselect={handleDeSelectDV}
                      readOnly={readOnly}
                      searchValue={searchValueDichVu}
                      onSearch={setSearchValueDichVu}
                      isParentUncheckable={false}
                      popupClassName={style["popup-services"]}
                      filterTreeNode={(search, item) => {
                        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                      }}
                    />
                  )}
                </Form.Item>
              </Tooltip>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>

      <div className={style["text-editor-wrapper"]}>
        <p className={style["title"]}>{i18n.t(languageKeys.mo_ta)}</p>
        <TextEditor
          value={htmlSample}
          onChange={setHtmlSample}
          className={classNames(style["text-editor-mota"], readOnly && style["read-only"])}
          onKeyDown={handleKeyDownTextEditor}
          disabled={readOnly}
        />
        {!readOnly && (
          <div className={style["btn-docx"]}>
            <UploadFileWord setValue={handleSetMoTaKetLuan} />
          </div>
        )}
      </div>

      <Form.Item label={i18n.t(languageKeys.field_ket_luan)} name="KET_LUAN" className={style["form-ket-luan"]}>
        {/* <Input.TextArea rows={2} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} /> */}
        <TextEditor
          className={classNames(style["text-editor"], readOnly && style["read-only"])}
          disabled={readOnly}
          menubar={true}
          toolbar={[
            "insertfile undo redo | styleselect | bold italic underline fontsizeselect lineheight| forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
          ]}
        />
      </Form.Item>
    </Form>
  );
};

FormMoTa = forwardRef(FormMoTa);
FormMoTa = memo(FormMoTa);
export { FormMoTa };

const formStandard = {
  ID: "",
  TEN_MAU: "",
  NHOM_DV_ID: "",
  LOAI_DV_ID: "",
  DS_DV_ID: ["", "", ""],
  MO_TA: "",
  KET_LUAN: "",
  LOAI_DV: "",
};
