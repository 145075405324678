import Icon, { PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Row, Space, Switch, Tooltip } from "antd";
import { memo, useMemo } from "react";
import { HLog, isJsonString } from "../../../../helpers";
import style from "./layouts.module.less";
import { Delete } from "../../../../assets/svg";
import { useSelector } from "react-redux";
import { featureKeys, keyLoaiNhanVien } from "../../../../constants/keys";
import i18n from "../../../../i18n";
import languageKeys from "../../../../i18n/languageKeys";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";

let TopBar = (
  { 
    selectedSample,
    onClickAddNew,
    handleUpdateStatus = () => {},
    handleDelete = () => {},
    loadingSwitch = false,
    isAddAllowed=false,
    isLockAllowed=false,
    isDeleteAllowed=false
  }) => {
  const userProfile = useSelector((state) => state.auth.user);

  // const isAllowTo = useMemo(() => {
  //   let createItem = false;
  //   let deleteItem = false;
  //   let lockItem = false;

  //   if (userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri) {
  //     createItem = true;
  //     deleteItem = true;
  //     lockItem = true;
  //   } else if (!!userProfile.QUYEN) {
  //     if (isAddAllowed) createItem = true;
  //     if (isDeleteAllowed) deleteItem = true;
  //     if (isLockAllowed) lockItem = true;
  //   }

  //   return { createItem, deleteItem, lockItem };
  // }, [userProfile]);

  HLog.render("Top Bar");

  const topBarContent = useMemo(() => {
    const isAtCreateView = !selectedSample;

    if (isAtCreateView) {
      return (
        <Space>
          <PlusCircleFilled className={style["plus-icon"]} />
          <h4>{i18n.t(languageKeys.title_Tao_mau_mo_ta)}</h4>
        </Space>
      );
    }

    return (
      <>
        <h4>{i18n.t(languageKeys.title_Tao_mau_mo_ta)}</h4>

        <Row size={10} align="middle">
          {/* {isAllowTo.lockItem && (
            <div className={style["box-action"]}>
              <CheckRoleTooltip hasTooltip={false} isAllowed={isLockAllowed}>
                <Switch checked={Boolean(selectedSample.KHOA)} onChange={handleUpdateStatus} loading={loadingSwitch} disabled={!isLockAllowed}/>
                <span className={style["text"]}>{i18n.t(languageKeys.field_Hien_thi)}</span>
              </CheckRoleTooltip>
            </div>
          )} */}
          <div className={style["box-action"]}>
              <CheckRoleTooltip hasTooltip={false} isAllowed={isLockAllowed}>
                <Switch checked={Boolean(selectedSample.KHOA)} onChange={handleUpdateStatus} loading={loadingSwitch} disabled={!isLockAllowed}/>
                <span className={style["text"]}>{i18n.t(languageKeys.field_Hien_thi)}</span>
              </CheckRoleTooltip>
            </div>

          {/* {isAllowTo.deleteItem && (
            <div className={style["box-action"]}>
              <CheckRoleTooltip oriTitle={i18n.t(languageKeys.xoa)} hasTooltip={true} isAllowed={isDeleteAllowed}>
                <Button
                  icon={<Icon component={Delete} style={{ fontSize: "20px" }} />}
                  style={{ backgroundColor: "#F5F7FB", border: "none", color: "#999999" }}
                  onClick={handleDelete}
                  disable={!isDeleteAllowed}
                ></Button>
              </CheckRoleTooltip>
            </div>
          )} */}
          <div className={style["box-action"]}>
              <CheckRoleTooltip oriTitle={i18n.t(languageKeys.xoa)} hasTooltip={true} isAllowed={isDeleteAllowed}>
                <Button
                  icon={<Icon component={Delete} style={{ fontSize: "20px" }} />}
                  style={{ backgroundColor: "#F5F7FB", border: "none", color: "#999999" }}
                  onClick={handleDelete}
                  disabled={!isDeleteAllowed}
                ></Button>
              </CheckRoleTooltip>
            </div>

          {/* {isAllowTo.createItem && (
            <CheckRoleTooltip hasTooltip={false} isAllowed={isAddAllowed}>
              <Button type="primary" onClick={onClickAddNew} icon={<PlusOutlined />}>
                {i18n.t(languageKeys.tao_moi_mau_mo_ta)}
              </Button>
            </CheckRoleTooltip>
          )} */}
          <CheckRoleTooltip hasTooltip={false} isAllowed={isAddAllowed}>
              <Button type="primary" disabled={!isAddAllowed} onClick={onClickAddNew} icon={<PlusOutlined />}>
                {i18n.t(languageKeys.tao_moi_mau_mo_ta)}
              </Button>
            </CheckRoleTooltip>
        </Row>
      </>
    );
  }, [onClickAddNew, selectedSample]);

  return <Layout.Header className={style["top-bar"]}>{topBarContent}</Layout.Header>;
};

TopBar = memo(TopBar);

export { TopBar };
