import React , {useState , useEffect} from "react";
import i18n, {languageKeys} from "../../../../i18n";
import {Form, Input, Row, Col } from "antd";
import style from "./style.module.less"
import {formPattern, HLog} from "../../../../helpers";
import { FieldKhaiThac, fieldVongBung } from "./fieldKhaiThac";
import { dataPartnerCode } from "constants/keys";
import { useSelector } from "react-redux";


const ChiSoSinhTon = ({ readOnlyInput, form , dataExam, disabled=false}, ref) => {

    const userProfile = useSelector(state => state.auth.user);

    const [data, setData] = useState({
        [`${FieldKhaiThac.MACH}`] : "",
        [`${FieldKhaiThac.NHIET_DO}`] : "",
        [`${FieldKhaiThac.HUYET_AP}`] : "",
        [`${FieldKhaiThac.NHIP_THO}`] : "",
        [`${FieldKhaiThac.CAN_NANG}`] : "",
        [`${FieldKhaiThac.CHIEU_CAO}`] : "",
        [`${FieldKhaiThac.SP_O2}`] : "",
    })

    useEffect(() => {
        if (dataExam) {
            setData({
                [`${FieldKhaiThac.MACH}`] : dataExam.MACH,
                [`${FieldKhaiThac.NHIET_DO}`] : dataExam.NHIET_DO,
                [`${FieldKhaiThac.HUYET_AP}`] :dataExam.HUYET_AP,
                [`${FieldKhaiThac.NHIP_THO}`] : dataExam.NHIP_THO,
                [`${FieldKhaiThac.CAN_NANG}`] : dataExam.CAN_NANG,
                [`${FieldKhaiThac.CHIEU_CAO}`] : dataExam.CHIEU_CAO,
                [`${FieldKhaiThac.SP_O2}`] : "",
            })
        }
    }, [dataExam]);


  const CHIEU_CAO = Form.useWatch('CHIEU_CAO', form);
  const CAN_NANG = Form.useWatch('CAN_NANG', form);

    const onChangeInput = (value, type, lable) => {
        let validate
        if (type === FieldKhaiThac.HUYET_AP){
            if (value.includes("/")){
                let arr = value.split("/")
                if (arr.length > 1 && arr.length <= 2) {
                    if (arr[1] !== "" && arr[0] !== "") {
                        validate = formPattern.numberDecimalPositive.test(arr[0]) && formPattern.numberDecimalPositive.test(arr[1])
                    }else {
                        validate = true
                    }
                }
            }else {
                validate =  formPattern.numberDecimalPositive.test(value)
            }
        }else {
            validate =  formPattern.numberDecimalPositive.test(value)
        }

        if(type  === FieldKhaiThac.SP_O2){
            validate = true
        }

        if (!validate && value !== ""){
            form.setFields([
                {
                    name : type,
                    value : data[`${type}`] === undefined ? null : data[`${type}`],
                    errors : [`${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${lable}`]
                }
            ])
            return
        }
        setData(prev => ({...prev, [`${type}`] : value}))

        form.setFields([
            {
                name : type,
                value : value,
                errors : []
            }
        ])
    }

    const calcBMI = () => {
        try {
            let result = 0;
            let height = data[FieldKhaiThac.CHIEU_CAO]/100
            if(data[FieldKhaiThac.CAN_NANG] && height){
                result = data[FieldKhaiThac.CAN_NANG]/((height * height));
                result = result.toFixed(2)
            } else {
                result = "";
            }
            return result
        } catch (error) {
            HLog(error);
            return 0;
        }
    }

    useEffect(() => {
      try {
        let result = 0;
        let height = CHIEU_CAO / 100;
        if (CAN_NANG && height) {
          result = CAN_NANG / (height * height);
          result = result.toFixed(2);
          form.setFields([
            {
              name: "BMI",
              value: result,
              errors: [],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "BMI",
              value: null,
              errors: [],
            },
          ]);
        }
        return result;
      } catch (error) {
        HLog(error);
        return 0;
      }
    }, [CHIEU_CAO, CAN_NANG]);
    

    const vongBungEo = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_EO],form);
    const vongBungRon = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_RON],form);
    const vongBungGaiChau = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN],form);

    useEffect(() => {
        try {
        if([dataPartnerCode.dth].includes(userProfile.partner_code)) {
            let vongBung = {
                [fieldVongBung.VONG_BUNG_QUA_EO]: vongBungEo,
                [fieldVongBung.VONG_BUNG_QUA_RON]: vongBungRon,
                [fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN]:  vongBungGaiChau,
            }
            vongBung = [
              vongBung[fieldVongBung.VONG_BUNG_QUA_EO],
              vongBung[fieldVongBung.VONG_BUNG_QUA_RON],
              vongBung[fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN],
            ];
            form.setFields([{
                name:FieldKhaiThac.VONG_BUNG,
                value: vongBung?.join(",") || undefined
            }])
        }
        } catch (error) {
            console.log(error)
        }
    },[vongBungEo, vongBungRon, vongBungGaiChau])

    return (
        <div style={styles.container} className={style['chiso-container']}  >
            <Row style={styles.body} gutter={10}>
                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.MACH}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.MACH, i18n.t(languageKeys.field_Mach))}
                            onBlur={(e) => {

                                form.setFields([{
                                    name :FieldKhaiThac.MACH,
                                    errors : []
                                }])
                            }}

                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.NHIP_THO}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.NHIP_THO, i18n.t(languageKeys.field_Nhip_tho))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.NHIP_THO,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>

               <Col span={8}>
                   <Form.Item
                       name={FieldKhaiThac.NHIET_DO}
                   >
                       <Input
                           prefix = {<p style={styles.txt}>{i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}</p>}
                           readOnly={readOnlyInput}
                           disabled={disabled}
                           placeholder={i18n.t(languageKeys.common_Nhap)}
                           onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.NHIET_DO, i18n.t(languageKeys.field_Nhiet_do))}
                           onBlur={() => form.setFields([{
                               name :FieldKhaiThac.NHIET_DO,
                               errors : []
                           }])}
                       />
                   </Form.Item>
               </Col>

                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.HUYET_AP}
                        rules={[
                            {
                                pattern: /^\d{1,3}\/\d{1,3}$/,
                                message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Huyet_ap)}`,
                            }
                        ]}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.field_Huyet_ap)} (mmHg)</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.HUYET_AP, i18n.t(languageKeys.field_Huyet_ap))}
                            onBlur={(e) => {
                                let value = e.target.value
                                if (!value){
                                    form.setFields([{
                                        name :FieldKhaiThac.HUYET_AP,
                                        errors : []
                                    }])
                                }
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.CAN_NANG}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.can_nang)} (Kg)</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.CAN_NANG, i18n.t(languageKeys.can_nang))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.CAN_NANG,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.CHIEU_CAO}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.chieu_cao)} (cm)</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.CHIEU_CAO, i18n.t(languageKeys.chieu_cao))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.CHIEU_CAO,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.BMI}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.bmi)}</p>}
                            readOnly={true}
                            disabled={disabled}
                            // placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.BMI, i18n.t(languageKeys.bmi))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.BMI,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.VONG_CO}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.vong_co)}</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_CO, i18n.t(languageKeys.VONG_CO))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.VONG_CO,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>
                {
                    [dataPartnerCode.dth].includes(userProfile.partner_code) ? (
                        <>
                        <Col span={8}>
                                <Form.Item
                                    name={FieldKhaiThac.SP_O2}
                                >
                                    <Input
                                        controls={false}
                                        prefix={<p style={styles.txt}>SpO2</p>}
                                        readOnly={readOnlyInput}
                                        disabled={disabled}
                                        placeholder={i18n.t(languageKeys.common_Nhap)}
                                        onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.SP_O2, i18n.t(languageKeys.tuoi_man_kinh))}
                                        onBlur={() => form.setFields([{
                                            name: FieldKhaiThac.SP_O2,
                                            errors: []
                                        }])}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={fieldVongBung.VONG_BUNG_QUA_EO}
                                >
                                    <Input
                                        prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_eo)}</p>}
                                        readOnly={readOnlyInput}
                                        disabled={disabled}
                                        placeholder={i18n.t(languageKeys.common_Nhap)}
                                        onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                                        onBlur={() => form.setFields([{
                                            name: FieldKhaiThac.VONG_BUNG,
                                            errors: []
                                        }])}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={fieldVongBung.VONG_BUNG_QUA_RON}
                                >
                                    <Input
                                        prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_ron)}</p>}
                                        readOnly={readOnlyInput}
                                        disabled={disabled}
                                        placeholder={i18n.t(languageKeys.common_Nhap)}
                                        onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                                        onBlur={() => form.setFields([{
                                            name: FieldKhaiThac.VONG_BUNG,
                                            errors: []
                                        }])}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN}
                                >
                                    <Input
                                        prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_gai_chau_truoc_tren)}</p>}
                                        readOnly={readOnlyInput}
                                        disabled={disabled}
                                        placeholder={i18n.t(languageKeys.common_Nhap)}
                                        onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                                        onBlur={() => form.setFields([{
                                            name: FieldKhaiThac.VONG_BUNG,
                                            errors: []
                                        }])}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    ) : (
                        <></>
                    )
                }
                        <Col span={8} 
                            hidden={[dataPartnerCode.dth].includes(userProfile.partner_code)}
                        >
                            <Form.Item
                                name={FieldKhaiThac.VONG_BUNG}
                                hidden={[dataPartnerCode.dth].includes(userProfile.partner_code)}
                            >
                                <Input
                                    prefix = {<p style={styles.txt}>{i18n.t(languageKeys.vong_bung)}</p>}
                                    readOnly={readOnlyInput}
                                    disabled={disabled}
                                    placeholder={i18n.t(languageKeys.common_Nhap)}
                                    onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                                    onBlur={() => form.setFields([{
                                        name :FieldKhaiThac.VONG_BUNG,
                                        errors : []
                                    }])}
                                />
                            </Form.Item>
                        </Col>
                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.VONG_HONG_CHAU}
                    >
                        <Input
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.vong_hong_chau)}</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.VONG_HONG_CHAU, i18n.t(languageKeys.vong_hong_chau))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.VONG_HONG_CHAU,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={FieldKhaiThac.TUOI_MAN_KINH}
                        rules={[{
                            required:false,
                            pattern:formPattern.numberOnly,
                            message:i18n.t(languageKeys.tuoi_man_kinh) + " " + i18n.t(languageKeys.wrong_format).toLowerCase()
                        }]}
                    >
                        <Input
                            controls={false}
                            prefix = {<p style={styles.txt}>{i18n.t(languageKeys.tuoi_man_kinh)}</p>}
                            readOnly={readOnlyInput}
                            disabled={disabled}
                            placeholder={i18n.t(languageKeys.common_Nhap)}
                            onChange={(e) => onChangeInput(e.target.value, FieldKhaiThac.TUOI_MAN_KINH, i18n.t(languageKeys.tuoi_man_kinh))}
                            onBlur={() => form.setFields([{
                                name :FieldKhaiThac.TUOI_MAN_KINH,
                                errors : []
                            }])}
                        />
                    </Form.Item>
                </Col>
                {/* <Col span={24} className={style['bmi-wrapper']}> <h4>BMI: </h4><h4>{calcBMI()}</h4>  </Col> */}
            </Row>
        </div>
    )
}
export default React.memo(ChiSoSinhTon);

const styles = {
    container : {
        //backgroundColor : "red",
        width : "100%",
    },
    title : {
        fontSize : 16,
        color : "#2C3782",
        fontWeight : "bold",
        margin : 15
    },
    body : {
        padding : 10
    },
    txt : {
        fontSize: 13,
    }

}

