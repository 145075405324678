import { Button, Dropdown, Layout, Menu, Row, Space, Spin, Col, Form, Input, notification, Tooltip} from "antd";
import { isArray, debounce } from "lodash";
import i18n, { languageKeys } from "../../../../../i18n";
import { ConfirmModal } from "../../../../../components";
import styles from "./toolbar.module.less";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { apis } from "../../../../../constants";
import { common_post, convertMillisecondToDate, HLog, isEmptyObject } from "../../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Delete, EditAlt } from "../../../../../assets/svg";
import HoiBenh from "../components/HoiBenh";
import KhamTongQuan from "../components/KhamTongQuan";
import ChanDoanXuTri from "../components/ChanDoanXuTri";
import { layDanhSachBenh } from "../../../apisKhamBenh/apisKhamBenh";
import { useForm } from "antd/lib/form/Form";
import { PlusCircleFilled } from "@ant-design/icons";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";
import { value_SO_DO_RANG } from "../components/constToothDiafram";
import { setDataDetailPopup } from "ducks/slices/khamBenhSlice";


const tabThongTin = {
  HOI_BENH : "HOI_BENH",
  KHAM_TONG_QUAN : "KHAM_TONG_QUAN",
  CHAN_DOAN : "CHAN_DOAN"
}

const typeMau = {
  TYPE_CHON_MAU: "TYPE_CHON_MAU",
  TYPE_LUU_MAU: "TYPE_LUU_MAU",
  TYPE_SUA_MAU: "TYPE_SUA_MAU"
}

const PopupThongTinKham = forwardRef(
  (
    {
      children,
      patientInfo = {},
      isOpen = false,
      setIsOpen = () => {},
      onResetSauLuuTTK = () => {},
      parseDataInfo = () => {},
      configPermissions = {},
      isEditAllowed=false,
      phongChiDinh={},
      isRenderKTQ=true,
      form
    },
    ref
  ) => {
  const deleteRef = useRef();
  const userProfile = useSelector((state) => state.auth.user);
  const [arrMauTTK, setArrMauTTK] = useState([]);
  const [loaiMauDaChon, setLoaiMauDaChon] = useState({}); //lưu thông tin loại mẫu đã chọn
  const [selectedMauTTK, setSelectedMauTTK] = useState({});
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isLoadingChiTiet, setIsLoadingChiTiet] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false); //cho phép nhập hay không 
  const [tabActive, setTabActive] = useState(tabThongTin.HOI_BENH)
  const dispatch = useDispatch();

  const [dsICD10Chinh, setDsICD10Chinh] = useState([]); // tìm kiếm danh sách bệnh chính
  const [dsICD10Phu, setDsICD10Phu] = useState([]); // tìm kiếm danh sách bệnh phụ
  const [dsBenhPhu, setDsBenhPhu] = useState([]);
  const [loaiMau, setLoaiMau] = useState(typeMau.TYPE_LUU_MAU); //loại nút để hiển thị text cho đúng

  const [dsYHCTChinh, setDsYHCTChinh] = useState([]); // tìm kiếm danh sách bệnh chính
  const [dsYHCTPhu, setDsYHCTPhu] = useState([]); // tìm kiếm danh sách bệnh phụ
  const [dsBenhYHCTPhu, setDsBenhYHCTPhu] = useState([]);
  const [countTTK, setCountTTK] = useState(1); //biến tăng thông tin khám lên 1 đơn vị, khi tạo mới local
  const [dataPatient, setDataPatient] = useState({});
  const [lastPhong, setLastPhong] = useState();
  const [formTTK] = useForm();
  
  useImperativeHandle(ref, () => ({
    callApiLuuMauTTK(data) {
      _callApiLuuMauTTK(data);
    },
  }));

  //hàm xử lí gọi api lưu mẫu thông tin khám
  const _callApiLuuMauTTK = (data) => {
    HLog("_callApiLuuMauTTK data: ", data);
    handleClickApply(typeMau.TYPE_LUU_MAU, data);
    onResetSauLuuTTK();
  }

  const handleVisibleChange = (visible) => {
    if (!!deleteRef.current?.visible) return;

    setIsOpen(visible);

    if (visible) {
      //HLog("handleVisibleChange patientInfo: ", patientInfo, " dataPatient: ", dataPatient);
      // if(arrMauTTK.length === 0) {
      try {
        if(lastPhong?.ID !== phongChiDinh?.ID) {
          // return
          apiDsMauThongTinKham();
          setLastPhong(phongChiDinh);
        } 
      } catch (error) {
        apiDsMauThongTinKham();
        HLog(error);
      }
      // } else
       if(patientInfo !== dataPatient) {
        setDataPatient(patientInfo);
        setSelectedMauTTK({});
        setLoaiMauDaChon({}); //lưu mẫu đã chọn, để lấy ID xử lí những nơi cần thiết
        setDsBenhPhu([]);
        setDsYHCTPhu([]);
        setDsICD10Phu([]);
        setDsBenhYHCTPhu([]);
        formTTK.resetFields(); //reset thông tin cũ
      }
     
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    //setSelectedMauTTK({});
    setIsShowLoading(false);
    setIsLoadingChiTiet(false);
  };

  //hàm xử lí chọn mẫu muốn xem chi tiết hoặc cập nhật chi tiết
  const handleSelectMau = ({ key: jsonMau }) => {
    setDsBenhPhu([]);
    setDsYHCTPhu([]);
    setDsICD10Phu([]);
    setDsBenhYHCTPhu([]);
    formTTK.resetFields();
    try {
      const donMau = JSON.parse(jsonMau);
      if(donMau === loaiMauDaChon) {
        return;
      }

      setSelectedMauTTK(donMau);
      setLoaiMauDaChon(donMau); //lưu mẫu đã chọn, để lấy ID xử lí những nơi cần thiết
      formTTK.resetFields(); //reset thông tin cũ
      HLog("handleSelectMau donMau: ", donMau)
     if(donMau.type === -1) { //nếu đây là mẫu vừa mới tạo ra ở local, thì hiển thị nút lưu
        setLoaiMau(typeMau.TYPE_LUU_MAU);
        setIsDisableInput(false);
     } else { //nếu đang chọn mẫu, thì hiển thị nút áp dụng mẫu
        setLoaiMau(typeMau.TYPE_CHON_MAU);
        let MAU_ID = donMau.ID;
        apiLayChiTietMau(MAU_ID);
        setIsDisableInput(true);
     }
      

    } catch (error) {
      HLog(error);
    }
  };

  //lấy danh sách mẫu thông tin khám
  // useEffect(() => {
  //   if(!isEmptyObject(phongChiDinh))
  //   apiDsMauThongTinKham()
  // }, [phongChiDinh])
  
  const apiDsMauThongTinKham = async () => {
    if(!phongChiDinh) {
      throw new Error('Có lỗi xảy ra, vui lòng chọn phòng!');
    }
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BAC_SI_ID: userProfile.NHANSU_ID,
      PHONG_ID: phongChiDinh?.ID,
      TEN_KHOA_PHONG: phongChiDinh?.TEN_KHOA_PHONG,
    };
    let api = apis.ds_mau_tt_kham;
    try {
      setIsShowLoading(true);
      const res = await common_post(api, req);

      if (res.status === "OK") {
        let list = res.result.map((item) => ({
          key: JSON.stringify(item),
          label: (
            <CheckRoleTooltip isAllowed={isEditAllowed} hasTooltip={false}>
              <Space align="center" style={{cursor: isEditAllowed ? 'pointer' : 'not-allowed', opacity: isEditAllowed ? 1 : 0.6}}>
                <Button 
                className={styles["btn-icon-delete"]}
                disabled={!configPermissions.XOA_MAU}
                icon={
                  <Delete
                    className={styles["icon-delete"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      if(!configPermissions.XOA_MAU) return;
                      deleteRef.current?.open(item);
                    }}
                  />}
                />

                <span>
                  {convertMillisecondToDate(item.THOI_GIAN_TAO) + " - " + item.TEN_MAU}
                </span>
              </Space>
            </CheckRoleTooltip>
          )
        }));
        setArrMauTTK(list);
        //setSelectedMauTTK(selectedMauTTK);
      }
      setIsShowLoading(false);
    } catch (error) {
      HLog(error);
    } finally {
      setIsShowLoading(false);
    }
  };

  //api lưu hoặc sửa mẫu thông tin khám
  const apiLuuSuaMauThongTinKham = async (type, request) => {
    let req = {
      ...request,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BAC_SI_ID: userProfile.NHANSU_ID,
      PHONG_ID: phongChiDinh?.ID,
      TEN_KHOA_PHONG: phongChiDinh?.TEN_KHOA_PHONG,
      RANG_TON_THUONG: request?.RANG_TON_THUONG?.toString() || ""
    };

    let api = type === typeMau.TYPE_LUU_MAU ? apis.luu_mau_tt_kham : apis.sua_mau_tt_kham;
    try {
      setIsLoadingChiTiet(true);
      const res = await common_post(api, req);
      if (res.status === "OK") {
        setLoaiMau(typeMau.TYPE_CHON_MAU);
        if(type === typeMau.TYPE_LUU_MAU) { //nếu là lưu mẫu, thì cập nhật lại danh sách mẫu
          notification.success({message :i18n.t(languageKeys.msg_luu_mau_thanh_cong)})
        } else {
          notification.success({message :i18n.t(languageKeys.msg_sua_mau_thanh_cong)})
        }
        apiDsMauThongTinKham(); //api reload lại danh sách
        formTTK.resetFields();
      }
      setIsLoadingChiTiet(false);
    } catch (error) {
      HLog(error);
    } finally {
      setIsLoadingChiTiet(false);
    }
  };

  //Hàm lấy chi tiết mẫu
  const apiLayChiTietMau = async(MAU_ID) => {
    try {
      setIsLoadingChiTiet(true);
      let api = apis.chi_tiet_mau_tt_kham;
      let req = {ID: MAU_ID}
      const res = await common_post(api, req);
      if (res.status === "OK" && res.result && res.result.length !== 0) {
        let dataChiTiet = res.result[0]; 
       
        parseDataMauTTK(dataChiTiet);
      }
      setIsLoadingChiTiet(false);
    } catch (error) {
      HLog(error);
    } finally {
      setIsLoadingChiTiet(false);
    }
  }

  //Hàm xoá mẫu thông tin khám
  const apiXoaMauTTK = async(MAU_ID) => {
    try {
      setIsShowLoading(true);
      let api = apis.xoa_mau_tt_kham;
      let req = {ID: MAU_ID, TRANG_THAI: 0}
      const res = await common_post(api, req);
      if (res.status === "OK") {
        notification.success({message :i18n.t(languageKeys.msg_xoa_mau_thanh_cong)})
        apiDsMauThongTinKham();
        if(MAU_ID === loaiMauDaChon.ID) {
          formTTK.resetFields();
        }
      }
      setIsShowLoading(false);
    } catch (error) {
      HLog(error);
    } finally {
      setIsShowLoading(false);
    }
  }

  //hàm xử lý xoá mẫu thông tin khám
  const handleXoaMauTTK = async (dataMau) => {
    HLog("handleXoaMauTTK arrMauTTK: ", arrMauTTK, " dataMau: ", dataMau)
    try {
      deleteRef.current?.loading(true);
      deleteRef.current?.close();
      if(dataMau && dataMau.type === -1) { //nếu xoá mẫu đang tạo local
        let newArrMau = arrMauTTK.filter((item) => {
          let dataKey = JSON.parse(item.key);
          return (dataKey.index !== dataMau.index)
        })
        setArrMauTTK(newArrMau);
      } else { //nếu xoá mẫu đã tạo trên server
        apiXoaMauTTK(dataMau.ID)
      }
    } catch (error) {
      HLog(error);
    } finally {
      deleteRef.current?.loading(false);
    }
  };

  const handleClickCancel = () => {
    setIsOpen(false);
    handleClose();
  };

  //Hàm xử lí lưu mẫu thông tin khám
  const onLuuTTK = async() => {
    const dataValue = await formTTK.validateFields();
    handleClickApply(loaiMau, dataValue);

  }

  //Hàm xử lí parse dữ liệu thông tin khám
  const handleClickApply = async(loaiMau, values) => {
   HLog("handleClickApply values: ", values);
    let benh_chinh = null;
    let benh_phu = []
    if (!!values.benh_chinh) {
      benh_chinh = {...JSON.parse(values?.benh_chinh), PHAN_LOAI: "CHINH"};
    }

    if (!!values.benh_phu) {
      benh_phu = values.benh_phu.map((item) => ({
        ...JSON.parse(item),
        PHAN_LOAI: "PHU",
      }));
    }
    let benh_yhct_chinh = null;
    let benh_yhct_phu = [];
    if (!!values.benh_yhct_chinh) {
      benh_yhct_chinh = {...JSON.parse(values?.benh_yhct_chinh), PHAN_LOAI: "CHINH"};
    }

    if (!!values.benh_yhct_phu) {
      benh_yhct_phu = values.benh_yhct_phu.map((item) => ({
        ...JSON.parse(item),
        PHAN_LOAI: "PHU",
      }));
    }

    let data_benh = benh_chinh ? [benh_chinh, ...benh_phu]: benh_phu;
    let data_benh_yhct = benh_yhct_chinh ? [benh_yhct_chinh, ...benh_yhct_phu]: benh_yhct_phu;
    let response = {
      ...values,
      data_benh,
      data_benh_yhct,
      TRANG_THAI: 1,
      PHONG_ID: phongChiDinh?.PHONG_ID,
      TEN_KHOA_PHONG: phongChiDinh?.TEN_KHOA_PHONG,
    };
    if(loaiMau === typeMau.TYPE_CHON_MAU) { //xử lí áp dụng chọn mẫu
      let { CAN_NANG, CHIEU_CAO, HUYET_AP, MACH, NHIET_DO, NHIP_THO, VONG_BUNG, VONG_CO, BMI, BAC_SI_ID } = await form?.getFieldsValue()
      
      parseDataInfo({ ...response, CAN_NANG, CHIEU_CAO, HUYET_AP, MACH, NHIET_DO, NHIP_THO, VONG_BUNG, VONG_CO, BMI, BAC_SI_ID });
      handleClickCancel();
    } else if(loaiMau === typeMau.TYPE_LUU_MAU) { //áp dụng gọi api lưu mẫu
      let tenMau = formTTK.getFieldValue(fieldKhamBenh.TEN_MAU);
      if(!tenMau || tenMau === "") {
        let TEN_MAU_TT = values.TEN_MAU;
        if(!TEN_MAU_TT || TEN_MAU_TT === "") {
          HLog("TEN_MAU_TT: ", TEN_MAU_TT);
          notification.warning({message :i18n.t(languageKeys.msg_ten_mau)})
        }
      }
      apiLuuSuaMauThongTinKham(loaiMau, response);
    } else { //sửa mẫu
      let tenMau = formTTK.getFieldValue(fieldKhamBenh.TEN_MAU);
      if(!tenMau || tenMau === "") {
        notification.warning({message :i18n.t(languageKeys.msg_ten_mau)})
      }
      response = {...response, ID: loaiMauDaChon.ID}
      apiLuuSuaMauThongTinKham(loaiMau, response);
    }
    
  };

  // tìm kiếm danh mục bệnh
  const timKiemICD10 = async (search_string = "", LOAI = "", PHAN_LOAI = "ICD") => {
    const response = await layDanhSachBenh(search_string, PHAN_LOAI);

    if (response) {
      const data = response.result.map((item) => ({
        ID_ICD: item.ID_ICD,
        MA_ICD: item.MA_ICD,
        TEN_ICD: item.TEN_ICD,
      }));

      if (PHAN_LOAI === "YHCT"){
        if (LOAI === "CHINH") setDsYHCTChinh(data);
        if (LOAI === "PHU") setDsYHCTPhu(data);
      }else {
        if (LOAI === "CHINH") setDsICD10Chinh(data);
        if (LOAI === "PHU") setDsICD10Phu(data);
      }
    }
  };

  //xử lí chuyển sang tab kê đơn thuốc
  const handleChuyenTabKeThuoc = async () => {
   

  }

  const handleThemMaBenhKemTheo = (value, PHAN_NHOM = "ICD") => {
    const data = {
      ID_ICD: null,
      MA_ICD: null,
      TEN_ICD: value,
      PHAN_LOAI: "PHU",
    };
    if (PHAN_NHOM === "YHCT") {
      const newList = [...dsBenhYHCTPhu, JSON.stringify(data)];
      formTTK.setFields([{ name: fieldKhamBenh.benh_yhct_phu, value: newList }]);
      setDsBenhYHCTPhu(newList);
    }else {
      const newList = [...dsBenhPhu, JSON.stringify(data)];
      formTTK.setFields([{ name: fieldKhamBenh.benh_phu, value: newList }]);
      setDsBenhPhu(newList);
    }
  };
  //tìm kiếm mã bệnh chính
  const handleICD10Chinh = useCallback(
    debounce((value) => timKiemICD10(value, "CHINH"), 1000),
    []
  );
   //tìm kiếm mã bệnh phụ 
  const handleICD10Phu = useCallback(
    debounce((value) => timKiemICD10(value, "PHU"), 1000),
    []
  );
    //tìm kiếm YHCT bệnh chính
  const handleYHCTChinh = useCallback(
    debounce((value) => timKiemICD10(value, "CHINH", "YHCT"), 1000),
    []
  );
    //tìm kiếm YHCT bệnh phụ
  const handleYHCTPhu = useCallback(
    debounce((value) => timKiemICD10(value, "PHU", "YHCT"), 1000),
    []
  );

  //hàm xử lí parse dữ liệu response và hiển thị thông tin khám lên giao diện
  const parseDataMauTTK = (dataResponse) => {
    HLog("parseDataInfo dataResponse: ", dataResponse)
    if(!dataResponse || isEmptyObject(dataResponse)) {
      return;
    }

    let fieldData = Object.values(fieldKhamBenh).map((name) => {
      if (name === fieldKhamBenh.RANG_TON_THUONG ) {

        let dataFormat = []

        if (name === fieldKhamBenh.SO_DO_RANG ) {
          return {
            name,
            value: dataResponse[fieldKhamBenh.SO_DO_RANG] || value_SO_DO_RANG.adult_jaw,
            errors: [],
          };
        }

        if (dataResponse[fieldKhamBenh.RANG_TON_THUONG]) {
          if (isArray(dataResponse[fieldKhamBenh.RANG_TON_THUONG])) {
            dataFormat = dataResponse[fieldKhamBenh.RANG_TON_THUONG]
          }else{
            dataFormat = dataResponse[fieldKhamBenh.RANG_TON_THUONG].split(",")
          }
          return {
            name,
            value: dataFormat,
            errors: [],
          };
        }
        return {
          name,
          value: dataResponse[name],
          errors: [],
        };
      }
      return {
        name,
        value: dataResponse[name],
        errors : []
      };
    });

    if (!!dataResponse["CHAN_DOAN_BENH_CHINH"] && dataResponse["CHAN_DOAN_BENH_CHINH"].length > 0) {
      let benh_chinh = null
      let benh_yhct_chinh = null;
      let data_benh = dataResponse["CHAN_DOAN_BENH_CHINH"] || []

      benh_chinh = data_benh.find(item => item.PHAN_NHOM === "ICD")
      benh_yhct_chinh = data_benh.find(item => item.PHAN_NHOM === "YHCT")
      if (benh_chinh) {
        let data = {
          ID_ICD: benh_chinh.ID_ICD,
          MA_ICD: benh_chinh.MA_ICD,
          TEN_ICD: benh_chinh.TEN_ICD,
        }
        setDsICD10Chinh([data])
        benh_chinh = JSON.stringify(data)
      }

      if (benh_yhct_chinh) {
        let data = {
          ID_ICD: benh_yhct_chinh.ID_ICD,
          MA_ICD: benh_yhct_chinh.MA_ICD,
          TEN_ICD: benh_yhct_chinh.TEN_ICD,
        }
        setDsYHCTChinh([data])
        benh_yhct_chinh = JSON.stringify(data)
      }

      if (!!benh_chinh) {
        fieldData.push({
          name: fieldKhamBenh.benh_chinh,
          value: benh_chinh,
        });
      }

      if (!!benh_yhct_chinh) {
        fieldData.push({
          name: fieldKhamBenh.benh_yhct_chinh,
          value: benh_yhct_chinh,
        });
      }

      
    }

    // benh_yhct_chinh : "benh_yhct_chinh",
    //     benh_yhct_phu : "benh_yhct_phu",
    if (!!dataResponse["CHAN_DOAN_BENH_PHU"] && dataResponse["CHAN_DOAN_BENH_PHU"].length > 0) {
      let benh_yhct_phu = [];
      let benh_phu = [];
      let data_benh = dataResponse["CHAN_DOAN_BENH_PHU"] || []

      benh_phu = data_benh.filter(item => item.PHAN_NHOM === "ICD")
      benh_yhct_phu = data_benh.filter(item => item.PHAN_NHOM === "YHCT")
      HLog("parseDataInfo benh_phu: ", benh_phu, " benh_yhct_phu: ", benh_yhct_phu)
      //duyệt bệnh phụ
      if (benh_phu && benh_phu.length > 0) {
        benh_phu = benh_phu.map((item => JSON.stringify({
          ID_ICD: item.ID_ICD,
          MA_ICD: item.MA_ICD,
          TEN_ICD: item.TEN_ICD,
        })));
        setDsBenhPhu(benh_phu);
      } else {
        setDsBenhPhu([]);
      }
      
      //Duyệt bệnh YHCT Phụ
      if (benh_yhct_phu && benh_yhct_phu.length > 0) {
        benh_yhct_phu = benh_yhct_phu.map((item => JSON.stringify({
          ID_ICD: item.ID_ICD,
          MA_ICD: item.MA_ICD,
          TEN_ICD: item.TEN_ICD,
        })));
        setDsBenhYHCTPhu(benh_yhct_phu);
      } else {
        setDsBenhYHCTPhu([]);
      }

      
      if (benh_phu.length > 0) {
        fieldData.push({
          name: fieldKhamBenh.benh_phu,
          value: benh_phu,
        });
      }

      if (benh_yhct_phu.length > 0) {
        fieldData.push({
          name: fieldKhamBenh.benh_yhct_phu,
          value: benh_yhct_phu,
        });
      }
    }

    HLog("parseDataMauTTK fieldData: ", fieldData)
    dispatch(setDataDetailPopup(dataResponse))

    formTTK.setFields(fieldData);
  }

  //hàm tạo mới thông tin khám
  const onTaoMoiTTKham = (arrMauTTK) => {
    
    let dataKey = {index: countTTK, type:-1};
    let newData = {
      key: JSON.stringify(dataKey),
      label: (
        <Space align="center">
          <Delete
            className={styles["icon-delete"]}
            onClick={(e) => {
              e.stopPropagation();
              HLog("PopupThongTinKham XoaMau dataKey: ", dataKey)
              deleteRef.current?.open(dataKey);
            }}
          />

          <span>
            {countTTK + " - "} {i18n.t(languageKeys.common_mau)} ---
          </span>
        </Space>
      )
    };
    let count = countTTK + 1;
    setCountTTK(count);
    let newArr = [newData].concat(arrMauTTK);
    HLog("onTaoMoiTTKham newArr: ", newArr, " newData: ", newData, " arrMauTTK: " , arrMauTTK)
    setArrMauTTK(newArr);
    setLoaiMauDaChon(dataKey);
    setLoaiMau(typeMau.TYPE_LUU_MAU);
    setIsDisableInput(false);
    setSelectedMauTTK(dataKey);
    setDsBenhPhu([]);
    setDsYHCTPhu([]);
    setDsICD10Phu([]);
    setDsBenhYHCTPhu([]);
    formTTK.resetFields(); //reset thông tin cũ
    
    
  }

  //hiển thị text chọn mẫu, tạo mẫu, sửa mẫu
  const getTextBtnLuu = (type) => {
    if(type === typeMau.TYPE_CHON_MAU) { //Chọn mẫu
      return i18n.t(languageKeys.common_Ap_dung);
    } else if(type === typeMau.TYPE_SUA_MAU) { //sửa mẫu
      return i18n.t(languageKeys.sua_mau);
    } else if(type === typeMau.TYPE_LUU_MAU) { //lưu mẫu
      return i18n.t(languageKeys.luu_mau);
    } else {
      return "";
    }

  }

  const renderPopupContent = () => (
    <div >
      <Form layout="vertical" form={formTTK}>
      <Layout className={styles["popup-ctn"]}>
        <Layout.Sider theme="light" className={styles["sider"]}>
          <div className={styles["title"]}>
            <h4>{i18n.t(languageKeys.ttk_tieu_de)}</h4>
          </div>

          <Spin spinning = {isShowLoading}>
            <Menu rootClassName={styles["menu"]} items={arrMauTTK} activeKey={JSON.stringify(selectedMauTTK)} onClick={handleSelectMau} />
          </Spin>
          <CheckRoleTooltip hasTooltip={false} isAllowed={isEditAllowed}>
            { configPermissions.THEM_MAU && (
                  <Space
                    style={{ paddingInline: "20px", marginTop: "20px", cursor: isEditAllowed ? "pointer" : 'not-allowed', opacity: isEditAllowed ? 1 : 0.6}}
                    align="center"
                    onClick={() => {
                      if(!isEditAllowed)  return;
                      onTaoMoiTTKham(arrMauTTK);
                    }}
                  >
                    <PlusCircleFilled style={{ color: "#4263eb", fontSize: 20 }} />
                    <p style={{ color: "#4263eb", fontWeight: "bold", fontSize: "14px" }}>{i18n.t(languageKeys.ttk_tao_moi)}</p>
                  </Space>
            )}
          </CheckRoleTooltip>
        </Layout.Sider>

        <Layout className={styles["left-thongTin"]}>
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                className={tabActive === tabThongTin.HOI_BENH ? styles["button-active"] : styles["button"]}
                onClick={() => setTabActive(tabThongTin.HOI_BENH)}
              >
                {i18n.t(languageKeys.hoi_benh)}
              </Button>
              {isRenderKTQ && <Button
                className={tabActive === tabThongTin.KHAM_TONG_QUAN ? styles["button-active"] : styles["button"]}
                onClick={() => setTabActive(tabThongTin.KHAM_TONG_QUAN)}
              >
                {i18n.t(languageKeys.kham_tong_quan)}
              </Button>}
              <Button
                className={tabActive === tabThongTin.CHAN_DOAN ? styles["button-active"] : styles["button"]}
                onClick={() => setTabActive(tabThongTin.CHAN_DOAN)}
              >
                {i18n.t(languageKeys.chan_doan_xu_tri)}
              </Button>
            </Col>
            <Space>
              {
                loaiMau === typeMau.TYPE_CHON_MAU &&
                <Tooltip placement="topLeft" title={i18n.t(!isEditAllowed ? languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay : languageKeys.tooltip_sua_mau)}>
                <EditAlt
                  className={styles["icon-edit"]}
                  onClick={(e) => {
                    if(!isEditAllowed) return;
                    setLoaiMau(typeMau.TYPE_SUA_MAU);
                    setIsDisableInput(false);
                  }}
                  style={{cursor: `${isEditAllowed ? 'auto' : 'not-allowed'}`, opacity: isEditAllowed ? 1 : 0.6}}
                />
                </Tooltip>
              }
            
            </Space>
          </Row>

          <Layout.Content className={styles["content"]} style={{overflow:"auto",paddingBottom:"30px"}} >
          <Spin spinning = {isLoadingChiTiet}>
            <HoiBenh containerStyle={{ display: tabActive === tabThongTin.HOI_BENH ? "block" : "none" }} form={formTTK} readOnly={isDisableInput} isEditAllowed={isEditAllowed}/>
            <KhamTongQuan containerStyle={{ display: tabActive === tabThongTin.KHAM_TONG_QUAN ? "block" : "none" }} readOnly={isDisableInput} isEditAllowed={isEditAllowed}  phongChiDinh={phongChiDinh}/>
            <ChanDoanXuTri
              containerStyle={{ display: tabActive === tabThongTin.CHAN_DOAN ? "block" : "none" }}
              form={formTTK}
              isNotRequired = {true}
              dsICD10Chinh={dsICD10Chinh}
              dsICD10Phu={dsICD10Phu}
              dsBenhPhu={dsBenhPhu}
              onSearchDsICD10Chinh={handleICD10Chinh}
              onSearchDsICD10Phu={handleICD10Phu}
              onSelectICDPhu={setDsBenhPhu}
              readOnly={isDisableInput || !isEditAllowed}
              onChuyenTabKeThuoc={handleChuyenTabKeThuoc}
              onPressEnterThemICD={(value) => handleThemMaBenhKemTheo(value, "ICD")}
              dsYHCTChinh={dsYHCTChinh}
              dsYHCTPhu={dsYHCTPhu}
              dsBenhYHCTPhu={dsBenhYHCTPhu}
              onSelectBenhYHCTPhu={setDsBenhYHCTPhu}
              onSearchDsYHCTChinh={handleYHCTChinh}
              onSearchDsYHCTPhu={handleYHCTPhu}
              onPressEnterThemYHCT={(value) => handleThemMaBenhKemTheo(value, "YHCT")}
              isEditAlowed={isEditAllowed}
              phongChiDinh={phongChiDinh}
              isPopup={true}
              patientInfo={patientInfo}
            />
            </Spin>
          </Layout.Content>

          <Layout.Footer className={styles["footer"]}>
            <Col span={16}>
              <Space>
                <p className={styles["title-input"]}> <span style={{color: "#FF0000"}}>* </span>{i18n.t(languageKeys.nhap_ten_mau)}</p>
                <Form.Item
                  label=""
                  name={fieldKhamBenh.TEN_MAU}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.msg_ten_mau),
                    },
                  ]}
                  >
                  <Input className={styles["input"]} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={isDisableInput}/>
                </Form.Item>
                  
              </Space>
            </Col>
            <Space size={5}>
              <Button type="primary" ghost onClick={handleClickCancel}>
                {i18n.t(languageKeys.common_Thoat)} (ESC)
              </Button>
              <CheckRoleTooltip hasTooltip={false} isAllowed={isEditAllowed}>
                <Button type="primary" onClick={onLuuTTK} disabled={!isEditAllowed}>
                  {getTextBtnLuu(loaiMau)}
                </Button>
              </CheckRoleTooltip>
            </Space>
          </Layout.Footer>
        </Layout>
      </Layout>
      </Form>
    </div>
  );

  return (
    <>
      <Dropdown trigger="click" open={isOpen} onOpenChange={handleVisibleChange} overlay={renderPopupContent()}>
        {children}
      </Dropdown>

      <ConfirmModal
        ref={deleteRef}
        title={i18n.t(languageKeys.title_Xac_nhan)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.txt_xac_nhan_xoa_mau)}
        danger={true}
        onOk={handleXoaMauTTK}
        zIndex={1051}
      />
    </>
  );
});

export default PopupThongTinKham;

export const fieldKhamBenh = {
  MACH: "MACH",
  NHIET_DO: "NHIET_DO",
  SP_O2: "SP_O2",
  NHIP_THO: "NHIP_THO",
  TAM_THU: "TAM_THU",
  TAM_TRUONG: "TAM_TRUONG",
  HUYET_AP : "HUYET_AP",
  CAN_NANG : "CAN_NANG",
  CHIEU_CAO : "CHIEU_CAO",

  // hỏi bệnh
  LY_DO_VAO_VIEN: "LY_DO_VAO_VIEN",
  QUA_TRINH_BENH_LY: "QUA_TRINH_BENH_LY",
  TIEN_SU_BENH: "TIEN_SU_BENH",
  BENH_SU : "BENH_SU",
  KHAM_BENH : "KHAM_BENH",
  TIEN_SU_SAN_KHOA : "TIEN_SU_SAN_KHOA",
  TIEN_SU_GIA_DINH:"TIEN_SU_GIA_DINH",
  TIEN_SU_DI_UNG : "TIEN_SU_DI_UNG",
  TRIEU_CHUNG_LAM_SANG : "TRIEU_CHUNG_LAM_SANG",
  TAI_NAN_THUONG_TICH : "TAI_NAN_THUONG_TICH",

  // khám tổng quát
  TOAN_THAN: "TOAN_THAN",
  CAC_BO_PHAN:"CAC_BO_PHAN",
  TUAN_HOAN : "TUAN_HOAN",
  HO_HAP : "HO_HAP",
  TIEU_HOA : "TIEU_HOA",
  THAN_TIET_NIEU : "THAN_TIET_NIEU",
  NOI_TIET:"NOI_TIET",
  CO_XUONG_KHOP : "CO_XUONG_KHOP",
  THAN_KINH : "THAN_KINH",


  // TIEN_SU_GIA_DINH: "TIEN_SU_GIA_DINH",
  KHAM_LAM_SANG: "KHAM_LAM_SANG",
  CHAN_DOAN_LAM_SANG: "CHAN_DOAN_LAM_SANG",
  CHAN_DOAN_XAC_DINH: "CHAN_DOAN_XAC_DINH",
  CACH_GIAI_QUYET: "CACH_GIAI_QUYET",
  GHI_CHU: "GHI_CHU",
  KQ_DIEU_TRI: "KQ_DIEU_TRI",
  XU_TRI: "XU_TRI",
  TAI_KHAM: "TAI_KHAM",
  TAI_KHAM_NGAY: "TAI_KHAM_NGAY",
  TAI_KHAM_LOAI: "TAI_KHAM_LOAI",
  TAI_KHAM_SAU: "TAI_KHAM_SAU",
  data_benh: "data_benh",
  benh_chinh: "benh_chinh",
  benh_phu: "benh_phu",
  KHOA_NOI_TRU: "KHOA_NOI_TRU",
  LOI_DAN:"LOI_DAN",
  data_benh_yhct : "data_benh_yhct",
  benh_yhct_chinh : "benh_yhct_chinh",
  benh_yhct_phu : "benh_yhct_phu",
  BAC_SI_KHAM:"BAC_SI_KHAM",
  TEN_MAU: "TEN_MAU",

  MAT_TRAI_CO_KINH:'MAT_TRAI_CO_KINH',
  MAT_TRAI_K_KINH:'MAT_TRAI_K_KINH',
  MAT_PHAI_CO_KINH:'MAT_PHAI_CO_KINH',
  MAT_PHAI_K_KINH:'MAT_PHAI_K_KINH',

  THI_THAM_TAI_TRAI:'NOI_THAM_TRAI',
  THI_THAM_TAI_PHAI:'NOI_THAM_PHAI',
  THUONG_TAI_TRAI:'NOI_THUONG_TRAI',
  THUONG_TAI_PHAI:'NOI_THUONG_PHAI',
  RANG_TON_THUONG: 'RANG_TON_THUONG',
  SO_DO_RANG:'SO_DO_RANG',

  HAM_TREN_SO_BO :'HAM_TREN_SO_BO',
  HAM_DUOI_SO_BO :'HAM_DUOI_SO_BO',
  HAM_TREN_XAC_DINH :'HAM_TREN_XAC_DINH',
  HAM_DUOI_XAC_DINH :'HAM_DUOI_XAC_DINH',
};

