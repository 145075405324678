/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  notification,
  InputNumber,
  //Tooltip,
  Layout,
  Checkbox,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Delete, DropDown, Search } from "../../../../../assets/svg";
import { ConfirmModal, FormDrawer, Table } from "../../../../../components";
import { apis, keys } from "../../../../../constants";
import { common_post, HLog, isEmptyObject, rid } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "./keDonThuoc.module.less";
//import styleSearchTable from "../../../../../components/SearchPopupTable/searchPopupTable.module.less";
import moment from "moment";
import { fieldNames } from "../../../../QuanLyDuocNgoaiTru/BanThuoc/fieldNamesBnBanThuoc";
import { Select } from "../../../../../components";
import { columnsDonThuoc } from "../../../../QuanLyDuocNgoaiTru/BanThuoc/columnsThuoc";
import { apiLayDSKhoThuoc } from "../../../../QuanLyDuocNgoaiTru/KhoThuoc/apiKhoThuoc/apisKhoThuoc";
import TimKiemThuocPopup from "./TimKiemThuocPopup";
import { fieldDonThuoc, fieldKhoThuoc, fieldThuocDVT } from "../fieldsThuoc";
import { apiTimDsThuocTheoKho } from "./apisKeDonThuoc";
import { useEventListener } from "../../../../../hooks";
import cn from "classnames";
import { widthDrawerKeys } from "../../../../../constants/keys";
import DonThuocMauPopup from "./DonThuocMauPopup";

const dateFormat = "HH:mm - DD/MM/YYYY";

const KeDonThuoc = forwardRef((props, ref) => {
  let {
    trang_thai,
    saving = false,
    patientInfo = {},
    onSaveDonThuoc = () => {},
    onClickHuyDon = () => {},
    reload = () => {},
    onChuyenSidebarTabSangDangKham = () => {},
  } = props;
  const input_SL = useRef(null);
  const input_moingay = useRef(null);
  const input_chialam = useRef(null);
  const input_cachdung = useRef(null);
  const input_ghi_chu = useRef(null);
  const input_storage = useRef();
  const input_template = useRef();
  const confirmDelete = useRef();
  const confirmDuplicate = useRef();
  const mauDonRef = useRef();
  const input_ghi_chu_thuoc = useRef(null);
  const input_don_vi = useRef(null);
  const [form] = useForm();
  const [formMauDon] = useForm();
  const [visible, setVisible] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(true);
  const userProfile = useSelector((state) => state.auth.user);
  let isKhoThuoc = userProfile.config && userProfile.config?.KHO_THUOC ? userProfile.config.KHO_THUOC : false;
  const storageConfirm = useRef();
  const rebornRef = useRef();

  const [dsThuocSelected, setDsThuocSelected] = useState([]); // danh sách thuốc đã chọn
  const [dvTinh, setDvTinh] = useState(); // đơn vị tính của thuốc
  const [need_reload, setNeed_reload] = useState(false);

  const [gioYLenh, setGioYLenh] = useState(); // giờ y lệnh
  const [dateYLenh, setDateYLenh] = useState(); // ngày y lệnh
  const [initTime, setInitTime] = useState();
  const [currentData, setCurrentData] = useState();
  const [editable, setEditable] = useState(true);
  const [onCheckPrint, setOnCheckPrint] = useState(true);
  const [loiDan, setLoiDan] = useState("");
  const [dsDvt, setDsDvt] = useState([]);
  const readOnly = false; // chỉ xem, không cho phép sửa data

  const [formTTThuoc] = useForm();
  const [columns, setColumns] = useState(columnsDonThuoc);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [initdataSource, setInitDataSource] = useState([]);
  const [dsKhoThuoc, setDSKhoThuoc] = useState([]); //hiển thị ds kho
  const [khoDaChon, setKhoDaChon] = useState({}); //thông tin kho đã chọn
  const [txtSearchThuoc, setTxtSearchThuoc] = useState(""); //string nội dung thuốc tìm kiếm
  const [thuocDaChon, setThuocDaChon] = useState({}); //thông tin thuốc đã chọn
  const [thuoc_dang_sua, setThuoc_dang_sua] = useState();
  const [daSua, setDaSua] = useState(false); //check xem đơn thuốc đã được sửa hay chưa
  const [PHONG_ID, setPHONG_ID] = useState("");
  const [isFocusInputTemplate, setisFocusInputTemplate] = useState(false);
  const [isLuuMauDon, setIsLuuMauDon] = useState(false);
  var print = false;

  const [initData, setInitData] = useState({});

  const searchDrugRef = useRef();
  const drugRef = useRef();

  useImperativeHandle(ref, () => ({
    visible,
    openModal(data = {}, isEdit = true) {
      console.log(data);
      setVisible(true);
      setDaSua(false);

      setInitData(data);
      setLoiDan(data.GHI_CHU);
      layDSKhoThuoc(data.KHO_THUOC_ID);
      if (!!data && !!data.DON_THUOC_ID) {
        getDataDonThuoc(data.DON_THUOC_ID);
      }
      console.log(!!data.NGAY_Y_LENH, !!data.GIO_Y_LENH);
      let date = !!data.NGAY_Y_LENH ? data.NGAY_Y_LENH : moment().format("YYYYMMDD");
      let time = !!data.GIO_Y_LENH ? data.GIO_Y_LENH : moment().format("HH:mm");
      if (!!data.NGAY_Y_LENH && !!data.GIO_Y_LENH) {
        setInitTime(moment(data.GIO_Y_LENH + " " + data.NGAY_Y_LENH, "HH:mm YYYYMMDD"));
      } else {
        setInitTime(moment());
      }
      setGioYLenh(time);
      setDateYLenh(date);
      setEditable(isEdit);
      input_storage?.current?.focus();
    },
    xacNhanLuuTrungMauDon(dataRequest, print) {
      confirmDuplicate.current.open({ dataRequest, print });
    },
    closeModal() {
      onClose();
      confirmDelete.current?.close();
      // rebornRef.current?.close();
    },
  }));

  // check xem là kho thuốc gì
  useEffect(() => {
    if (visible && dsDvt.length === 0) {
      layDsDvt({});
    }
  }, [visible]);

  const layDsDvt = async ({ page = 1, search_string = "", limit = 1000 }) => {
    let req = {
      page,
      limit,
      search_string,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    };
    try {
      let response = await common_post(apis.dm_don_vi_tinh_lay_ds, req);
      if (response && response.status === "OK") {
        setDsDvt(response.result);
      }
    } catch (error) {
      HLog("Api lay danh sach don vi tinh error", error);
    }
  };

  const updateGhiChuDonThuoc = async (loiDan) => {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      DON_THUOC_ID: initData.DON_THUOC_ID,
      GHI_CHU: loiDan,
    };
    try {
      let response = await common_post(apis.sua_ghi_chu_don_thuoc, req);
      if (response && response.status === "OK") {
        reload();
        if (
          JSON.stringify(dataSource.map((item) => ({ Thuoc_ID: item.Thuoc_ID, Thuoc_SL: item.Thuoc_SL }))) ===
          JSON.stringify(initdataSource.map((item) => ({ Thuoc_ID: item.Thuoc_ID, Thuoc_SL: item.Thuoc_SL })))
        ) {
          onClose();
        }
      }
    } catch (error) {
      HLog("Api lay danh sach don vi tinh error", error);
    }
  };

  const onClose = () => {
    setVisible(false);
    setLoading(false);
    setIsCreateNew(true);
    setGioYLenh();
    setDateYLenh();
    setInitTime();
    setDsThuocSelected([]);
    setEditable(true);
    setOnCheckPrint(true);
    setCurrentData();
    setThuocDaChon({});
    setKhoDaChon({});
    setDataSource([]);
    setLoiDan("");
    form.resetFields();
    formTTThuoc.resetFields();
    setInitData({});
    setThuoc_dang_sua();
    setDaSua(false);
    setIsLuuMauDon(false);
    formMauDon.resetFields();

    searchDrugRef.current?.resetData();
  };

  const onSubmit = async ({ dataRequest = {}, print = false }) => {
    // let dataSubmit = {
    //   NGAY_Y_LENH: dateYLenh,
    //   GIO_Y_LENH: gioYLenh,
    //   list_thuoc: list,
    //   DON_THUOC_ID: isCreateNew ? "" : currentData.DON_THUOC_ID,
    //   LOI_DAN: loiDan,
    // };

    setLoading(true);

    const result = await onSaveDonThuoc(dataRequest, print);

    if (!!result) {
      if (trang_thai === "KHAM_XONG") {
        rebornRef.current.close();

        onChuyenSidebarTabSangDangKham();
      }
      // if (onCheckPrint) props.handlePrint(result);
      onClose();
    }

    setLoading(false);
  };

  // chọn thời gian y lệnh
  const onChangeTime = (value) => {
    let date = moment(value).format("YYYYMMDD");
    let time = moment(value).format("HH:mm");
    setGioYLenh(time);
    setDateYLenh(date);
    setInitTime(moment(value, "HH:mm YYYYMMDD"));
    HLog("Date", date);
    HLog("Time", time);
  };

  //định dạng format lại ngày
  const customFormat = (value) => `${value.format(dateFormat)}`;
  //hàm xử lí nhấn nút lưu đơn thuốc
  const onPressLuuDonThuoc = async (isprint = false) => {
    print = isprint;
    if (isLuuMauDon) {
      formMauDon.submit();
    } else {
      onLuuDonThuoc(isprint);
    }
  };

  const onSubmitMauDon = (values) => {
    console.log("luu don mau");
    onLuuDonThuoc(print, values);
  };

  //hàm xử lí nhấn lưu ghi đè mẫu đơn thuốc
  const xacNhanGhiDeMauDonThuoc = async (data) => {
    confirmDuplicate.current.close();
    if (trang_thai === "KHAM_XONG") {
      rebornRef.current?.open({ dataRequest: { ...data.dataRequest, CHO_PHEP_GHI_DE: true }, print: data.print });
    } else {
      await onSaveDonThuoc({ ...data.dataRequest, CHO_PHEP_GHI_DE: true }, data.print);

      // if (trang_thai === "CHO_KHAM") {
      //   onChuyenSidebarTabSangDangKham();
      // }
    }
  };

  //hàm xử lí nhấn nút lưu đơn thuốc
  const onLuuDonThuoc = async (print = false, dataMauDon) => {
    console.log("đâs");
    if (
      // !initData.DON_THUOC_ID ||
      // (!!initData.DON_THUOC_ID && loiDan !== initData.GHI_CHU) ||
      // daSua ||
      // isLuuMauDon
      (!initData.DON_THUOC_ID || daSua || isLuuMauDon) &&
      dataSource.length > 0
    ) {
      console.log(dataSource);
      let dataRequest = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        BAC_SI_ID: userProfile.NHANSU_ID,
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        NGAY_Y_LENH: dateYLenh,
        GIO_Y_LENH: gioYLenh,
        KHO_THUOC: props.kho_thuoc,
        KHO_THUOC_ID: !!khoDaChon && isKhoThuoc ? khoDaChon.ID : "",
        GHI_CHU: loiDan,
        IS_DON_THUOC_MAU: isLuuMauDon,
        MA_DON_THUOC_MAU: !!dataMauDon ? dataMauDon.MA_DON_THUOC_MAU : null,
        TEN_DON_THUOC_MAU: !!dataMauDon ? dataMauDon.TEN_DON_THUOC_MAU : null,
        CHO_PHEP_GHI_DE: false,
        list_thuoc: dataSource.map((item) => ({
          Thuoc_ID: item.Thuoc_ID,
          Thuoc_Code: item.Thuoc_Code,
          Thuoc_Ten: item.Thuoc_Ten,
          Thuoc_Dvt: item.Thuoc_Dvt,
          Thuoc_Dvt_Cach_Dung: item.Thuoc_Dvt_Cach_Dung,
          Thuoc_SL: item.Thuoc_SL,
          Thuoc_HoatChat: item.Thuoc_HoatChat,
          Duongdung_Code: item.Duongdung_Code,
          Duongdung: item.Duongdung,
          Lieuluong: item.Lieuluong,
          Tansuat: item.Tansuat,
          Chidan: item.Chidan,
          GHI_CHU: item.GHI_CHU,
          KHO_THUOC: props.kho_thuoc,
          GIA_BAN: item.GIA_BAN,
          THANH_TIEN: Number(item.Thuoc_SL) * Number(item.GIA_BAN),
        })),
      };
      console.log(dataRequest);

      //nếu data thay đổi
      if (daSua || !initData.DON_THUOC_ID || isLuuMauDon) {
        if (trang_thai === "KHAM_XONG") {
          rebornRef.current?.open({ dataRequest, print });
        } else {
          await onSaveDonThuoc(dataRequest, print);

          // if (trang_thai === "CHO_KHAM") {
          //   onChuyenSidebarTabSangDangKham();
          // }
        }
      } else {
        if (print) {
          //nếu data không thay đổi thì chỉ in
          props.handlePrint();
        }
      }
      return;
    }

    if (!!initData.DON_THUOC_ID && loiDan !== initData.GHI_CHU) {
      updateGhiChuDonThuoc(loiDan);
      if (print) {
        //nếu data không thay đổi thì chỉ in
        props.handlePrint();
      }
    }
  };

  //hàm xử lí xoá thuốc
  const onDelete = (record) => {
    setDaSua(true);
    setDataSource((data) => data.filter((item) => item.Thuoc_ID !== record.Thuoc_ID));
  };

  //hàm xử lí click thuốc và sửa thông tin thuốc
  const onClickEditThuoc = (record) => {
    handleChonThuoc(record, true);
  };

  //form xử lí khi finish nhập form thuốc
  const onFinishThuoc = async (values) => {
    setDaSua(true);
    console.log(thuocDaChon, thuoc_dang_sua, values);
    if (!!thuoc_dang_sua) {
      let data_moi = dataSource.map((item) =>
        item.Thuoc_ID === thuoc_dang_sua.Thuoc_ID
          ? {
              ...item,
              GIA_BAN: !!thuocDaChon.GIA_BAN ? thuocDaChon.GIA_BAN : 0,
              THANH_TIEN: !!thuocDaChon.GIA_BAN ? Number(values.SO_LUONG) * Number(thuocDaChon.GIA_BAN) : 0,
              NONG_DO: thuocDaChon.NONG_DO,
              HAM_LUONG: thuocDaChon.HAM_LUONG,
              Thuoc_HoatChat: thuocDaChon.Thuoc_HoatChat,
              Duongdung_Code: thuocDaChon.Duongdung_Code,
              Duongdung: thuocDaChon.Duongdung,
              Thuoc_Code: thuocDaChon.Thuoc_Code,
              Thuoc_Ten: thuocDaChon.Thuoc_Ten,
              Thuoc_SL: values.SO_LUONG,
              Thuoc_Dvt: thuocDaChon.Thuoc_Dvt,
              Thuoc_Dvt_Cach_Dung: dvTinh,
              Lieuluong: values.MOI_NGAY,
              Tansuat: values.CHIA_LAM,
              Chidan: values.CACH_DUNG,
              GHI_CHU: values.GHI_CHU,
            }
          : item
      );
      setDataSource(data_moi);
    } else {
      if (dataSource.some((item) => item.Thuoc_ID === thuocDaChon.Thuoc_ID)) {
        //báo noti thuốc đã có không thể sửa
        notification.warning({
          message: i18n.t(languageKeys.noti_warning_thuoc) + thuocDaChon.Thuoc_Ten + i18n.t(languageKeys.noti_warning_thuoc_co_trong_don),
        });
      } else {
        setDataSource((data) => [
          ...data,
          {
            key: rid(5),
            Thuoc_ID: thuocDaChon.Thuoc_ID,
            Thuoc_Ten: thuocDaChon.Thuoc_Ten,
            Thuoc_Code: thuocDaChon.Thuoc_Code,
            Thuoc_SL: values.SO_LUONG,
            Thuoc_Dvt: thuocDaChon.Thuoc_Dvt,
            Thuoc_Dvt_Cach_Dung: dvTinh,
            NONG_DO: thuocDaChon.NONG_DO,
            HAM_LUONG: thuocDaChon.HAM_LUONG,
            Thuoc_HoatChat: thuocDaChon.Thuoc_HoatChat,
            Duongdung_Code: thuocDaChon.Duongdung_Code,
            Duongdung: thuocDaChon.Duongdung,
            GIA_BAN: !!thuocDaChon.GIA_BAN ? thuocDaChon.GIA_BAN : 0,
            THANH_TIEN: !!thuocDaChon.GIA_BAN ? Number(values.SO_LUONG) * Number(thuocDaChon.GIA_BAN) : 0,
            Lieuluong: values.MOI_NGAY,
            Tansuat: values.CHIA_LAM,
            Chidan: values.CACH_DUNG,
            GHI_CHU: values.GHI_CHU,
            KHO_THUOC: "KT_TU_NGUYEN",
          },
        ]);
      }

      handleFocusDrugInput();
    }
    formTTThuoc.resetFields([
      fieldDonThuoc.Thuoc_Ten,
      fieldDonThuoc.HAM_LUONG,
      fieldDonThuoc.NONG_DO,
      fieldDonThuoc.SO_LUONG,
      fieldDonThuoc.MOI_NGAY,
      fieldDonThuoc.CHIA_LAM,
      fieldDonThuoc.CACH_DUNG,
      fieldDonThuoc.GHI_CHU,
    ]);
    setDvTinh();
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const propsSelect = {
    disabled: readOnly,
    placeholder: readOnly ? "" : i18n.t(languageKeys.common_Chon),
  };

  //api lấy ds kho thuốc được phép kê
  const layDSKhoThuoc = async (KHO_THUOC_ID) => {
    if (!!patientInfo) {
      console.log(patientInfo.PHONG_ID, PHONG_ID);
    }

    if (!isKhoThuoc) {
      return;
    }

    if ((!!patientInfo && patientInfo.PHONG_ID !== PHONG_ID) || dsKhoThuoc.length === 0) {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        limit: keys.maxLimit,
        page: 1,
        PHONG_ID: !!props.patientInfo ? props.patientInfo.PHONG_ID : "",
        LOAI_THUOC: "NHA_THUOC",
      };
      let response = await apiLayDSKhoThuoc(dataRequest);
      if (response && !isEmptyObject(response)) {
        setPHONG_ID(patientInfo.PHONG_ID);
        const list = await response.result
          .filter((item) => item.LOAI_KHO !== "KHO_TONG")
          .map((item) => ({
            ...item,
            key: rid(5),
          }));
        setDSKhoThuoc(list);
        if (list.length === 1) {
          setKhoDaChon(list[0]);
          formTTThuoc.setFieldsValue({ [`${fieldNames.KHO_ID}`]: list[0].ID });
        }
        if (!!KHO_THUOC_ID) {
          let parseVal = response.result[response.result.map((item) => item.ID).indexOf(KHO_THUOC_ID)];
          if (!!parseVal) {
            setKhoDaChon(parseVal);
            formTTThuoc.setFieldsValue({ [`${fieldNames.KHO_ID}`]: KHO_THUOC_ID });
          }
          formTTThuoc.resetFields([
            fieldDonThuoc.Thuoc_Ten,
            fieldDonThuoc.HAM_LUONG,
            fieldDonThuoc.NONG_DO,
            fieldDonThuoc.SO_LUONG,
            fieldDonThuoc.MOI_NGAY,
            fieldDonThuoc.CHIA_LAM,
            fieldDonThuoc.CACH_DUNG,
          ]);
        }
      }
    } else {
      if (dsKhoThuoc.length > 0) {
        if (dsKhoThuoc.length === 1) {
          setKhoDaChon(dsKhoThuoc[0]);
          formTTThuoc.setFieldsValue({ [`${fieldNames.KHO_ID}`]: dsKhoThuoc[0].ID });
        }
        let parseVal = dsKhoThuoc[dsKhoThuoc.map((item) => item.ID).indexOf(KHO_THUOC_ID)];
        if (!!parseVal) {
          setKhoDaChon(parseVal);
          formTTThuoc.setFieldsValue({ [`${fieldNames.KHO_ID}`]: KHO_THUOC_ID });
        }
        formTTThuoc.resetFields([
          fieldDonThuoc.Thuoc_Ten,
          fieldDonThuoc.HAM_LUONG,
          fieldDonThuoc.NONG_DO,
          fieldDonThuoc.SO_LUONG,
          fieldDonThuoc.MOI_NGAY,
          fieldDonThuoc.CHIA_LAM,
          fieldDonThuoc.CACH_DUNG,
        ]);
        return;
      }
    }
  };

  //hàm xử lí chọn kho
  const handleChonKho = async (val) => {
    let parseVal = await dsKhoThuoc[dsKhoThuoc.map((item) => item.ID).indexOf(val)];
    if (!!khoDaChon && parseVal.ID !== khoDaChon.ID && dataSource.length > 0) {
      storageConfirm.current.open(parseVal);
    } else {
      setKhoDaChon(parseVal);
      formTTThuoc.resetFields([
        fieldDonThuoc.Thuoc_Ten,
        fieldDonThuoc.HAM_LUONG,
        fieldDonThuoc.NONG_DO,
        fieldDonThuoc.SO_LUONG,
        fieldDonThuoc.MOI_NGAY,
        fieldDonThuoc.CHIA_LAM,
        fieldDonThuoc.CACH_DUNG,
      ]);
      handleClearDrugInput();
      searchDrugRef.current?.searchData("", parseVal.ID, true);
      handleFocusDrugInput();
    }
  };

  //hàm xử lí chọn đơn vị tính
  const handleChonDVT = (val) => {
    setDvTinh(val);
    let MOI_NGAY = formTTThuoc.getFieldValue(fieldDonThuoc.MOI_NGAY);
    let CHIA_LAM = formTTThuoc.getFieldValue(fieldDonThuoc.CHIA_LAM);
    if (!!MOI_NGAY && !!CHIA_LAM) {
      formTTThuoc.setFieldsValue({
        [`${fieldDonThuoc.CACH_DUNG}`]:
          i18n.t(languageKeys.khambenh_moi_ngay) +
          ` ${MOI_NGAY} ${val}, ${i18n.t(languageKeys.khambenh_chia_lam)} ${CHIA_LAM} ` +
          i18n.t(languageKeys.khambenh_lan),
      });
    }
    input_chialam.current?.focus({
      cursor: "end",
    });
  };

  //xác nhận thay đổi kho
  const onSubmitChangeStorage = (data) => {
    setKhoDaChon(data);
    setDataSource([]);
    formTTThuoc.resetFields([
      fieldDonThuoc.Thuoc_Ten,
      fieldDonThuoc.HAM_LUONG,
      fieldDonThuoc.NONG_DO,
      fieldDonThuoc.SO_LUONG,
      fieldDonThuoc.MOI_NGAY,
      fieldDonThuoc.CHIA_LAM,
      fieldDonThuoc.CACH_DUNG,
      fieldDonThuoc.GHI_CHU,
    ]);
  };
  // hàm xử lý tìm kiếm thuốc
  const handleTimThuoc = (e) => {
    const { value } = e.target;
    if (khoDaChon && !isEmptyObject(khoDaChon)) {
      let khoID = khoDaChon.ID;
      setTxtSearchThuoc(value);
      searchDrugRef.current?.searchData(value, khoID);
    } else {
      if (!isKhoThuoc) {
        setTxtSearchThuoc(value);
        searchDrugRef.current?.searchData(value);
      }
    }
  };

  //hàm xử lí chọn thuốc cần thêm
  const handleChonThuoc = async (dataThuoc, suaThuoc = false) => {
    HLog("KeDonThuoc handleChonThuoc dataThuoc: ", dataThuoc);
    setThuocDaChon(dataThuoc);
    if (suaThuoc) {
      setThuoc_dang_sua(dataThuoc);
      let dataform = {
        [`${fieldDonThuoc.Thuoc_Ten}`]: dataThuoc.Thuoc_Ten,
        [`${fieldDonThuoc.Thuoc_HoatChat}`]: dataThuoc.Thuoc_HoatChat,
        [`${fieldDonThuoc.HAM_LUONG}`]: dataThuoc.HAM_LUONG,
        [`${fieldDonThuoc.NONG_DO}`]: dataThuoc.NONG_DO,
        [`${fieldDonThuoc.SO_LUONG}`]: dataThuoc.Thuoc_SL,
        [`${fieldDonThuoc.MOI_NGAY}`]: dataThuoc.Lieuluong,
        [`${fieldDonThuoc.CHIA_LAM}`]: dataThuoc.Tansuat,
        [`${fieldDonThuoc.CACH_DUNG}`]: dataThuoc.Chidan,
        [`${fieldDonThuoc.GHI_CHU}`]: dataThuoc.GHI_CHU,
      };
      await getDataThuoc(dataThuoc.Thuoc_ID, dataThuoc.KHO_THUOC_ID).then((res) => {
        if (!!res) {
          let so_thuoc_cu = 0;
          let index_thuoc_cu = initdataSource.map((item) => item.Thuoc_ID).indexOf(res.Thuoc_ID);
          if (index_thuoc_cu !== -1) {
            so_thuoc_cu = initdataSource[index_thuoc_cu].Thuoc_SL;
          }
          setThuocDaChon({ ...res, TON_CUOI: Number(res.TON_CUOI) + Number(so_thuoc_cu) });
        }
      });
      formTTThuoc.setFieldsValue(dataform);
      setDvTinh(dataThuoc.Thuoc_Dvt_Cach_Dung);
    } else {
      if (dataSource.some((item) => item.Thuoc_ID === dataThuoc.Thuoc_ID)) {
        //báo noti thuốc đã có không thể sửa
        notification.warning({
          message: i18n.t(languageKeys.noti_warning_thuoc) + dataThuoc.Thuoc_Ten + i18n.t(languageKeys.noti_warning_thuoc_co_trong_don),
        });
        return;
      }
      setDvTinh(dataThuoc.Thuoc_Dvt);
      setThuoc_dang_sua();
      searchDrugRef.current?.setVisible(false);
      formTTThuoc.setFields(
        Object.values(fieldDonThuoc).map((key) => {
          return {
            name: key,
            value: dataThuoc[key],
            errors: [],
          };
        })
      );
    }
    input_SL.current?.focus({
      cursor: "end",
    });
  };

  // hàm lấy chi tiết thuoc
  const getDataThuoc = async (ID, KHO_THUOC_ID) => {
    try {
      const dataRequest = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        search_string: "",
        limit: keys.limit,
        ID,
        page: 1,
        KHO_THUOC_ID, // chỉ xuất / bán thuốc thì mới true !!!
        LOAI_THUOC: "NHA_THUOC",
      };

      let response = await apiTimDsThuocTheoKho(dataRequest);
      if (response && !isEmptyObject(response)) {
        return response.result[0];
      }
    } catch (error) {
      HLog(error);
    }
  };

  //lấy data thuốc trong đơn
  const getDataDonThuoc = async (DON_THUOC_ID) => {
    if (!DON_THUOC_ID) {
      return;
    }
    try {
      const url = apis.lay_chi_tiet_don_thuoc_ke_don;
      const body = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        DON_THUOC_ID,
      };
      const res = await common_post(url, body);
      if (res.status === "OK") {
        const list = res.result.list_thuoc.map((item, index) => ({
          ...item,
          key: rid(5),
        }));
        setDataSource(list);
        setInitDataSource(list);
      }
    } catch (error) {
      HLog(error);
    }
  };

  const handleFocusDrugInput = () => {
    setTimeout(() => {
      drugRef.current?.blur();
      drugRef.current?.focus();
    }, 100);
  };

  //hàm xử lí xoá tìm kiếm
  const handleClearDrugInput = () => {
    setTxtSearchThuoc("");
  };

  const handleThayTheDonThuocMau = (data) => {
    console.log(data);
    setDaSua(true);
    setDataSource(
      data.map((item) => ({
        key: rid(5),
        Thuoc_ID: item.Thuoc_ID,
        Thuoc_Ten: item.Thuoc_Ten,
        Thuoc_Code: item.Thuoc_Code,
        Thuoc_SL: item.Thuoc_SL,
        Thuoc_Dvt: item.Thuoc_Dvt,
        Thuoc_Dvt_Cach_Dung: item.Thuoc_Dvt_Cach_Dung,
        NONG_DO: item.NONG_DO,
        HAM_LUONG: item.HAM_LUONG,
        Thuoc_HoatChat: item.Thuoc_HoatChat,
        Duongdung_Code: item.Duongdung_Code,
        Duongdung: item.Duongdung,
        GIA_BAN: item.GIA_BAN,
        THANH_TIEN: item.THANH_TIEN,
        Lieuluong: item.Lieuluong,
        Tansuat: item.Tansuat,
        Chidan: item.Chidan,
        GHI_CHU: item.GHI_CHU,
        KHO_THUOC: item.KHO_THUOC,
      }))
    );
  };

  const handleThemDonThuocMau = (data) => {
    setDaSua(true);
    let data_moi = [];
    let data_cu = dataSource;

    data.forEach((item) => {
      if (data_cu.some((thuoc_cu) => thuoc_cu.Thuoc_ID === item.Thuoc_ID)) {
        data_cu = data_cu.map((thuoc_cu) =>
          item.Thuoc_ID === thuoc_cu.Thuoc_ID
            ? {
                ...thuoc_cu,
                GIA_BAN: item.GIA_BAN,
                THANH_TIEN: item.THANH_TIEN,
                NONG_DO: item.NONG_DO,
                HAM_LUONG: item.HAM_LUONG,
                Thuoc_HoatChat: item.Thuoc_HoatChat,
                Duongdung_Code: item.Duongdung_Code,
                Duongdung: item.Duongdung,
                Thuoc_Code: item.Thuoc_Code,
                Thuoc_Ten: item.Thuoc_Ten,
                Thuoc_SL: Number(item.Thuoc_SL) + Number(thuoc_cu.Thuoc_SL),
                Thuoc_Dvt: item.Thuoc_Dvt,
                Thuoc_Dvt_Cach_Dung: item.Thuoc_Dvt_Cach_Dung,
                Lieuluong: item.Lieuluong,
                Tansuat: item.Tansuat,
                Chidan: item.Chidan,
                GHI_CHU: item.GHI_CHU,
              }
            : thuoc_cu
        );
      } else {
        data_moi.push({
          key: rid(5),
          Thuoc_ID: item.Thuoc_ID,
          Thuoc_Ten: item.Thuoc_Ten,
          Thuoc_Code: item.Thuoc_Code,
          Thuoc_SL: item.Thuoc_SL,
          Thuoc_Dvt: item.Thuoc_Dvt,
          Thuoc_Dvt_Cach_Dung: item.Thuoc_Dvt_Cach_Dung,
          NONG_DO: item.NONG_DO,
          HAM_LUONG: item.HAM_LUONG,
          Thuoc_HoatChat: item.Thuoc_HoatChat,
          Duongdung_Code: item.Duongdung_Code,
          Duongdung: item.Duongdung,
          GIA_BAN: item.GIA_BAN,
          THANH_TIEN: item.THANH_TIEN,
          Lieuluong: item.Lieuluong,
          Tansuat: item.Tansuat,
          Chidan: item.Chidan,
          GHI_CHU: item.GHI_CHU,
          KHO_THUOC: item.KHO_THUOC,
        });
      }
    });
    setDataSource(data_cu.concat(data_moi));
  };

  // hàm xử lý phím tắt
  function keydownEvent(event) {
    console.log(event, saving);
    if (saving) {
      return;
    }

    if (event.key === "s" && event.ctrlKey) {
      event.preventDefault();
      onPressLuuDonThuoc();
    } else if (event.key === "i" && event.ctrlKey && !!initData.DON_THUOC_ID) {
      event.preventDefault();
      if (!!initData.DON_THUOC_ID) {
        props.handlePrint();
        // onClose()
      }
    } else if (event.key === "g" && event.ctrlKey) {
      event.preventDefault();
      input_ghi_chu.current.focus();
    } else if (event.key === "l" && event.ctrlKey) {
      event.preventDefault();
      onPressLuuDonThuoc(true);
    } else if (event.key === "a" && event.ctrlKey) {
      event.preventDefault();
      if (!isEmptyObject(thuocDaChon)) {
        formTTThuoc.submit();
      } else {
        handleFocusDrugInput();
      }
    } else if (event.key === "d" && event.ctrlKey) {
      event.preventDefault();
      if (!!initData.DON_THUOC_ID) {
        confirmDelete.current.open();
      }
    } else if (event.key === "esc" || event.key === "Escape") {
      event.preventDefault();
      console.log(!confirmDelete.current?.visible, !searchDrugRef.current?.visible, !storageConfirm.current?.visible);
      if (!confirmDelete.current?.visible && !searchDrugRef.current?.visible && !storageConfirm.current?.visible) {
        onClose();
      }
    }
  }

  useEventListener("keydown", keydownEvent, window);

  return (
    <FormDrawer
      form={form}
      title={i18n.t(languageKeys.Ke_don_thuoc)}
      visible={visible}
      loading={loading}
      pending={saving}
      onCancel={onClose}
      width={widthDrawerKeys.large}
      hiddenTopAction
      // onClickBack={onClose}
      className={style["add-drug"]}
      keyboard={false}
      addOnActions={
        <Row gutter={10} align="middle">
          <Col>{i18n.t(languageKeys.field_Thoi_gian_y_lenh)}</Col>
          <Col>
            <DatePicker
              className={style["select-w"]}
              showTime
              format={customFormat}
              showSecond={false}
              onChange={(value) => onChangeTime(value)}
              defaultValue={moment()}
              value={initTime ? initTime : moment()}
              disabled={!editable}
            />
          </Col>
        </Row>
      }
    >
      <Layout>
        <Layout.Sider className={style["wrap-sider"]} width={300}>
          {/* thông tin người dùng, thông tin thuốc */}
          <div className={style["user-profile"]}>
            <Form layout="vertical" form={formTTThuoc} onFinish={onFinishThuoc}>
              <div className={style["form-container"]}>
                <Row gutter={10} style={{ marginLeft: 0, marginRight: 0 }}>
                  {isKhoThuoc && (
                    <Col span={24}>
                      <Form.Item
                        label={i18n.t(languageKeys.txt_chon_kho)}
                        name={fieldNames.KHO_ID}
                        rules={[
                          {
                            required: false,
                            message: i18n.t(languageKeys.vui_long_nhap),
                          },
                        ]}
                      >
                        <Select
                          dataSource={dsKhoThuoc}
                          titleKey={fieldKhoThuoc.TEN_KHO}
                          valueKey="ID"
                          showSearch
                          filterOption={filterOption}
                          {...propsSelect}
                          readOnly={dsKhoThuoc.length < 2}
                          onSelect={handleChonKho}
                          ref={input_storage}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* Chọn thuốc*/}
                  <Col span={24}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_ten_thuoc)}
                      name={fieldDonThuoc.Thuoc_Ten}
                      rules={[
                        {
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        readOnly={false}
                        onFocus={() => handleTimThuoc({ target: { value: txtSearchThuoc } })}
                        onClick={() => handleTimThuoc({ target: { value: txtSearchThuoc } })}
                        onPressEnter={handleTimThuoc}
                        onChange={({ target }) => {
                          if (!isEmptyObject(thuocDaChon)) {
                            formTTThuoc.resetFields([
                              fieldDonThuoc.HAM_LUONG,
                              fieldDonThuoc.NONG_DO,
                              fieldDonThuoc.SO_LUONG,
                              fieldDonThuoc.MOI_NGAY,
                              fieldDonThuoc.CHIA_LAM,
                              fieldDonThuoc.CACH_DUNG,
                              fieldDonThuoc.GHI_CHU,
                            ]);
                            setDvTinh();
                            setThuocDaChon({});
                            setThuoc_dang_sua();
                          }
                          console.log(target);
                          setTxtSearchThuoc(target.value);
                          searchDrugRef.current?.setVisible(false);
                        }}
                        ref={drugRef}
                      />
                    </Form.Item>
                  </Col>

                  <TimKiemThuocPopup ref={searchDrugRef} handleChonThuoc={handleChonThuoc} clearInput={handleClearDrugInput} />
                  <Col span={24}>
                    <Row gutter={10}>
                      {/*hàm lượng*/}
                      <Col span={12}>
                        <Form.Item name={fieldDonThuoc.HAM_LUONG} label={i18n.t(languageKeys.field_ham_luong)}>
                          <Input autoComplete="off" placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={true} />
                        </Form.Item>
                      </Col>

                      {/*nồng độ*/}
                      <Col span={12}>
                        <Form.Item name={fieldDonThuoc.NONG_DO} label={i18n.t(languageKeys.field_nong_do)}>
                          <Input autoComplete="off" placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {/*Số lượng*/}
                  <Col span={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || (!!value && Number(value) <= 0)) {
                              return Promise.reject(new Error(i18n.t(languageKeys.khambenh_so_luong_thuoc_lon_hon_0)));
                            }
                            let max = thuocDaChon.TON_CUOI;
                            if (!isKhoThuoc || !value || Number(value) <= Number(max)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(i18n.t(languageKeys.khambenh_so_luong_thuoc_khong_du)));
                          },
                        }),
                      ]}
                      name={fieldDonThuoc.SO_LUONG}
                      label={
                        !!thuocDaChon.TON_CUOI ? (
                          <Row align="bottom" className={style["label-so-luong"]}>
                            {i18n.t(languageKeys.txt_So_luong)}{" "}
                            <p className={style["label-so-luong-value"]}>
                              {" "}
                              {i18n.t(languageKeys.khambenh_toi_da)}: {thuocDaChon.TON_CUOI}
                            </p>{" "}
                          </Row>
                        ) : (
                          i18n.t(languageKeys.txt_So_luong)
                        )
                      }
                    >
                      <Input
                        ref={input_SL}
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        autoComplete="off"
                        onPressEnter={() => {
                          input_moingay.current?.focus({
                            cursor: "end",
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/*Mỗi ngày*/}
                  <Col span={24}>
                    <Form.Item
                      name={fieldDonThuoc.MOI_NGAY}
                      label={i18n.t(languageKeys.field_Moi_ngay)}
                      dependencies={[fieldDonThuoc.SO_LUONG]}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        ref={input_moingay}
                        min={0}
                        // max = {Number(thuocDaChon.TON_CUOI || 0)}
                        autoComplete="off"
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        onChange={(val) => {
                          formTTThuoc.setFieldsValue({
                            [`${fieldDonThuoc.CACH_DUNG}`]:
                              !!formTTThuoc.getFieldValue(fieldDonThuoc.CHIA_LAM) && val
                                ? i18n.t(languageKeys.khambenh_moi_ngay) +
                                  ` ${val} ${dvTinh}, ${i18n.t(languageKeys.khambenh_chia_lam)} ${formTTThuoc.getFieldValue(
                                    fieldDonThuoc.CHIA_LAM
                                  )} ` +
                                  i18n.t(languageKeys.khambenh_lan)
                                : i18n.t(languageKeys.khambenh_moi_ngay) + ` ${val} ${dvTinh}`,
                          });
                        }}
                        onKeyDown={(event) => {
                          console.log(event);
                          if (event.code === "Enter" && event.altKey) {
                            input_don_vi.current?.focus();
                          } else if (event.code === "Enter") {
                            input_chialam.current?.focus({
                              cursor: "end",
                            });
                          }
                        }}
                        addonAfter={
                          <div style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "5px", borderRadius: "5px" }}>
                            <Select
                              ref={input_don_vi}
                              dataSource={dsDvt}
                              valueKey={"TEN_DVT"}
                              value={dvTinh}
                              titleKey={fieldThuocDVT.TEN_DVT}
                              showSearch
                              filterOption={filterOption}
                              {...propsSelect}
                              placeholder={i18n.t(languageKeys.field_Don_vi)}
                              onSelect={(val) => handleChonDVT(val)}
                            />
                          </div>
                        }
                        className={style["input-num"]}
                      />
                    </Form.Item>
                  </Col>

                  {/*chia làm*/}
                  <Col span={24}>
                    <Form.Item name={fieldDonThuoc.CHIA_LAM} label={i18n.t(languageKeys.field_Chia_lam)}>
                      <Input
                        ref={input_chialam}
                        onPressEnter={() => {
                          input_cachdung.current?.focus({
                            cursor: "end",
                          });
                        }}
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        onChange={({ target }) => {
                          formTTThuoc.setFieldsValue({
                            [`${fieldDonThuoc.CACH_DUNG}`]:
                              !!formTTThuoc.getFieldValue(fieldDonThuoc.MOI_NGAY) && target.value
                                ? i18n.t(languageKeys.khambenh_moi_ngay) +
                                  ` ${formTTThuoc.getFieldValue(fieldDonThuoc.MOI_NGAY)} ${dvTinh}, ${i18n.t(languageKeys.khambenh_chia_lam)} ${
                                    target.value
                                  } ` +
                                  i18n.t(languageKeys.khambenh_lan)
                                : "",
                          });
                        }}
                        addonAfter={
                          <div>
                            <div style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "5px", borderRadius: "5px" }}>
                              {i18n.t(languageKeys.field_Lan)}
                            </div>
                          </div>
                        }
                      />
                    </Form.Item>
                  </Col>

                  {/*Cách dùng*/}
                  <Col span={24}>
                    <Form.Item
                      name={fieldDonThuoc.CACH_DUNG}
                      label={i18n.t(languageKeys.field_Cach_dung)}
                      rules={[
                        {
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || (!!value && !!value.trim())) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(i18n.t(languageKeys.vui_long_nhap)));
                          },
                        }),
                      ]}
                    >
                      <Input.TextArea
                        ref={input_cachdung}
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        autoSize
                        onPressEnter={({ target }) => {
                          if (target.value.endsWith("\n")) {
                            setTimeout(() => {
                              input_ghi_chu_thuoc.current?.focus({
                                cursor: "end",
                              });

                              formTTThuoc.setFieldsValue({ [`${fieldDonThuoc.CACH_DUNG}`]: target.value.slice(0, -2) });
                            }, 100);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/*Ghi chú*/}
                  <Col span={24}>
                    <Form.Item name={fieldDonThuoc.GHI_CHU} label={i18n.t(languageKeys.field_Ghi_chu)}>
                      <Input.TextArea
                        ref={input_ghi_chu_thuoc}
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        autoSize
                        onPressEnter={({ target }) => {
                          if (target.value.endsWith("\n")) {
                            setTimeout(() => {
                              input_ghi_chu.current?.focus({
                                cursor: "end",
                              });

                              formTTThuoc.setFieldsValue({ [`${fieldDonThuoc.GHI_CHU}`]: target.value.slice(0, -2) });
                            }, 100);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className={style["layout-btn-thuoc"]}>
                {/*nút thêm thuốc*/}
                <Button className={style["btn-them-thuoc"]} type="primary" onClick={() => formTTThuoc.submit()}>
                  + {i18n.t(!!thuoc_dang_sua ? languageKeys.txt_luu_thuoc : languageKeys.txt_them_thuoc)} (Ctrl+A)
                </Button>
              </div>
            </Form>
          </div>
        </Layout.Sider>
        <Layout.Content className={style["wrap-layout"]}>
          {/* <Layout> */}
          <Layout.Content className={isLuuMauDon ? style["content-thuoc-with-template"] : style["content-thuoc"]}>
            {/* tiêu đề */}
            <Row className={style["header-text"]} align="middle" justify="space-between">
              <Col flex="auto">
                <h3>{i18n.t(languageKeys.txt_ds_thuoc_chi_dinh)}</h3>
              </Col>

              <Col>
                <Input
                  autoComplete="off"
                  className={style["input-template"]}
                  ref={input_template}
                  placeholder={i18n.t(languageKeys.txt_placeholder_chon_don_thuoc_mau)}
                  onFocus={({ target }) => {
                    setisFocusInputTemplate(true);
                  }}
                  onBlur={() => setisFocusInputTemplate(false)}
                  onPressEnter={({ target }) => mauDonRef.current?.searchData(target.value)}
                  onClick={({ target }) => mauDonRef.current?.searchData(target.value)}
                  suffix={<DropDown />}
                  prefix={isFocusInputTemplate ? <Search /> : <span />}
                />
                <DonThuocMauPopup ref={mauDonRef} handleThayTheDonThuocMau={handleThayTheDonThuocMau} handleThemDonThuocMau={handleThemDonThuocMau} />
              </Col>
            </Row>
            {/* danh sách thuốc đã chọn */}
            <div className={cn([style["table-container"]])}>
              <Table
                scroll={{
                  y: `calc(100vh - ${315}px)`,
                  x: `${(columns.length + 2) * 100}px`,
                }}
                loading={loading}
                showPagination={false}
                onClickRow={onClickEditThuoc}
                size="small"
                columns={[
                  ...columns,
                  {
                    title: "",
                    key: "action_btn",
                    fixed: "right",
                    dataIndex: "action_btn",
                    width: "60px",
                    render: (_, record) => (
                      <Row
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(record);
                        }}
                      >
                        <Delete className={style["delete-icon"]} />
                      </Row>
                    ),
                  },
                ]}
                dataSource={dataSource.map((item, index) => ({ ...item, STT: index + 1 }))}
              />
            </div>
          </Layout.Content>
          <Layout.Footer className={style["footer"]}>
            <div className={style["note-container"]}>
              <div className={style["label"]}>{i18n.t(languageKeys.field_Ghi_chu)} (Ctrl+G)</div>
              <Input.TextArea
                placeholder={i18n.t(languageKeys.txt_nhap_loi_dan)}
                ref={input_ghi_chu}
                value={loiDan}
                row={4}
                onChange={({ target }) => setLoiDan(target.value)}
              />
            </div>
            <div className={style["template-container"]}>
              <Checkbox checked={isLuuMauDon} value={isLuuMauDon} onChange={({ target }) => setIsLuuMauDon(target.checked)}>
                <div style={{ color: "#2C3782" }}>{i18n.t(languageKeys.txt_luu_mau_don_thuoc)}</div>
              </Checkbox>
              {isLuuMauDon && (
                <Form form={formMauDon} onFinish={onSubmitMauDon}>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <Form.Item name={"MA_DON_THUOC_MAU"} label={i18n.t(languageKeys.txt_ma_mau_don_thuoc)}>
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                      </Form.Item>
                    </Col>
                    <Col flex="auto" style={{ marginLeft: "15px" }}>
                      <Form.Item
                        name={"TEN_DON_THUOC_MAU"}
                        label={i18n.t(languageKeys.txt_ten_mau_don_thuoc)}
                        rules={[
                          {
                            required: true,
                            message: i18n.t(languageKeys.vui_long_nhap),
                          },
                        ]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} width={"100%"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
            <Row justify="end" gutter={10} wrap={false} style={{ marginRight: "10px" }}>
              <Col>
                <div className={style["wrap-btn"]}>
                  <Button type="primary" ghost color="#6576FF" className={style["button-payment"]} onClick={onClose}>
                    {i18n.t(languageKeys.common_Thoat)} (ESC)
                  </Button>
                </div>
              </Col>
              {!!initData.DON_THUOC_ID && (
                <Col>
                  <div className={style["wrap-btn"]}>
                    <Button type="primary" ghost danger color="red" onClick={() => confirmDelete.current.open()} disabled={!initData.DON_THUOC_ID}>
                      {i18n.t(languageKeys.common_huy_don)} (Ctrl + D)
                    </Button>
                  </div>
                </Col>
              )}
              {/* nếu đơn thuốc đã tạo và không thay đổi thì có nút in */}
              {!!initData.DON_THUOC_ID && loiDan === initData.GHI_CHU && !daSua && (
                <Col>
                  <div className={style["wrap-btn"]}>
                    <Button type="primary" onClick={() => props.handlePrint()}>
                      {i18n.t(languageKeys.common_In)} (Ctrl + I)
                    </Button>
                  </div>
                </Col>
              )}
              {/* nếu đơn thuốc chưa tạo hoặc thay đổi thì có nút in và lưu */}
              {(!initData.DON_THUOC_ID || (!!initData.DON_THUOC_ID && loiDan !== initData.GHI_CHU) || daSua || isLuuMauDon) && (
                <Col>
                  <div className={style["wrap-btn"]}>
                    <Button type="primary" onClick={() => onPressLuuDonThuoc(true)} disabled={!dataSource.length}>
                      {i18n.t(languageKeys.common_Luu_va_In)} (Ctrl + L)
                    </Button>
                  </div>
                </Col>
              )}
              {/* nếu đơn thuốc chưa tạo hoặc thay đổi thì có lưu */}
              {(!initData.DON_THUOC_ID || (!!initData.DON_THUOC_ID && loiDan !== initData.GHI_CHU) || daSua || isLuuMauDon) && (
                <Col>
                  <div className={style["wrap-btn"]}>
                    <Button type="primary" onClick={() => onPressLuuDonThuoc()} disabled={!dataSource.length}>
                      {i18n.t(languageKeys.common_Luu)} (Ctrl + S)
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </Layout.Footer>
        </Layout.Content>
      </Layout>

      <ConfirmModal
        ref={rebornRef}
        title={i18n.t(languageKeys.title_Xac_nhan)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham)}
        onOk={(data) => {
          rebornRef.current.loading(true);
          onSubmit(data);
        }}
      />

      <ConfirmModal
        okText={i18n.t(languageKeys.title_Xac_nhan)}
        ref={confirmDelete}
        danger={true}
        content={i18n.t(languageKeys.confirm_xoa_don_thuoc)}
        onOk={onClickHuyDon}
      />

      <ConfirmModal
        okText={i18n.t(languageKeys.common_Thay_the)}
        ref={confirmDuplicate}
        cancelText={i18n.t(languageKeys.common_Huy)}
        danger={true}
        content={i18n.t(languageKeys.ma_don_thuoc_mau_da_ton_tai)}
        onOk={xacNhanGhiDeMauDonThuoc}
      />

      <ConfirmModal
        ref={storageConfirm}
        title={i18n.t(languageKeys.title_Xac_nhan)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.confirm_Thay_doi_kho_thuoc)}
        danger={true}
        onOk={(data) => {
          storageConfirm.current.close(true);
          onSubmitChangeStorage(data);
        }}
        onCancel={() => {
          formTTThuoc.setFieldsValue({ [`${fieldNames.KHO_ID}`]: khoDaChon.ID });
        }}
      />
    </FormDrawer>
  );
});

export default KeDonThuoc;
