import i18n, { languageKeys } from "../../../i18n";
import { ConfirmModal, Popup, Topbar } from "../../../components";
import { Button, Col, Row, Layout, Checkbox, Popover, notification, Tooltip, Spin, DatePicker, Select } from "antd";
import style from "./soHoaDon.module.less";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useGetSoHoaDon } from "./hooksSoHoaDon";
import { Trash, Edit, Receipt } from "../../../assets/svg";
import { useHistory, useLocation } from "react-router-dom";
import ModalSoHoaDon from "./ModalSoHoaDon/ModalSoHoaDon";
import { fieldsSoHoaDon } from "./fieldsSoHoaDon";
import { paths } from "../../../constants";
import { apiXoaSoHoaDon } from "./apisSoHoaDon";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberToPrice, handleCheckPermissions, isAccessAllowed } from "../../../helpers";
import keys, { PAGE_SIZE_OPTIONS, featureKeys, loaiSo, permissionActionKey } from "../../../constants/keys";
// import { resetTitle, updateTitle } from "../../../ducks/slices/titlePageSlice";
import moment from "moment";
import { useMemo } from "react";
import Pagination from "components/Pagination_v2";

const SoHoaDon = () => {
  const history = useHistory();
  const configRef = useRef();
  const deleteRef = useRef();
  const userProfile = useSelector((state) => state.auth.user);
  const { dataSource, totalResult, getDataSource, loadingList, currentPage, pageSize } = useGetSoHoaDon(); // custom hook lấy danh sách lịch khám
  const [deleteList, setDeleteList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const { state } = useLocation();
  const [filterDate, setFilterDate] = useState((state && state?.date) ? [state?.date[0], state?.date[1]] : [moment().format("YYYYMMDD"), moment().format("YYYYMMDD")]);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(updateTitle(i18n.t(languageKeys.menu_Quan_ly_so_hoa_don)));

  //   return () => {
  //     dispatch(resetTitle());
  //   };
  // }, []);

  useEffect(() => {
    getDataSource(searchString, state?.currentPage, filterDate[0], filterDate[1], state?.pageSize);
  }, [filterDate, state])

  // Hàm xử lý mở modal thêm mới/chỉnh sửa tiếp đón
  const handleOpenConfig = (data) => configRef.current.open(data);

  // Hàm xử lý gọi danh sách theo từ khóa tìm kiếm
  const submitSearch = () => {
    
    getDataSource(searchString, 1, filterDate[0], filterDate[1]);
  }

  // Hàm xử lý tải lại danh sách (clear luôn từ khóa tìm kiếm)
  const handleReloadList = () => {
    const from = moment().format("YYYYMMDD");
    const to = moment().format("YYYYMMDD");
    setFilterDate([from, to]);
    setSearchString('');
    getDataSource('', 1, from, to, PAGE_SIZE_OPTIONS[0]);
  };

  //lấy loại sổ
  const layLoaiSo = (key) => {
    if (key === loaiSo.SO_THU_TIEN) {
      return i18n.t(languageKeys.vien_phi_so_hoa_don);
    }
    if (key === loaiSo.SO_HOAN_TIEN){
      return i18n.t(languageKeys.so_hoan_tien)
    }
    if (key === loaiSo.SO_TAM_UNG){
      return i18n.t(languageKeys.so_tam_ung)
    }
    if (key === loaiSo.SO_CONG_NO){
      return i18n.t(languageKeys.so_cong_no)
    }
  };

  //lấy trạng thái sổ còn mơi hay không
  // const layTrangThaiSo = (record) => {
  //   let { SO_HD_DA_TAO = 0, TONG_SO_PHIEU = 0 } = record;
  //   if (TONG_SO_PHIEU === SO_HD_DA_TAO) {
  //     return <div className={cn(style["inner-box"], style["red"])}>{i18n.t(languageKeys.status_Da_day)}</div>;
  //   } else if (TONG_SO_PHIEU - SO_HD_DA_TAO < 100) {
  //     return <div className={cn(style["inner-box"], style["gold"])}>{i18n.t(languageKeys.status_Gan_day)}</div>;
  //   } else {
  //     return <div className={cn(style["inner-box"], style["green"])}>{i18n.t(languageKeys.status_Moi)}</div>;
  //   }
  // };

  const onRequestDelete = (item) => {
    deleteRef.current.open();
    setDeleteList([item]);
  };

  const getListId = () => {
    let listId = [];

    deleteList.forEach((item) => {
      listId.push(item.ID);
    });

    return listId;
  };

  const handleDeleteRecord = (item) => {
    const from = moment().format("YYYYMMDD");
    const to = moment().format("YYYYMMDD");
    deleteRef.current.loading(true);
    apiXoaSoHoaDon({
      listId: getListId(),
      partner_code: userProfile.partner_code,
    }).then((res) => {
      if (!!res) {
        if (res.status === "OK") {
          notification.success({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
          });
          getDataSource(searchString, 1, from, to);
        }
      }
      deleteRef.current.close();
    });

    setDeleteList([]);
  };

  //Hàm tích 1 sổ
  // const handleCheckItem = (record, type) => {
  //   if (type) {
  //     setDeleteList(deleteList.concat([record]));
  //   } else {
  //     setDeleteList(deleteList.filter((item) => record.ID !== item.ID));
  //   }
  // };

  const simulateMouseClick = (element) => {
    ['mousedown', 'click', 'mouseup'].forEach(mouseEventType =>
        element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
        )
    );
  }

  const handleChangeDateOption = (value) => {
    const applyFilter = (from, to) => {
      setFilterDate([from, to]);
      getDataSource(searchString, 1, from, to);
    }
    let from = moment().format("YYYYMMDD");
    let to = moment().format("YYYYMMDD");
    switch (value) {
      case dateValue.TODAY:
        applyFilter(from, to);
        return;
      case dateValue.YESTERDAY:
        from = moment().subtract(1, 'day').format("YYYYMMDD");
        to = moment().subtract(1, 'day').format("YYYYMMDD");
        applyFilter(from, to);
        return;
      case dateValue.LAST7DAYS:
        from = moment().subtract(6, 'day').format("YYYYMMDD");
        to = moment().format("YYYYMMDD");
        applyFilter(from, to);
        return;
      case dateValue.LAST30DAYS:
        from = moment().subtract(29, 'day').format("YYYYMMDD");
        to = moment().format("YYYYMMDD");
        applyFilter(from, to);
        return;
      case dateValue.OTHER:
        simulateMouseClick(document.querySelector('.ant-layout-header .ant-picker-input'));
    }
  }

  const handleChangeDate = (values) => {
    if(!values) return;
    const from = moment(values[0])?.format("YYYYMMDD");
    const to = moment(values[1])?.format("YYYYMMDD");
    setFilterDate([from, to]);
    getDataSource(searchString, 1, from, to);
  };

  const configPermissions = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_so_hoa_don);
  }, [userProfile]);


  const renderThongTinSo = (item) => {
    if(item?.LOAI_SO === loaiSo.SO_THU_TIEN || item?.LOAI_SO === loaiSo.SO_HOAN_TIEN){
      return (
        <>
          <li className={style["item"]}>
            {i18n.t(languageKeys.tong_tien)}: <span style={stylePrice}>{formatNumberToPrice(Number(item?.TONG_CHI_PHI))}</span>
          </li>
          <li className={style["item"]}>
            {i18n.t(languageKeys.field_Mien_giam)}: <span style={stylePrice}>{formatNumberToPrice(Number(item?.MIEN_GIAM))}</span>
          </li>
          <li className={style["item"]}>
            {i18n.t(languageKeys.tong_thanh_toan)}:{" "}{formatNumberToPrice(Number(item?.TONG_THANH_TOAN))}
          </li>
        </>
      )
    }

    if(item?.LOAI_SO === loaiSo.SO_TAM_UNG){
      return (
        <>
          <li className={style["item"]}>
            {i18n.t(languageKeys.tong_tien)}: <span style={stylePrice}>{formatNumberToPrice(Number(item?.TONG_THANH_TOAN))}</span>
          </li>
          <li style={{opacity: 0, visibility: 'hidden'}}></li>
          <li style={{opacity: 0, visibility: 'hidden'}}></li>
        </>
      )
    }

    if(item?.LOAI_SO === loaiSo.SO_CONG_NO){
      return (
        <>
          <li className={style["item"]}>
            {i18n.t(languageKeys.tong_thanh_toan)}:{" "}{formatNumberToPrice(Number(item?.TONG_THANH_TOAN))}
          </li>
          <li className={style["item"]}>
            {i18n.t(languageKeys.da_thanh_toan)}: <span style={stylePrice}>{formatNumberToPrice(Number(item?.DA_THANH_TOAN))}</span>
          </li>
          <li className={style["item"]}>
            {i18n.t(languageKeys.so_du_cong_no)}: <span style={stylePrice}>{formatNumberToPrice(Number(item?.SO_DU_CONG_NO))}</span>
          </li>
        </>
      )
    }
    return <></>
  }

  const getSelectValue = () => {
    if(filterDate[0] === moment().format('YYYYMMDD') && filterDate[1] === moment().format('YYYYMMDD'))
      return dateValue.TODAY
    if(filterDate[0] === moment().subtract(1, 'day').format("YYYYMMDD") && filterDate[1] === moment().subtract(1, 'day').format("YYYYMMDD"))
      return dateValue.YESTERDAY
    if(filterDate[0] === moment().subtract(6, 'day').format("YYYYMMDD") && filterDate[1] === moment().format('YYYYMMDD'))
      return dateValue.LAST7DAYS
    if(filterDate[0] === moment().subtract(29, 'day').format("YYYYMMDD") && filterDate[1] === moment().format('YYYYMMDD'))
      return dateValue.LAST30DAYS
    return dateValue.OTHER
  }

  return (
    <Layout style={{height: `calc(100vh - 50px - 50px)`, overflow: 'auto',  paddingBottom: '52px', paddingTop: '50px'}}>
      <Topbar
        className={style["sub-header"]}
        title={`${i18n.t(languageKeys.cate_so_hoa_don)}`}
        disabledAddBtn={!configPermissions[permissionActionKey.THEM_MOI]}
        onAdd={isAccessAllowed(userProfile, featureKeys.quan_ly_so_hoa_don) ? handleOpenConfig : false}
        totalNum={totalResult}
        searchString={searchString}
        setSearchString={setSearchString}
        onSearch={() => {
          if(searchString.trim()?.length < keys.limit_min_length)  return; // update 8/8/2024 bỏ giới hạn search
          submitSearch();
        }}
        searchReset={() => getDataSource('', 1, filterDate[0], filterDate[1])}
        disableEnterWhenEmpty
        addOnActions={
          <>
            <Select
              options={pickerPresets}
              onChange={handleChangeDateOption}
              value={getSelectValue()}
              className={style["date-select"]}
            />
            <DatePicker.RangePicker
              style={{ width: 250, borderRadius: 10, backgroundColor: '#F5F7FB' }}
              format={"DD/MM/YYYY"}
              onChange={(value) => handleChangeDate(value)}
              value={[moment(filterDate[0]), moment(filterDate[1])]}
              defaultValue={filterDate ? [moment(filterDate[0]), moment(filterDate[1])] : [moment(), moment()]}
              disabledDate={(current) => current && current < moment().startOf('day').add(1, 'days')}
            />
          </>
        }
      />

      <Spin spinning={loadingList}>
        <div className={style["container"]}>
          {dataSource.map((item, index) => (
            <div
              className={style["item-wrapper"]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push(paths.thong_tin_so_hoa_don_nId + item.ID, {
                  date: [filterDate],
                  currentPage,
                  pageSize
                });
              }}
              key={index}
            >
              <div className={style["name_container"]}>
                <Row justify="end">
                  {/* <Col flex="auto">
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCheckItem(item, e.target.checked);
                      }}
                    />
                  </Col>

                  <Col>{layTrangThaiSo(item)}</Col> */}

                  {isAccessAllowed(userProfile, featureKeys.quan_ly_so_hoa_don) && (
                    <Col>
                      <div
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Tooltip
                          title={
                            configPermissions[permissionActionKey.SUA]
                              ? i18n.t(languageKeys.common_Chinh_sua)
                              : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
                          }
                        >
                          <Edit
                            className={configPermissions[permissionActionKey.SUA] && style["icon-edit"]}
                            onClick={() => configPermissions[permissionActionKey.SUA] && configRef.current.open(item)}
                          />
                        </Tooltip>

                        <Tooltip
                          title={
                            configPermissions[permissionActionKey.XOA]
                              ? i18n.t(languageKeys.xoa)
                              : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
                          }
                        >
                          <Trash
                            className={cn(style["icon-trash"], !configPermissions[permissionActionKey.XOA] && style["icon-trash-disabled"])}
                            onClick={() => configPermissions[permissionActionKey.XOA] && onRequestDelete(item)}
                          />
                        </Tooltip>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>

              <div className={style["content_container"]}>
                <div className={style["inner"]}>
                  <Row align="middle" style={{flexWrap: 'nowrap'}}>
                    <Receipt width="60" height="60" style={{ marginRight: 10, flexShrink: 0 }} />

                    <Col>
                      <div className={style["receipt-code"]}>
                        {i18n.t(languageKeys.field_shd_ma_so)}: {item[`${fieldsSoHoaDon.ma_soHD}`]}
                      </div>

                      <Tooltip title={item[fieldsSoHoaDon.ten_soHD]}>
                        <h2 style={{padding: 0}}>{item[fieldsSoHoaDon.ten_soHD]}</h2>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>

                {/* <li className={style["item"]}>
                  {i18n.t(languageKeys.field_Ki_hieu)}: {item[`${fieldsSoHoaDon.ky_hieu_soHD}`]}
                </li> */}

                <li className={style["item"]}>
                  {i18n.t(languageKeys.field_Loai_so)}: {layLoaiSo(item.LOAI_SO)}
                </li>
                {renderThongTinSo(item)}
              </div>
            </div>
          ))}
        </div>
      </Spin>
      <ModalSoHoaDon ref={configRef} doAfterSubmit={handleReloadList} />
      <ConfirmModal
        ref={deleteRef}
        title={i18n.t(languageKeys.common_Canh_bao)}
        content={i18n.t(languageKeys.noti_xoa_so)}
        onCancel={() => {
          setDeleteList([]);
        }}
        onOk={handleDeleteRecord}
        danger
      />
    <Pagination
      className={style['styledPagination']}
      total={totalResult}
      currentPage={currentPage}
      onChangePage={page => getDataSource(searchString, page, filterDate[0], filterDate[1], pageSize)}
      onChangePageSize={pageSize => getDataSource(searchString, 1, filterDate[0], filterDate[1], pageSize)}
      pageSize={pageSize}
    />
    </Layout>
  );
};

const stylePrice = {
  color: "#2cb2a5",
  fontSize: 14,
  fontWeight: "bold",
  textAlign: "right",
  marginRight: 10,
};

export const dateValue = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST7DAYS: 'LAST7DAYS',
  LAST30DAYS: 'LAST30DAYS',
  OTHER: 'OTHER'
}

export const pickerPresets = [
  {
    value: dateValue.TODAY,
    label: i18n.t(languageKeys.common_Hom_nay)
  },
  {
    value: dateValue.YESTERDAY,
    label: i18n.t(languageKeys.common_Hom_qua)
  },
  {
    value: dateValue.LAST7DAYS,
    label: i18n.t(languageKeys.last_7_days)
  },
  {
    value: dateValue.LAST30DAYS,
    label: i18n.t(languageKeys.last_30_days)
  },
  {
    value: dateValue.OTHER,
    label: i18n.t(languageKeys.common_lua_chon_khac)
  }
];


export default SoHoaDon;
