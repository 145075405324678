import { Button, Layout, Space } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { featureKeys, keyLoaiNhanVien } from "../../../../constants/keys";
import { HLog, isJsonString } from "../../../../helpers";
import { useEventListener } from "../../../../hooks";
import i18n, { languageKeys } from "../../../../i18n";
import style from "./layouts.module.less";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";
import { Eye } from "assets/svg";

export const BottomBar = ({ selectedSample, onClickSave, onClickPreview, onClickEscape, loadingSave, isEditAllowed=false, isAddAllowed=false }) => {
  const userProfile = useSelector((state) => state.auth.user);

  // const isAllowTo = useMemo(() => {
  //   let editItem = false;
  //   let createItem = false;

  //   if (userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri) {
  //     editItem = true;
  //     createItem = true;
  //   } else if (!!userProfile.QUYEN) {
  //     let dsQuyen = [];

  //     if (isJsonString(userProfile.QUYEN)) {
  //       dsQuyen = JSON.parse(userProfile.QUYEN);
  //     } else {
  //       dsQuyen = userProfile.QUYEN;
  //     }

  //     editItem = dsQuyen.some((quyen) => quyen.includes(featureKeys.sua_mau_mo_ta_cdha));
  //     createItem = dsQuyen.some((quyen) => quyen.includes(featureKeys.tao_mau_mo_ta_cdha));
  //   }

  //   return { editItem, createItem };
  // }, [userProfile]);

  // const showSaveBtn = selectedSample ? isAllowTo.editItem && !!selectedSample : isAllowTo.createItem;
  HLog.render("Bottom Bar");

  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "s" && (!!selectedSample ? isEditAllowed : isAddAllowed)) {
      event.stopPropagation();
      event.preventDefault();
      onClickSave();
    }

    // if (event.key === "Escape" && !!selectedSample) {
    if (event.key === "Escape" ) {
      event.stopPropagation();
      event.preventDefault();
      onClickEscape();
    }
  };

  useEventListener("keydown", keydownEvent, window.document);

  // console.log("USER_Profile", isJsonString(userProfile.QUYEN) && JSON.parse(userProfile.QUYEN));
  return (
    <Layout.Footer className={style["bottom-bar"]}>
      <Space>
        {/*{!!selectedSample && (*/}
          <Button type="primary" ghost onClick={onClickEscape}>
            {!!selectedSample ? i18n.t(languageKeys.common_Thoat) :  i18n.t(languageKeys.huy_tao)} (ESC)
          </Button>
          <Button type="primary" ghost onClick={onClickPreview}>
            <span style={{display:"flex", alignItems:"center", gap: 10 }}><Eye/> {i18n.t(languageKeys.common_Xem_truoc)}</span>
          </Button>
        {/*)}*/}

        {/* {showSaveBtn && (
          <Button
            type="primary"
            onClick={onClickSave}
            loading={loadingSave}
            disabled={!!selectedSample ? !isAllowTo.editItem : !isAllowTo.createItem}
          >
            {i18n.t(languageKeys.luu)} (Ctrl + S)
          </Button>
        )} */}
        <CheckRoleTooltip hasTooltip={false} isAllowed={!!selectedSample ? isEditAllowed : isAddAllowed}>
          <Button
            type="primary"
            onClick={onClickSave}
            loading={loadingSave}
            disabled={!!selectedSample ? !isEditAllowed : !isAddAllowed}
          >
            {i18n.t(languageKeys.luu)} (Ctrl + S)
          </Button>
        </CheckRoleTooltip>
      </Space>
    </Layout.Footer>
  );
};
