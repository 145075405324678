import i18n, { languageKeys } from "../../../../../i18n";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import style from "./style.module.less";
import { Button, Checkbox, Col, Empty, Image, Modal, Row, Space, notification } from "antd";
import { useState } from "react";
import ModalEditImageList from "../ModalEditImageList";
import _ from "lodash";

const ModalImageList = (
  {
    arrImgPrev,
    setSeletedImage = () => {},
    setArrImgPrev = () => {},
    removeMultipleImageInDescription = () => {},
    seletedImage,
    updateEditedImagesInDescription = () => {},
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState([]);
  const editRef = useRef();

  useImperativeHandle(ref, () => ({
    open,
    handleOpen,
    handleClose,
  }));

  const handleClose = () => {
    setOpen(false);
    setSelectedImg([]);
  };

  const handleOpen = (data) => {
    setOpen(true);
    setSelectedImg(data);
  };

  const handleEditImage = () => {
    editRef?.current?.handleOpen(selectedImg);
  };

  const handleSubmitUseImage = async () => {
    const notVinImage = seletedImage?.filter(item => !item?.url);
    setSeletedImage(notVinImage.concat(selectedImg));
    const removedElement = _.filter(seletedImage, (itemA) => !_.find(selectedImg, (itemB) => itemB.key === itemA.key) && !!itemA?.url);
    removeMultipleImageInDescription(removedElement);
    handleClose();
  };

  const handleToggleSelect = (e, selectedItem) => {
    if (!e.target.checked) setSelectedImg((prev) => prev?.filter((item) => item.key !== selectedItem.key));
    else {
      const notVinImage = seletedImage?.filter(item => !item?.url);
      if (selectedImg.length >= 8 - notVinImage.length) {
        notification.error({
          message: i18n.t(languageKeys.so_luong_anh_toi_da),
          placement: 'bottomLeft'
        })
        return;
      }
      setSelectedImg((prev) => prev.concat(selectedItem))
    }
  };

  const renderFooter = () => {
    return (
      <Row justify={"end"} align="middle">
        <Button onClick={handleClose} danger ghost type="primary">
          {i18n.t(languageKeys.huy)}
        </Button>
        <Button onClick={handleEditImage} type="primary" disabled={!selectedImg.length}>
          {i18n.t(languageKeys.common_Chinh_sua)}
        </Button>
        <Button onClick={handleSubmitUseImage} type="primary">
          {i18n.t(languageKeys.su_dung_anh)}
        </Button>
      </Row>
    );
  };

  return (
    <>
      <Modal
        open={open}
        centered
        title={<span style={{ fontSize: "22px", fontWeight: 700 }}>{i18n.t(languageKeys.danh_sach_hinh_anh)}</span>}
        footer={renderFooter()}
        onCancel={handleClose}
        wrapClassName={style["container"]}
        width={"900px"}
      >
        <div style={{ height: "500px", overflowY: "auto" }}>
          {arrImgPrev && arrImgPrev.length ? (
            <Row className={style["image-list"]} gutter={[10, 10]}>
              {arrImgPrev.map((item) => (
                <Col key={item?.key} span={4} className={style["image-item"]}>
                  <Checkbox
                    className={style["image-checkbox"]}
                    onClick={(e) => handleToggleSelect(e, item)}
                    checked={selectedImg.find((selectedItem) => selectedItem.key === item.key)}
                  />
                  <Image width={"100%"} style={{ borderRadius: 6 }} wrapperStyle={{ zIndex: 2 }} src={item?.thumbnail} preview={false} />
                </Col>
              ))}
            </Row>
          ) : (
            <Empty className={style['empty']} image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "auto" }} description={<span>{i18n.t(languageKeys.khambenh_empty)}</span>} />
          )}
        </div>
      </Modal>
      <ModalEditImageList
        ref={editRef}
        arrImgPrev={arrImgPrev}
        selectedImg={selectedImg}
        seletedImage={seletedImage}
        setSeletedImage={setSeletedImage}
        handleSubmitUseImage={handleSubmitUseImage}
        setArrImgPrev={setArrImgPrev}
        closeListModal={handleClose}
        updateEditedImagesInDescription={updateEditedImagesInDescription}
      />
    </>
  );
};

export default forwardRef(ModalImageList);
