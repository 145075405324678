import { Row, Col, Input, Spin, InputNumber, Tooltip } from "antd";
import i18n, { languageKeys } from "../../../../../../i18n";
import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.less";
import { formatNumberToPrice, getRoomWithServiceId } from "../../../../../../helpers";
import SelectRows from "../../../../../QuanLyTiepDon/components/SelectRows";
import { Checked, UnCheck } from "../../../../../../assets/svg";
import { useSelector } from "react-redux";
import { ActionButton } from "../../../../../../components";
import { indexedDbKeys } from "constants/keys";
import SelectChangeTarget from "components_v2/SelectChangeTarget/SelectChangeTarget";
import { isRoomWorking } from "pages/QuanLyTiepDon/ModalTiepDon.new/helpers";
import classNames from "classnames";

function RowTable({
  dichvu,
  onPrint = async () => {},
  onDelete = () => {},
  onChangePhong = () => {},
  onChangeNoiThucHien = () => {},
  onChangeGhiChu = () => {},
  onChangeSoLuong = () => {},
  disabled = false,
  userIsAdminOrDoctor = true,
  onChangeCheck = () => {},
  initData = {},
  cskcbBrand,
  disabledPrint = false,
  disabledDelete = false,
  disabledDeleteByPermission = false,
  currentSubclinicalList = [],
  setCurrentSubclinicalList = () => {},
  setSelectedExamServices = () => {},
  rowClassName = '',
}) {

  const PHONG = useSelector((state) => state.data[indexedDbKeys.phong]);

  const [dsPhong, setDsPhong] = useState([]);
  const [selectedPhong, setSelectedPhong] = useState();
  const [selectedNoiThucHien, setSelectedNoiThucHien] = useState();

  const syncTargetRef = useRef()

  useEffect(() => {
    layDsPhongLocal();
    dichvu && dichvu?.NOI_THUC_HIEN_XN && setSelectedNoiThucHien(dichvu.NOI_THUC_HIEN_XN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getGiaDV() {
    if (dichvu.GIA_THU_PHI && Number(dichvu.GIA_THU_PHI) !== 0) {
      return Number(dichvu.GIA_THU_PHI);
      // } else if (dichvu.GIA_DICH_VU && Number(dichvu.GIA_DICH_VU) !== 0) {
      //   return Number(dichvu.GIA_DICH_VU);
    } else {
      return 0;
    }
  }

  function onSelectPhong(item) {
    // console.log("xxxxxxxxddddd", item, dichvu);
    if (selectedPhong && selectedPhong.ID === item.ID) {
      return;
    }
    setSelectedPhong(item);
    onChangePhong(item, dichvu.DV_ID);
  }

  function onSelectNoiThucHien(item) {
    setSelectedNoiThucHien(item);
    onChangeNoiThucHien(item, dichvu);
  }

  const layDsPhongLocal = () => {
    let result = [];
    if (dichvu?.DV_KEM_THEO_ID) {
      if (dichvu.PHONG_THUC_HIEN_ID) result = getRoomWithServiceId(PHONG, [dichvu.PHONG_THUC_HIEN_ID])
    } else {
      if (dichvu.LIST_PHONG_ID) result = getRoomWithServiceId(PHONG, dichvu.LIST_PHONG_ID)
    }
    setDsPhong(result)
    if (result.length > 0) onSelectPhong(result[0])
  }

  // const layDsPhong = async () => {
  //   setLoadingPhong(true);

  //   try {
  //     let req = {
  //       ...dichvu,
  //       PHAN_LOAI: dichvu.DV_KEM_THEO_ID ? "" : "KHAM_CLS",
  //       partner_code: cskcbBrand || userProfile.partner_code,
  //       PHONG_ID: initData?.PHONG_ID,
  //       DV_ID: dichvu.DV_KEM_THEO_ID || dichvu.DV_ID || dichvu.DICH_VU_ID
  //     };
  //     req = (await validFragment([req], standardRequestGetListRoom))[0];
  //     const res = await common_post(apis.tiep_don_ds_phong_theo_dv, req);
  //     if (res.status === "OK") {
  //       setDsPhong(dichvu.DV_KEM_THEO_ID
  //         ? res.result.filter(i => i.ID === dichvu.PHONG_THUC_HIEN_ID)
  //         : res.result);
  //         // nếu là combo dịch vụ thì chọn phòng đã có dữ liệu luôn
  //         if (!!dichvu.STT_VAO_KHAM || !!dichvu?.PHONG_THUC_HIEN || dichvu.IS_COMBO_DV) {
  //           setSelectedPhong(dichvu);
  //           setLoadingPhong(false);
  //           return
  //         }
  //       // console.log("xxxxxxyyyyy", res.result);

  //       if (res.result.length > 0) {
  //         onSelectPhong(dichvu.DV_KEM_THEO_ID
  //           ? res.result.filter(i => i.ID === dichvu.PHONG_THUC_HIEN_ID)[0]
  //           : res.result[0]
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     HLog(error);
  //   }
  //   setLoadingPhong(false);
  // };

  const handleChangeTarget = (value, record, disable, changeAll = false) => {
    //
    const GIA_DT = (obj) => {
      const data = obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)
      return data
        ? data.GIA_DICH_VU
        : obj.LIST_GIA_THEO_DICH_VU?.find(i => !!i.DOI_TUONG_MAC_DINH)?.GIA_DICH_VU
    }
    if (changeAll) {
      setCurrentSubclinicalList((currenArr) => currenArr.map(dv => ({
        ...dv,
        GIA_THU_PHI: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        GIA_GOC_DICH_VU: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        DM_DOI_TUONG_ID: value
      })))
      setSelectedExamServices((currenArr) => currenArr.map(dv => ({
        ...dv,
        GIA_THU_PHI: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        GIA_GOC_DICH_VU: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        DM_DOI_TUONG_ID: value
      })))
      syncTargetRef.current?.setVisible(false)
    } else {
      if (disable) {
        setCurrentSubclinicalList((currentArray) =>
          currentArray.map((item) => {
            if (item.DV_ID === record.DV_ID) {
              return {
                ...item,
                GIA_THU_PHI: GIA_DT(item) ?? item.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT(item) ?? item.GIA_DICH_VU,
                DM_DOI_TUONG_ID: value,
              };
            } else {
              return item;
            }
          })
        );
        setSelectedExamServices((currentArray) =>
          currentArray.map((item) => {
            if (item.DV_ID === record.DV_ID) {
              return {
                ...item,
                GIA_THU_PHI: GIA_DT(item) ?? item.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT(item) ?? item.GIA_DICH_VU,
                DM_DOI_TUONG_ID: value,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        syncTargetRef.current?.open({ value, record })
      }
    }
  };


  return (
    <div className={classNames(style["row-table"], rowClassName)}>
      <Row align="middle" justify="space-between" gutter={8} wrap={false}>
        {/*<Col>*/}
        {/*  <Checkbox value={dichvu} checked={dichvu.checked} onChange={onChangeCheck} disabled={disabled} />*/}
        {/*</Col>*/}

        <Col flex="200px" style={{ marginLeft: dichvu.DV_KEM_THEO_ID ? 15 : 0 }}>
          <span>{dichvu.DV_KEM_THEO_ID ? dichvu.TEN_DICH_VU_KEM_THEO : dichvu.TEN_DICHVU}</span>
        </Col>

        <Col flex="200px">
          {dichvu.DV_KEM_THEO?.length > 0
            ? <></>
            : <SelectRows
              disabled={disabled}
              dataSource={dsPhong}
              width={700}
              placeholder={i18n.t(languageKeys.chua_co_phong_thuc_hien)}
              columns={[
                {
                  title: i18n.t(languageKeys.field_Ten_phong),
                  key: "TEN_KHOA_PHONG",
                  dataIndex: "TEN_KHOA_PHONG",
                },
                {
                  title: i18n.t(languageKeys.field_So_phong),
                  key: "MA_PHONG",
                  dataIndex: "MA_PHONG",
                },
                {
                  title: i18n.t(languageKeys.field_Benh_nhan_cho_kham),
                  key: "SO_CHO_KHAM",
                  dataIndex: "SO_CHO_KHAM",
                  width: 80,
                },
                {
                  title: i18n.t(languageKeys.field_thoi_gian),
                  key: "GIO_BAT_DAU",
                  dataIndex: "GIO_BAT_DAU",
                  render: (_, record) => (record.GIO_BAT_DAU || "") + " - " + (record.GIO_KET_THUC || ""),
                },
                {
                  title: i18n.t(languageKeys.field_Trang_thai),
                  key: "TRANG_THAI",
                  dataIndex: "TRANG_THAI",
                  render: (_, record) => (
                    <div className={isRoomWorking(record) ? "green-txt" : "red-txt"}>
                      {isRoomWorking(record) ? i18n.t(languageKeys.dang_hoat_dong) : i18n.t(languageKeys.da_nghi)}
                    </div>
                  ),
                },
              ]}
              keyValueInput="TEN_KHOA_PHONG"
              initTxt={selectedPhong ? selectedPhong.TEN_KHOA_PHONG || selectedPhong.PHONG_THUC_HIEN : ""}
              onSelectRow={(value) => onSelectPhong(value)}
              error={selectedPhong && !isRoomWorking(selectedPhong)}
            />
          }
        </Col>

        <Col flex="110px">
          {dichvu.DV_KEM_THEO_ID
            ? "-"
            : <span>{`${formatNumberToPrice(getGiaDV())} ${i18n.t(languageKeys.currency_viet_nam)}`}</span>
          }
        </Col>

        <Col flex="65px" style={{ display: "flex", justifyContent: "center" }}>
          {dichvu.DV_KEM_THEO_ID
            ? "-"
            : <InputNumber
              disabled={disabled}
              value={dichvu.SO_LUONG}
              defaultValue={1}
              min={1}
              onChange={(value) => onChangeSoLuong(value, dichvu.DV_ID)}
            />
          }
        </Col>

        <Col flex="110px">
          {dichvu.DV_KEM_THEO_ID
            ? "-"
            : <span>{dichvu.SO_LUONG !== undefined ? formatNumberToPrice(getGiaDV() * dichvu.SO_LUONG) : formatNumberToPrice(getGiaDV())}</span>
          }
        </Col>

        <Col flex="135px">
          {dichvu.DV_KEM_THEO_ID || dichvu?.IS_COMBO_DV
            ? <></>
            : <SelectChangeTarget
              id={dichvu.DM_DOI_TUONG_ID}
              record={dichvu}
              syncTargetRef={syncTargetRef}
              handleChangeTarget={handleChangeTarget}
              dataSource={currentSubclinicalList}
              readOnly={disabled}
            />
          }
        </Col>

        <Col
          flex="60px"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={style["test"]}>
            {!!dichvu.THANH_TOAN ? (
              <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}>
                <Checked />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t(languageKeys.status_Chua_thanh_toan)}>
                <UnCheck />
              </Tooltip>
            )}
          </div>
        </Col>

        <Col flex="170px">
          {dichvu.DV_KEM_THEO?.length > 0 || !dichvu?.LIST_NOI_THUC_HIEN_XN || dichvu?.LIST_NOI_THUC_HIEN_XN?.length === 0
            ? <>{dichvu?.TEN_NOI_TH || ""}</>
            : <SelectRows
              disabled={disabled}
              dataSource={dichvu.LIST_NOI_THUC_HIEN_XN}
              width={200}
              placeholder={i18n.t(languageKeys.common_Chon)}
              columns={[
                {
                  title: i18n.t(languageKeys.noi_thuc_hien),
                  dataIndex: "TEN_NOI_TH",
                },
              ]}
              keyValueInput="TEN_NOI_TH"
              initTxt={selectedNoiThucHien ? selectedNoiThucHien.TEN_NOI_TH : ""}
              onSelectRow={(value) => onSelectNoiThucHien(value)}
            />
          }
        </Col>

        <Col flex="200px">
          {dichvu.DV_KEM_THEO_ID
            ? <></>
            : <Input
              placeholder={i18n.t(languageKeys.common_Nhap)}
              defaultValue=""
              value={dichvu.GHI_CHU}
              disabled={disabled}
              onChange={(e) => onChangeGhiChu(e.target.value, dichvu.DV_ID)}
            />
          }
        </Col>

        <div className={classNames(style["fixed"], rowClassName)}>
          {dichvu.DV_KEM_THEO_ID
            ? <></>
            : <ActionButton
              showEdit={false}
              showPrint={true}
              disabledPrint={!dichvu.LUOT_DIEU_TRI_ID || disabledPrint}
              onPrint={() => onPrint({ ...dichvu, list_DV_ID: [dichvu.DV_ID] })}
              onDelete={() => onDelete(dichvu)}
              deleteTip={i18n.t(languageKeys.tip_xoa_ban_ghi)}
              printTip={i18n.t(languageKeys.tip_in_ban_ghi)}
              deleteDisabled={(!!userIsAdminOrDoctor && !!dichvu.THANH_TOAN) || disabled || disabledDelete}
              tooltipInTheoQuyen={!disabledPrint}
              tooltipXoaTheoQuyen={!disabledDeleteByPermission}
            />
          }
        </div>
      </Row>
    </div>
  );
}

export function RowTableExam({
  dichvu,
  onPrint = async () => {},
  onDelete = () => {},
  onChangePhong = () => {},
  onChangeGhiChu = () => {},
  onChangeSoLuong = () => {},
  disabled = false,
  userIsAdminOrDoctor = true,
  onChangeCheck = () => {},
  initData = {},
  cskcbBrand,
  disabledPrint = false,
  disabledDelete = false,
  dataSource = [],
  setCurrentSubclinicalList = () => {},
  setSelectedExamServices = () => {},
  rowClassName = '',
}) {

  const syncTargetRef = useRef()

  const userProfile = useSelector((state) => state.auth.user);
  const PHONG = useSelector((state) => state.data[indexedDbKeys.phong]);

  const [dsPhong, setDsPhong] = useState([]);
  const [selectedPhong, setSelectedPhong] = useState();
  const [loadingPhong, setLoadingPhong] = useState(false);

  useEffect(() => {
    layDsPhongLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layDsPhongLocal = () => {
    let result = [];
    if (dichvu?.DV_KEM_THEO_ID) {
      if (dichvu.PHONG_THUC_HIEN_ID) result = getRoomWithServiceId(PHONG, [dichvu.PHONG_THUC_HIEN_ID])
    } else {
      if (dichvu.LIST_PHONG_ID) result = getRoomWithServiceId(PHONG, dichvu.LIST_PHONG_ID)
    }
    setDsPhong(result)
    if (result.length > 0) onSelectPhong(result[0])
  }

  function getGiaDV() {
    if (dichvu.GIA_THU_PHI && Number(dichvu.GIA_THU_PHI) !== 0) {
      return Number(dichvu.GIA_THU_PHI);
      // } else if (dichvu.GIA_DICH_VU && Number(dichvu.GIA_DICH_VU) !== 0) {
      //   return Number(dichvu.GIA_DICH_VU);
    } else {
      return 0;
    }
  }

  function onSelectPhong(item) {
    // console.log("xxxxxxxxddddd", item, dichvu);
    if (selectedPhong && selectedPhong.ID === item.ID) {
      return;
    }
    setSelectedPhong(item);
    onChangePhong(item, dichvu.DV_ID || dichvu.DICH_VU_ID);
  }

  // const layDsPhong = async () => {
  //   setLoadingPhong(true);
  //   try {
  //     let req = {
  //       ...dichvu,
  //       PHAN_LOAI: dichvu.DV_KEM_THEO_ID ? "" : "KHAM_BENH",
  //       partner_code: cskcbBrand || userProfile.partner_code,
  //       PHONG_ID: initData?.PHONG_ID,
  //       DV_ID: dichvu.DV_KEM_THEO_ID || dichvu.DV_ID
  //     };
  //     req = (await validFragment([req], standardRequestGetListRoom))[0];
  //     const res = await common_post(apis.tiep_don_ds_phong_theo_dv, req);
  //     if (res.status === "OK") {
  //       setDsPhong(dichvu.DV_KEM_THEO_ID
  //         ? res.result.filter(i => i.ID === dichvu.PHONG_THUC_HIEN_ID)
  //         : res.result);

  //       // parentKey: chỉ dùng tại màn dịch vụ chỉ định

  //       // setSelectedPhong(dichvu);
  //       // setLoadingPhong(false);

  //       if (res.result.length > 0) {
  //         onSelectPhong(dichvu.DV_KEM_THEO_ID
  //           ? res.result.filter(i => i.ID === dichvu.PHONG_THUC_HIEN_ID)[0]
  //           : res.result[0]
  //         );
  //       }
  //       // console.log("xxxxxxyyyyy", res.result);

  //     }
  //   } catch (error) {
  //     HLog(error);
  //   }
  //   setLoadingPhong(false);
  // };

  const handleChangeTarget = (value, record, disable, changeAll = false) => {
    //
    const GIA_DT = (obj) => {
      const data = obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)
      return data
        ? data.GIA_DICH_VU
        : obj.LIST_GIA_THEO_DICH_VU?.find(i => !!i.DOI_TUONG_MAC_DINH)?.GIA_DICH_VU
    }
    if (changeAll) {
      setCurrentSubclinicalList((currenArr) => currenArr.map(dv => ({
        ...dv,
        GIA_THU_PHI: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        GIA_GOC_DICH_VU: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        DM_DOI_TUONG_ID: value,
      })))
      setSelectedExamServices((currenArr) => currenArr.map(dv => ({
        ...dv,
        GIA_THU_PHI: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        GIA_GOC_DICH_VU: GIA_DT(dv) ?? dv.GIA_DICH_VU,
        DM_DOI_TUONG_ID: value,
      })))
      syncTargetRef.current?.setVisible(false)
    } else {
      if (disable) {
        setCurrentSubclinicalList((currentArray) =>
          currentArray.map((item) => {
            if (item.DV_ID === record.DV_ID) {
              console.log(GIA_DT(item))
              return {
                ...item,
                GIA_THU_PHI: GIA_DT(item) ?? item.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT(item) ?? item.GIA_DICH_VU,
                DM_DOI_TUONG_ID: value,
              };
            } else {
              return item;
            }
          })
        );
        setSelectedExamServices((currentArray) =>
          currentArray.map((item) => {
            if (item.DV_ID === record.DV_ID) {
              console.log(item)
              return {
                ...item,
                GIA_THU_PHI: GIA_DT(item) ?? item.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT(item) ?? item.GIA_DICH_VU,
                DM_DOI_TUONG_ID: value,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        syncTargetRef.current?.open({ value, record })
      }
    }
  };

  return (
    <Spin spinning={loadingPhong}>
      <div className={classNames(style["row-table"], rowClassName)}>
        <Row align="middle" justify="space-between" gutter={10} wrap={false}>
          {/*<Col>*/}
          {/*  <Checkbox value={dichvu} checked={dichvu.checked} onChange={onChangeCheck} disabled={disabled} />*/}
          {/*</Col>*/}

          <Col flex="200px" style={{ marginLeft: dichvu.DV_KEM_THEO_ID ? 15 : 0 }}>
            <span>{dichvu.DV_KEM_THEO_ID ? dichvu.TEN_DICH_VU_KEM_THEO : dichvu.TEN_DICHVU}</span>
          </Col>

          <Col flex="200px">
            {dichvu.DV_KEM_THEO?.length > 0
              ? <></>
              : <SelectRows
                disabled={disabled}
                dataSource={dsPhong}
                width={700}
                placeholder={i18n.t(languageKeys.chua_co_phong_thuc_hien)}
                columns={[
                  {
                    title: i18n.t(languageKeys.field_Ten_phong),
                    key: "TEN_KHOA_PHONG",
                    dataIndex: "TEN_KHOA_PHONG",
                  },
                  {
                    title: i18n.t(languageKeys.field_So_phong),
                    key: "MA_PHONG",
                    dataIndex: "MA_PHONG",
                  },
                  {
                    title: i18n.t(languageKeys.field_Benh_nhan_cho_kham),
                    key: "SO_CHO_KHAM",
                    dataIndex: "SO_CHO_KHAM",
                    width: 80,
                  },
                  {
                    title: i18n.t(languageKeys.field_thoi_gian),
                    key: "GIO_BAT_DAU",
                    dataIndex: "GIO_BAT_DAU",
                    render: (_, record) => (record.GIO_BAT_DAU || "") + " - " + (record.GIO_KET_THUC || ""),
                  },
                  {
                    title: i18n.t(languageKeys.field_Trang_thai),
                    key: "TRANG_THAI",
                    dataIndex: "TRANG_THAI",
                    render: (_, record) => (
                      <div className={isRoomWorking(record) ? "green-txt" : "red-txt"}>
                        {isRoomWorking(record) ? i18n.t(languageKeys.dang_hoat_dong) : i18n.t(languageKeys.da_nghi)}
                      </div>
                    ),
                  },
                ]}
                keyValueInput="TEN_KHOA_PHONG"
                initTxt={selectedPhong ? selectedPhong.TEN_KHOA_PHONG || selectedPhong.PHONG_THUC_HIEN : ""}
                onSelectRow={(value) => onSelectPhong(value)}
                error={selectedPhong && !isRoomWorking(selectedPhong)}
              />
            }
          </Col>

          <Col flex="110px">
            {dichvu.DV_KEM_THEO_ID
              ? "-"
              : <span>{`${formatNumberToPrice(getGiaDV())} ${i18n.t(languageKeys.currency_viet_nam)}`}</span>
            }
          </Col>

          <Col flex="65px" style={{ display: "flex", justifyContent: "center" }}>
            {dichvu.DV_KEM_THEO_ID
              ? "-"
              : <InputNumber
                disabled={disabled}
                value={dichvu.SO_LUONG}
                defaultValue={1}
                min={1}
                onChange={(value) => onChangeSoLuong(value, dichvu.DV_ID)}
              />
            }
          </Col>

          <Col flex="110px">
            {dichvu.DV_KEM_THEO_ID
              ? "-"
              : <span>{dichvu.SO_LUONG !== undefined ? formatNumberToPrice(getGiaDV() * dichvu.SO_LUONG) : formatNumberToPrice(getGiaDV())}</span>
            }
          </Col>

          <Col flex="135px">
            {dichvu.DV_KEM_THEO_ID || dichvu?.IS_COMBO_DV
              ? <></>
              : <SelectChangeTarget
                id={dichvu.DM_DOI_TUONG_ID}
                record={dichvu}
                syncTargetRef={syncTargetRef}
                handleChangeTarget={handleChangeTarget}
                dataSource={dataSource}
                readOnly={disabled}
              />
            }
          </Col>

          <Col
            flex="60px"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={style["test"]}>
              {!!dichvu.THANH_TOAN ? (
                <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}>
                  <Checked />
                </Tooltip>
              ) : (
                <Tooltip title={i18n.t(languageKeys.status_Chua_thanh_toan)}>
                  <UnCheck />
                </Tooltip>
              )}
            </div>
          </Col>

          <Col flex="170px"></Col>

          <Col style={{ width: "200px" }}>
            {dichvu.DV_KEM_THEO_ID
              ? <></>
              : <Input
                placeholder={i18n.t(languageKeys.common_Nhap)}
                defaultValue=""
                value={dichvu.GHI_CHU}
                disabled={disabled}
                onChange={(e) => onChangeGhiChu(e.target.value, dichvu.DV_ID)}
              />
            }
          </Col>

          <div className={classNames(style["fixed"], rowClassName)}>
            {dichvu.DV_KEM_THEO_ID
              ? <></>
              : <ActionButton
                showEdit={false}
                showPrint={true}
                disabledPrint={!dichvu.LUOT_DIEU_TRI_ID || disabledPrint}
                onPrint={() => onPrint({ ...dichvu, list_DV_ID: [dichvu.DV_ID] })}
                onDelete={() => onDelete(dichvu)}
                deleteTip={i18n.t(languageKeys.tip_xoa_ban_ghi)}
                printTip={i18n.t(languageKeys.tip_in_ban_ghi)}
                deleteDisabled={(!!userIsAdminOrDoctor && !!dichvu.THANH_TOAN) || disabled || disabledDelete}
                tooltipInTheoQuyen={!disabledPrint}
                tooltipXoaTheoQuyen={!disabledDelete}
              />
            }
          </div>
        </Row>
      </div>
    </Spin>
  );
}


export default React.memo(RowTable);


const standardRequestGetListRoom = {
  DV_ID: "",
  PHAN_LOAI: "",
  MA_NHOM_BHYT: "",
  NHOM_BHYT_ID: "",
  PHONG_ID: "",
  partner_code: "",
  BENH_VIEN_ID: "",
};
