import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Spin } from "antd"
import i18n, { languageKeys } from '../../../i18n'
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styles from './exemptions.module.less'
import { Search, SmallPlus, Trash } from "assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { modalLoadingState } from "ducks/slices/loadingSlice";
import { addCommissionListLocal, getServicePackages, updateIsModalExemption } from "ducks/slices/GoiDichVu/DSGoiDichVu/DSGoiDichVuSlice";
import { customAlphabet } from "nanoid";
import { common_post } from "helpers";
import { apis } from "../../../constants";
import { DM_NHOM_NGUON_KHACH_ID } from "./Exemptions";
import { MIEN_GIAM_GOI } from "./FormGoiDichVu";
import { isEmpty } from "lodash";
import { useEventListener } from "hooks";


export const MIEN_GIAM = 'MIEN_GIAM_THEM'

const ModalExemptions = forwardRef(({formExemptionModal, field, isCommissionLocal, commissionList, enableAdd, setIsAddCommission, detail, total}, ref) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isApplyAll, setIsApplyAll] = useState(false);
    const [searchString, setSearchString] = useState("");
    const modalLoading = useSelector(modalLoadingState)
    const {customerSourceList} = useSelector(getServicePackages)

    useEffect(() => {
        dispatch(updateIsModalExemption(false))
        setIsApplyAll(false);
        if (isCommissionLocal) {
          form.setFieldValue(
            ["MIEN_GIAM_THEM"],
            commissionList[`mienGiamGoi${field.key}`]?.map((item) => ({
              exemption: item.exemption || 0,
              customer: item.customer,
              checked: item.checked,
              id: item.id,
            })),
          );
        } else {
          form.setFieldValue('MIEN_GIAM_THEM', customerSourceList?.map(item => ({
            exemption: 0,
            customer: item.TEN_NGUON_KHACH,
            checked: false,
            id: item.ID,
          })))
          if(!isEmpty(detail)) {
            const { MIEN_GIAM } = formExemptionModal.getFieldsValue()
            if(MIEN_GIAM[field.name]?.MIEN_GIAM_DATA?.length > 0) {
              console.log(customerSourceList, MIEN_GIAM[field.name]?.MIEN_GIAM_DATA, 'customerSourceList');
              const valueExemption = customerSourceList?.map(item => {
                const arrExemption = MIEN_GIAM[field.name]?.MIEN_GIAM_DATA
                const itemHasExemption = arrExemption?.find(i => i.id === item.ID)
                if(!!itemHasExemption) {
                  return {
                    customer: itemHasExemption.customer,
                    checked: itemHasExemption.exemption > 0,
                    exemption: itemHasExemption.exemption,
                    id: itemHasExemption.id,
                  }
                }
                return {
                  customer: item.TEN_NGUON_KHACH,
                  checked: false,
                  exemption: 0,
                  id: item.ID,
                }
              })
              form.setFieldValue('MIEN_GIAM_THEM', valueExemption)
            } else {
              form.setFieldValue('MIEN_GIAM_THEM', customerSourceList?.map(item => ({
                exemption: 0,
                customer: item.TEN_NGUON_KHACH,
                checked: false,
                id: item.ID,
              })))
            }
          } 
        }
        form.setFieldValue(["MIEN_GIAM_THEM", "commissionApplyAll"], 0);

    }, [isCommissionLocal, commissionList, field.key, form, detail, customerSourceList]);

    useImperativeHandle(ref, () => ({
        showModal,
    }))
    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        dispatch(updateIsModalExemption(true))
        const { MIEN_GIAM_THEM } = form.getFieldsValue(["MIEN_GIAM_THEM"]);
        const commissionListFormat = MIEN_GIAM_THEM?.map((item) => ({
            customer: item.customer,
            checked: item.checked,
            exemption: item.exemption,
            id: item.id,
        }));

        const commissionAddList = MIEN_GIAM_THEM?.filter((item) => !item.id);
        const commissionAddIndex = MIEN_GIAM_THEM?.findIndex((item) => !item.id);
        if (commissionAddIndex !== -1) {
            const newCommissionList = await Promise.all(
              commissionAddList?.map(async (item) => {
                const nanoid = customAlphabet("1234567890", 4);
                const res = await common_post(apis.dm_them_nguon_khach, {
                  TEN_NGUON_KHACH: item.customer,
                  MA_NGUON_KHACH: nanoid(),
                  DM_NHOM_NGUON_KHACH_ID: formExemptionModal.getFieldValue([MIEN_GIAM_GOI, field.name, DM_NHOM_NGUON_KHACH_ID]),
                  NHANSU_ID: null,
                });
                return res;
              }),
            );
            newCommissionList.forEach((item, index) => {
              commissionListFormat[index + commissionAddIndex] = {
                ...commissionListFormat[index + commissionAddIndex],
                id: item.result[0].ID,
              };
            });
        }
        formExemptionModal.setFieldValue(["MIEN_GIAM", field.name, 'MIEN_GIAM_DATA'], commissionListFormat);
        dispatch(
            addCommissionListLocal({
            name: `mienGiamGoi${field.key}`,
            value: commissionListFormat,
            }),
        );
        setIsModalOpen(false);
        setIsAddCommission(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClearApplyAll = (changedValues) => {
      if (Array.isArray(changedValues.MIEN_GIAM_THEM)) {
        const isChangeCommission = changedValues.MIEN_GIAM_THEM?.some((item) => "exemption" in item);
        if (isChangeCommission) {
          setIsApplyAll(false);
          form.setFieldValue(["MIEN_GIAM_THEM", "commissionApplyAll"], 0);
        }
      }
    }
    const handleApplyAll = (e) => {
        const isChecked = e.target.checked;
        setIsApplyAll(isChecked);
        const allApplyDiscount = form.getFieldValue(["MIEN_GIAM_THEM", "commissionApplyAll"]);
        const commissionItem = form.getFieldsValue(["MIEN_GIAM_THEM"]);
        const result = commissionItem?.MIEN_GIAM_THEM?.map((item) => ({
          ...item,
          checked:
            item?.customer && item?.customer.toLowerCase().includes(searchString.toLowerCase().trim())
              ? isChecked
              : item?.checked,
          exemption:
            item?.customer && item?.customer.toLowerCase().includes(searchString.toLowerCase().trim())
              ? isChecked
                ? allApplyDiscount || 0
                : 0
              : item?.commission,
        }));
        form.setFieldValue("MIEN_GIAM_THEM", result);
        form.setFieldValue(["MIEN_GIAM_THEM", "commissionApplyAll"], isChecked ? allApplyDiscount : 0);
    }
    const handleAddFormItem = (cb) => {
        cb()
    }
    const handleChange = ({value, name, isDiscountAll}) => {
      if(isDiscountAll) {
        if(isApplyAll) {
          const commissionItem = form.getFieldsValue(["MIEN_GIAM_THEM"]);
          const result = commissionItem?.MIEN_GIAM_THEM?.map((item) => ({
            ...item,
            exemption: value
          }));
          form.setFieldValue("MIEN_GIAM_THEM", result);
          form.setFieldValue(["MIEN_GIAM_THEM", "commissionApplyAll"], value);
        }
      } else {
        if (value) {
          form.setFieldValue(["MIEN_GIAM_THEM", name, "checked"], true);
        } else {
          form.setFieldValue(["MIEN_GIAM_THEM", name, "checked"], false);
        }
      }
    };

    const renderFormListItems = (key, name, restField, remove) => (
        <div key={key} className={styles["form-item"]}>
        <Form.Item name={[name, "id"]} style={{ display: "none" }}>
          <Input />
        </Form.Item>
        <Form.Item name={[name, "checked"]} valuePropName="checked">
          <Checkbox disabled />
        </Form.Item>
        <Form.Item name={[name, "customer"]}>
          <Input
            className={
              name < (isCommissionLocal ? commissionList[`mienGiamGoi${field.key}`]?.length : commissionList?.length) &&
              styles["input-all"]
            }
            // className={styles['input-all']}
          />
        </Form.Item>
        <Form.Item {...restField} name={[name, "exemption"]}>
          <DiscountInput name={name} onChange={(value) => handleChange({value, name, isDiscountAll: false})} total={total} />
        </Form.Item>
        <Trash
          onClick={() => remove(name)}
          className={
            name < (isCommissionLocal ? commissionList[`mienGiamGoi${field.key}`]?.length : commissionList?.length)
              ? styles["hidden-icon-delete"]
              : styles["show-icon-delete"]
          }
        />
      </div>
    )

    const renderFooterModal = () => {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Button style={{width: '100%', color: '#6576FF', borderColor: '#6576FF'}} type="ghost" onClick={handleCancel}>{i18n.t(languageKeys.common_huy)} (ESC)</Button>
                </Col>
                <Col span={12}>
                    <Button style={{width: '100%'}} type="primary" onClick={handleOk}>{i18n.t(languageKeys.common_Luu)} (Ctrl+S)</Button>
                </Col>
            </Row>
        )
    }

    const handleSearch = (e) => setSearchString(e.target.value);

    const keydownEvent = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.stopPropagation();
        event.preventDefault();
        handleOk()
      }
    };
  
    useEventListener("keydown", keydownEvent, window.document, isModalOpen);

    return (
        <Modal width={406} wrapClassName={styles['modal-wrap']} title={i18n.t(languageKeys.them_co_che_mien_giam)} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={renderFooterModal()}>
            <Input
                className={styles["style-input"]}
                placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                addonBefore={<Search />}
                value={searchString}
                onChange={handleSearch}
            />
            <div className={styles["header"]}>
                <span>{i18n.t(languageKeys.ho_va_ten)}</span>
                <span>{i18n.t(languageKeys.field_Mien_giam)}</span>
            </div>
            <Spin spinning={modalLoading}>
                <Form ref={ref} className={styles["form"]} form={form} onValuesChange={handleClearApplyAll}>
                    <Form.List name={[MIEN_GIAM]}>
                    {(fields, { add, remove }) => {
                        const commisionFomrList = form.getFieldValue(MIEN_GIAM);
                        return (
                        <>
                            <div className={styles["form-item"]}>
                                <Checkbox checked={isApplyAll} onChange={handleApplyAll} />
                                <Input value={i18n.t(languageKeys.ap_dung_tat_ca)} className={styles["input-all"]} />
                                <Form.Item name={"commissionApplyAll"}>
                                    <DiscountInput onChange={(value) => handleChange({value, isDiscountAll: true})} />
                                </Form.Item>
                            </div>
                            {fields.map(({ key, name, ...restField }) => {
                                return searchString.trim() ? (
                                commisionFomrList[key]?.customer
                                    ?.toLowerCase()
                                    .includes(searchString?.toLowerCase().trim()) ? (
                                    renderFormListItems(key, name, restField, remove)
                                ) : (
                                    <></>
                                )
                                ) : (
                                renderFormListItems(key, name, restField, remove)
                                );
                            })}
                            {enableAdd && (
                            <Form.Item>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 4,
                                        marginTop: 8,
                                        width: "fit-content",
                                        color: '#6576FF'
                                    }}
                                    onClick={() => handleAddFormItem(add)}
                                >
                                    <SmallPlus />
                                    <span>{i18n.t(languageKeys.them_chi_tiet_doi_tuong)}</span>
                                </div>
                            </Form.Item>
                            )}
                        </>
                        );
                    }}
                    </Form.List>
                </Form>
            </Spin>
        </Modal>
    );
})

const DiscountInput = (props) => {
    const handleKeyDown = (e) => {
      if (e.key === "-") {
        e.preventDefault();
        return false;
      }
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();
        return false;
      }
    };
  
    return (
      <InputNumber
        type="tel"
        className={styles["discount-input"]}
        addonAfter={(props.value || 0) <= 100 ? "%" : "đ"}
        min={0}
        // max={1000}
        onKeyDown={handleKeyDown}
        formatter={(value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        parser={(value) => value.replace(/\./g, '')}
        defaultValue={0}
        {...props}
      />
    );
  };

export default ModalExemptions