import { Collapse, Spin, Tabs } from "antd";
import { FormDrawer } from "components";
import { forwardRef, useImperativeHandle, useState } from "react";
import style from "../../modalTiepDon.module.less";
import { useDispatch, useSelector } from "react-redux";
import { tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import TableDeXuat from "pages/QuanLyGoiDichVu/GoiLieuTrinh/ChiDinhDvGoi/TableDeXuat";
import { CaretRightOutlined } from "@ant-design/icons";
import ChiDinhVatTu from "pages/QuanLyGoiDichVu/GoiLieuTrinh/ChiDinhVatTu";
import { getLieuTrinhData, getPhacDoDieuTri, resetGoiVatTuSlice } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import i18n, { languageKeys } from "../../../../../i18n";

const XemTienTrinhGoi = forwardRef(({ selectedPatient }, ref) => {

  const dispatch = useDispatch()
  const { dsGoiDvBenhNhan } = useSelector(tiepDonState);
  const { serviceUsageList, supplyUsageList, loading } = useSelector(getLieuTrinhData);

  const [open, setOpen] = useState(false);
  const [currentBundle, setCurrentBundle] = useState();

  const bundleList = dsGoiDvBenhNhan.filter(i => i.NHOM_GOI === "LIEU_TRINH" && !!i.IS_ACTIVE)

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true)
      handleChangeCurrentBundle(bundleList?.[0]?.GOI_ID)
    }
  }))

  const handleClose = () => {
    setOpen(false)
    dispatch(resetGoiVatTuSlice())
  }

  const handleChangeCurrentBundle = (id) => {
    setCurrentBundle(id)
    dispatch(getPhacDoDieuTri({
      data: {
        BENH_NHAN_ID: selectedPatient?.BENH_NHAN_ID,
        PHAN_LOAI: 'PHAC_DO_SU_DUNG',
        GOI_DV_ID: id,
      },
      afterSuccess: () => {}
    }))
  }

  return (
    <FormDrawer
      className={style["drawer"]}
      open={open}
      width={'calc(100vw - 60px)'}
      title={i18n.t(languageKeys.tien_trinh_sd_goi)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
    >
      <Tabs
        activeKey={currentBundle}
        onChange={handleChangeCurrentBundle}
        className={style["tab-ctn"]}
        items={bundleList.map(i => ({
          key: i.GOI_ID,
          label: i.TEN_GOI_DV,
          disabled: !i.IS_ACTIVE
        }))}
      />
      <Spin spinning={loading}>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          defaultActiveKey={["DICH_VU", "VAT_TU"]}
        >
          <Collapse.Panel header={i18n.t(languageKeys.dv_sd_trong_goi)} key="DICH_VU">
            <TableDeXuat
              selectedTab={"PHAC_DO_SU_DUNG"}
              dataSource={serviceUsageList}
              readOnly
              hideHeader
            />
          </Collapse.Panel>
          <Collapse.Panel header={i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)} key="VAT_TU">
            <ChiDinhVatTu
              supplyList={supplyUsageList}
              readOnly
              selectedTab={"PHAC_DO_SU_DUNG"}
            />
          </Collapse.Panel>
        </Collapse>
      </Spin>
    </FormDrawer>
  )
})

export default XemTienTrinhGoi;