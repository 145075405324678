/* eslint-disable react/jsx-pascal-case */
import { keys } from "../../constants";
import { Space, Tag, Tooltip } from "antd";
import i18n, { languageKeys } from "../../i18n";
import { fieldsChonPhongKham, fieldsTimKiemBenhNhan } from "./fieldsTiepDon";
import style from "./quanLyTiepDon.module.less";
import { TRANG_THAI_KHAM_BENH_CUOC_KHAM, TRANG_THAI_THANH_TOAN } from "../../constants/keys";
import moment from "moment";
import { Check_box, Uncheckbox } from "../../assets/svg";
import { PopupLichSuKham } from "./components/PopupLichSuKham";
import { uniq } from "lodash";

// ellipsis chữ (độ ... đằng sau nếu quá dài)
const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

export const columnsBaoCaoTiepDon = [
  {
    title: i18n.t(languageKeys.field_Ma_cuoc_kham),
    key: "MA_CUOC_KHAM",
    dataIndex: "MA_CUOC_KHAM",
    unViewer: false,
    width: "80px",
  },
  {
    title: i18n.t(languageKeys.field_Ma_benh_nhan),
    key: "MA_BENH_NHAN",
    dataIndex: "MA_BENH_NHAN",
    unViewer: false,
    width: "100px",
  },
  {
    title: i18n.t(languageKeys.field_Ten_benh_nhan),
    key: "TEN_BENH_NHAN",
    dataIndex: "TEN_BENH_NHAN",
    fix: "left",
    width: "200px",
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_Nam_sinh),
    key: "NGAY_SINH",
    dataIndex: "NGAY_SINH",
    width: "75px",
    render: (date) => {
      return date ? moment(date, "YYYYMMDD").format("YYYY") : "";
    },
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_Gioi_tinh),
    key: "GIOI_TINH",
    dataIndex: "GIOI_TINH",
    width: "80px",
    render: (GIOI_TINH) => getGioiTinhBN(GIOI_TINH),
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_So_dien_thoai),
    key: "SO_DIEN_THOAI",
    dataIndex: "SO_DIEN_THOAI",
    unViewer: false,
    width: "100px",
  },
  {
    title: i18n.t(languageKeys.field_Doi_tuong_benh_nhan),
    key: "TEN_DOI_TUONG",
    dataIndex: "TEN_DOI_TUONG",

    width: "80px",
    // render: (doi_tuong) => {
    //   let result = danhSachDoiTuong.find((item) => item.key === doi_tuong);
    //   if (!!result) return result.name;
    //   return doi_tuong;
    // },
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.khoa),
    key: "TEN_KHOA_PHONG",
    dataIndex: "TEN_KHOA_PHONG",
    unViewer: false,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.label_ngay_tiep_don),
    key: "NGAY",
    dataIndex: "NGAY",
    unViewer: false,
    width: 200,
    render: (date, record) => { return (<span>{record?.GIO ? record?.GIO + " - " : ""} {date ? moment(date, "YYYYMMDD").format("DD/MM/YYYY") : ""}</span>) }
  },
  {
    title: i18n.t(languageKeys.field_Nguoi_tiep_don),
    key: "NGUOI_TIEP_DON",
    dataIndex: "NGUOI_TIEP_DON",
    unViewer: false,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.field_CMND_CCCD),
    key: "CCCD",
    dataIndex: "CCCD",
    width: "120px",
    unViewer: true,
  },
  {
    title: i18n.t(languageKeys.field_Email),
    key: "EMAIL",
    dataIndex: "EMAIL",
    unViewer: true,
    width: "100px",
  },
  {
    title: i18n.t(languageKeys.field_THX),
    key: "MA_KHU_VUC",
    dataIndex: "MA_KHU_VUC",
    unViewer: true,
    width: "80px",
  },
  {
    title: i18n.t(languageKeys.field_Tinh_thanh),
    key: "TEN_TINH_THANH",
    dataIndex: "TEN_TINH_THANH",
    unViewer: true,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.field_Quan_huyen),
    key: "TEN_QUAN_HUYEN",
    dataIndex: "TEN_QUAN_HUYEN",
    ...ellipsisProps,
    unViewer: true,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.field_Xa_phuong),
    key: "TEN_PHUONG_XA",
    dataIndex: "TEN_PHUONG_XA",
    unViewer: true,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.field_Dia_chi_chi_tiet),
    key: "DIA_CHI_CHI_TIET",
    dataIndex: "DIA_CHI_CHI_TIET",
    unViewer: true,
    width: "150px",
  },
  {
    title: i18n.t(languageKeys.field_Nghe_nghiep),
    key: "NGHE_NGHIEP",
    dataIndex: "NGHE_NGHIEP",
    unViewer: true,
    width: "200px",
  },
  {
    title: i18n.t(languageKeys.field_Quoc_gia),
    key: "QUOC_TICH",
    dataIndex: "QUOC_TICH",
    unViewer: true,
    width: "100px",
  },
  {
    title: i18n.t(languageKeys.field_Dan_toc),
    key: "DAN_TOC",
    dataIndex: "DAN_TOC",
    unViewer: true,
    width: "100px",
  },
  {
    title: i18n.t(languageKeys.field_Trang_thai),
    key: "TRANG_THAI_HIEN_TAI",
    dataIndex: "TRANG_THAI_HIEN_TAI",
    width: "100px",
    render: (data) => (
      <span className={getColorTextByStatus(data)} style={{ fontWeight: "bold" }}>
        {getTextByStatus(data)}
      </span>
    ),
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.status_Da_thanh_toan),
    key: "TRANG_THAI_THANH_TOAN",
    dataIndex: "TRANG_THAI_THANH_TOAN",
    width: "58px",
    align: "center",
    render: (data) => <div className={style["test"]}>{data === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN ? <Uncheckbox /> : <Check_box />}</div>,
    unViewer: false,
  },
];

export const columnsTimKiemBenhNhan = [
  {
    title: i18n.t(languageKeys.field_Ma_benh_nhan),
    key: fieldsTimKiemBenhNhan.ma_benh_nhan,
    dataIndex: fieldsTimKiemBenhNhan.ma_benh_nhan,
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.field_Ten_benh_nhan),
    key: fieldsTimKiemBenhNhan.ten_benh_nhan,
    dataIndex: fieldsTimKiemBenhNhan.ten_benh_nhan,
    ...ellipsisProps,
    width: "160px",
  },
  {
    title: i18n.t(languageKeys.field_Nam_sinh),
    key: fieldsTimKiemBenhNhan.ngay_sinh,
    dataIndex: fieldsTimKiemBenhNhan.ngay_sinh,
    ellipsis: true,
    render: (date) => moment(date, "YYYYMMDD").format("YYYY"),
  },
  {
    title: i18n.t(languageKeys.field_Gioi_tinh),
    key: fieldsTimKiemBenhNhan.gioi_tinh,
    ellipsis: true,
    dataIndex: fieldsTimKiemBenhNhan.gioi_tinh,
    render: (data) => {
      switch (data) {
        case keys.gioi_tinh_NAM:
          return i18n.t(languageKeys.gioi_tinh_Nam);
        case keys.gioi_tinh_NU:
          return i18n.t(languageKeys.gioi_tinh_Nu);
        case keys.gioi_tinh_KHAC:
          return i18n.t(languageKeys.gioi_tinh_Khac);
        default:
          return "";
      }
    },
  },
  {
    title: i18n.t(languageKeys.field_So_dien_thoai),
    ellipsis: true,
    key: fieldsTimKiemBenhNhan.so_dien_thoai,
    dataIndex: fieldsTimKiemBenhNhan.so_dien_thoai,
  },
  // {
  //   title: i18n.t(languageKeys.field_Ma_cccd),
  //   key: fieldsTimKiemBenhNhan.ma_cccd,
  //   dataIndex: fieldsTimKiemBenhNhan.ma_cccd,
  // },
  {
    title: i18n.t(languageKeys.field_Ngay_kham),
    ellipsis: true,
    key: fieldsTimKiemBenhNhan.ngay_kham,
    dataIndex: fieldsTimKiemBenhNhan.ngay_kham,
    render: (date) => (!!date ? moment(date, "YYYYMMDD").format("DD/MM/YYYY") : ""),
    remove: true
  },
  {
    title: i18n.t(languageKeys.field_Gio_kham),
    key: fieldsTimKiemBenhNhan.gio_kham,
    ellipsis: true,
    dataIndex: fieldsTimKiemBenhNhan.gio_kham,
    remove: true,
  },
];

export const columnsChonPhongKham = [
  {
    title: i18n.t(languageKeys.field_Ten_phong),
    key: fieldsChonPhongKham.ten_phong,
    dataIndex: fieldsChonPhongKham.ten_phong,
  },
  {
    title: i18n.t(languageKeys.field_So_phong),
    key: fieldsChonPhongKham.so_phong,
    dataIndex: fieldsChonPhongKham.so_phong,
  },
  {
    title: i18n.t(languageKeys.field_Benh_nhan_cho_kham),
    key: fieldsChonPhongKham.benh_nhan_cho_kham,
    dataIndex: fieldsChonPhongKham.benh_nhan_cho_kham,
  },
];

export const columnsTHX = [
  {
    title: i18n.t(languageKeys.field_Ten_viet_tat),
    key: "TEN_VIET_TAT",
    dataIndex: "TEN_VIET_TAT",
    width: 110,
  },
  {
    title: i18n.t(languageKeys.field_Tinh_thanh),
    key: "TEN_TINH_THANH",
    dataIndex: "TEN_TINH_THANH",
    width: 180,
  },
  {
    title: i18n.t(languageKeys.field_Quan_huyen),
    key: "TEN_QUAN_HUYEN",
    dataIndex: "TEN_QUAN_HUYEN",
    width: 180,
  },
  {
    title: i18n.t(languageKeys.field_Xa_phuong),
    key: "TEN_PHUONG_XA",
    dataIndex: "TEN_PHUONG_XA",
    width: 180,
  },
];

export const getTextByTarget = (target) => {
  switch (target) {
    case keys.BHYT:
      return i18n.t(languageKeys.field_doi_tuong_BHYT);

    case keys.YEU_CAU:
      return i18n.t(languageKeys.field_doi_tuong_yeu_cau);

    case keys.VIEN_PHI:
      return i18n.t(languageKeys.field_doi_tuong_vien_phi);

    case keys.NUOC_NGOAI:
      return i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai);

    case keys.MIEN_GIAM:
      return i18n.t(languageKeys.field_doi_tuong_mien_giam);

    default:
      return "";
  }
};

export const statusList = [
  // {
  //   label: i18n.t(languageKeys.status_Cho_xac_nhan),
  //   value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_XAC_NHAN
  // },
  // {
  //   label: i18n.t(languageKeys.title_Xac_nhan),
  //   value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.XAC_NHAN
  // },
  {
    title: i18n.t(languageKeys.tat_ca),
    key: "all",
    value: "all",
    children: [
      {
        label: i18n.t(languageKeys.Cho_kham),
        value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_KHAM,
      },
      {
        label: i18n.t(languageKeys.status_Dang_kham),
        value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.DANG_KHAM,
      },
      {
        label: i18n.t(languageKeys.common_Kham_xong),
        value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.KHAM_XONG,
      },
      {
        label: i18n.t(languageKeys.common_Hoan_thanh),
        value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.HOAN_THANH,
      },
      {
        label: i18n.t(languageKeys.status_Da_huy),
        value: TRANG_THAI_KHAM_BENH_CUOC_KHAM.HUY,
      },
    ],
  },
];
export const paidStatus = [
  {
    label: i18n.t(languageKeys.status_Chua_thanh_toan),
    value: TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN,
  },
  {
    label: i18n.t(languageKeys.status_Da_thanh_toan),
    value: TRANG_THAI_THANH_TOAN.DA_THANH_TOAN,
  },
];

export const getTextByStatus = (status) => {
  switch (status) {
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_XAC_NHAN:
      return i18n.t(languageKeys.status_Cho_xac_nhan);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.XAC_NHAN:
      return i18n.t(languageKeys.title_Xac_nhan);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_KHAM:
      return i18n.t(languageKeys.Cho_kham);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.DANG_KHAM:
      return i18n.t(languageKeys.status_Dang_kham);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.KHAM_XONG:
      return i18n.t(languageKeys.common_Kham_xong);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HOAN_THANH:
      return i18n.t(languageKeys.common_Hoan_thanh);

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HUY:
      return i18n.t(languageKeys.status_Da_huy);
    default:
      return "";
  }
};

export const getTextByPayStatus = (statusKey) => {
  switch (statusKey) {
    case TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN:
      return i18n.t(languageKeys.vienphi_chua_thanh_toan)
    case TRANG_THAI_THANH_TOAN.DA_THANH_TOAN:
      return i18n.t(languageKeys.vienphi_da_tt)
    default:
      return '';
  }
}

export const getColorTextByStatus = (status) => {
  switch (status) {
    // case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_XAC_NHAN:
    //   return "#6576FF"

    // case TRANG_THAI_KHAM_BENH_CUOC_KHAM.XAC_NHAN:
    //   return i18n.t(languageKeys.title_Xac_nhan)

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_KHAM:
      return "orange-txt";

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.DANG_KHAM:
      return "blue-txt";

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.KHAM_XONG:
      return "cyan_blue-txt";

    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HOAN_THANH:
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.DA_KHAM:
      return "green-txt";
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HUY:
      return "red-txt";
    default:
      return "blue-txt";
  }
};

export const getGioiTinhBN = (gt) => {
  if (gt === "nam") {
    return i18n.t(languageKeys.gioi_tinh_Nam);
  } else if (gt === "nu") {
    return i18n.t(languageKeys.gioi_tinh_Nu);
  } else {
    return i18n.t(languageKeys.gioi_tinh_Khac);
  }
};

export const columnsTiepDon = [
  {
    title: i18n.t(languageKeys.field_Trang_thai),
    key: "TRANG_THAI_HIEN_TAI",
    dataIndex: "TRANG_THAI_HIEN_TAI",
    // ellipsis: true,
    width: 100,
    render: (data) => (
      <span className={getColorTextByStatus(data)} style={{ fontWeight: "bold" }}>
        {getTextByStatus(data)}
      </span>
    ),
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.status_Da_thanh_toan),
    key: "TRANG_THAI_THANH_TOAN",
    dataIndex: "TRANG_THAI_THANH_TOAN",
    width: 60,
    align: "center",
    render: (data, record) => {
      if (record?.TRANG_THAI_CHI_DINH == 1 && data === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN) {
        return <div className={`${style["test"]} ${style["unCheckMore"]}`} style={{ marginTop: 5 }}>
          <Uncheckbox />
        </div>
      }
      return <div className={style["test"]} style={{ marginTop: 5 }}>
        {data === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN ? <Uncheckbox /> : <Check_box />}
      </div>
    },
    unViewer: false,
  },

  {
    title: i18n.t(languageKeys.field_Ten_benh_nhan),
    key: "TEN_BENH_NHAN",
    dataIndex: "TEN_BENH_NHAN",
    fix: "left",
    width: 200,
    unViewer: false,
    render: (name, record) => {
      return (
        // <PopupLichSuKham medExam={record}>
        //   <Space>
        //     {!!record.BENH_NHAN_MOI
        //       ? <div className={style.new}>{i18n.t(languageKeys.new)}</div>
        //       : <></>}
        //     <span>{name}</span>
        //   </Space>
        // </PopupLichSuKham>
        <Space>
          {!!record.BENH_NHAN_MOI
            ? <div className={style.new}>{i18n.t(languageKeys.new)}</div>
            : <></>}
          <span>{name}</span>
        </Space>
      );
    },
  },
  {
    title: i18n.t(languageKeys.field_Loai_dich_vu),
    key: "HINH_THUC_KHAM",
    ellipsis: true,
    dataIndex: "HINH_THUC_KHAM",
    width: 110,
    render: (val) => {
      if (val === 'KHAM_THUONG') return i18n.t(languageKeys.kham_dich_vu)
      if (val === 'KHAM_GOI') return i18n.t(languageKeys.kham_goi)
      if (val === 'KHAM_TONG_HOP') return (
        <div>
          <span>{i18n.t(languageKeys.kham_goi)}</span>{' '}
          <Tag color="#6576ff">+ 1</Tag>
        </div>
      )
    },
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_Nam_sinh),
    key: "NGAY_SINH",
    ellipsis: true,
    dataIndex: "NGAY_SINH",
    width: 85,
    render: (date) => {
      return date ? moment(date, "YYYYMMDD").format("YYYY") : "";
    },
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_Gioi_tinh),
    key: "GIOI_TINH",
    dataIndex: "GIOI_TINH",
    width: 70,
    render: (GIOI_TINH) => getGioiTinhBN(GIOI_TINH),
    unViewer: false,
  },
  {
    title: i18n.t(languageKeys.field_So_dien_thoai),
    key: "SO_DIEN_THOAI",
    dataIndex: "SO_DIEN_THOAI",
    unViewer: false,
    width: 110,
  },
  {
    title: i18n.t(languageKeys.field_Ma_cuoc_kham),
    key: "MA_CUOC_KHAM",
    dataIndex: "MA_CUOC_KHAM",
    unViewer: false,
    width: 100,
  },
  {
    title: i18n.t(languageKeys.field_Ma_benh_nhan),
    key: "MA_BENH_NHAN",
    dataIndex: "MA_BENH_NHAN",
    unViewer: false,
    width: 130,
  },
  {
    title: i18n.t(languageKeys.field_CMND_CCCD),
    key: "CCCD",
    dataIndex: "CCCD",
    ...ellipsisProps,
    unViewer: true,
    width: 100,
  },
  {
    title: i18n.t(languageKeys.field_Doi_tuong_benh_nhan),
    key: "LIST_DOI_TUONG",
    dataIndex: "LIST_DOI_TUONG",
    width: 105,
    // ...ellipsisProps,
    render: (target) => {
      const data = uniq(target?.flatMap(i => i.TEN_DOI_TUONG))
      if (data && data.length) {
        if (data.length > 1) {
          return (
            <div>
              <span>{data[0]}</span>{' '}
              <Tooltip title={data?.slice(1).join(', ')}>
                <Tag color="#6576ff">+ {data?.slice(1)?.length}</Tag>
              </Tooltip>
            </div>
          );
        } else {
          return <span>{data?.[0]}</span>
        }
      } else {
        return <></>
      }
    },
    unViewer: false,
  },
  // {
  //   title: i18n.t(languageKeys.field_The_bhyt),
  //   key: "MA_BHYT",
  //   dataIndex: "MA_BHYT",
  // },
  {
    title: i18n.t(languageKeys.label_ngay_tiep_don),
    key: "NGAY",
    dataIndex: "NGAY",
    unViewer: false,
    width: 150,
    render: (date, record) => { return (<span>{record?.GIO ? record?.GIO + " - " : ""} {date ? moment(date, "YYYYMMDD").format("DD/MM/YYYY") : ""}</span>) }
  },
  {
    title: i18n.t(languageKeys.field_Nguoi_tiep_don),
    key: "NGUOI_TIEP_DON",
    dataIndex: "NGUOI_TIEP_DON",
    unViewer: false,
    width: 200,
  },

  {
    title: i18n.t(languageKeys.field_Email),
    key: "EMAIL",
    dataIndex: "EMAIL",
    unViewer: true,
    width: 100,
  },
  {
    title: i18n.t(languageKeys.field_THX),
    key: "MA_KHU_VUC",
    dataIndex: "MA_KHU_VUC",
    unViewer: true,
    width: 80,
  },
  {
    title: i18n.t(languageKeys.field_Tinh_thanh),
    key: "TEN_TINH_THANH",
    dataIndex: "TEN_TINH_THANH",
    unViewer: true,
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_Quan_huyen),
    key: "TEN_QUAN_HUYEN",
    dataIndex: "TEN_QUAN_HUYEN",
    ...ellipsisProps,
    unViewer: true,
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_Xa_phuong),
    key: "TEN_PHUONG_XA",
    dataIndex: "TEN_PHUONG_XA",
    unViewer: true,
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_Dia_chi_chi_tiet),
    key: "DIA_CHI_CHI_TIET",
    dataIndex: "DIA_CHI_CHI_TIET",
    unViewer: true,
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_Nghe_nghiep),
    key: "NGHE_NGHIEP",
    dataIndex: "NGHE_NGHIEP",
    unViewer: true,
    width: 200,
  },
  {
    title: i18n.t(languageKeys.field_Quoc_gia),
    key: "QUOC_TICH",
    dataIndex: "QUOC_TICH",
    unViewer: true,
    width: 100,
  },
  {
    title: i18n.t(languageKeys.field_Dan_toc),
    key: "DAN_TOC",
    dataIndex: "DAN_TOC",
    unViewer: true,
    width: 100,
  },
];
