import React, { useState, useEffect } from "react";
import styles from "./style.module.less";
import { Row, Col, Form } from "antd";
import { ChiSo, Note, Sleep } from "../../../../assets/svg";
import ChiSoSinhTon from "./ChiSoSinhTon";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TienSuBanThan } from "./TienSuBanThan";
import { TienSuGiaDinh } from "./TienSuGiaDinh";
import { ThongTinKhac } from "./ThongTinKhac";
import i18n, { languageKeys } from "../../../../i18n";
import { TinhTrangGiacNgu } from "./TinhTrangGiacNgu";
const KhaiThacLichSuBenh = ({ form, readOnlyInput = false }) => {
  return (
    <div className={styles["container"]} >
      <RenderTitle  icon={<ChiSo />} title={i18n.t(languageKeys.field_Chi_so_sinh_ton)} />
      <Row className={styles["wrap-content"]}>
        <ChiSoSinhTon form={form} readOnlyInput={readOnlyInput} dataExam={form?.getFieldsValue()} />
      </Row>
      <RenderTitle  icon={<Note />} title={i18n.t(languageKeys.field_Tien_su_ban_than)} />
      <Row className={styles["wrap-content"]}>
        <TienSuBanThan form={form} readOnlyInput={readOnlyInput} />
      </Row>
      <RenderTitle  icon={<Note />} title={i18n.t(languageKeys.field_Tien_su_gia_dinh)} />
      <Row className={styles["wrap-content"]}>
        <TienSuGiaDinh form={form} readOnlyInput={readOnlyInput} />
      </Row>
      <RenderTitle  icon={<Sleep />} title={i18n.t(languageKeys.danh_gia_tt_buon_ngu)} />
      <Row className={styles["wrap-content"]}>
        <TinhTrangGiacNgu form={form} readOnlyInput={readOnlyInput} />
      </Row>
      <RenderTitle  icon={<InfoCircleOutlined style={{ color: "#6576FF" }} />} title={i18n.t(languageKeys.field_Thong_tin_khac)} />
      <Row className={styles["wrap-content"]}>
        <ThongTinKhac form={form} readOnlyInput={readOnlyInput} />
      </Row>
    </div>
  );
};

export default KhaiThacLichSuBenh;

const RenderTitle = ({ icon, title }) => {
  return (
    <Row className={styles["title-container"]} align="middle"  style={{backgroundColor:"#E9EFFF"}}  >
      <Col>{icon}</Col>
      <Col style={{ marginLeft: 20 }} className={styles["title"]}>
        {title.toUpperCase()}
      </Col>
    </Row>
  );
};
