import { Button, Col, Empty, Layout, notification, Row, Spin, Tooltip } from "antd";
import { ConfirmModal, Table } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { apiLayThongTinDonThuoc } from "../../apisKhamBenh/apisKhamBenh";
import { common_post, formatNumberToPrice, getErrorMessage, HLog, isEmptyObject, rid } from "../../../../helpers";
import { DeleteOutlined, EditOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import style from "./kdttn.module.less";
import KeDonThuoc from "./KeDonThuoc/KeDonThuoc";
import moment from "moment";
import { apis } from "../../../../constants";
import ModalPDF from "../../../../components/Modal/ModalPDF";
import { apiLuuDonThuoc } from "./KeDonThuoc/apisKeDonThuoc";
import { keyLoaiNhanVien, TRANG_THAI_THANH_TOAN } from "../../../../constants/keys";
import { useEventListener } from "../../../../hooks";
import cn from "classnames";

let KeDonThuocTuNguyen = ({
  patientInfo,
  kho_thuoc,
  trang_thai,
  isVisible,
  isViewHistory = false,
  setInfoDonThuoc = () => {},
  onChuyenSidebarTabSangDangKham = () => {},
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [donThuoc, setDonThuoc] = useState({});
  const [isSaving, setSaving] = useState(false);

  const ref = useRef();
  const pdfRef = useRef();
  const confirmDelete = useRef();
  const [loadingData, setLoadingData] = useState(false);
  // const isEdit = trang_thai !== "KHAM_XONG" ? true : false;
  const isKhoThuoc = userProfile.config && userProfile.config?.KHO_THUOC ? userProfile.config.KHO_THUOC : false;
  const isEdit = true;
  const maCchnExist = !!userProfile.MA_CCHN; // user có MÃ CCHN

  const userIsAdminOrDoctor = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri || userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.bac_si; // nếu user là admin hoặc bác sĩ, user được phép khám bệnh

  useEffect(() => {
    setInfoDonThuoc(donThuoc);
  }, [donThuoc]);

  useEffect(() => {
    if (!!patientInfo && !!isVisible) {
      layDsDonThuoc();
    }
    if (!patientInfo && isVisible && !isEmptyObject(donThuoc)) {
      setDonThuoc({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, isVisible]);

  async function layDsDonThuoc() {
    setLoadingData(true);
    setDonThuoc({});
    let dataRequest = {
      LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
      partner_code: userProfile.partner_code,
      KHO_THUOC: kho_thuoc,
    };
    const response = await apiLayThongTinDonThuoc({ ...dataRequest });
    if (!!response && response.length > 0) {
      HLog("RESSPPPONSSEEE", response);
      let don_thuoc = response[0];
      if (don_thuoc.CHI_TIET_DON_THUOC.length > 0) {
        don_thuoc.CHI_TIET_DON_THUOC = don_thuoc.CHI_TIET_DON_THUOC.map((item) => ({
          ...item,
          key: rid(),
        }));
      }
      setDonThuoc(don_thuoc);
    }
    setLoadingData(false);
  }

  async function onSaveDonThuoc(dataSubmit, print) {
    HLog("dataSubmit", dataSubmit);
    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BAC_SI_ID: userProfile.NHANSU_ID,
      BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
      LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
      DON_THUOC_ID: !!donThuoc ? donThuoc.DON_THUOC_ID : "",
      PHIEU_THUOC_ID: !!donThuoc ? donThuoc.PHIEU_THUOC_ID : undefined,
      KHO_THUOC: kho_thuoc,
      GHI_CHU: "",
      ...dataSubmit,
    };
    setSaving(true);

    HLog("dataSubmit", dataRequest);

    try {
      const response = await apiLuuDonThuoc(dataRequest);

      console.log(response);

      const don_thuoc_id = response.DON_THUOC_ID;

      console.log("data save dont huoc", don_thuoc_id, print);

      if (!!response) {
        if (response.status === "KO") {
          setSaving(false);
          if (response.error_code === "001") {
            ref.current.xacNhanLuuTrungMauDon(dataSubmit, print);
          } else if (response.error_code !== "003") {
            notification.error({ message: getErrorMessage(response.error_code) });
          }
          return;
        } else {
          console.log("run else");
          setSaving(false);

          ref.current.closeModal();

          notification.success({
            message: i18n.t(languageKeys.thao_tac_thanh_cong),
          });

          if (trang_thai === "CHO_KHAM") {
            console.log("it come here 1");
            onChuyenSidebarTabSangDangKham();
          }

          layDsDonThuoc();

          if (!!print) {
            handlePrint(don_thuoc_id);
          }

          return don_thuoc_id;
        }
      }
    } catch (error) {
      HLog(error.message);
      setSaving(false);
    }
  }

  const handlePrint = (id_don_thuoc) => {
    const dataRequest = {
      partner_code: userProfile.partner_code,
      DON_THUOC_ID: !!id_don_thuoc ? id_don_thuoc : donThuoc.DON_THUOC_ID,
    };
    const api = apis.lay_data_in_don_thuoc;
    pdfRef.current.openModal(dataRequest, api);
  };

  // hàm xóa đơn thuốc
  const handleDelete = async () => {
    confirmDelete.current.loading(true);
    try {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        NHANSU_ID: userProfile.NHANSU_ID,
        DON_THUOC_ID: donThuoc.DON_THUOC_ID,
      };
      HLog("dataRequest", dataRequest);
      const response = await common_post(apis.xoa_don_thuoc, dataRequest);
      if (response && response.status === "OK") {
        notification.success({
          message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
        });
        setDonThuoc({});
        // layDsDonThuoc();
        ref.current?.closeModal();
        confirmDelete.current?.close();
      }
    } catch (error) {
      confirmDelete.current.close();
      notification.error({ message: i18n.t(languageKeys.noti_Xoa_that_bai) });
    }
  };

  const columns = [
    {
      title: i18n.t(languageKeys.field_Ma_thuoc),
      width: "100px",
      key: "Thuoc_Code",
      dataIndex: "Thuoc_Code",
      ellipsis: true,
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_Ten_thuoc),
      width: "200px",
      key: "Thuoc_Ten",
      dataIndex: "Thuoc_Ten",
      // render: (Thuoc_Ten, record) => <p className={style['elipsis-column']}>{!!Thuoc_Ten ? Thuoc_Ten : record.TEN_THUOC  } </p>,
      ellipsis: true,
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_Hoat_chat),
      width: "200px",
      key: "Thuoc_HoatChat",
      dataIndex: "Thuoc_HoatChat",
      // render: (Thuoc_HoatChat, record) => <p className={style['elipsis-column']}>{Thuoc_HoatChat}{Thuoc_HoatChat} </p>,
      ellipsis: true,
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_Don_vi),
      width: "70px",
      key: "Thuoc_Dvt",
      dataIndex: "Thuoc_Dvt",
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      width: "100px",
      key: "MA_LO",
      dataIndex: "MA_LO",
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.vien_phi_Don_gia),
      width: "100px",
      //align: 'right',
      dataIndex: "GIA_BAN",
      render: (data) => formatNumberToPrice(data),
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_So_luong),
      width: "50px",
      //align: 'right',
      key: "Thuoc_SL",
      dataIndex: "Thuoc_SL",
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      width: "100px",
      //align: 'right',
      dataIndex: "THANH_TIEN",
      render: (THANH_TIEN, record) => formatNumberToPrice(THANH_TIEN),
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_Cach_dung),
      width: "225px",
      key: "Chidan",
      dataIndex: "Chidan",
      ellipsis: true,
      unViewer: false,
    },
    {
      title: i18n.t(languageKeys.field_Ghi_chu),
      width: "200px",
      key: "GHI_CHU",
      dataIndex: "GHI_CHU",
      ellipsis: true,
      unViewer: false,
    },
    // {
    //   title: i18n.t(languageKeys.field_Ghi_chu),
    //   key: "GHI_CHU",
    //   dataIndex: "GHI_CHU",
    // },
  ];
  // console.log(patientInfo, "pls");
  // ham tinh tong ds dich vu
  const countTotalPrice = !!donThuoc.CHI_TIET_DON_THUOC
    ? donThuoc.CHI_TIET_DON_THUOC.reduce((prevVal, item) => {
        return prevVal + Number(item.GIA_BAN) * Number(item.Thuoc_SL);
      }, 0)
    : 0;

  // hàm xử lý phím tắt
  function keydownEvent(event) {
    if (ref.current.visible) {
      return;
    }

    if (
      event.key === "d" &&
      event.ctrlKey &&
      !!donThuoc.DON_THUOC_ID &&
      !(!userIsAdminOrDoctor || donThuoc.TRANG_THAI_PHIEU === "HOAN_THANH" || donThuoc.TRANG_THAI_THANH_TOAN === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN)
    ) {
      event.preventDefault();
      // if (!maCchnExist) {
      //   return notification.error({
      //     placement: "bottomLeft",
      //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
      //   });
      // }

      confirmDelete.current.open();
    } else if (event.key === "i" && event.ctrlKey && !!donThuoc.DON_THUOC_ID) {
      event.preventDefault();
      handlePrint();
    }
  }

  useEventListener("keydown", keydownEvent, window);

  return (
    <Spin spinning={loadingData}>
      <Layout className={style["container"]}>
        <Layout.Content className={cn([style["wrap-content"], !donThuoc.DON_THUOC_ID && style["wrap-content-full-screen"]])}>
          {/* Ở ĐÂY */}
          {!isViewHistory && !donThuoc.DON_THUOC_ID ? (
            <Row justify="space-between" wrap={false} gutter={20}>
              <Col>
                <h3>{kho_thuoc === "KT_TU_NGUYEN" ? i18n.t(languageKeys.field_Don_thuoc_tu_nguyen) : i18n.t(languageKeys.Don_thuoc_bhyt)}</h3>
              </Col>

              {!donThuoc.DON_THUOC_ID && (
                <Col>
                  {trang_thai !== "HUY" && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        // if (!maCchnExist) {
                        //   return notification.error({
                        //     placement: "bottomLeft",
                        //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
                        //   });
                        // }

                        ref.current.openModal(donThuoc);
                      }}
                      disabled={!userIsAdminOrDoctor || !patientInfo}
                    >
                      {i18n.t(languageKeys.Ke_don_thuoc)}
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          ) : (
            <div></div>
          )}
          {!!donThuoc.DON_THUOC_ID && (
            <>
              <Row align="middle" style={{ marginBottom: 15 }} className={style["info-don-thuoc"]}>
                <Col className={style["info-item-up-left"]}>
                  <div>
                    <span>{i18n.t(languageKeys.field_Bac_si_ke_don)}: </span>
                    {donThuoc.HO + " " + donThuoc.TEN}
                  </div>
                </Col>

                <Col className={style["info-item-up-right"]} flex={"auto"}>
                  <div>
                    <span>{i18n.t(languageKeys.field_Thoi_gian_y_lenh)}:</span>{" "}
                    {donThuoc.GIO_Y_LENH + " - " + moment(donThuoc.NGAY_Y_LENH, "YYYYMMDD").format("DD/MM/YYYY")}
                  </div>
                </Col>
                <Col span={24} className={style["info-item"]}>
                  <Tooltip
                    placement="bottomRight"
                    title={donThuoc.GHI_CHU}
                    color={"rgba(255,255,255,0.8)"}
                    overlayInnerStyle={{ color: "#000", minWidth: "60vw" }}
                  >
                    {i18n.t(languageKeys.field_Loi_dan)}: {donThuoc.GHI_CHU}
                  </Tooltip>
                </Col>
              </Row>

              <Table
                dataSource={donThuoc.CHI_TIET_DON_THUOC}
                columns={columns.filter((item) => (!!isKhoThuoc ? true : item.dataIndex !== "MA_LO"))}
                scroll={{
                  y: "calc(100vh - 350px)",
                }}
              />

              <Row justify="end" style={{ marginTop: 10 }}>
                <span className={style["sum-price-title"]}>{i18n.t(languageKeys.field_Tong_tien)}:</span>

                <span className={style["sum-price-value"]}>
                  {formatNumberToPrice(countTotalPrice)} {` ${i18n.t(languageKeys.currency_viet_nam)}`}
                </span>
              </Row>
            </>
          )}

          {!loadingData && !donThuoc.DON_THUOC_ID && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "70vh",
              }}
            >
              <Row>
                <Col
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<div>{i18n.t(languageKeys.khambenh_empty)}</div>} />

                  <h4 style={{ marginBlock: "20px" }}>
                    {/* {isViewHistory
                      ? i18n.t(languageKeys.error_view_Khong_co_don_thuoc)
                      : trang_thai === "KHAM_XONG"
                      ? i18n.t(languageKeys.error_view_Khong_co_don_thuoc)
                      : i18n.t(languageKeys.error_view_Chua_co_don_thuoc)} */}
                  </h4>
                </Col>
              </Row>
            </div>
          )}
        </Layout.Content>
        {!!donThuoc.DON_THUOC_ID && !isViewHistory && (
          <Layout.Footer className={style["footer"]}>
            <Row justify="end" gutter={10} wrap={false}>
              {isEdit && (
                <Col>
                  <Tooltip
                    title={
                      !!donThuoc.PHIEU_THUOC_ID && donThuoc.TRANG_THAI_PHIEU !== "CHO_DUYET" ? i18n.t(languageKeys.khambenh_khong_huy_don_da_ban) : ""
                    }
                  >
                    <Button
                      type="primary"
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                      disabled={!userIsAdminOrDoctor || (!!donThuoc.PHIEU_THUOC_ID && donThuoc.TRANG_THAI_PHIEU !== "CHO_DUYET")}
                      onClick={() => {
                        // if (!maCchnExist) {
                        //   return notification.error({
                        //     placement: "bottomLeft",
                        //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
                        //   });
                        // }

                        confirmDelete.current.open();
                      }}
                    >
                      {i18n.t(languageKeys.common_huy_don)} (Ctrl + D)
                    </Button>
                  </Tooltip>
                </Col>
              )}
              {isEdit && (
                <Col>
                  <Tooltip
                    title={
                      !!donThuoc.PHIEU_THUOC_ID && donThuoc.TRANG_THAI_PHIEU !== "CHO_DUYET" ? i18n.t(languageKeys.khambenh_khong_sua_don_da_ban) : ""
                    }
                  >
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      disabled={!userIsAdminOrDoctor || (!!donThuoc.PHIEU_THUOC_ID && donThuoc.TRANG_THAI_PHIEU !== "CHO_DUYET")}
                      onClick={() => {
                        // if (!maCchnExist) {
                        //   return notification.error({
                        //     placement: "bottomLeft",
                        //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
                        //   });
                        // }

                        ref.current.openModal(donThuoc);
                      }}
                    >
                      {i18n.t(languageKeys.common_Chinh_sua)}
                    </Button>
                  </Tooltip>
                </Col>
              )}

              <Col>
                <Button icon={<PrinterOutlined style={{ color: "#fff" }} />} type="primary" onClick={() => handlePrint()}>
                  {i18n.t(languageKeys.button_In_don_thuoc)} (Ctrl + I)
                </Button>
              </Col>
            </Row>
          </Layout.Footer>
        )}

        <KeDonThuoc
          saving={isSaving}
          ref={ref}
          donThuoc={donThuoc}
          kho_thuoc={kho_thuoc}
          onSaveDonThuoc={onSaveDonThuoc}
          trang_thai={trang_thai}
          handlePrint={handlePrint}
          updateDonThuoc={(data) => setDonThuoc({ ...donThuoc, ...data })}
          onClickHuyDon={() => handleDelete()}
          reload={layDsDonThuoc}
          patientInfo={patientInfo}
          onChuyenSidebarTabSangDangKham={onChuyenSidebarTabSangDangKham}
        />

        <ModalPDF ref={pdfRef} />

        <ConfirmModal
          okText={i18n.t(languageKeys.title_Xac_nhan)}
          ref={confirmDelete}
          danger={true}
          content={i18n.t(languageKeys.confirm_xoa_don_thuoc)}
          onOk={handleDelete}
        />
      </Layout>
    </Spin>
  );
};

export default KeDonThuocTuNguyen;
