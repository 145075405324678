import i18n, { languageKeys } from "../../../../../i18n";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import style from "./style.module.less";
import { Button, Checkbox, Col, Image, Modal, Row, Space } from "antd";
import { useState } from "react";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import { CameraOutlined } from "@ant-design/icons";
import _ from 'lodash';

const ModalEditImageList = ({arrImgPrev, selectedImg, seletedImage, setSeletedImage = () => {}, setArrImgPrev = () => {}, closeListModal=() => {}, updateEditedImagesInDescription=()=>{}}, ref) => {
  const [open, setOpen] = useState(false);
  const [editedImages, setEditedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState();

  const imageEditorRef = useRef();

  useImperativeHandle(ref, () => ({
    open,
    handleOpen,
    handleClose,
  }));

  const handleClose = () => {
    setOpen(false);
    setEditedImages([]);
    setCurrentImage();
  };

  const handleOpen = (data) => {
    setOpen(true);
    setEditedImages(data);
    setCurrentImage(data[0]);
  };

  const handleSaveImage = () => {
    const canvas = document.querySelector(".lower-canvas");
    const imageData = canvas.toDataURL("image/jpeg");
    setEditedImages(prev => {
        return prev.map(item => {
            if (item.key === currentImage.key) {
                return {
                    ...item,
                    thumbnail: imageData
                }
            }
            return item
        })
    });
}

  const handleChangeCurrentImage = (item) => {
    if(item?.key === currentImage?.key) return;
    else {
        handleSaveImage();
        setCurrentImage(item)
    }
  }

  const handleSubmitUseImage = () => {
    const canvas = document.querySelector(".lower-canvas");
    const imageData = canvas.toDataURL("image/jpeg");
    const latestUpdatedItem = {...currentImage, thumbnail: imageData}
    const updatedArr = editedImages.map(item => item?.key === currentImage?.key ? latestUpdatedItem : item)
    setArrImgPrev(arrImgPrev.map(item => {
        if(item?.key === currentImage?.key){
            return latestUpdatedItem
        } else {
            const editedItem = editedImages.find(editedItem => editedItem?.key === item?.key);
            return editedItem ? editedItem : item
        }
    }))
    const notVinImage = seletedImage?.filter(item => !item?.url);
    setSeletedImage(notVinImage.concat(updatedArr));
    const removedElement = _.filter(seletedImage, (itemA) => !_.find(updatedArr, (itemB) => itemB.key === itemA.key) && !!itemA?.url);
    updateEditedImagesInDescription(removedElement, updatedArr)
    handleClose();
    closeListModal();
  }

  const renderFooter = () => {
    return (
      <Row justify={"end"} align="middle">
        <Button onClick={handleClose} danger ghost type="primary">
          {i18n.t(languageKeys.huy)}
        </Button>
        {/* <Button onClick={handleEditImage} type="primary">
          {i18n.t(languageKeys.common_Chinh_sua)}
        </Button> */}
        <Button onClick={handleSubmitUseImage} type="primary">
          {i18n.t(languageKeys.su_dung_anh)}
        </Button>
      </Row>
    );
  };

  return (
    <Modal
      open={open}
      centered
      title={<span style={{ fontSize: "22px", fontWeight: 700 }}>{i18n.t(languageKeys.chinh_sua_hinh_anh)}</span>}
      footer={renderFooter()}
      onCancel={handleClose}
      wrapClassName={style["container"]}
      width={"974px"}
    >
      <div className={style["edit-container"]}>
        {currentImage ? (
          <ImageEditor
            ref={imageEditorRef}
            key={currentImage.key}
            includeUI={{
              loadImage: {
                path: currentImage.thumbnail,
                name: currentImage.key,
                key: currentImage.key,
              },
              uiSize: {
                width: "100%",
                height: "550px",
              },
              menuBarPosition: "bottom",
            }}
            cssMaxHeight={550}
            cssMaxWidth={550}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={style["list-container"]}>
        <Row className={style["image-list"]}>
          {editedImages.map((item, index) => (
            <Col key={item?.key} span={3} className={style["image-wrapper"]}>
              <div
                className={style["image-item"]}
                onClick={() => handleChangeCurrentImage(item)}
              >
                <span>{`0${index+1}`}</span>
                    <Image width={"100%"} wrapperStyle={{ zIndex: 2 }} src={item?.thumbnail} preview={false} />
                {item.key !== currentImage?.key ? <div className={style['image-overlay']}></div> : <></>}
              </div>
            </Col>
          ))}
          {editedImages.length < 8 &&
            Array(8 - editedImages.length)
              .fill(0)
              .map((img, index) => {
                return (
                  <>
                    <Col key={editedImages.length + index + 1} span={3} className={style["image-wrapper"]}>
                      <div className={style["image-item"]}>
                        <span>{`0${(editedImages.length) + index + 1}`}</span>
                        <div className={style['empty-item']}>
                            <CameraOutlined style={{ color: "#999", transform: "scale(1.2)", margin: "auto" }} />
                        </div>
                        <div className={style["image-overlay"]}></div>
                      </div>
                    </Col>
                  </>
                );
              })}
        </Row>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalEditImageList);
