import React from "react";
import style from "./style.module.less";
import { Row, Col, Form, Radio, Checkbox, Input } from "antd";
import { FieldKhaiThac } from "./fieldKhaiThac";
import i18n, { languageKeys } from "../../../../i18n";
const { Item } = Form;

export const optionsBenhSu = [
    { label: i18n.t(languageKeys.huyet_ap_cao), value: 'HUYET_AP_CAO' },
    { label: i18n.t(languageKeys.tieu_duong), value: 'TIEU_DUONG' },
    { label: i18n.t(languageKeys.tai_bien_nao), value: 'TAI_BIEN_NAO' },
    { label: i18n.t(languageKeys.benh_tim_mach), value: 'BENH_TIM_MACH' },
    { label: i18n.t(languageKeys.benh_than), value: 'BENH_THAN' },
    { label: i18n.t(languageKeys.benh_kawasaki), value: 'BENH_KAWASAKI' },
    { label: i18n.t(languageKeys.sot_xuat_huyet), value: 'SOT_XUAT_HUYET' },
    { label: i18n.t(languageKeys.benh_lao), value: 'LAO' },
    { label: i18n.t(languageKeys.viem_da_di_ung), value: 'VIEM_DA_DI_UNG' },
    { label: i18n.t(languageKeys.gan), value: 'GAN' },
    { label: i18n.t(languageKeys.than), value: 'THAN' },
    { label: i18n.t(languageKeys.roi_loan_lipit), value: 'ROI_LOAN_LIPIT' },
    { label: i18n.t(languageKeys.other), value: 'KHAC' },
]
export const TienSuBanThan = (
    {
        readOnlyInput,
        form
    }
) => {

    const DI_UNG = Form.useWatch(FieldKhaiThac.DI_UNG, form);
    const TUNG_NHAP_VIEN = Form.useWatch(FieldKhaiThac.TUNG_NHAP_VIEN, form);
    const DANG_DT_DUNG_THUOC = Form.useWatch(FieldKhaiThac.DANG_DT_DUNG_THUOC, form);
    const SINH_CON = Form.useWatch(FieldKhaiThac.SINH_CON, form);
    const NHAP_VIEN_TAI_NAN_VALUE = Form.useWatch(FieldKhaiThac.NHAP_VIEN_TAI_NAN, form);
    // const KHAM_LOANG_XUONG_VALUE = Form.useWatch(FieldKhaiThac.KHAM_LOANG_XUONG, form);
    // const NHAP_VIEN_GAY_XUONG_VALUE = Form.useWatch(FieldKhaiThac.NHAP_VIEN_GAY_XUONG, form);
    
    return (
        <div className={style["tien-su-ban-than"]}>
            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>1. {i18n.t(languageKeys.question_1)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.DI_UNG}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {DI_UNG ?
                            <Item name={FieldKhaiThac.DI_UNG_VALUE} style={{ width: 300, marginLeft: 20 }} >
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>2. {i18n.t(languageKeys.question_2)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.TUNG_NHAP_VIEN}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {TUNG_NHAP_VIEN ?
                            <Item name={FieldKhaiThac.TUNG_NHAP_VIEN_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>3. {i18n.t(languageKeys.question_3)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.TRUYEN_MAU}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {/* {TRUYEN_MAU ? 
                    <Item name={FieldKhaiThac.TRUYEN_MAU_VALUE} style={{width:300, marginLeft:20}}>
                        <Input disabled = {readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)}/>
                    </Item> :""} */}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>4. {i18n.t(languageKeys.question_4)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.DANG_DT_DUNG_THUOC}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {DANG_DT_DUNG_THUOC ?
                            <Item name={FieldKhaiThac.DANG_DT_DUNG_THUOC_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>5. {i18n.t(languageKeys.question_sinh_con)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.SINH_CON}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {SINH_CON ?
                            <Item name={FieldKhaiThac.SINH_CON_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>6. {i18n.t(languageKeys.question_nhap_vien)}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.NHAP_VIEN_TAI_NAN}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {NHAP_VIEN_TAI_NAN_VALUE ?
                            <Item name={FieldKhaiThac.NHAP_VIEN_TAI_NAN_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>7. {i18n.t(languageKeys.quest_gay_xuong)  + " ?"}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.NHAP_VIEN_GAY_XUONG}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {/* {NHAP_VIEN_GAY_XUONG_VALUE ?
                            <Item name={FieldKhaiThac.NHAP_VIEN_GAY_XUONG_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""} */}
                    </div>
                </Col>
            </Row>
            
            
            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>8. {i18n.t(languageKeys.quest_di_kham_loang_xuong) + " ?"}</span>
                </Col>
                <Col flex={"auto"}>
                    <div style={{ display: "flex" }}>
                        <Item name={FieldKhaiThac.KHAM_LOANG_XUONG}>
                            <Radio.Group disabled={readOnlyInput}>
                                <Radio value={1}>{i18n.t(languageKeys.answer_co)}</Radio>
                                <Radio value={0}>{i18n.t(languageKeys.answer_khong)}</Radio>
                            </Radio.Group>
                        </Item>
                        {/* {KHAM_LOANG_XUONG_VALUE ?
                            <Item name={FieldKhaiThac.KHAM_LOANG_XUONG_VALUE} style={{ width: 300, marginLeft: 20 }}>
                                <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                            </Item> : ""} */}
                    </div>
                </Col>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>9. {i18n.t(languageKeys.benh_su)}</span>
                </Col>
                <Col flex={"auto"}>
                </Col>
            </Row>
            <Row align={"middle"} wrap={false} style={{ marginTop: 10 }}>
                <Item name={FieldKhaiThac.BENH_SU}>
                    <Checkbox.Group options={optionsBenhSu} disabled={readOnlyInput} />
                </Item>
            </Row>

            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>10. {i18n.t(languageKeys.tien_su_benh_khac)}</span>
                </Col>
                <Col flex={"auto"}>
                    <Item name={FieldKhaiThac.TIEN_SU_BENH_KHAC}>
                        <Input disabled={readOnlyInput} placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Item>
                </Col>
            </Row>
        </div>
    )
}
