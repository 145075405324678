import {
    R11,
    R12,
    R13,
    R14,
    R15,
    R16,
    R17,
    R18,
    R21,
    R22,
    R23,
    R24,
    R25,
    R26,
    R27,
    R28,
    R48,
    R47,
    R46,
    R45,
    R44,
    R43,
    R42,
    R41,
    R31,
    R32,
    R33,
    R34,
    R35,
    R36,
    R37,
    R38,
    R51,
    R52,
    R53,
    R54,
    R55,
    R61,
    R62,
    R63,
    R64,
    R65,
    R71,
    R72,
    R73,
    R74,
    R75,
    R81,
    R82,
    R83,
    R84,
    R85,
  } from "assets/img";

  export const arrayToothAdultTop = [
    {
      value: "R18",
      image: R18,
    },
    {
      value: "R17",
      image: R17,
    },
    {
      value: "R16",
      image: R16,
    },
    {
      value: "R15",
      image: R15,
    },
    {
      value: "R14",
      image: R14,
    },
    {
      value: "R13",
      image: R13,
    },
    {
      value: "R12",
      image: R12,
    },
    {
      value: "R11",
      image: R11,
    },
    {
      value: "R21",
      image: R21,
    },
    {
      value: "R22",
      image: R22,
    },
    {
      value: "R23",
      image: R23,
    },
    {
      value: "R24",
      image: R24,
    },
    {
      value: "R25",
      image: R25,
    },
    {
      value: "R26",
      image: R26,
    },
    {
      value: "R27",
      image: R27,
    },
    {
      value: "R28",
      image: R28,
    },
  ];
  
  export const arrayToothAdultBottom = [
    {
      value: "R48",
      image: R48,
    },
    {
      value: "R47",
      image: R47,
    },
    {
      value: "R46",
      image: R46,
    },
    {
      value: "R45",
      image: R45,
    },
    {
      value: "R44",
      image: R44,
    },
    {
      value: "R43",
      image: R43,
    },
    {
      value: "R42",
      image: R42,
    },
    {
      value: "R41",
      image: R41,
    },
  
    {
      value: "R31",
      image: R31,
    },
    {
      value: "R32",
      image: R32,
    },
    {
      value: "R33",
      image: R33,
    },
    {
      value: "R34",
      image: R34,
    },
    {
      value: "R35",
      image: R35,
    },
    {
      value: "R36",
      image: R36,
    },
    {
      value: "R37",
      image: R37,
    },
    {
      value: "R38",
      image: R38,
    },
  ];
  export const arrayToothChildTop = [
    {
      value: "R55",
      image: R55,
    },
    {
      value: "R54",
      image: R54,
    },
    {
      value: "R53",
      image: R53,
    },
    {
      value: "R52",
      image: R52,
    },
    {
      value: "R51",
      image: R51,
    },
    {
      value: "R61",
      image: R61,
    },
    {
      value: "R62",
      image: R62,
    },
    {
      value: "R63",
      image: R63,
    },
    {
      value: "R64",
      image: R64,
    },
    {
      value: "R65",
      image: R65,
    },
  ];
  export const arrayToothChildBottom = [
    {
      value: "R85",
      image: R85,
    },
    {
      value: "R84",
      image: R84,
    },
    {
      value: "R83",
      image: R83,
    },
    {
      value: "R82",
      image: R82,
    },
    {
      value: "R81",
      image: R81,
    },
    {
      value: "R71",
      image: R71,
    },
    {
      value: "R72",
      image: R72,
    },
    {
      value: "R73",
      image: R73,
    },
    {
      value: "R74",
      image: R74,
    },
    {
      value: "R75",
      image: R75,
    },
  ];

  export const value_SO_DO_RANG = {
    adult_jaw:'NGUOI_LON',
    child_jaw:'TRE_CON',
  }