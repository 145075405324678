import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import ChiTietLichSuKham from "../../../../components/LichSuKhamBenh/ChiTietLichSuKham";
import { Row, Col , Button, Empty, Divider } from "antd"
import i18n, {languageKeys} from "../../../../i18n";
import DanhSachLichSuKham from "./DanhSachLichSuKham";
import style from "./lskb.module.less"
import { useSelector } from "react-redux"
import {common_post, HLog} from "../../../../helpers";
import {apis} from "../../../../constants";
import { isEmptyObject } from './../../../../helpers';
const initPage = {
    currentPage: 1, totalPage : 0
}
const LichSuKhamBenh = React.forwardRef(({ patientInfo, currentTab, isVisible, phongChiDinh }, ref) => {
    const refDanhSach = useRef();
    const refThongTin = useRef()
    const userProfile = useSelector(state => state.auth.user)
    const [dataKhamBenh, setDataKhamBenh] = useState([])
    const [dataDonThuoc, setDataDonThuoc] = useState([])
    const [dataKetquaCls, setDataKqCls] = useState([])
    const [loadingKB, setLoadingKB] = useState(false)
    const [loadingDT, setLoadingDT] = useState(false)
    const [loadingCLS, setLoadingCLS] = useState(false)
    const [pageKhamBenh, setPageKhamBenh] = useState(initPage)
    const [pageDonThuoc, setPageDonThuoc] = useState(initPage)
    const [pageKqCls, setPageKqCls] = useState(initPage)

    let common_req = {
        partner_code : userProfile.partner_code,
        BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
        limit : 15
    }
    useEffect(() => {
        if (patientInfo && isVisible){
            HLog("LỊCH SỬ KHÁM - CÓ THÔNGTIN")
            // if (isVisible){
                // HLog("LỊCH SỬ KHÁM - ĐANG Ở TAB LICH SU")
                // if (currentUser && currentUser.LICH_KHAM_ID === patientInfo.LICH_KHAM_ID) {
                //     HLog("LỊCH SỬ KHÁM - VẪN LÀ BẸNH NHAN CŨ")
                //     return
                // }
                // HLog("LỊCH SỬ KHÁM - GET DATA")
                // setCurrentUser(patientInfo)
                refThongTin.current?.reset()
                layLichSuLDT().then()
                layLichSuDonThuoc()
                layLichSuCLS()
            // }
        }else {
            HLog("LỊCH SỬ KHÁM - RESET DATA")
            handleReset()
        }
    }, [patientInfo, isVisible])


    useImperativeHandle(ref, () => ({
        reset: handleReset,
    }));

    const handleReset = () => {
        setDataKhamBenh([])
        setDataDonThuoc([])
        setDataKqCls([])
        setPageDonThuoc(initPage)
        setPageKhamBenh(initPage)
        setPageKqCls(initPage)
        refThongTin.current?.reset()
    }


    async function layLichSuLDT(page = 1) {
        let req = {
            ...common_req,
            page,
            BENH_NHAN_ID : patientInfo.BENH_NHAN_ID,
            limit : 5
        }
        try {
            setLoadingKB(true)
            const response = await common_post(apis.khambenh_lay_lich_su_luot_dieu_tri, req)
            if (response && response.status === "OK") {
                let { result , current_page, count_page} = response
                if (page === 1) {
                    setDataKhamBenh(result)
                }else {
                    setDataKhamBenh(prev => prev.concat(result))
                }
                setPageKhamBenh({currentPage: current_page, totalPage : count_page })
                setLoadingKB(false)
            }
        }catch (e){

        }
    }
    async function layLichSuDonThuoc(page = 1) {
        let req = {
            ...common_req,
            page,
            BENH_NHAN_ID : patientInfo.BENH_NHAN_ID
        }
        try {
            setLoadingDT(true)
            const response = await common_post(apis.khambenh_lay_lich_su_don_thuoc, req)
            if (response && response.status === "OK") {
                let { result , current_page, count_page} = response
                if (page === 1) {
                    setDataDonThuoc(result)
                }else {
                    setDataDonThuoc(prev => prev.concat(result))
                }
                setPageDonThuoc({currentPage: current_page, totalPage : count_page })
                setLoadingDT(false)
            }
        }catch (e){

        }
    }
    async function layLichSuCLS(page = 1) {
        let req = {
            ...common_req,
            page,
            BENH_NHAN_ID : patientInfo.BENH_NHAN_ID
        }
        try {
            setLoadingCLS(true)
            const response = await common_post(apis.khambenh_lay_lich_can_lam_sang, req)
            if (response && response.status === "OK") {
                let { result , current_page, count_page} = response
                if (page === 1) {
                    setDataKqCls(result)
                }else {
                    setDataKqCls(prev => prev.concat(result))
                }
                setPageKqCls({currentPage: current_page, totalPage : count_page })
                setLoadingCLS(false)
            }
        }catch (e){

        }
    }

    function handleXemNhanh() {
        refDanhSach.current.openModal()
    }
    const RenderEmpty = () => {
        return (
            <div style={{display : "flex",  height : "calc(100vh - 150px)"}}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE} style = {{margin : "auto"}}
                    description={<span>{i18n.t(languageKeys.chua_chon_benh_nhan_vao_kham)}</span>}
                />
            </div>
        )
    }
  return (
    <div style={{  }} className={style["lichsu-kham-container"]}>
            <Row justify="space-between" align="middle">
                <Col>
                    <p style={{fontSize : 16, color : "#2C3782"}}>{i18n.t(languageKeys.lich_su_kham_benh)}</p>
                </Col>
                <Col>
                    <Button type="primary" style={{width : 130}} onClick={handleXemNhanh} disabled={!patientInfo || isEmptyObject(patientInfo)}>
                        {i18n.t(languageKeys.xem_nhanh)}
                    </Button>
                </Col>
            </Row>
            <Divider style={{height: 2, margin: "10px 0"}} />
            {patientInfo ?  <ChiTietLichSuKham
                ref = {refThongTin}
                fromKhamBenh={true}
                dataKhamBenh = {dataKhamBenh}
                dataDonThuoc = {dataDonThuoc}
                dataKetquaCls = {dataKetquaCls}
                pageKhamBenh = {pageKhamBenh}
                pageDonThuoc = {pageDonThuoc}
                pageKqCls = {pageKqCls}
                patientInfo={patientInfo}
                loading = {{
                    loadingKB,
                    loadingDT,
                    loadingCLS
                }}
                onLoadMoreKhamBenh = {() => layLichSuLDT(pageKhamBenh.currentPage + 1)}
                onLoadMoreDonThuoc = {() => layLichSuDonThuoc(pageDonThuoc.currentPage + 1)}
                onLoadMoreCls = {() => layLichSuCLS(pageKqCls.currentPage + 1)}
            /> :
                <RenderEmpty/>
            }
            <DanhSachLichSuKham ref={refDanhSach} patientInfo ={patientInfo} userProfile = {userProfile} isVisible={isVisible}/>
    </div>
  );
});

export default LichSuKhamBenh;
