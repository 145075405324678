import { Button, Col, Image, Modal, notification, Row, Spin } from "antd";
import { apis } from "../../../../../constants";
import { common_post, layDiaChi } from "helpers";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import { messaging } from "config/firebase";
import { ModalPDF } from "components";

const ModalQR = ({ customer = {}, data = {}, onSuccess = () => {}, formatTempDataPrint = () => {}, onPrint = () => {} }, ref) => {

  const syncRef = useRef({})
  const pdfRef = useRef()

  const userProfile = useSelector((state) => state.auth.user);

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [QrData, setQrData] = useState({})

  useImperativeHandle(ref, () => ({
    open: (amount, print) => {
      setOpen(true)
      getQRCode(amount, print)
    }
  }));

  useEffect(() => {
    syncRef.current = { ...QrData, onPrint };
  }, [QrData]);

  //Firebase
  useEffect(() => {
    onMessage(messaging, (payload) => {
      const state = syncRef.current
      //
      if (payload.data?.billId && state.billId && payload.data.billId === state.billId) {
        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
          placement: "bottomLeft",
        });
        handleSuccess()
        state.print && state.onPrint(payload.data)
      }
    });
  }, []);

  const getQRCode = async (amount, print) => {
    setLoading(true)
    try {
      const res = await common_post(apis.genQRCode, {
        name: customer.TEN || customer.TEN_BENH_NHAN,
        address: layDiaChi(customer),
        amount,
        description: `Thanh toan hoa don ${customer.BENH_NHAN_ID}`,
        BENH_NHAN_ID: customer.BENH_NHAN_ID,
        LICH_KHAM_ID: customer.LICH_KHAM_ID,
        SO_HOADON_ID: data.SO_HOADON_ID,
        THU_NGAN_ID: userProfile.NHANSU_ID,
        IS_GOI: customer.LOAI === "THANH_TOAN_MUA_GOI",
        GOI_DV_ID: data.GOI_DV_ID,
      })
      if (res) {
        if (res.status === "OK") {
          setQrData({
            billId: res.billId?.toString(),
            base64: res.partner_response?.vietQRImage,
            print,
          })
          setLoading(false)
        } else {
          notification.error({
            message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
            placement: "bottomLeft",
          });
          handleClose()
        }
      } else {
        notification.error({
          message: i18n.t(languageKeys.common_co_loi_xay_ra),
          placement: "bottomLeft",
        });
        handleClose()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSuccess = () => {
    handleClose()
    onSuccess()
  }

  const handleClose = () => {
    setOpen(false)
    setQrData({})
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
      zIndex={99999}
      centered
      bodyStyle={{ padding: 0 }}
      width={null}
    >

      <Spin spinning={loading}>

        {
          !loading ? (
            <Image
              preview={false}
              src={`data:image/png;base64, ${QrData.base64}`}
              // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              height={'80vh'}
            // width={'100%'}
            />
          ) : <div style={{ height: '80vh', width: '40vw' }} />
        }

        <Row gutter={8} style={{ padding: 20 }}>
          <Col span={8}>
            <Button
              block type="primary" ghost
              disabled={loading}
              onClick={() => pdfRef.current?.openModalWithData(formatTempDataPrint(QrData.base64), "phieuThuK80")}
            >
              {i18n.t(languageKeys.field_In_phieu)}
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block type="primary" ghost
              disabled={loading}
              onClick={() => pdfRef.current?.openModalWithData({ QR: QrData.base64 }, "QR")}
            >
              {i18n.t(languageKeys.common_In)} QR
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block type="primary"
              disabled={loading}
              onClick={() => handleSuccess()}
            >
              {i18n.t(languageKeys.thanh_toan)}
            </Button>
          </Col>
        </Row>

      </Spin>

      <ModalPDF ref={pdfRef} />

    </Modal>
  )
}

export default forwardRef(ModalQR);