import { debounce } from "lodash";
import { useEffect, useCallback, useState } from "react";
import {
  apiLayDsDanToc,
  apiLayDsHuyen,
  apiLayDsNgheNghiep,
  apiLayDsQuocGiaCoHieuLuc,
  apiLayDsTinh,
  apiLayDsXaPhuong,
} from "../QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDanhMuc";
import { fieldDantoc, fieldHuyen, fieldNgheNghiep, fieldQuocGia, fieldTinh, fieldXa } from "../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { apiLayDsDaTiepNhan, apiLayDsDoiTuongBenhNhan, apiTimKiemBenhNhan } from "./apisTiepDon";

import { HLog, isJsonString, rid, validFragment } from "../../helpers";
import { useSelector } from "react-redux";
import { keys } from "../../constants";
import i18n, { languageKeys } from "../../i18n";
import * as _ from "lodash";
import moment from "moment";

export const appointmentViews = {
  da_tiep_nhan: "da_tiep_nhan",
  cuoc_hen_sap_toi: "cuoc_hen_sap_toi",
};

export const useGetAppointment = (currentView = appointmentViews.da_tiep_nhan) => {
  const [dataSource, setDataSource] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const userProfile = useSelector((state) => state.auth.user);

  const getDataSource = useCallback(
    async (search_string = "", page = 1, fromDate, toDate) => {
      let res = undefined;

      setLoading(true);

      switch (currentView) {
        case appointmentViews.da_tiep_nhan:
          res = await apiLayDsDaTiepNhan({
            search_string,
            page,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            partner_code: userProfile.partner_code,
            TU: fromDate,
            DEN: toDate,
          });
          break;
        case appointmentViews.cuoc_hen_sap_toi:
          break;
        default:
          break;
      }

      setLoading(false);

      if (!!res) {
        const dataList = res.result.map((item) => ({
          ...item,
          key: rid(),
        }));

        setDataSource(dataList);
        setCurrentPage(res.current_page);
        setTotalResult(res.total);
      } else resetState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentView]
  );

  const resetState = () => {
    setDataSource([]);
    setTotalResult(0);
    setCurrentPage();
  };

  useEffect(() => {
    getDataSource();
    return () => setDataSource([]);
  }, [getDataSource]);

  return {
    dataSource,
    totalResult,
    currentPage,
    resetState,
    getDataSource,
    loadingList: loading,
  };
};

export const useFormData = () => {
  const [dsBenhNhan, setDsBenhNhan] = useState([]);
  const [dsDoiTuongBenhNhan, setDsDoiTuongBenhNhan] = useState([]);
  const [dsTinhThanh, setDsTinhThanh] = useState([]);
  const [dsQuanHuyen, setDsQuanHuyen] = useState([]);
  const [dsXaPhuong, setDsXaPhuong] = useState([]);
  const [dsNgheNghiep, setDsNgheNghiep] = useState([]);
  const [dsDanToc, setDsDanToc] = useState([]);
  const [dsQuocGia, setDsQuocGia] = useState([]);

  //const [searchString , setSearchString ] = useState("")
  const userProfile = useSelector((state) => state.auth.user);

  // Hàm reset toàn bộ state ở trên
  const resetState = () => {
    setDsBenhNhan([]);
    setDsDoiTuongBenhNhan([]);
    setDsTinhThanh([]);
    setDsQuanHuyen([]);
    setDsXaPhuong([]);
    setDsNgheNghiep([]);
    setDsDanToc([]);
    setDsQuocGia([]);
  };
  // Hàm xử lý lấy danh sách bệnh nhân
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchBenhNhan = useCallback(
    debounce((value) => {
      apiTimKiemBenhNhan({
        search_string: value,
        partner_code: userProfile.partner_code,
      }).then((res) => {
        if (!!res) {
          const data = res.map((item) => ({
            ...item,
            key: rid(),
          }));

          setDsBenhNhan(data);
        }
      });
    }, 1000),
    []
  );
  // Hàm xử lý lấy danh sách đối tượng bệnh nhân
  const layDsDoiTuongBenhNhan = () => {
    apiLayDsDoiTuongBenhNhan().then((res) => {
      if (res) {
        let listDT = res.map((item) => {
          switch (item) {
            case keys.BHYT:
              return {
                name: i18n.t(languageKeys.field_doi_tuong_BHYT),
                MA_DOI_TUONG: item,
              };
            case keys.YEU_CAU:
              return {
                name: i18n.t(languageKeys.field_doi_tuong_yeu_cau),
                MA_DOI_TUONG: item,
              };
            case keys.VIEN_PHI:
              return {
                name: i18n.t(languageKeys.field_doi_tuong_vien_phi),
                MA_DOI_TUONG: item,
              };
            case keys.NUOC_NGOAI:
              return {
                name: i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai),
                MA_DOI_TUONG: item,
              };
            case keys.MIEN_GIAM:
              return {
                name: i18n.t(languageKeys.field_doi_tuong_mien_giam),
                MA_DOI_TUONG: item,
              };
            default:
              return {};
          }
        });

        const config = userProfile.config;

        listDT = listDT.filter((item) => {
          if (item.MA_DOI_TUONG === keys.BHYT) return false;

          if (
            !!config.DM_DICHVU_KYTHUAT &&
            !!config.DM_DICHVU_KYTHUAT.isCo &&
            Array.isArray(config.DM_DICHVU_KYTHUAT.TRUONG_BO) &&
            item.MA_DOI_TUONG === keys.YEU_CAU
          ) {
            return !config.DM_DICHVU_KYTHUAT.TRUONG_BO.some((item) => item === "GIA_YEU_CAU");
          }

          return true;
        });

        setDsDoiTuongBenhNhan(listDT);
      }
    });
  };
  // Hàm xử lý lấy danh sách nghề nghiệp
  const layDsNgheNghiep = () => {
    apiLayDsNgheNghiep().then((res) => {
      if (!!res) {
        const ma_nghe_nghiep = fieldNgheNghiep.ma_nghe_nghiep;
        const ten_nghe_nghiep = fieldNgheNghiep.ten_nghe_nghiep;

        const data = res.result.map((item) => ({
          [ma_nghe_nghiep]: item[ma_nghe_nghiep],
          [ten_nghe_nghiep]: item[ten_nghe_nghiep],
        }));

        setDsNgheNghiep(data);
      }
    });
  };
  // Hàm xử lý lấy danh sách dân tộc
  const layDsDanToc = () => {
    apiLayDsDanToc({}).then((res) => {
      if (!!res) {
        const ma_dan_toc = fieldDantoc.ma_dan_toc;
        const ten_dan_toc = fieldDantoc.ten_dan_toc;

        const data = res.result.map((item) => ({
          [ma_dan_toc]: item[ma_dan_toc],
          [ten_dan_toc]: item[ten_dan_toc],
        }));

        setDsDanToc(data);
      }
    });
  };
  // Hàm xử lý lấy danh sách quốc gia
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuocGia = useCallback(
    debounce((value) => {
      apiLayDsQuocGiaCoHieuLuc({ limit: keys.limit, search_string: value }).then((res) => {
        if (!!res) setDsQuocGia(res.result);
      });
    }, 1000),
    []
  );
  // Hàm xử lý lấy danh sách tỉnh thành
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTinhThanh = useCallback(
    debounce((value) => {
      apiLayDsTinh({ limit: keys.limit, search_string: value }).then((res) => {
        if (!!res) {
          const ma_tinh_thanh = fieldTinh.id_tinh;
          const ten_tinh_thanh = fieldTinh.ten_tinh;
          const ma_hanh_chinh = fieldTinh.ma_tinh_byt;

          const data = res.result.map((item) => ({
            [ma_tinh_thanh]: item[ma_hanh_chinh],
            [ten_tinh_thanh]: item[ten_tinh_thanh],
          }));

          setDsTinhThanh(data);
        }
      });
    }, 1000),
    []
  );
  // Hàm xử lý lấy danh sách quận huyện
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuanHuyen = useCallback(
    debounce((value, MA_TINH_THANH) => {
      if (!!MA_TINH_THANH) {
        apiLayDsHuyen({
          limit: keys.limit,
          search_string: value,
          MA_TINH_THANH,
        }).then((res) => {
          if (!!res) {
            const ma_quan_huyen = fieldHuyen.id_huyen;
            const ten_quan_huyen = fieldHuyen.ten_huyen;
            const ma_hanh_chinh = fieldHuyen.ma_huyen_byt;

            const data = res.result.map((item) => ({
              [ma_quan_huyen]: item[ma_hanh_chinh],
              [ten_quan_huyen]: item[ten_quan_huyen],
            }));

            setDsQuanHuyen(data);
          }
        });
      }
    }, 1000),
    []
  );
  // Hàm xử lý lấy danh sách xã phường
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchXaPhuong = useCallback(
    debounce((value, MA_QUAN_HUYEN) => {
      if (!!MA_QUAN_HUYEN) {
        apiLayDsXaPhuong({
          limit: keys.limit,
          search_string: value,
          MA_QUAN_HUYEN,
        }).then((res) => {
          if (!!res) {
            const ma_xa_phuong = fieldXa.id_xa;
            const ma_hanh_chinh = fieldXa.ma_xa_byt;
            const ten_xa_phuong = fieldXa.ten_xa;
            const code_thx = fieldXa.code_thx;

            const data = res.result.map((item) => ({
              [ma_xa_phuong]: item[ma_hanh_chinh],
              [ten_xa_phuong]: item[ten_xa_phuong],
              [code_thx]: item[code_thx],
            }));

            setDsXaPhuong(data);
          }
        });
      }
    }, 1000),
    []
  );

  return {
    dsBenhNhan,
    dsDoiTuongBenhNhan,
    dsTinhThanh,
    dsQuanHuyen,
    dsXaPhuong,
    dsNgheNghiep,
    dsDanToc,
    dsQuocGia,
    resetState,
    searchBenhNhan,
    layDsDoiTuongBenhNhan,
    layDsNgheNghiep,
    layDsDanToc,
    searchQuocGia,
    searchTinhThanh,
    searchQuanHuyen,
    searchXaPhuong,
    setDsQuanHuyen,
    setDsXaPhuong,
    // searchTHX,
    // dsTHX,
    // totalPageTHX,
    // currentPageTHX,
    // loadMoreTHX
  };
};


const formatDate = (strDate) => {
  let format_string = 'DDMMYYYY';

  if (`${strDate}`.length === format_string.length) {
    return moment(strDate, format_string).format('YYYYMMDD')
  } else {
    return `${strDate}`;
  }
}

/**
 * Xử lý và làm sạch data bệnh nhân trước khi đẩy lên server
 * @param {!{}} patient_data data bệnh nhân
 */
export const formatPatientData = async (patient_data, doFormatAddress = true) => {
  /**
   * Format lại những data nào type Select trong form
   * Lưu ý: Hàm này chỉ chạy đúng NẾU sau khi chọn bệnh nhân cũ, các trường Select có name của Form.Item là key_name
   * @param {!string} key_id key của id giá trị
   * @param {!string} key_name key của tên giá trị
   * @param {!string} dm_key_id key id danh mục
   * @param {!string} dm_key_name key tên danh mục
   */
  const formatDataTypeSelect = (key_id, key_name, dm_key_id, dm_key_name) => {
    const value_name = patient_data[key_name];

    if (value_name) {
      /**
       * Điều kiện này xảy ra trong các trường hợp:
       * (-) chọn giá trị ngay từ đầu khi vào màn hình
       * (-) chọn giá trị sau khi fill data bệnh nhân cũ, sau đó người dùng chọn lại data mới
       */
      if (_.isObject(value_name)) {
        patient_data[key_id] = value_name[dm_key_id];
        patient_data[key_name] = value_name[dm_key_name];
      }
    } else {
      if (key_id !== "MA_DAN_TOC") {
        patient_data[key_id] = "";
        patient_data[key_name] = "";
      }
    }
  };

  let parsedValues = {};

  // parse tất cả các value dạng string json
  Object.keys(patient_data).forEach((key) => {
    const value = patient_data[key];

    // nếu !!data == true và data là string json thì parse chúng
    if (value && isJsonString(value) && !_.isNumber(value) && key !== "ID" && key !== "BENH_NHAN_ID") {
      parsedValues[key] = JSON.parse(value);
    } else if ((value === undefined || value === null) && key !== "MA_DAN_TOC") {
      parsedValues[key] = "";
    }
  });

  // ghi đè các data vừa chỉnh sửa ở trên
  patient_data = {
    ...patient_data,
    ...parsedValues,
  };

  if (doFormatAddress) {
    // format lại data tỉnh thành
    formatDataTypeSelect("MA_TINH_THANH", "TEN_TINH_THANH", fieldTinh.id_tinh, fieldTinh.ten_tinh);

    // format lại data quận huyện
    formatDataTypeSelect("MA_QUAN_HUYEN", "TEN_QUAN_HUYEN", fieldHuyen.id_huyen, fieldHuyen.ten_huyen);

    // format lại data phường xã
    formatDataTypeSelect("MA_PHUONG_XA", "TEN_PHUONG_XA", fieldXa.id_xa, fieldXa.ten_xa);

    // format lại data nghề nghiệp
    formatDataTypeSelect("MA_NGHE_NGHIEP", "NGHE_NGHIEP", fieldNgheNghiep.ma_nghe_nghiep, fieldNgheNghiep.ten_nghe_nghiep);

    // format lại data quốc gia
    formatDataTypeSelect("MA_QUOC_TICH", "QUOC_TICH", fieldQuocGia.ma_quoc_gia, fieldQuocGia.ten_quoc_gia);

    // format lại data dân tộc
    formatDataTypeSelect("MA_DAN_TOC", "DAN_TOC", fieldDantoc.id, fieldDantoc.ten_dan_toc);
  }

  // format lại data ngày sinh
  patient_data.NGAY_SINH = (`${patient_data.NGAY_SINH}`.length === 4) ? `${patient_data.NGAY_SINH}` : formatDate(`${patient_data.NGAY_SINH}`)


  // thêm họ của bệnh nhân
  patient_data.HO = patient_data.TEN.split(" ")[0];

  // convert sđt sang string
  patient_data.SO_DIEN_THOAI = patient_data.SO_DIEN_THOAI.toString();

  // cắt xén đi những trường thừa thãi
  patient_data = (await validFragment([patient_data], patientColumnStandard))[0];

  return patient_data;
};

/**
 * Format danh sách dịch vụ đã chọn
 * @param {!Object[]} ds_dich_vu danh sách dịch vụ đã chọn
 * @param {!Callback} setError hàm set lỗi nếu dịch vụ chưa chọn phòng thực hiện
 */
export const formatDanhSachDichVu = (ds_dich_vu, setError) => {
  return ds_dich_vu.map((item) => {
    if (!item.DV_KEM_THEO && !item.PHONG_THUC_HIEN_ID) {
      setError(true);
      return {};
    }

    return {
      ID: item?.old ? item?.ID : null,
      PHONG_ID: item.PHONG_THUC_HIEN_ID,
      DICH_VU_ID: item.DV_ID,
      SO_LUONG: item.SO_LUONG,
      GIA_DICH_VU: Number(item.GIA_DICH_VU),
      PHAN_LOAI: item.PHAN_LOAI,
      // KHOA_ID: item.PHONG_THUC_HIEN.KHOA_ID,
      UU_TIEN: 0,
      BAC_SI_ID: item?.BAC_SI?.NHANSU_ID,
      PHONG_CHI_DINH: i18n.t(languageKeys.phong_tiep_don),
      PHONG_THUC_HIEN_ID: item.PHONG_THUC_HIEN_ID,
      MIEN_GIAM_TIEP_DON: item.MIEN_GIAM_TIEP_DON || 0,
      DM_DOI_TUONG_ID: item.DM_DOI_TUONG_ID,
      DV_XN_NOI_THUC_HIEN_ID: item?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID || undefined,
      STT_VAO_KHAM: item?.STT_VAO_KHAM || null,
      dv_con:
        item?.children?.map((values) => {
          return ({
            DICH_VU_ID: values.DV_KEM_THEO_ID,
            DV_XN_NOI_THUC_HIEN_ID: values?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID,
            DV_CHA_ID: item.DV_ID,
            ID: values?.PDT_ID || undefined,
            STT_VAO_KHAM: values?.STT_VAO_KHAM || null
          })
        }) || undefined,
      TIEP_TAN_ID: item.TIEP_TAN_ID || item.NGUOI_TAO.NHANSU_ID,
      PDT_ID: item.PDT_ID,
      GIA_GOC_DICH_VU: item.GIA_GOC_DICH_VU,
    };
  });
};

export const formatDanhSachDichVuGoi = (ds_dich_vu, setError) => {
  return ds_dich_vu.map((item) => {
    try {
      if (!item.DV_KEM_THEO && !item.PHONG_THUC_HIEN_ID) {
        setError(true);
        return {};
      }
      const DV_XN_NOI_THUC_HIEN_ID = item?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID || undefined

      let result = {
        PHONG_THUC_HIEN_ID: item.PHONG_THUC_HIEN_ID,
        DV_ID: item.DV_ID || item.DICH_VU_ID,
        DICH_VU_ID: item.DICH_VU_ID || item.DV_ID,
        SO_LUONG: item.SO_LUONG,
        // KHOA_ID: item.KHOA_ID,
        PHONG_CHI_DINH: i18n.t(languageKeys.phong_tiep_don),
        UU_TIEN: 0,
        DON_GIA: checkNumber(+item.DON_GIA),
        THANH_TIEN: checkNumber(+item.DON_GIA * item.SO_LUONG),
        DON_GIA_PHAN_BO: checkNumber(+item.DON_GIA_PHAN_BO),
        THANH_TIEN_PHAN_BO: checkNumber(+item.DON_GIA_PHAN_BO * item.SO_LUONG),
        ID: item.ID,
        RLS_BN_GOI_DV_ID: item.RLS_BN_GOI_DV_ID,
        PHAN_LOAI: item.PHAN_LOAI,
        DV_KEM_THEO: item.DV_KEM_THEO,
        RLS_BN_GOI_ID: item.RLS_BN_GOI_ID,
        BAC_SI_ID: item?.BAC_SI?.NHANSU_ID,
        RLS_BN_GOI_DV_DVCON_ID: item.RLS_BN_GOI_DV_DVCON_ID,
        PDT_ID: item.PDT_ID,
        GOI_ID: item.GOI_ID,
        DM_DOI_TUONG_ID: item.DM_DOI_TUONG_ID,
        DV_XN_NOI_THUC_HIEN_ID,
        ID_DV_GOI: item.ID_DV_GOI,
        TIEP_TAN_ID: item.TIEP_TAN_ID || item.NGUOI_TAO.NHANSU_ID,
      }

      // if (item.DV_KEM_THEO) {
      //   result.RLS_BN_GOI_ID = item.RLS_BN_GOI_ID
      // } else if (!item.DV_KEM_THEO) {
      //   result.RLS_BN_GOI_DV_DVCON_ID = item.RLS_BN_GOI_DV_DVCON_ID
      // }
      return result;
    } catch (error) {
      HLog('Sai ở format đây này::', error);
    }
  });
};

const checkNumber = (value) => { return (Number.isNaN(+value) ? null : + value) }
export const patientColumnStandard = {
  BENH_NHAN_ID: "",
  MA_BENH_NHAN: "",
  ID: "",
  TEN: "",
  HO: "",
  MO_TA: "",
  LICH_KHAM_ID: "",
  MAT_KHAU: "",
  SO_DIEN_THOAI: "",
  EMAIL: "",
  NGAY_SINH: "",
  GIOI_TINH: "",
  DIA_CHI_CHI_TIET: "",
  MA_BHXH: "",
  CMTND: "",
  CCCD: "",
  HO_CHIEU: "",
  BANG_LAI_XE: "",
  NOI_CONG_TAC: "",
  TIEN_SU_CA_NHAN: "",
  TIEN_SU_GIA_DINH: "",
  DI_UNG: "",
  MA_TINH_THANH: "",
  TEN_TINH_THANH: "",
  MA_QUAN_HUYEN: "",
  TEN_QUAN_HUYEN: "",
  MA_PHUONG_XA: "",
  TEN_PHUONG_XA: "",
  MA_NGHE_NGHIEP: "",
  NGHE_NGHIEP: "",
  MA_DAN_TOC: "",
  DAN_TOC: "",
  MA_QUOC_TICH: "",
  QUOC_TICH: "",
  MA_VAN_HOA: "",
  VAN_HOA: "",
  MA_KHU_VUC: "",
  MA_TON_GIAO: "",
  TON_GIAO: "",
  TIEP_TAN_ID: "",
  TEN_MAU: "",
  IS_MAU: "",
  NGUON_KHACH_ID: "",
};
