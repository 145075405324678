import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Layout, notification, Row } from "antd";
import moment from "moment";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trash } from "../../../../../assets/svg";
import { ConfirmModal, Select } from "../../../../../components";
import { keys } from "../../../../../constants";
import {
  calcTotal,
  currencyParser,
  formatCurrency,
  formatNumberToPrice2,
  HLog,
  isEmptyObject,
  rid,
} from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import { huyDichVu, huyHoaDonTT, layDsDichVuChuaThanhToan, layGoiDichVuChuaThanhToan, layTamUng, luuHoaDonThanhToan_v2, luuHoaDonThanhToanGoiDv } from "../../apisDvThanhToan/apisDvThanhToan";
import style from "../../style.module.less";
import Constants from "../../../../../constants/Constants";
import { PlusCircleFilled, QrcodeOutlined } from "@ant-design/icons";
import { useEventListener, useFakeSyncronousState } from "../../../../../hooks";
import { keyViews } from "../../DichVuCanThanhToan";
import TextArea from "antd/lib/input/TextArea";
import { setLoading } from "../../../../../ducks/slices/snapshotSlice";
import { TRANG_THAI_THANH_TOAN } from "../../components/SiderBar";
import useDvChTT from "./DvChTT.hook";
import TableChTT from "./TableChTT";
import { doFetchBook } from "ducks/slices/ListData/SharedData.slice";
import { paymentMethodProto } from "constants/keys";
import _, { uniq } from "lodash";
import ModalQR from "./ModalQR";


const DichVuChuaThanhToan = forwardRef(
  (
    {
      tabView,
      // reloadSiderBar = () => {},
      currentPatient = null,
      onThemSo,
      onPrint = () => {},
      onAfterSubmit = () => {},
      setTabView = () => {},
      visibleDrawerSoHoaDon = false,
      isPayAllowed = false,
      isDeleteAllowed = false,
      isAddRevenueBookAllowed = true,
      advanceMode = false,
      hiddenHeader = false,
      styleMode,
      soCongNoId,
      handleOpenModalDebtList = () => {},
      scrollY = 0,
      setDisabledCongNo = () => {},
      onReloadPaid = () => {},
      forceFinish = false,
      setSelectedPatient = () => {},
      //
      editMode = false,
      skipEffect = false,
      editID,
    },
    ref
  ) => {

    const confirmRef = useRef();
    const QrRef = useRef();

    const [form] = Form.useForm();

    const userProfile = useSelector((state) => state.auth.user);
    const dataEvent = useSelector((state) => state.eventSlice.event);
    const loading = useSelector((state) => state.snapShot.loading);
    const dispatch = useDispatch();

    const [dataSource, setDataSource] = useState([]); // các dịch vụ chưa thanh toán
    const [selectedService, setSelectedService] = useState([]); // danh sách các dịch vụ được chọn
    const [cancelItem, setCancelItem] = useState();
    const [loadingDsDv, setLoadingDsDv] = useState(false);
    const [tienNhan, setTienNhan] = useState(0);
    //
    const { getState: print, setState: setPrint } = useFakeSyncronousState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [luuCongNo, setLuuCongNo] = useState(false)
    const forceRender = useState();
    const [expandedRow, setExpandedRow] = useState([]);
    //
    const [idTamUng, setIdTamUng] = useState();
    const [isTamUng, setIsTamUng] = useState(false)
    const [tienTamUng, setTienTamUng] = useState()

    const [thongTinTamUng, setThongTinTamUng] = useState()

    const Payment = Form.useWatch("list_dv_thanh_toan", form)
    const MIEN_GIAM = Form.useWatch("MIEN_GIAM", form)

    const {
      dsHinhThucThanhToan,
      dsSoHoaDon,
      formatDataSource,
      tongTienCanThanhToan,
      checkDuplicateMethod,
      handleSelectPaymentMethod,
      tongChiPhi,
      tongMienGiam,
      formatTempDataPrint,
    } = useDvChTT({ form, selectedService, setDisabledCongNo, dataSource, Payment, currentPatient })

    const ID_CASH = dsHinhThucThanhToan?.find(i => i?.PHAN_LOAI_HINH_THUC === paymentMethodProto.TIEN_MAT)?.ID

    useEffect(() => {
      !advanceMode && setTienNhan(Payment
        ?.reduce((acc, obj) => acc + (obj?.BENH_NHAN_DUA ? Number(obj.BENH_NHAN_DUA) : 0), 0)
        + (isTamUng ? tienTamUng : 0))
      if (Payment) {
        const previousPaymentMoneyTotal = Payment?.reduce((result, item) => (result += (item?.BENH_NHAN_DUA || 0)), 0)
        const newPaymentIndex = Payment?.findIndex(item => typeof item === 'undefined')
        if (newPaymentIndex !== -1) {

          if (isTamUng) {
            Payment[newPaymentIndex] = {
              BENH_NHAN_DUA: tongTienCanThanhToan() - previousPaymentMoneyTotal - tienTamUng
            }
          } else {
            Payment[newPaymentIndex] = {
              BENH_NHAN_DUA: tongTienCanThanhToan() - previousPaymentMoneyTotal
            }
          }
        }
        form.setFieldValue('list_dv_thanh_toan', Payment)
      }

    }, [Payment, selectedService, isTamUng])


    useImperativeHandle(ref, () => ({
      reloadSo: () => dispatch(doFetchBook({ LOAI_SO: 'SO_THU_TIEN' })),
      loadingBtn,
      onSubmit: (print) => {
        setPrint(print);
        !loadingBtn && handleSubmit();
      },
      handleSaveDebt: () => {
        if (!soCongNoId) {
          return notification.warning({
            message: i18n.t(languageKeys.vui_long_chon_so_cong_no)
          })
        }
        setPrint();
        setLuuCongNo(true)
        !loadingBtn && handleSubmit();
      },
      resetFieldPaymentMethod: () => form.resetFields(['list_dv_thanh_toan']),
      //
      setDataSource,
      setSelectedService,
      setExpandedRow,
      setLoadingDsDv,
      setFields: (data) => form.setFields(data),
    }));


    useEffect(() => {
      if (currentPatient && Object.keys(currentPatient).length) {
        if (!editMode) {
          layDsDichvu(currentPatient.BENH_NHAN_ID, currentPatient?.NGAY, currentPatient.LICH_KHAM_ID, currentPatient.LOAI, currentPatient.ID);
          form.resetFields(["MIEN_GIAM", "LY_DO_MIEN_GIAM", "list_dv_thanh_toan"]);
          handleGetTamUng()
        }
        setThongTinTamUng(null)
        setIsTamUng(false)
        setDisabledCongNo(false)
        form.setFieldValue("NGAY", moment())
      }
      return () => {
        setDataSource([]);
        setSelectedService([]);
        setPrint(false);
        setIdTamUng();
        setThongTinTamUng(null)
        setCancelItem();
        form.resetFields(["MIEN_GIAM", "LY_DO_MIEN_GIAM", "list_dv_thanh_toan"]);
        setIsTamUng(false)
        setDisabledCongNo(false)
        setTienTamUng()
        setLuuCongNo(false)
      };
    }, [currentPatient]);


    useEffect(() => {
      if (dsHinhThucThanhToan.length && !skipEffect) {
        if (advanceMode) {
          form.setFields([
            {
              name: "list_dv_thanh_toan",
              value: undefined
            },
          ]);
        } else {
          const defaultMethod = dsHinhThucThanhToan.find(i => [paymentMethodProto.TIEN_MAT].some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))
          form.setFields([
            {
              name: "list_dv_thanh_toan",
              value: [{
                HINH_THUC_THANH_TOAN: defaultMethod?.ID,
                TEN_HINH_THUC_THANH_TOAN: defaultMethod?.TEN_HINH_THUC_THANH_TOAN,
                BENH_NHAN_DUA: tongTienCanThanhToan(),
              }]
            },
            {
              name: "TIEN_KHACH_DUA",
              value: tongTienCanThanhToan()
            },
          ]);
        }
      }
    }, [dsHinhThucThanhToan, currentPatient, selectedService, tongMienGiam, tongChiPhi]);


    const handleChangeDataSource = useCallback(
      (callback) => {
        setDataSource(callback);
        setSelectedService(callback);
        forceRender[1](rid());
        //
        let tong_tien = 0;
        for (let i = 0; i < dataSource?.length; i++) {
          const item = dataSource[i];
          tong_tien = tong_tien + (item.SO_LUONG || 0) * Number(item.DON_GIA || 0) - Number(item["MIEN_GIAM_DICH_VU"] || 0);
        }
      },
      [dataSource]
    );


    const keydownEvent = (event) => {
      const stopDefaultEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };
      switch (tabView) {
        case keyViews.CHUA_THANH_TOAN:
          if (event.ctrlKey && event.key === "l") {
            stopDefaultEvent(event);
            if (!isPayAllowed || loading) return;
            handleSubmit();
            setPrint(true);
          }
          if (event.ctrlKey && event.key === "s" && !visibleDrawerSoHoaDon) {
            stopDefaultEvent(event);
            if (!isPayAllowed || loading) return;
            handleSubmit();
            setPrint(false);
          }
          if (event.ctrlKey && event.key === "b" && !visibleDrawerSoHoaDon) {
            stopDefaultEvent(event);
            if (!isPayAllowed) return;
            handleOpenModalDebtList()
            setPrint(false);
          }
          break;
        case keyViews.DA_HUY:
          break;
        default:
          break;
      }
    };
    useEventListener("keydown", keydownEvent, window.document, tabView === keyViews.CHUA_THANH_TOAN);


    useEffect(() => {
      reloadDetail(dataEvent);
    }, [dataEvent]);


    //hàm xử lí reload chi tiết cuộc hẹn ở màn thanh toán, trường hợp bác sĩ thêm CLS mà đang chọn đối tượng đó
    const reloadDetail = (event) => {
      HLog("DichVuCanThanhToan reloadDetail event: ", event);
      if (event && !isEmptyObject(event)) {
        let { type, LICH_KHAM_ID } = event;

        switch (type) {
          case Constants.EVENT_LUOT_DIEU_TRI_CHI_DINH_CLS: //khi tiếp tân nhấn nút thanh toán
          case Constants.CUOC_KHAM_CHI_DINH_CLS:
          case Constants.XOA_LUOT_DIEU_TRI:
          case Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI:
          case Constants.CUOC_KHAM_DA_THANH_TOAN:
            if (currentPatient && !isEmptyObject(currentPatient)) {
              let ID_Lich_Kham = currentPatient.LICH_KHAM_ID;
              if (LICH_KHAM_ID === ID_Lich_Kham) {
                layDsDichvu(currentPatient.BENH_NHAN_ID, currentPatient.NGAY, LICH_KHAM_ID, currentPatient.LOAI, currentPatient.ID);
              }
            }
            break;
          default:
            break;
        }
      }
    };


    const handleSubmit = async () => {
      try {
        if (!currentPatient || selectedService?.length < 1) {
          notification.error({
            message: i18n.t(languageKeys.noti_Chua_chon_benh_nhan_dv_thanh_toan),
          });
          return;
        }
        if (tongMienGiam > tongChiPhi) {
          notification.error({
            message: i18n.t(languageKeys.error_Gia_tri_mien_giam_ko_hop_le),
            placement: "bottomLeft",
          });
          return;
        }
        if (editMode) {
          dispatch(setLoading(true));
          const res = await huyHoaDonTT({
            HOA_DON_ID: editID,
            NGUOI_HUY: userProfile?.NHANSU_ID,
            LY_DO_HUY: i18n.t(languageKeys.field_chinh_sua_phieu)
          })
          if (!res) {
            dispatch(setLoading(false));
            return notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: 'bottomLeft' })
          }
        }
        form.submit();
      } catch (error) {
        HLog(error);
      }
    };


    //   hàm xử lý lấy danh sách dịch vụ
    async function layDsDichvu(BENH_NHAN_ID, filterDate, LICH_KHAM_ID, LOAI, ID) {
      //setDataSource([]);
      setLoadingDsDv(true);
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        BENH_NHAN_ID,
        LICH_KHAM_ID,
        NGAY: filterDate ? filterDate : moment().format("YYYYMMDD"),
        ID,
        // IS_GET_DV_GOI: 0,
      };
      if (LOAI === "THANH_TOAN_KHAM_BENH" || LOAI === "THANH_TOAN_KHAM_GOI") {
        const res = await layDsDichVuChuaThanhToan(dataRequest);
        if (res) {
          const data = res.result.map((item) => ({ ...item, key: rid() }));

          let _prevDataSource = data
            .filter((item) => item.TRANG_THAI !== "HUY" && item.THANH_TOAN === 0)
            .map((item) => ({
              ...item,
              //
              MIEN_GIAM_DICH_VU: (/*LOAI === "THANH_TOAN_KHAM_GOI" &&*/ item.IS_GOI_DV)
                ? (item.DON_GIA * item.SO_LUONG)
                : (item.MIEN_GIAM_TIEP_DON
                  ? (item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON))
                  : 0),
              //
              GIAM_TIEN: (/*LOAI === "THANH_TOAN_KHAM_GOI" &&*/ item.IS_GOI_DV)
                ? (item.DON_GIA * item.SO_LUONG)
                : (item.MIEN_GIAM_TIEP_DON
                  ? (item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON))
                  : 0),
              //
              TONG_TIEN_GIAM: (/*LOAI === "THANH_TOAN_KHAM_GOI" &&*/ item.IS_GOI_DV)
                ? (item.DON_GIA * item.SO_LUONG)
                : (item.MIEN_GIAM_TIEP_DON
                  ? (item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON))
                  : 0),
              //
              GIAM_PHAN_TRAM: (/*LOAI === "THANH_TOAN_KHAM_GOI" &&*/ item.IS_GOI_DV)
                ? (item.DON_GIA * item.SO_LUONG)
                : (item.MIEN_GIAM_TIEP_DON
                  ? (item.MIEN_GIAM_TIEP_DON <= 100 ? item.MIEN_GIAM_TIEP_DON : 0)
                  : 0),
            }));

          form.setFields([
            {
              name: "MIEN_GIAM",
              value: data?.[0]?.MIEN_GIAM_TONG_HOA_DON
            },
            {
              name: "LY_DO_MIEN_GIAM",
              value: uniq(data?.flatMap(i => i.MA_GOI_DV ?? []) || [])?.join(", ")
            },
          ])
          setDataSource(_prevDataSource);
          setSelectedService(_prevDataSource);
          setExpandedRow(['KHAM_DICH_VU', 'KHAM_GOI_DICH_VU'])
        }
      }
      if (LOAI === "THANH_TOAN_MUA_GOI") {
        const res = await layGoiDichVuChuaThanhToan(dataRequest);
        if (res) {
          const data = res.result.DS_GOI?.map(item => ({
            ...item,
            key: item.ID,
            DON_GIA: item.TONG_TIEN_PHAN_BO,
            SO_LUONG: 1,
            MIEN_GIAM_DICH_VU: item.MIEN_GIAM > 100 ? item.MIEN_GIAM : (item.TONG_TIEN_PHAN_BO / 100 * item.MIEN_GIAM) || 0,
            GIAM_TIEN: item.MIEN_GIAM > 100 ? item.MIEN_GIAM : (item.TONG_TIEN_PHAN_BO / 100 * item.MIEN_GIAM) || 0,
            TONG_TIEN_GIAM: item.MIEN_GIAM > 100 ? item.MIEN_GIAM : (item.TONG_TIEN_PHAN_BO / 100 * item.MIEN_GIAM) || 0,
            GIAM_PHAN_TRAM: item.MIEN_GIAM <= 100 ? item.MIEN_GIAM : 0,
          }))
          setDataSource(data);
          setSelectedService(data);
          setExpandedRow(data?.flatMap(i => i.key))
        }
      }
      setLoadingDsDv(false);
    }

    //   Hàm xử lý hủy dịch vụ
    async function handleCancelItem() {
      if (!cancelItem) {
        return;
      }
      confirmRef.current.loading(true);
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        data: [
          {
            ID: cancelItem.ID,
            LUOT_DIEU_TRI_ID: cancelItem.LUOT_DIEU_TRI_ID,
            PHONG_ID: cancelItem.PHONG_ID,
            LO_KHAM_ID: cancelItem.LO_KHAM_ID,
          },
        ],
      };
      try {
        let response = await huyDichVu(dataRequest);
        if (response) {
          confirmRef.current.close();

          layDsDichvu(currentPatient.BENH_NHAN_ID, currentPatient.NGAY, currentPatient.LICH_KHAM_ID, currentPatient.LOAI, currentPatient.ID);
        }
      } catch (error) {
        HLog("huy eerrror", error);
        confirmRef.current.loading(false);
      }
    }


    // khi chọn dịch vụ tính toán tiền
    function tinhToanTien() {
      // tính tổng tiền chưa giảm
      let tong_tien = 0;
      for (let index = 0; index < selectedService?.length; index++) {
        const item = selectedService[index];
        tong_tien = tong_tien + (item.SO_LUONG || 0) * Number(item.DON_GIA || 0) - Number(item["MIEN_GIAM_DICH_VU"] || 0);
      }
    }


    // Lưu thanh toán
    async function onThanhToan(value) {

      let ngayGioThanhToan = {
        NGAY: value?.NGAY ? moment(value?.NGAY).format("YYYYMMDD") : moment().format("HH:mm"),
        GIO: value?.NGAY ? moment(value?.NGAY).format("HH:mm") : moment().format("HH:mm")
      }

      // Check thanh toán QR
      const QR = Payment?.find(i => i?.PHAN_LOAI_HINH_THUC === "QUET_MA_QR")
      if (QR && currentPatient.LOAI !== "THANH_TOAN_MUA_GOI") {
        QrRef.current.open(QR.BENH_NHAN_DUA, print())
        return;
      }

      // Thanh toán bình thường

      dispatch(setLoading(true));

      let SO_HOADON_ID = value.SO_HOADON_ID;
      let LY_DO_MIEN_GIAM = value.LY_DO_MIEN_GIAM;
      let GHI_CHU = value.GHI_CHU;

      const tong = selectedService?.reduce((acc, curr) => {
        return acc + Number(curr.SO_LUONG || 0) * Number(curr.DON_GIA || 0) - Number(curr.GIAM_TIEN || 0);
      }, 0);

      try {
        let list_dv;
        list_dv = selectedService.map((item) => {
          const THANH_TIEN = Number(item.SO_LUONG) * Number(item.DON_GIA) - Number(item.MIEN_GIAM_DICH_VU)
          if (item.IS_GOI_DV) {
            return { ...item, THANH_TIEN }
          } else {
            return {
              ...item,
              //
              // MIEN_GIAM_DICH_VU: value.MIEN_GIAM
              //   ? value.MIEN_GIAM > 100
              //     ? Math.round(item.MIEN_GIAM_DICH_VU + (THANH_TIEN * value.MIEN_GIAM / tong))
              //     : Math.round(item.MIEN_GIAM_DICH_VU + (THANH_TIEN / 100 * value.MIEN_GIAM))
              //   : item.MIEN_GIAM_DICH_VU,
              //
              GIAM_TIEN: value.MIEN_GIAM
                ? value.MIEN_GIAM > 100
                  ? Math.round(item.GIAM_TIEN + (THANH_TIEN * value.MIEN_GIAM / tong))
                  : Math.round(item.GIAM_TIEN + (THANH_TIEN / 100 * value.MIEN_GIAM))
                : item.GIAM_TIEN,
              //
              TONG_TIEN_GIAM: value.MIEN_GIAM
                ? value.MIEN_GIAM > 100
                  ? Math.round(item.TONG_TIEN_GIAM + (THANH_TIEN * value.MIEN_GIAM / tong))
                  : Math.round(item.TONG_TIEN_GIAM + (THANH_TIEN / 100 * value.MIEN_GIAM))
                : item.TONG_TIEN_GIAM,
              //
              GIAM_PHAN_TRAM: value.MIEN_GIAM ? 0 : item.GIAM_PHAN_TRAM,
              //
              THANH_TIEN,
              MIEN_GIAM_TIEP_DON: item.GIAM_PHAN_TRAM > 0 ? item.GIAM_PHAN_TRAM : item.MIEN_GIAM_DICH_VU,
              GIA_DICH_VU: item.DON_GIA,
              //
              TONG_DA_THANH_TOAN: luuCongNo
                ? null
                : Number(item.SO_LUONG) * Number(item.DON_GIA) - (
                  value.MIEN_GIAM
                    ? value.MIEN_GIAM > 100
                      ? Math.round(item.TONG_TIEN_GIAM + (THANH_TIEN * value.MIEN_GIAM / tong))
                      : Math.round(item.TONG_TIEN_GIAM + (THANH_TIEN / 100 * value.MIEN_GIAM))
                    : item.TONG_TIEN_GIAM
                ),
            }
          }
        }).filter(i => !i.children);

        // Miễn giảm tổng
        let MIEN_GIAM = calcTotal(selectedService, "GIAM_TIEN") + (
          value.MIEN_GIAM
            ? value.MIEN_GIAM > 100
              ? value.MIEN_GIAM
              : calcTotal(list_dv, "THANH_TIEN") / 100 * value.MIEN_GIAM
            : 0
        )

        // Xử lý chia bị lẻ
        const tempExp = calcTotal(list_dv, "GIAM_TIEN")
        if (MIEN_GIAM !== tempExp && currentPatient.LOAI !== "THANH_TOAN_MUA_GOI") {
          const record = list_dv.at(-1)
          list_dv[list_dv.length - 1] = {
            ...record,
            // MIEN_GIAM_DICH_VU: record.MIEN_GIAM_DICH_VU + (MIEN_GIAM - tempExp),
            GIAM_TIEN: record.GIAM_TIEN + (MIEN_GIAM - tempExp),
            TONG_TIEN_GIAM: record.TONG_TIEN_GIAM + (MIEN_GIAM - tempExp),
          }
        }

        // HTTT
        let list_dv_thanh_toan = value.list_dv_thanh_toan
        if (isTamUng && tienTamUng > 0) {
          const payload = {
            HINH_THUC_THANH_TOAN: dsHinhThucThanhToan.find(i => i?.PHAN_LOAI_HINH_THUC?.includes?.(paymentMethodProto.TAM_UNG))?.ID,
            TEN_HINH_THUC_THANH_TOAN: "TAM_UNG",
            BENH_NHAN_DUA: tienTamUng
          }
          list_dv_thanh_toan = list_dv_thanh_toan
            ? [...list_dv_thanh_toan, payload].filter(i => i.BENH_NHAN_DUA && i.HINH_THUC_THANH_TOAN)
            : [payload]
        }
        if (advanceMode) {
          list_dv_thanh_toan = [{
            HINH_THUC_THANH_TOAN: dsHinhThucThanhToan.find(i => i?.PHAN_LOAI_HINH_THUC?.includes?.(paymentMethodProto.TAM_UNG))?.ID,
            TEN_HINH_THUC_THANH_TOAN: "TAM_UNG",
            BENH_NHAN_DUA: tienTamUng
          }]
        }
        if (luuCongNo) {
          list_dv_thanh_toan = [{
            HINH_THUC_THANH_TOAN: dsHinhThucThanhToan.find(i => i?.PHAN_LOAI_HINH_THUC?.includes?.(paymentMethodProto.CONG_NO))?.ID,
            TEN_HINH_THUC_THANH_TOAN: "CONG_NO",
            BENH_NHAN_DUA: 0
          }]
        }

        // Đm khách nhiễu vkloz
        if (!luuCongNo && tienNhan !== tongTienCanThanhToan()) {
          let tien_con_lai = tienNhan
          const sort_list_dv = _.orderBy(list_dv, ['TONG_DA_THANH_TOAN'], ['asc'])
          sort_list_dv.forEach(item => {
            if (tien_con_lai < item.TONG_DA_THANH_TOAN) {
              const idx = list_dv.findIndex(i => i.ID === item.ID)
              list_dv[idx].TONG_DA_THANH_TOAN = tien_con_lai
            }
            tien_con_lai = Math.max(0, tien_con_lai - item.TONG_DA_THANH_TOAN)
          })
        }

        let dataRequest = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          list_dv: list_dv?.map(list_dv_detail => ({ ...list_dv_detail, ...ngayGioThanhToan })),
          SO_HOADON_ID: luuCongNo ? soCongNoId : SO_HOADON_ID,
          BENH_NHAN_ID: currentPatient.BENH_NHAN_ID,
          TONG_CHI_PHI: tongChiPhi,
          MIEN_GIAM,
          TONG_THANH_TOAN: tongTienCanThanhToan(),
          NHANSU_ID: userProfile.NHANSU_ID,
          LICH_KHAM_ID: currentPatient.LICH_KHAM_ID,
          NHOM: luuCongNo ? 'CONG_NO' : "THANH_TOAN",
          LY_DO_MIEN_GIAM,
          GHI_CHU,
          MIEN_GIAM_PHAN_TRAM: null,
          PHAN_LOAI: luuCongNo ? 'CONG_NO' : "THANH_TOAN",
          TIEN_THUA: luuCongNo ? 0 : tinhTienThua,
          BENH_NHAN_DUA: luuCongNo ? 0 : tienNhan,
          // PHIEU_UNG_ID: advanceMode ? currentPatient.PHIEU_UNG_ID : null,
          PHIEU_UNG_ID: (isTamUng || advanceMode) ? (currentPatient.PHIEU_UNG_ID || thongTinTamUng?.PHIEU_UNG_ID) : null,
          list_dv_thanh_toan,
          SO_TAMUNG_ID: (isTamUng || advanceMode) ? idTamUng : null,
          MIEN_GIAM_TONG_HOA_DON: value.MIEN_GIAM
            ? value.MIEN_GIAM > 100
              ? value.MIEN_GIAM
              : calcTotal(list_dv, "THANH_TIEN") / 100 * value.MIEN_GIAM
            : 0,
          TIEN_TT_THUC_TE: value.TIEN_KHACH_DUA,
          ...ngayGioThanhToan
        };

        HLog("submit_dich_vu::::: ", dataRequest);

        let response
        if (currentPatient.LOAI === "THANH_TOAN_MUA_GOI") {

          let listTienNhan = {}
          selectedService.forEach(item => {
            let TONG_THANH_TOAN = item.TONG_TIEN_PHAN_BO - tongMienGiam
            let TINH_TIEN_NHAN = (advanceMode ? tienTamUng : tienNhan) - Object.values(listTienNhan).reduce((acc, curr) => acc + curr, 0)
            if (TINH_TIEN_NHAN < TONG_THANH_TOAN) {
              listTienNhan[item.GOI_DV_ID] = TINH_TIEN_NHAN
            } else {
              listTienNhan[item.GOI_DV_ID] = TONG_THANH_TOAN
            }
          })

          let listHTTT = {}
          selectedService.forEach(item => {
            let TIEN_NHAN = listTienNhan[item.GOI_DV_ID]

            listHTTT[item.GOI_DV_ID] = []

            list_dv_thanh_toan.forEach(httt => {

              let CURRENT_TOTAL = listHTTT[item.GOI_DV_ID]?.reduce((acc, curr) => acc + curr.BENH_NHAN_DUA, 0)

              if (CURRENT_TOTAL >= TIEN_NHAN) {
                return listHTTT[item.GOI_DV_ID].push({
                  ...httt,
                  BENH_NHAN_DUA: 0,
                })
              }

              let TONG_TIEN_HTTT = Object.values(listHTTT).flat(1)
                .filter(i => i.HINH_THUC_THANH_TOAN === httt.HINH_THUC_THANH_TOAN)
                .reduce((acc, curr) => acc + curr.BENH_NHAN_DUA, 0)

              let TINH_TIEN_HTTT = httt.BENH_NHAN_DUA - TONG_TIEN_HTTT

              if (CURRENT_TOTAL + TINH_TIEN_HTTT >= TIEN_NHAN) {
                return listHTTT[item.GOI_DV_ID].push({
                  ...httt,
                  BENH_NHAN_DUA: TIEN_NHAN - CURRENT_TOTAL,
                })
              }

              if (TINH_TIEN_HTTT < TIEN_NHAN) {
                return listHTTT[item.GOI_DV_ID].push({
                  ...httt,
                  BENH_NHAN_DUA: TINH_TIEN_HTTT,
                  log: 2
                })
              } else {
                return listHTTT[item.GOI_DV_ID].push({
                  ...httt,
                  BENH_NHAN_DUA: TIEN_NHAN,
                  log: 1
                })
              }
            })
          })

          let dataPayload = {
            DS_GOI: selectedService.map((item, index) => {
              //
              let list_dv = item.DS_DV.map(i => {
                const exemp = item.MIEN_GIAM_DICH_VU
                  ? item.GIAM_PHAN_TRAM > 0
                    ? Math.round(i.MIEN_GIAM + ((i.THANH_TIEN_PHAN_BO - i.MIEN_GIAM) / 100 * item.GIAM_PHAN_TRAM))
                    : Math.round((item.MIEN_GIAM_DICH_VU + calcTotal(item.DS_DV, "MIEN_GIAM")) * parseFloat(i.TY_LE) / 100)
                  : i.MIEN_GIAM
                return {
                  ...i,
                  MIEN_GIAM_DICH_VU: exemp,
                  GIAM_TIEN: exemp,
                  TONG_TIEN_GIAM: exemp,
                  GIAM_PHAN_TRAM: 0,
                  // THANH_TIEN: i.DON_GIA_PHAN_BO - exemp,
                  PHONG_DIEU_TRI_ID: null,
                }
              })
              // Lẻ miễn giảm
              const totalEx = calcTotal(list_dv, "MIEN_GIAM") + (
                item.GIAM_PHAN_TRAM > 0
                  ? (item.TONG_TIEN_PHAN_BO - calcTotal(list_dv, "MIEN_GIAM")) / 100 * item.GIAM_PHAN_TRAM
                  : item.MIEN_GIAM_DICH_VU
              )
              const currEx = calcTotal(list_dv, "MIEN_GIAM_DICH_VU")
              if (totalEx !== currEx) {
                const mien_giam_moi = list_dv[0].MIEN_GIAM_DICH_VU + (totalEx - currEx)
                list_dv[0] = {
                  ...list_dv[0],
                  MIEN_GIAM_DICH_VU: mien_giam_moi,
                  GIAM_TIEN: mien_giam_moi,
                  TONG_TIEN_GIAM: mien_giam_moi,
                  // THANH_TIEN: list_dv[0].DON_GIA_PHAN_BO - mien_giam_moi,
                }
              }
              // Return nè
              return {
                list_dv: list_dv?.map(list_dv_detail => ({ ...list_dv_detail, ...ngayGioThanhToan })),
                SO_HOADON_ID,
                BENH_NHAN_ID: currentPatient.BENH_NHAN_ID,
                TONG_CHI_PHI: item.TONG_TIEN_PHAN_BO,
                MIEN_GIAM: tongMienGiam,
                TONG_THANH_TOAN: item.TONG_TIEN_PHAN_BO - tongMienGiam,
                NHANSU_ID: userProfile.NHANSU_ID,
                NHOM: luuCongNo ? 'CONG_NO' : "THANH_TOAN",
                LY_DO_MIEN_GIAM: selectedService.length === 1 ? LY_DO_MIEN_GIAM : item.LY_DO_MIEN_GIAM,
                GHI_CHU,
                // MIEN_GIAM_PHAN_TRAM: null,
                PHAN_LOAI: luuCongNo ? 'CONG_NO' : "THANH_TOAN",
                TIEN_THUA: index === 0
                  ? (luuCongNo ? 0 : tinhTienThua)
                  : 0,
                BENH_NHAN_DUA: luuCongNo ? 0 : listTienNhan[item.GOI_DV_ID],
                // PHIEU_UNG_ID: advanceMode ? currentPatient.PHIEU_UNG_ID : null,
                PHIEU_UNG_ID: (isTamUng || advanceMode) ? (currentPatient.PHIEU_UNG_ID || thongTinTamUng?.PHIEU_UNG_ID) : null,
                GOI_DV_ID: item.GOI_DV_ID,
                list_dv_thanh_toan: luuCongNo ? list_dv_thanh_toan : listHTTT[item.GOI_DV_ID],
                SO_TAMUNG_ID: (isTamUng || advanceMode) ? idTamUng : null,
                NHOM_GOI: item.NHOM_GOI,
                TIEN_TT_THUC_TE: value.TIEN_KHACH_DUA,
                ...ngayGioThanhToan
              }
            }),
            ...ngayGioThanhToan
          }
          response = await luuHoaDonThanhToanGoiDv(dataPayload);

        } else {
          response = await luuHoaDonThanhToan_v2(dataRequest);
        }
        if (response.status === "OK") {
          dispatch(setLoading(false));
          notification.success({
            message: i18n.t(languageKeys.thao_tac_thanh_cong),
          });
          form.resetFields(["mien_giam", "LY_DO_MIEN_GIAM"]);
          // reloadSiderBar();
          //setDsDichVu([]);
          if (print()) {
            onPrint(currentPatient.LOAI === "THANH_TOAN_MUA_GOI"
              ? response.result.flatMap(i => i.HOA_DON_ID)
              : response.HOA_DON_ID,
              currentPatient.LOAI === "THANH_TOAN_MUA_GOI");
          }

          setSelectedService([]);
          setDataSource([]);
          setTienNhan(0);
          setIdTamUng();
          setThongTinTamUng(null)
          if (!editMode) {
            await layDsDichvu(currentPatient.BENH_NHAN_ID, currentPatient.NGAY, currentPatient.LICH_KHAM_ID, currentPatient.LOAI, currentPatient.ID);
          }
          // setSelectedPatient()
          setTabView(keyViews.DA_THANH_TOAN);

          if (forceFinish || (!!dataSource && !!selectedService) && dataSource?.length === selectedService?.length) {
            onAfterSubmit(TRANG_THAI_THANH_TOAN.DA_THANH_TOAN);
            // setSelectedPatient({
            //   ...currentPatient,
            //   TOTAL_DA_THANH_TOAN: currentPatient.TOTAL_DA_THANH_TOAN + (currentPatient.LOAI === "THANH_TOAN_MUA_GOI" ? selectedService.length : 1)
            // })
          }
          onReloadPaid();

          //   daThanhToanRef.current?.reloadList();
        } else if (response.status === "KO" && response.error_code === "003") {
          HLog(response.message);
          notification.error({
            message: i18n.t(languageKeys.vienphi_so_hd_day),
            placement: "bottomLeft",
          });
          dispatch(doFetchBook({ LOAI_SO: 'SO_THU_TIEN' }))

        } else if (response.status === 'KO' && response.error_code === "017") {
          notification.error({
            message: i18n.t(languageKeys.dich_vu_da_thanh_toan),
            placement: "bottomLeft",
          })
          onAfterSubmit(TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN);
          setSelectedPatient()
        }

      } catch (error) {
        HLog("error THanh toan", error);
      } finally {
        setLuuCongNo(false)
        setLoadingBtn(false);
        dispatch(setLoading(false));
      }
    }


    // khi chọn dịch vụ
    useEffect(() => {
      if (selectedService?.length > 0) {
        tinhToanTien();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedService, dataSource]);


    const handleGetTamUng = async () => {
      dispatch(setLoading(true));
      try {
        const res = await layTamUng({ BENH_NHAN_ID: currentPatient.BENH_NHAN_ID })
        if (!!res) {
          setIdTamUng(res?.result?.SO_TAMUNG_ID)
          setTienTamUng(Number(res.result?.TOTAL_TAM_UNG))
          setThongTinTamUng(res?.result)
          advanceMode && setTienNhan(Number(res.result?.TOTAL_TAM_UNG))
        }
      } catch (error) {
        HLog("error lay tam ung", error);
      } finally {
        dispatch(setLoading(false));
      }
    }


    const handleCheckTamUng = (e) => {
      setIsTamUng(e.target.checked)
      const tongTT = tongTienCanThanhToan()
      if (e.target.checked) {
        setTienNhan(tienNhan + tienTamUng)
        const defaultMethod = dsHinhThucThanhToan.find(i => [paymentMethodProto.TIEN_MAT].some(txt => i?.PHAN_LOAI_HINH_THUC?.includes(txt)))
        if (tienTamUng > tongTT) {
          form.setFields([
            {
              name: "list_dv_thanh_toan",
              value: [{
                HINH_THUC_THANH_TOAN: defaultMethod?.ID,
                TEN_HINH_THUC_THANH_TOAN: defaultMethod?.TEN_HINH_THUC_THANH_TOAN,
                BENH_NHAN_DUA: undefined,
              }]
            },
            {
              name: 'TIEN_KHACH_DUA',
              value: 0
            }
          ]);
        } else {
          form.setFields([
            {
              name: "list_dv_thanh_toan",
              value: [{
                HINH_THUC_THANH_TOAN: defaultMethod?.ID,
                TEN_HINH_THUC_THANH_TOAN: defaultMethod?.TEN_HINH_THUC_THANH_TOAN,
                BENH_NHAN_DUA: tongTT - tienTamUng,
              }]
            },
            {
              name: 'TIEN_KHACH_DUA',
              value: tongTT - tienTamUng
            }
          ]);
        }
      } else {
        setTienNhan(tienNhan - tienTamUng)
        const defaultMethod = dsHinhThucThanhToan.find(i => [paymentMethodProto.TIEN_MAT].some(txt => i?.PHAN_LOAI_HINH_THUC?.includes(txt)))
        form.setFields([
          {
            name: "list_dv_thanh_toan",
            value: [{
              HINH_THUC_THANH_TOAN: defaultMethod?.ID,
              TEN_HINH_THUC_THANH_TOAN: defaultMethod?.TEN_HINH_THUC_THANH_TOAN,
              BENH_NHAN_DUA: tongTT,
            }]
          },
          {
            name: 'TIEN_KHACH_DUA',
            value: tongTT
          }
        ]);
      }
    }


    const handleCheckAddPayment = () => {
      let isDisableAddPayment = false
      const tongTT = tongTienCanThanhToan()
      if (Payment?.reduce((total, item) => total += (item?.BENH_NHAN_DUA || 0), 0) >= tongTT) {
        isDisableAddPayment = true
      } else if (isTamUng && Payment?.reduce((total, item) => total += (item?.BENH_NHAN_DUA || 0), tienTamUng) >= tongTT) {
        isDisableAddPayment = true
      }
      return isDisableAddPayment
    }


    const value_TIEN_KHACH_DUA = Form.useWatch(['TIEN_KHACH_DUA'], form);
    const value_list_dv_thanh_toan = Form.useWatch(['list_dv_thanh_toan'], form);

    const tinhTienThua = useMemo(() => {
      const tienThua = (
        form.getFieldValue('TIEN_KHACH_DUA')
        - form.getFieldValue('list_dv_thanh_toan')
          ?.find(i => i?.HINH_THUC_THANH_TOAN === ID_CASH)
          ?.BENH_NHAN_DUA
      )
      return tienThua < 0 ? 0 : tienThua
    }, [value_TIEN_KHACH_DUA, value_list_dv_thanh_toan])


    //
    // RETURN HTML
    //
    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------HTML----------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------------------------------------


    return (
      <>

        <TableChTT
          loadingDsDv={loadingDsDv}
          hiddenHeader={hiddenHeader}
          styleMode={styleMode}
          advanceMode={advanceMode}
          dataSource={dataSource}
          handleChangeDataSource={handleChangeDataSource}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          scrollY={scrollY}
          currentPatient={currentPatient}
          isDeleteAllowed={isDeleteAllowed}
          handleDelete={(record) => {
            setCancelItem(record);
            confirmRef.current.open();
          }}
          // configThanhToan={configThanhToan}
          formatDataSource={formatDataSource}
          expandedRow={expandedRow}
          setExpandedRow={setExpandedRow}
        />

        <Layout.Sider theme="light" className={style["payment-sider"]} width={210}>
          <Form form={form} onFinish={onThanhToan} layout="vertical">
            <h2 style={{ fontWeight: 700, fontSize: 16, padding: "6px 12px" }}>{i18n.t(languageKeys.field_Thanh_toan)}</h2>
            <Divider style={{ margin: "0" }} />

            <Row justify="space-between" align="middle" className={style["sider-item"]}>
              <Col className={style["title-money"]}>
                <label>{i18n.t(languageKeys.field_tong_tien)}:</label>
              </Col>
              <Col className={style["money-special"]} style={{ color: "#2c3782" }}>
                {formatCurrency(Math.round(tongChiPhi), "đ", true)}
              </Col>
            </Row>

            {currentPatient?.LOAI === "THANH_TOAN_KHAM_BENH"
              ? (
                <Row justify="space-between" align="middle" className={style["sider-item"]}>
                  <Form.Item name="MIEN_GIAM" label={i18n.t(languageKeys.vien_phi_mien_giam)} style={{ margin: 0 }}>
                    <InputNumber
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      controls={false}
                      max={tongChiPhi - calcTotal(selectedService, "GIAM_TIEN")}
                      min={0}
                      addonAfter={MIEN_GIAM > 100 ? "đ" : "%"}
                    />
                  </Form.Item>
                </Row>
              )
              : <></>
            }

            <Row className={style["sider-item"]} style={{ marginBottom: -5 }}>
              <label className={style["title-money"]} style={{ marginBottom: 12 }}>
                {i18n.t(languageKeys.field_Ly_do_mien_giam)}{" "}
              </label>
              <Form.Item name="LY_DO_MIEN_GIAM" style={{ width: "100%" }}>
                <Input
                  className={style["input"]}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  style={{ width: "100%" }}
                  disabled={currentPatient?.LOAI === "THANH_TOAN_MUA_GOI" && selectedService?.length > 1}
                />
              </Form.Item>
            </Row>

            <Row justify="space-between" align="middle" className={style["sider-item"]}>
              <Col className={style["title-money"]}>
                <label>{i18n.t(languageKeys.vienphi_tong_mien_giam)}:</label>
              </Col>
              <Col className={style["money-special"]} style={{ color: "#2c3782" }}>
                {formatCurrency(Math.round(tongMienGiam), "đ", true)}
              </Col>
            </Row>

            <Row justify="space-between" align="middle" className={style["sider-item"]}>
              <Col className={style["title-money"]}>
                <label>{i18n.t(languageKeys.vienphi_tong_tt)}:</label>
              </Col>
              <Col className={style["money-special"]}>
                {formatCurrency(tongTienCanThanhToan(), "đ", true)}
              </Col>
            </Row>

            <Divider style={{ margin: "0" }} />

            <Row gutter={10} justify="space-between" className={style["sider-item"]}>
              <Row>

                {tienTamUng > 0 && (
                  <>
                    <Col span={24} style={{ margin: 5 }}>
                      <Checkbox
                        checked={isTamUng || advanceMode}
                        disabled={!currentPatient || !tienTamUng > 0}
                        onChange={advanceMode ? () => {} : handleCheckTamUng}
                      >
                        {i18n.t(languageKeys.sd_tien_tam_ung)}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="middle" style={{ margin: '0 5px' }}>
                        <Col className={style["title-money"]}>
                          <label>{i18n.t(languageKeys.tong_tam_ung)}:</label>
                        </Col>
                        <Col className={style["money-special"]}>
                          {tienTamUng ? formatCurrency(tienTamUng, "đ", true) : '- đ'}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}

                <Form.List name="list_dv_thanh_toan" initialValue={[{}]}>
                  {(fields, { add, remove }) => (
                    <Col span={24}>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row size={0} direction="vertical" className={style["payment-container"]} key={key}>
                          {fields.length > 1 && (
                            <div type="link" className={style["delete-btn"]} onClick={() => remove(name)}>
                              <Trash />
                            </div>
                          )}

                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, "HINH_THUC_THANH_TOAN"]}
                              label={i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}
                              rules={[
                                {
                                  required: luuCongNo ? false : (tongTienCanThanhToan() > 0 && !(isTamUng && tienTamUng)),
                                  message: i18n.t(languageKeys.vui_long_nhap)
                                },
                                { validator: checkDuplicateMethod }
                              ]}
                            >
                              <Select
                                dataSource={dsHinhThucThanhToan
                                  .filter(i => ![paymentMethodProto.TRA_GOP, paymentMethodProto.CONG_NO, paymentMethodProto.TIEN_DU, paymentMethodProto.TAM_UNG]
                                    .some(txt => i.PHAN_LOAI_HINH_THUC?.includes?.(txt)))}
                                valueKey="ID"
                                titleKey="TEN_HINH_THUC_THANH_TOAN"
                                onChange={(val) => handleSelectPaymentMethod(val, name)}
                              />
                            </Form.Item>
                          </Col>

                          <Form.Item {...restField} name={[name, "TEN_HINH_THUC_THANH_TOAN"]} hidden><Input /></Form.Item>

                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, "BENH_NHAN_DUA"]}
                              label={i18n.t(languageKeys.so_tien_thanh_toan)}
                              rules={[
                                {
                                  required: luuCongNo ? false : (tongTienCanThanhToan() > 0 && !(isTamUng && tienTamUng)),
                                  message: i18n.t(languageKeys.vui_long_nhap),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue(['list_dv_thanh_toan', name, 'BENH_NHAN_DUA']) <= tongTienCanThanhToan()) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject('')
                                  },
                                })
                              ]}
                            >
                              <InputNumber
                                placeholder={i18n.t(languageKeys.common_Nhap)}
                                min={0}
                                parser={currencyParser}
                                formatter={(val) => val ? formatNumberToPrice2(val) : val}
                                controls={false}
                                addonAfter={
                                  Payment?.[name]?.PHAN_LOAI_HINH_THUC === "QUET_MA_QR" && currentPatient.LOAI !== "THANH_TOAN_MUA_GOI"
                                    ? (
                                      <Button
                                        type="link"
                                        icon={<QrcodeOutlined />}
                                        onClick={() => handleSubmit()}
                                      />
                                    ) : undefined
                                }
                              />
                            </Form.Item>
                          </Col>

                        </Row>
                      ))}
                      {(advanceMode || handleCheckAddPayment())
                        ? <></>
                        : (
                          <Row className={style["btn-add"]} onClick={() => add()}>
                            <PlusCircleFilled className="blue-txt" style={{ fontSize: 16, marginRight: 7 }} />
                            <p className="blue-txt">{i18n.t(languageKeys.them_httt)}</p>
                          </Row>
                        )
                      }
                    </Col>
                  )}
                </Form.List>

              </Row>
            </Row>
            <Divider style={{ margin: 0 }} />

            <Row className={style["sider-item"]}>
              <Col span={24} className={style["title-money"]} style={{ marginBottom: 12 }}>
                <label>
                  {i18n.t(languageKeys.vien_phi_so_hoa_don)} <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="SO_HOADON_ID"
                  rules={[
                    {
                      required: !luuCongNo,
                      message: i18n.t(languageKeys.vienphi_vui_long_chon_so_tt),
                    },
                  ]}
                >
                  <Select
                    className={style["select-custom"]}
                    dataSource={dsSoHoaDon}
                    titleKey="TEN_SO"
                    valueKey="ID"
                    onAdd={onThemSo && isAddRevenueBookAllowed && !advanceMode}
                    onClickAdd={() => {
                      onThemSo && onThemSo("SO_THU_TIEN")
                    }}
                    textAdd={onThemSo && i18n.t(languageKeys.common_Them_moi)}
                    onSelect={(id) => localStorage.setItem(keys.id_shd, id)}
                    iconAdd={onThemSo && <PlusCircleFilled />}
                    showSearch
                  />
                </Form.Item>
              </Col>
              {
                Payment?.find(i => i?.HINH_THUC_THANH_TOAN === ID_CASH) && <>
                  <Col span={24}>
                    <Form.Item name='TIEN_KHACH_DUA' label={i18n.t(languageKeys.tien_khach_dua)}>
                      <InputNumber
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        min={0}
                        parser={currencyParser}
                        formatter={(val) => val ? formatNumberToPrice2(val) : val}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', margin: '4px 0' }}>
                    <label>{i18n.t(languageKeys.vien_phi_tien_thua)}</label>
                    <span>{formatCurrency(tinhTienThua, "đ", true)}</span>
                  </Col>
                </>
              }
              {
                advanceMode && tienTamUng > tongTienCanThanhToan() && (
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', margin: '4px 0' }}>
                    <label>{i18n.t(languageKeys.vien_phi_tien_thua)}</label>
                    <span>{formatCurrency(tinhTienThua, "đ", true)}</span>
                  </Col>
                )
              }

            </Row>
            <Row className={style["sider-item"]}>
              <label className={style["title-money"]} style={{ marginBottom: 12 }}>
                {i18n.t(languageKeys.ngay_thanh_toan)}
              </label>
              <Form.Item name="NGAY" style={{ width: "100%" }}>
                <DatePicker allowClear={false} style={{ width: "100%", backgroundColor: "#f5f7fb" }} format={"DD/MM/YYYY"} defaultValue={moment()} />
              </Form.Item>
            </Row>
            <Row className={style["sider-item"]}>
              <label className={style["title-money"]} style={{ marginBottom: 12 }}>
                {i18n.t(languageKeys.vienphi_ghi_chu)}{" "}
              </label>
              <Form.Item name="GHI_CHU" style={{ width: "100%" }}>
                <TextArea rows={2} placeholder={i18n.t(languageKeys.common_Nhap)} style={{ width: "100%" }} />
              </Form.Item>
            </Row>
          </Form>

        </Layout.Sider>

        <ConfirmModal
          ref={confirmRef}
          content={i18n.t(languageKeys.noti_confirm_Huy_dich_vu)}
          danger={true}
          onOk={handleCancelItem}
          onCancel={() => {
            setCancelItem();
          }}
        />

        <ModalQR
          ref={QrRef}
          customer={currentPatient}
          data={{
            ...form.getFieldsValue(),
            GOI_DV_ID: selectedService.flatMap(i => i.GOI_DV_ID),
          }}
          formatTempDataPrint={formatTempDataPrint}
          onPrint={(res) => {
            onPrint(res.HOA_DON_ID)
            setPrint(false)
          }}
          onSuccess={() => {
            setTabView(keyViews.DA_THANH_TOAN)
            onAfterSubmit(TRANG_THAI_THANH_TOAN.DA_THANH_TOAN);
          }}
        />

      </>
    );
  }
);
export default DichVuChuaThanhToan;
