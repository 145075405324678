import { Layout } from "antd";
import cn from "classnames";
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsChange } from "../../../../ducks/slices/snapshotSlice";
import i18n, { languageKeys } from "../../../../i18n";
import { TextEditor } from "../../components";
// import { Editor } from '@tinymce/tinymce-react';
import { Collapse } from "components/Collapse";
import { PhanLoaiSieuAmRIS, PhanLoaiXQuangRIS } from "constants/data";

// const toolbar = [
//   "insertfile undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
// ];

const FormRISContent = forwardRef(({ visible, classNames, readOnly = false, onChangeContent, onClickButtonFile, currService }, ref) => {
  const [description, setDescription] = useState("");
  const [ketLuan, setKetLuan] = useState("");
  const [phanLoai, setPhanLoai] = useState();
  const [kyThuat, setKyThuat] = useState("");
  const dispatch = useDispatch();
  const refTextEditor = useRef(null);
  const refTextEditorKL = useRef(null);
  const refTextEditorKyThuat = useRef(null);

  useImperativeHandle(ref, () => ({
    description,
    ketLuan,
    setDescription,
    setKetLuan,
    phanLoai,
    setPhanLoai,
    kyThuat,
    setKyThuat,
  }));

  useEffect(() => {
    return () => {
      setDescription("");
      setKyThuat("");
      setKetLuan();
    };
  }, [visible]);

  const userProfile = useSelector((state) => state.auth.user);

  const onChangeDescription = (value) => {
    //value = value.replace(`<img class="ant-image-img"`, `<img class="ant-image-img" style='width: 100%; max-height: 100%; object-fit: contain;'`);
    // value = value.replaceAll(`<img `, `<img style="max-width: 100%; max-height: 100%;object-fit: contain"`);
    if (value !== description) {
      dispatch(setIsChange(true));
      setDescription(value);
      onChangeContent(value);
    }
  };

  const onChangeKyThuat = (value) => {
    //value = value.replace(`<img class="ant-image-img"`, `<img class="ant-image-img" style='width: 100%; max-height: 100%; object-fit: contain;'`);
    // value = value.replaceAll(`<img `, `<img style="max-width: 100%; max-height: 100%;object-fit: contain"`);
    if (value !== kyThuat) {
      dispatch(setIsChange(true));
      setKyThuat(value);
    }
  };

  const onChangeKetLuan = (value) => {
    //value = value.replace(`<img class="ant-image-img"`, `<img class="ant-image-img" style='width: 100%; max-height: 100%; object-fit: contain;'`);
    // value = value.replaceAll(`<img `, `<img style="max-width: 100%; max-height: 100%;object-fit: contain"`);
    if (value !== ketLuan) {
      dispatch(setIsChange(true));
      setKetLuan(value);
    }
  };

  const renderOptions = () => {
    switch (currService?.MA_LOAI_DV) {
      case "LDV-SA":
        return PhanLoaiSieuAmRIS;
      case "CDHA_XQUANG":
        return PhanLoaiXQuangRIS;
      default:
        return [];
    }
  };

  const renderKyThuat = useMemo(() => {
    if (userProfile?.partner_code == "isgisg123" || userProfile?.partner_code == "noiquoctuan5" || userProfile?.partner_code == "dakhoadeepcare") {
      let MA_LOAI_DV = currService?.MA_LOAI_DV || currService?.LOAI_DV;
      switch (MA_LOAI_DV) {
        case "CDHA_MAMO":
        case "CDHA_CT":
        case "CDHA_MRI":
        case "CDHA_XQUANG":
          return true;
        default:
          return false;
      }
    }
    return false;
  }, [currService]);

  return (
    <Layout.Content className={`${classNames["container-content"]}`} style={{ overflow: "scroll", backgroundColor: "#ffffff" }}>
      {renderKyThuat ? (
        <Collapse
          bordered={false}
          defaultActiveKey={["KY_THUAT"]}
          panels={[
            {
              key: "KY_THUAT",
              header: i18n.t(languageKeys.ky_thuat),
              children: (
                <TextEditor
                  className={cn(classNames["text-editor"], readOnly && classNames["editor-disabled"])}
                  onChange={(value) => {
                    onChangeKyThuat(value);
                  }}
                  value={kyThuat}
                  disabled={readOnly}
                  ref={refTextEditor}
                  toolbar={[
                    "insertfile undo redo | styleselect | bold italic underline fontsizeselect lineheight| forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
                  ]}
                  plugins={[]}
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <div style={{ height: "80%", padding: 12, backgroundColor: "#f5f7fb", overflow: "auto", borderRadius: 10, marginBottom: 10, marginTop: 10 }}>
        <h2 style={{ fontSize: 15 }}>{i18n.t(languageKeys.mo_ta)}</h2>
        <TextEditor
          className={cn(classNames["text-editor"], readOnly && classNames["editor-disabled"])}
          onChange={(value) => {
            onChangeDescription(value);
          }}
          value={description}
          //toolbar={toolbar}
          disabled={readOnly}
          ref={refTextEditor}
          onClickButtonFile={onClickButtonFile}
        />
      </div>
      <div style={{ padding: 12, backgroundColor: "#f5f7fb", borderRadius: 10 }}>
        <h2 style={{ fontSize: 15 }}>{i18n.t(languageKeys.ket_luan)}</h2>
        <TextEditor
          className={cn(classNames["text-editor-kl"], readOnly && classNames["editor-disabled"])}
          onChange={(value) => {
            onChangeKetLuan(value);
          }}
          value={ketLuan}
          disabled={readOnly}
          ref={refTextEditorKL}
          menubar={false}
          toolbar={[
            "insertfile undo redo | styleselect | bold italic underline fontsizeselect lineheight| forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
          ]}
          plugins={[]}
          onClickButtonFile={false}
        />

        {/* <Input.TextArea
            style={{ height: "100%", background: '#fff' }}
            className={classNames.boldTextArea}
            rows={2}
            value={ketLuan}
            onChange={(e) => {
              dispatch(setIsChange(true));
              setKetLuan(e.target.value);
            }}
            readOnly={readOnly}
          /> */}
      </div>
      <div style={{ backgroundColor: "#F5F7FB" }}>
        {/* <div style={{ padding:12, margin:10, borderRadius:10, backgroundColor: "#fff",  overflow:"scroll"}}>
      <h2 style={{ fontSize: 15 }}>{i18n.t(languageKeys.phan_loai_benh_tat)}</h2>
      <div style={{ width: "200px" }}>
            <Select
              value={phanLoai}
              dataSource={renderOptions()}
              valueKey='value'
              titleKey='label'
              onChange={setPhanLoai}
              className={classNames.selectEditor}
            />
          </div>
      </div> */}
      </div>
    </Layout.Content>
  );
});

export default memo(FormRISContent);
