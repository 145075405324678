import React from "react";
import i18n, { languageKeys } from "../../../../../../i18n";
import { Col, Row } from "antd";
import style from "./style.module.less";
import { formatNumberToPrice } from "helpers";

const FooterTable = ({ currentSubclinicalList }) => {
  function getGiaDV(dichvu) {
    if (dichvu?.GIA_THU_PHI && Number(dichvu.GIA_THU_PHI) !== 0) {
      return Number(dichvu.GIA_THU_PHI);
    } else if (dichvu?.GIA_DICH_VU && Number(dichvu.GIA_DICH_VU) !== 0) {
      return Number(dichvu.GIA_DICH_VU);
    } else {
      return 0;
    }
  }

  return (
    <div className={style["footer-table"]}>
      <Row align="middle" justify="space-between" gutter={8} wrap={false} >
        <Col flex="200px"></Col>
        <Col flex="200px">
          <span className="navy-txt" style={{ padding: "0px 11px" }}>
            {i18n.t(languageKeys.tong)}
          </span>
        </Col>
        <Col flex="110px"></Col>
        <Col flex="65px">
          <span className={style["blue-txt"]} style={{ padding: "0px 12px" }}>
            {currentSubclinicalList.reduce((total, current) => total + (current.SO_LUONG === undefined ? 1 : current.SO_LUONG), 0)}
          </span>
        </Col>
        <Col flex="110px">
          <span className={style["blue-txt"]} style={{ marginLeft: 1 }}>
            {formatNumberToPrice(
              currentSubclinicalList.reduce((total, current) => {
                return total + (current.SO_LUONG === undefined ? getGiaDV(current) : current.SO_LUONG * getGiaDV(current));
              }, 0)
            )}
          </span>
        </Col>
        <Col flex="130px"></Col>
        <Col
          flex="60px"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Col>
        <Col flex="170px"></Col>
        <Col flex="200px"></Col>
        <div className={style["fixed"]}>
          <div style={{ height: "100%" }}></div>
        </div>
      </Row>
    </div>
  );
};

export default FooterTable;
