import { Dropdown, Tooltip } from "antd";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from "react";
import style from "./timKiemThuocPopup.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { common_post, HLog, isEmptyObject, rid } from "../../../../../helpers";
import { Table } from "../../../../../components";
import { apis, keys } from "../../../../../constants";
import { useSelector } from "react-redux";
import { apiTimDsThuocTheoKho } from "./apisKeDonThuoc";
import { useEventListener } from "../../../../../hooks";
const ellipsisProps = {
  ellipsis: true,
  render: (data) => {
    return (
      <Tooltip placement="topLeft" title={data} zIndex={1}>
        {data === "null" || data === "undefined" || !data ? "" : data}
      </Tooltip>
    );
  },
};

const columns_data = [
  {
    title: i18n.t(languageKeys.field_Ma_thuoc),
    dataIndex: "Thuoc_Code",
    width:120,
  },
  {
    title: i18n.t(languageKeys.field_Ten_thuoc),
    dataIndex: "Thuoc_Ten",
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    dataIndex: "Thuoc_Dvt",
    width:80,
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    dataIndex: "Thuoc_HoatChat",
  },
  {
    title: i18n.t(languageKeys.field_ton_kho),
    width:120,
    dataIndex: "TON_CUOI",
  },
];

const TimKiemThuocPopup = forwardRef(
  ({ children, handleChonThuoc = (data) => {}, isKeDonThoc = false, focusInput = () => {}, clearInput = () => {} }, ref) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loadingDataSource, setLoadingDataSource] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectingIndex, setSelectingIndex] = useState(-1);
    const [currentSearch, setCurrentSearch] = useState(undefined);
    let isKhoThuoc = userProfile.config && userProfile.config?.KHO_THUOC ? userProfile.config.KHO_THUOC : false;
    const [columns, setColumns] = useState(columns_data.filter((item) => (!isKhoThuoc ? item.dataIndex !== "TON_CUOI" : true)));
    useEffect(() => {
      if (dataSource.length > 0) {
        setSelectingIndex(0);
      } else {
        setSelectingIndex(-1);
      }
    }, [dataSource]);
    useImperativeHandle(ref, () => ({
      visible,
      setVisible,
      searchData: (data, KHO_THUOC_ID, isChangeKho = false) => (isKhoThuoc ? getDataSource(data, KHO_THUOC_ID, isChangeKho) : layDsThuoc(data)),
      resetData: () => {
        setDataSource([]);
        setCurrentSearch(undefined);
      },
    }));
    // lấy danh sách thuốc
    const layDsThuoc = async (search_string, limit = 30, page = 1) => {
      try {
        console.log("search_string", search_string);
        if (!visible) {
          setVisible(true);
        }
        const dataRequest = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          search_string,
          limit,
          page,
          isKeDonThuoc: 1,
          configChoPhepKeThuocHet: userProfile.config.CHO_PHEP_KE_THUOC_HET,
        };
        setLoadingDataSource(true);
        const response = await common_post(apis.search_dm_thuoc, dataRequest);
        if (response && response.status === "OK") {
          const list = response.result.map((item) => ({
            ...item,
            key: rid(5),
          }));
          setDataSource(list);
          setTotal(response.total);
        }
        setLoadingDataSource(false);
      } catch (error) {
        HLog("tìm thuốc lỗi ", error);
        setLoadingDataSource(false);
      }
    };

    // hàm lấy danh sách thuốc
    const getDataSource = async (search_string = "", KHO_THUOC_ID, isChangeKho) => {
      try {
        if (!visible) {
          setVisible(true);
        }
        //nếu search string không thay đổi thì chỉ hiển thị data, không cần load lại tránh gọi api nhiều lần
        if (search_string === currentSearch && !isChangeKho) {
          return;
        }
        setCurrentSearch(search_string);
        const dataRequest = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          search_string,
          limit: keys.limit,
          page: 1,
          KHO_THUOC_ID, // chỉ xuất / bán thuốc thì mới true !!!
          LOAI_THUOC: "NHA_THUOC", //có 2 loại thuốc là NHA_THUOC và THUOC_THUONG
        };

        setLoadingDataSource(true);
        let response = await apiTimDsThuocTheoKho(dataRequest);
        if (response && !isEmptyObject(response)) {
          const list = response.result.map((item) => ({
            ...item,
            key: rid(5),
          }));
          setDataSource(list);
          setTotal(response.total);
        }
        setLoadingDataSource(false);
      } catch (error) {
        HLog(error);
        setLoadingDataSource(false);
      }
    };

    // hàm tìm kiếm thuốc
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const searchData = useCallback(debounce(getDataSource, 1000), []);

    // hàm xử lý hiển thị popup
    const handleVisible = (bool) => {
      setVisible(bool);
      if (bool) getDataSource();
      else {
        // setTimeout(() => setDataSource([]), 200);
        // clearInput();
        setSelectingIndex(-1);
      }
    };

    // hàm xử lý khi chọn 1 row thuốc
    const handleClickRow = (record) => {
      handleChonThuoc(record);
      focusInput();
    };

    // hàm xử lý phím tắt
    function keydownEvent(event) {
      if (!visible) {
        return;
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectingIndex((currentIndex) => {
          if (currentIndex <= 0) {
            return dataSource.length - 1;
          } else {
            return currentIndex - 1;
          }
        });
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectingIndex((currentIndex) => {
          if (currentIndex >= dataSource.length - 1) {
            return 0;
          } else {
            return currentIndex + 1;
          }
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (!!dataSource[selectingIndex]) {
          handleClickRow(dataSource[selectingIndex]);
        }
      } else if (event.key === "Backspace" && visible) {
        event.preventDefault();
        setVisible(false);
      }
    }

    useEventListener("keydown", keydownEvent, window, visible);

    return (
      <Dropdown
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisible}
        overlayClassName={style["container"]}
        overlay={
          <>
            <h4 className={style["title"]} onClick={focusInput}>
              {i18n.t(languageKeys.field_Ket_qua_tim_kiem)}: {total}
            </h4>

            <div className={style["table-ctn"]}>
              <Table
                columns={columns}
                dataSource={dataSource}
                loading={loadingDataSource}
                scroll={{ y: "calc(100vh - 480px)" }}
                onClickRow={handleClickRow}
                pointingIndex={selectingIndex}
                pointingRow={true}
                onHeaderRow={() => ({
                  onClick: focusInput,
                })}
              />
            </div>
          </>
        }
      >
        <div style={{ width: "100%" }}>{children}</div>
      </Dropdown>
    );
  }
);

export default memo(TimKiemThuocPopup);
