import React, { useState, useEffect, useMemo } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { Col, Form, Input, Row } from "antd";
import style from "./style.module.less";
import { formPattern, handleCheckPermissions } from "../../../../../helpers";
import { fieldKhamBenh } from "../ThongTinKham";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { dataPartnerCode, featureKeys } from "constants/keys";
import { FieldKhaiThac, fieldVongBung } from "pages/QuanLyTiepDon/components/KhaiThacLichSuBenh/fieldKhaiThac";
import { setDataDetail } from "ducks/slices/khamBenhSlice";

const ChiSoSinhTon = ({ readOnly, form, dataExam, isOnlyCSST }, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [hideWarning, setHideWarning] = useState(false);
  const dispatch = useDispatch();
  const dataLichKham = useSelector((state) => state.khamBenh.dataDetail);

  const [data, setData] = useState({
    [`${fieldKhamBenh.MACH}`]: "",
    [`${fieldKhamBenh.NHIET_DO}`]: "",
    [`${fieldKhamBenh.HUYET_AP}`]: "",
    [`${fieldKhamBenh.NHIP_THO}`]: "",
    [`${fieldKhamBenh.CAN_NANG}`]: "",
    [`${fieldKhamBenh.CHIEU_CAO}`]: "",
  });

  useEffect(() => {
    if (dataExam) {
      setData({
        [`${fieldKhamBenh.MACH}`]: dataExam.MACH,
        [`${fieldKhamBenh.NHIET_DO}`]: dataExam.NHIET_DO,
        [`${fieldKhamBenh.HUYET_AP}`]: dataExam.HUYET_AP,
        [`${fieldKhamBenh.NHIP_THO}`]: dataExam.NHIP_THO,
        [`${fieldKhamBenh.CAN_NANG}`]: dataExam.CAN_NANG,
        [`${fieldKhamBenh.CHIEU_CAO}`]: dataExam.CHIEU_CAO,
      });
    }
  }, [dataExam]);

  const CHIEU_CAO = Form.useWatch("CHIEU_CAO", form);
  const CAN_NANG = Form.useWatch("CAN_NANG", form);

  useEffect(() => {
    try {
      let result = 0;
      let height = CHIEU_CAO / 100;
      if (CAN_NANG && height) {
        result = CAN_NANG / (height * height);
        result = result.toFixed(2);
        form.setFields([
          {
            name: "BMI",
            value: result,
            errors: [],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "BMI",
            value: null,
            errors: [],
          },
        ]);
      }
      return result;
    } catch (error) {
      return 0;
    }
  }, [CHIEU_CAO, CAN_NANG]);

  const onChangeInput = (value, type, lable) => {
    let validate;
    if (type === fieldKhamBenh.HUYET_AP) {
      if (value.includes("/")) {
        let arr = value.split("/");
        if (arr.length > 1 && arr.length <= 2) {
          if (arr[1] !== "" && arr[0] !== "") {
            validate = formPattern.numberDecimalPositive.test(arr[0]) && formPattern.numberDecimalPositive.test(arr[1]);
          } else {
            validate = true;
          }
        }
      } else {
        validate = formPattern.numberDecimalPositive.test(value);
      }
    } else {
      validate = formPattern.numberDecimalPositive.test(value);
    }

    if (type === fieldKhamBenh.SP_O2) {
      validate = true
    }
    
    if (!validate && value !== "") {
      form.setFields([
        {
          name: type,
          value: data[`${type}`] === undefined ? null : data[`${type}`],
          errors: [`${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${lable}`],
        },
      ]);
      return;
    }
    setData((prev) => ({ ...prev, [`${type}`]: value }));

    form.setFields([
      {
        name: type,
        value: value,
        errors: [],
      },
    ]);
  };

  // set giá trị cho disabled (ngược với kết quả trả về)
  const configPermissionEdit = useMemo(() => {
    return !handleCheckPermissions(userProfile, featureKeys.thong_tin_kham).THEM;
  }, [userProfile]);

  useEffect(() => {
    if (!hideWarning) {
      setTimeout(() => {
        setHideWarning(true);
      }, 5000);
    }
  }, [hideWarning]);

      const vongBungEo = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_EO], form);
      const vongBungRon = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_RON], form);
      const vongBungGaiChau = Form.useWatch([fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN], form);

      useEffect(() => {
        try {
          if ([dataPartnerCode.dth].includes(userProfile.partner_code)) {
            let vongBung = {
              [fieldVongBung.VONG_BUNG_QUA_EO]: vongBungEo,
              [fieldVongBung.VONG_BUNG_QUA_RON]: vongBungRon,
              [fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN]: vongBungGaiChau,
            };
            vongBung = [
              vongBung[fieldVongBung.VONG_BUNG_QUA_EO],
              vongBung[fieldVongBung.VONG_BUNG_QUA_RON],
              vongBung[fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN],
            ];
            form.setFields([
              {
                name: FieldKhaiThac.VONG_BUNG,
                value: vongBung?.join(",") || undefined,
              },
            ]);
          }
        } catch (error) {
          console.log(error);
        }
      }, [vongBungEo, vongBungRon, vongBungGaiChau]);

  if (isOnlyCSST) {
    return (
      <div style={styles.container} className={style["chiso-container-2"]}>
        <p style={styles.title2}>{i18n.t(languageKeys.field_Chi_so_sinh_ton)}</p>
        <div style={styles.body2}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Form.Item name={fieldKhamBenh.NHIET_DO}>
                <Input
                  prefix={
                    <p style={styles.txt}>
                      {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}
                    </p>
                  }
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.NHIET_DO, i18n.t(languageKeys.field_Nhiet_do))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.NHIET_DO,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={fieldKhamBenh.MACH}>
                <Input
                  prefix={
                    <p style={styles.txt}>
                      {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}
                    </p>
                  }
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.MACH, i18n.t(languageKeys.field_Mach))}
                  onBlur={(e) => {
                    form.setFields([
                      {
                        name: fieldKhamBenh.MACH,
                        errors: [],
                      },
                    ]);
                  }}
                  //value={mach}
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={fieldKhamBenh.HUYET_AP}
                rules={[
                  {
                    pattern: /^\d{1,3}\/\d{1,3}$/,
                    message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Huyet_ap)}`,
                  },
                ]}
              >
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.field_Huyet_ap)} (mmHg)</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.HUYET_AP, i18n.t(languageKeys.field_Huyet_ap))}
                  onBlur={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      form.setFields([
                        {
                          name: fieldKhamBenh.HUYET_AP,
                          errors: [],
                        },
                      ]);
                    }
                  }}
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={fieldKhamBenh.NHIP_THO}>
                <Input
                  prefix={
                    <p style={styles.txt}>
                      {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}
                    </p>
                  }
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.NHIP_THO, i18n.t(languageKeys.field_Nhip_tho))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.NHIP_THO,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={fieldKhamBenh.CAN_NANG}>
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.can_nang)} (Kg)</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onKeyDown={()=>{
                    dispatch(setDataDetail({...dataLichKham,isTheSecond: true}))
                  }}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.CAN_NANG, i18n.t(languageKeys.can_nang))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.CAN_NANG,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={fieldKhamBenh.CHIEU_CAO}>
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.chieu_cao)} (cm)</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onKeyDown={()=>{
                    dispatch(setDataDetail({...dataLichKham,isTheSecond: true}))
                  }}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.CHIEU_CAO, i18n.t(languageKeys.chieu_cao))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.CHIEU_CAO,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={fieldKhamBenh.BMI}
                // rules={[
                //     {
                //         pattern: formPattern.numberDecimalPositive,
                //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
                //     }
                // ]}
              >
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.bmi)}</p>}
                  readOnly={true}
                  // placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.BMI, i18n.t(languageKeys.bmi))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.BMI,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={fieldKhamBenh.VONG_CO}
                // rules={[
                //     {
                //         pattern: formPattern.numberDecimalPositive,
                //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
                //     }
                // ]}
              >
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_co)}</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onKeyDown={()=>{
                    dispatch(setDataDetail({...dataLichKham,isTheSecond: true}))
                  }}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_CO, i18n.t(languageKeys.vong_co))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.VONG_CO,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={fieldKhamBenh.VONG_BUNG}
              >
                <Input
                  prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung)}</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.VONG_BUNG,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>

            { [dataPartnerCode.dth].includes(userProfile.partner_code) ? ( <Col span={8}>
              <Form.Item
                name={fieldKhamBenh.SP_O2}
              >
                <Input
                  prefix={<p style={styles.txt}>SpO2</p>}
                  readOnly={readOnly}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.SP_O2, i18n.t(languageKeys.vong_bung))}
                  onBlur={() =>
                    form.setFields([
                      {
                        name: fieldKhamBenh.SP_O2,
                        errors: [],
                      },
                    ])
                  }
                  disabled={configPermissionEdit}
                />
              </Form.Item>
            </Col>):""}
           
          </Row>
          {configPermissionEdit && (
            <div className={classNames(style["noti-phan-quyen"], hideWarning && style["noti-phan-quyen-hidden"])}>
              {i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container} className={style["chiso-container"]}>
      <p style={styles.title}>{i18n.t(languageKeys.field_Chi_so_sinh_ton)}</p>
      <div style={styles.body}>
        <Form.Item
          name={fieldKhamBenh.MACH}
          // rules={[
          //     {
          //         pattern: formPattern.numberDecimalPositive,
          //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Mach)}`,
          //     }
          // ]}
        >
          <Input
            prefix={
              <p style={styles.txt}>
                {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}
              </p>
            }
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.MACH, i18n.t(languageKeys.field_Mach))}
            onBlur={(e) => {
              form.setFields([
                {
                  name: fieldKhamBenh.MACH,
                  errors: [],
                },
              ]);
            }}
            //value={mach}
            disabled={configPermissionEdit}
          />
        </Form.Item>

        <Form.Item
          name={fieldKhamBenh.NHIET_DO}
          // rules={[
          //     {
          //         pattern: formPattern.numberDecimalPositive,
          //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Nhiet_do)}`,
          //     }
          // ]}
        >
          <Input
            prefix={
              <p style={styles.txt}>
                {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}
              </p>
            }
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.NHIET_DO, i18n.t(languageKeys.field_Nhiet_do))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.NHIET_DO,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.HUYET_AP}
          rules={[
            {
              pattern: /^\d{1,3}\/\d{1,3}$/,
              message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Huyet_ap)}`,
            },
          ]}
        >
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.field_Huyet_ap)} (mmHg)</p>}
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.HUYET_AP, i18n.t(languageKeys.field_Huyet_ap))}
            onBlur={(e) => {
              let value = e.target.value;
              if (!value) {
                form.setFields([
                  {
                    name: fieldKhamBenh.HUYET_AP,
                    errors: [],
                  },
                ]);
              }
            }}
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.NHIP_THO}
          // rules={[
          //     {
          //         pattern: formPattern.numberDecimalPositive,
          //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.field_Nhip_tho)}`,
          //     }
          // ]}
        >
          <Input
            prefix={
              <p style={styles.txt}>
                {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}
              </p>
            }
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.NHIP_THO, i18n.t(languageKeys.field_Nhip_tho))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.NHIP_THO,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.CAN_NANG}
          // rules={[
          //     {
          //         pattern: formPattern.numberDecimalPositive,
          //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.can_nang)}`,
          //     }
          // ]}
        >
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.can_nang)} (Kg)</p>}
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onKeyDown={() => {
              dispatch(setDataDetail({ ...dataLichKham, isTheSecond: true }));
            }}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.CAN_NANG, i18n.t(languageKeys.can_nang))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.CAN_NANG,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.CHIEU_CAO}
          // rules={[
          //     {
          //         pattern: formPattern.numberDecimalPositive,
          //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
          //     }
          // ]}
        >
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.chieu_cao)} (cm)</p>}
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onKeyDown={() => {
              dispatch(setDataDetail({ ...dataLichKham, isTheSecond: true }));
            }}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.CHIEU_CAO, i18n.t(languageKeys.chieu_cao))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.CHIEU_CAO,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.BMI}
        >
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.bmi)}</p>}
            readOnly={true}
            // placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.BMI, i18n.t(languageKeys.bmi))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.BMI,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>
        <Form.Item
          name={fieldKhamBenh.VONG_CO}
        >
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_co)}</p>}
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_CO, i18n.t(languageKeys.vong_co))}
            onKeyDown={() => {
              dispatch(setDataDetail({ ...dataLichKham, isTheSecond: true }));
            }}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.VONG_CO,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>

        {[dataPartnerCode.dth].includes(userProfile.partner_code) ? (
          <>
            <Form.Item name={fieldVongBung.VONG_BUNG_QUA_EO}>
              <Input
                prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_eo)}</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.VONG_BUNG,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>
            <Form.Item
              name={fieldVongBung.VONG_BUNG_QUA_RON}
              // rules={[
              //     {
              //         pattern: formPattern.numberDecimalPositive,
              //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
              //     }
              // ]}
            >
              <Input
                prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_ron)}</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.VONG_BUNG,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>
            <Form.Item
              name={fieldVongBung.VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN}
              // rules={[
              //     {
              //         pattern: formPattern.numberDecimalPositive,
              //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
              //     }
              // ]}
            >
              <Input
                prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung_qua_gai_chau_truoc_tren)}</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.VONG_BUNG,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>
            <Form.Item
              name={fieldKhamBenh.SP_O2}
            >
              <Input
                prefix={<p style={styles.txt}>SpO2</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.SP_O2, i18n.t(languageKeys.vong_bung))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.SP_O2,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item name={fieldKhamBenh.VONG_BUNG} hidden={[dataPartnerCode.dth].includes(userProfile.partner_code)}>
          <Input
            prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_bung)}</p>}
            readOnly={readOnly}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_BUNG, i18n.t(languageKeys.vong_bung))}
            onBlur={() =>
              form.setFields([
                {
                  name: fieldKhamBenh.VONG_BUNG,
                  errors: [],
                },
              ])
            }
            disabled={configPermissionEdit}
          />
        </Form.Item>

        {[dataPartnerCode.dth].includes(userProfile.partner_code) ? (
          <>
            <Form.Item
              name={fieldKhamBenh.VONG_HONG_CHAU}
              // rules={[
              //     {
              //         pattern: formPattern.numberDecimalPositive,
              //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
              //     }
              // ]}
            >
              <Input
                prefix={<p style={styles.txt}>{i18n.t(languageKeys.vong_hong_chau)}</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.VONG_HONG_CHAU, i18n.t(languageKeys.vong_hong_chau))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.VONG_HONG_CHAU,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>

            <Form.Item
              name={fieldKhamBenh.TUOI_MAN_KINH}
              // rules={[
              //     {
              //         pattern: formPattern.numberDecimalPositive,
              //         message: `${i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)} ${i18n.t(languageKeys.chieu_cao)}`,
              //     }
              // ]}
            >
              <Input
                prefix={<p style={styles.txt}>{i18n.t(languageKeys.tuoi_man_kinh)}</p>}
                readOnly={readOnly}
                placeholder={i18n.t(languageKeys.common_Nhap)}
                onChange={(e) => onChangeInput(e.target.value, fieldKhamBenh.TUOI_MAN_KINH, i18n.t(languageKeys.tuoi_man_kinh))}
                onBlur={() =>
                  form.setFields([
                    {
                      name: fieldKhamBenh.TUOI_MAN_KINH,
                      errors: [],
                    },
                  ])
                }
                disabled={configPermissionEdit}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        {configPermissionEdit && (
          <div className={classNames(style["noti-phan-quyen"], hideWarning && style["noti-phan-quyen-hidden"])}>
            {i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(ChiSoSinhTon);

const styles = {
  container: {
    //backgroundColor : "red",
    width: "100%",
  },
  title: {
    fontSize: 16,
    color: "#2C3782",
    fontWeight: "bold",
    margin: 15,
  },
  title2: {
    fontSize: 20,
    color: "#2C3782",
    fontWeight: "700",
    margin: 24,
  },
  body: {
    borderTop: "1px solid #E0E0E0",
    padding: 10,
  },
  body2: {
    padding: 10,
    backgroundColor: "#F5F7FB",
    margin: 24,
    borderRadius: 10,
  },
  txt: {
    fontSize: 13,
  },
};
