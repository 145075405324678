import { CaretDownOutlined, CaretRightOutlined, LinkOutlined, PrinterOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table as TableAnt, Spin, Divider, Form, Checkbox, Input as InputAntd } from "antd";
import React, { useState, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { FormDrawer, ModalPDF, Select } from "../../../../../components";
import { apis } from "../../../../../constants";
import { HLog, checkIsNameLeTanThuNgan, common_post, converGiaTriNguyCo, converGiaTriNguyCoV2, converGiaTriThamChieu, converGiaTriThamChieuV2, converKetQuaXnMay, converKetQuaXnMayV2, convertGender, formatNumberToPrice, getResultColor2, getResultColor2KqMay, locdau, matchGiaTriThamChieu } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import ModalFileDinhKem from "./ModalFile";
import styles from "./style.module.less";
import moment from "moment";
import classNames from "classnames";
import { useEventListener } from "../../../../../hooks";
import { Input } from "components_v2";
import { NewBackIcon } from "assets/svg";

function KetQuaXN({}, ref) {
  const userProfile = useSelector((state) => state.auth.user);
  const refModalFile = useRef();
  const [formPhieu] = Form.useForm();
  const refPrint = useRef();
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [stopEvent, setStopEvent] = useState(false);
  const [currentPatient, setCurrentPatient] = useState();
  const [benhPham, setBenhPham] = useState();

  useImperativeHandle(ref, () => ({
    onpenModal: handleOpenModal,
    closeModal: handleCloseModal,
  }));

  const handleOpenModal = (item, patientInfo = {}) => {
    setCurrentPatient(patientInfo);
    handleInitDataTicket({ ...item, ...patientInfo });
    // console.log('item', item, patientInfo)
    if (item && item.PHIEU_IN_CLS_ID) {
      handleGetDataPhieu(item.PHIEU_IN_CLS_ID);
      setVisible(true);
      setCurrentData(item);
    }
  };

   const renderSTT = (stt) => {
    if (stt == "-") {
      return ""
    }
    return Number(stt) < 10 ? `0${stt}` : stt;
  };

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>{i18n.t(languageKeys.stt)}</div>,
      key: "STT",
      dataIndex: "STT",
      width: 50,
      // align : "center",
      onCell: (record) => ({
        colSpan: !record.isLoai ? 1 : columns.length,
      }),
      render: (data, record) => {
        if (record.isLoai) {
          return (
            <div style={{ fontSize: 13 }}>
              {record.TEN_LOAI_DV} ({record.SL_DV_CON})
            </div>
          );
        } else {
          return <div>{renderSTT(data)}</div>;
        }
      },
    },
    // {
    //   title: i18n.t(languageKeys.tra_sau),
    //   key: "IS_TRA_SAU",
    //   dataIndex: "IS_TRA_SAU",
    //   width: 70,
    //   align: "center",
    //   onCell: sharedOnCell,
    //   render: (data) => <Checkbox checked={!!data} />,
    // },
    {
      title: i18n.t(languageKeys.da_KQ),
      key: "IS_DA_KQ",
      dataIndex: "IS_DA_KQ",
      width: 70,
      align: "center",
      onCell: sharedOnCell,
      render: (data) => <Checkbox checked={!!data} />,
    },

    {
      title: i18n.t(languageKeys.ten_xet_nghiem),
      key: "TEN_DV",
      dataIndex: "TEN_DV",
      width: 200,
      onCell: sharedOnCell,
    },
    // {
    //   title: i18n.t(languageKeys.kq_may),
    //   key: "KET_QUA_XN_MAY",
    //   dataIndex: "KET_QUA_XN_MAY",
    //   width: 200,
    //   onCell: sharedOnCell,
    //   render: (value, record) => {
    //     return <span
    //       style={{
    //         margin: 0,
    //         color: record?.HIEN_THI_THONG_SO_MAY ? getResultColor2KqMay(value, record, currentPatient): getResultColor2(value, record, currentPatient),
    //         // fontWeight: getResultColor2(value, record, infoBenhNhan, "fontWeight")
    //       }}
    //     >{converKetQuaXnMay(record, currentPatient)}</span>
    //   }
    // },

    {
      title: i18n.t(languageKeys.field_Ket_qua),
      key: "KET_QUA_XN",
      dataIndex: "KET_QUA_XN",
      width: 170,
      onCell: sharedOnCell,
      render: (value, record) => {
        let item = value || record?.KET_QUA_XN_MAY
        const renderResultTest = () => {
          try {
            let jsonResult =  item
            try {
              jsonResult = JSON.parse(item);
            } catch (error) {}
            if (Array.isArray(jsonResult)) {
              return (
               <p>
                 <Select
                  suffixIcon={false}
                  style={{ width: "100%" }}
                  multiple
                  value={jsonResult}
                  allowClear={false}
                  tagCount={5}
                  readOnly
                  dataSource={jsonResult}
                />
                <p> {matchGiaTriThamChieu(record, currentPatient, jsonResult)}</p>
               </p>
              );
            } else {
              return <span style={{ color: record?.HIEN_THI_THONG_SO_MAY ? getResultColor2KqMay(jsonResult, record, currentPatient): getResultColor2(jsonResult, record, currentPatient) }}>
                {/* {converKetQuaXnMayV2(jsonResult,currentPatient)} */}
                {/* {jsonResult} */}
                {matchGiaTriThamChieu(record, currentPatient, jsonResult)}
              </span>
                ;
            }
          } catch (error) {
            return item;
          }
        };
        return <>{renderResultTest(item)}</>;
      },
    },

    {
      title: i18n.t(languageKeys.field_Don_vi),
      key: "TEN_DVT",
      dataIndex: "TEN_DVT",
      width: 80,
      onCell: sharedOnCell,
      render: (value, item) => {
        if (!(item.LOAI_CHI_SO != "NHOM_CHI_SO" || (item.LOAI_CHI_SO != "CHI_SO_THUONG" && item?.GIA_TRI?.length > 0))) {
          return ""
        } else {

          if (item?.HIEN_THI_THONG_SO_MAY) {
            return item?.DVT_MAY
          }
          return value
        }

      }
    },
    {
      title: i18n.t(languageKeys.gia_tri_tham_chieu),
      key: "CAN_DUOI",
      dataIndex: "CAN_DUOI",
      width: 250,
      onCell: sharedOnCell,
      render: (_, record) => {
        if (record?.HIEN_THI_THONG_SO_MAY) {
          return record?.THAM_CHIEU_MAY
        }

        // return <div>
          // {converGiaTriThamChieu(record, currentPatient)}
        //   <br />
        //   {converGiaTriNguyCo(record, currentPatient)?.length > 0 ? <span>
        //     GZ: {" "}{converGiaTriNguyCo(record, currentPatient)?.join(", ")}
        //   </span> : ""}
        // </div>;
        return <div>
        <span dangerouslySetInnerHTML={{ __html: converGiaTriThamChieuV2(record, currentPatient, userProfile.partner_code) }}></span>
        {/* {converGiaTriThamChieu(record, infoBenhNhan)} */}

        {converGiaTriNguyCoV2(record, currentPatient, userProfile.partner_code) && converGiaTriNguyCoV2(record, currentPatient, userProfile.partner_code)?.length > 0 ? <>
          <br />
          <span>
            GZ:
            <br />
            <span dangerouslySetInnerHTML={{ __html: converGiaTriNguyCoV2(record, currentPatient, userProfile.partner_code) }}></span>
            {/* {converGiaTriNguyCo(record, infoBenhNhan)?.join(", ")} */}
          </span></> : ""}
        </div>;
      },
    },
    {
      title: i18n.t(languageKeys.field_Ghi_chu),
      key: "GHI_CHU",
      dataIndex: "GHI_CHU",
      width: 140,
      onCell: sharedOnCell,
      render:(value, record) => {
        return <>{value || record?.GHI_CHU_MAY || " "}</>
      }
      // render: (data, record) => renderNote(record),
    },
    {
      title: i18n.t(languageKeys.ngay_nhan_kq),
      key: "NGAY_NHAN_KQ",
      dataIndex: "NGAY_NHAN_KQ",
      width: 120,
      ellipsis: true,
      align: "center",
      onCell: sharedOnCell,
      render: (data) => (data ? moment(data, "YYYYMMDD").format("DD/MM/YYYY") : "-"),
    },
    {
      title: i18n.t(languageKeys.thiet_bi),
      key: "TEN_MAY_XN",
      dataIndex: "TEN_MAY_XN",
      width: 100,
      onCell: sharedOnCell,
      render: (data) => (data ? data : "-"),
    },
    {
      title: i18n.t(languageKeys.so_chay),
      key: "SO_CHAY",
      dataIndex: "SO_CHAY",
      width: 100,
      onCell: sharedOnCell,
      render: (data) => data || "-",
    },
    {
      title: i18n.t(languageKeys.ngay_chay),
      key: "NGAY_CHAY",
      dataIndex: "NGAY_CHAY",
      width: 110,
      onCell: sharedOnCell,
      render: (data) => (data ? moment(data, "YYYYMMDD").format("DD/MM/YYYY") : "-"),
    },
    {
      title: i18n.t(languageKeys.duyet),
      key: "IS_DA_DUYET",
      dataIndex: "IS_DA_DUYET",
      width: 70,
      align: "center",
      onCell: sharedOnCell,
      render: (data) => <Checkbox checked={!!data} />,
      // render: (data) => (data ? <Checked /> : <UnCheck />),
    },
    {
      title: i18n.t(languageKeys.ngay_duyet),
      key: "NGAY_DUYET",
      dataIndex: "NGAY_DUYET",
      width: 120,
      onCell: sharedOnCell,
      render: (data) => (data ? moment(data, "YYYYMMDD").format("DD/MM/YYYY") : "-"),
    },
    {
      title: i18n.t(languageKeys.nguoi_duyet),
      key: "TEN_NGUOI_DUYET",
      dataIndex: "TEN_NGUOI_DUYET",
      width: 120,
      onCell: sharedOnCell,
    },
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      key: "NGAY_TAO",
      dataIndex: "NGAY_TAO",
      width: 150,
      onCell: sharedOnCell,
      render: (data, record) => (data ? `${record.GIO_TAO ? record.GIO_TAO : ""} ${moment(data, "YYYYMMDD").format("DD/MM/YYYY")}` : "-"),
    },
    {
      title: i18n.t(languageKeys.nguoi_tao),
      key: "TEN_NGUOI_TAO",
      dataIndex: "TEN_NGUOI_TAO",
      width: 110,
      onCell: sharedOnCell,
      render: (data) => (data ? data : "-"),
    },
    {
      title: i18n.t(languageKeys.tiepdon_ngay_sua),
      key: "NGAY_SUA_KQ",
      dataIndex: "NGAY_SUA_KQ",
      width: 150,
      onCell: sharedOnCell,
      render: (data, record) => data && `${record.GIO_SUA_KQ ? record.GIO_SUA_KQ : ""} ${moment(record.NGAY_SUA_KQ, "YYYYMMDD").format("DD/MM/YYYY")}`,
    },

    // {
    //   title: i18n.t(languageKeys.tiepdon_nguoi_sua),
    //   key: "NGAY_SUA_KQ",
    //   dataIndex: "NGAY_SUA_KQ",
    //   width: 150,
    //   onCell: sharedOnCell,
    //   render: (data, record) => (data ? `${record.GIO_SUA_KQ ? record.GIO_TAO : ""} ${moment(data, "YYYYMMDD").format("DD/MM/YYYY")}` : "-"),
    // },
    {
      title: i18n.t(languageKeys.tiepdon_nguoi_sua),
      key: "NGUOI_SUA_KQ",
      dataIndex: "NGUOI_SUA_KQ",
      width: 110,
      onCell: sharedOnCell,
      render: (data) => data || "-",
    },

    {
      title: "SID",
      key: "BARCODE_XET_NGHIEM",
      dataIndex: "BARCODE_XET_NGHIEM",
      width: 100,
      align: "center",
      onCell: sharedOnCell,
      render: (data) => data || "-",
    },
    {
      title: i18n.t(languageKeys.field_don_gia),
      key: "DON_GIA",
      dataIndex: "DON_GIA",
      width: 100,
      onCell: sharedOnCell,
      render: (data) => (data ? formatNumberToPrice(data) : "-"),
    },
  ];

  const handleInitDataTicket = (item) => {
    try {
      let fieldData = Object.entries(nameFormPhieu).map(([key, value]) => {
        if (key === nameFormPhieu.GIOI_TINH) {
          return { name: value, value: convertGender(item[key]) };
        }
        if (key === nameFormPhieu.NGAY_SINH) {
          let ngay_sinh = item[key] || ""
          if (item[key].length === 8) {
            ngay_sinh = moment(item[key], "YYYYMMDD").format("YYYY");
          }
          return { name: value, value: ngay_sinh };
        }
        //5s yeu cau ko hien thi le tan, tiep don
        if (key === nameFormPhieu.TEN_NGUOI_TAO) {
          let TEN_NGUOI_TAO = item[key] || ""
          if (checkIsNameLeTanThuNgan(TEN_NGUOI_TAO)) {
            return { name: value, value: "" };
          }
          return { name: value, value: TEN_NGUOI_TAO };
        }
        if (key === nameFormPhieu.TEN_NGUOI_TAO_PHIEU) {
          let TEN_NGUOI_TAO_PHIEU = item[key]
          if (checkIsNameLeTanThuNgan(TEN_NGUOI_TAO_PHIEU)) {
            return { name: value, value: "" };
          }
          return { name: value, value: TEN_NGUOI_TAO_PHIEU };
        }
        return {
          name: value,
          value: item[key],
        };
      });
      formPhieu.setFields(fieldData);
    } catch (error) {}
  };
  const handleExtraTicketInfo = (data) => {
    try {

      //5s yeu cau ko hien thi le tan, tiep don
      let fieldData = Object.entries(nameFormPhieu).map(([key, value]) => {
        if (key === nameFormPhieu.TEN_NGUOI_TAO) {
          let TEN_NGUOI_TAO = data[key] || ""
          if (checkIsNameLeTanThuNgan(TEN_NGUOI_TAO)) {
            return { name: value, value: "" };
          }
          return { name: value, value: TEN_NGUOI_TAO };
        }
        if (key === nameFormPhieu.TEN_NGUOI_TAO_PHIEU) {
          let TEN_NGUOI_TAO_PHIEU = data[key]
          if (checkIsNameLeTanThuNgan(TEN_NGUOI_TAO_PHIEU)) {
            return { name: value, value: "" };
          }
          return { name: value, value: TEN_NGUOI_TAO_PHIEU };
        }
        if (key in data) {
          return {
            name: value,
            value: data[key],
          };
        } else if (key === nameFormPhieu.THOI_GIAN_DUYET) {
          return {
            name: value,
            value:
              data["NGAY_DUYET"] && data["GIO_DUYET"]
                ? moment(`${data["GIO_DUYET"]} ${data["NGAY_DUYET"]}`, "HH:mm YYYYMMDD").format("HH:mm DD/MM/YYYY")
                : undefined,
          };
        }
      });
      fieldData = fieldData.filter((i) => !!i);
      formPhieu.setFields(fieldData);
    } catch (error) {
      HLog(error);
    }
  };

  const handleCloseModal = () => {
    setExpandedRowKeys([]);
    setDataSource([]);
    setCurrentData();
    setCurrentPatient();
    setVisible(false);
  };

  const handleGetDataPhieu = async (PHIEU_ID) => {
    try {
      setLoading(true);
      const response = await common_post(apis.lay_chi_tiet_phieu_xn, {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_ID,
      });
      if (response.status === "OK") {
        let data = fomatData(response.result);
        let { chiTietPhieu } = response;
        handleExtraTicketInfo(chiTietPhieu[0]);
        setBenhPham(chiTietPhieu);

        setDataSource(data);
        if (data.length > 0) {
          setExpandedRowKeys((prevState) => prevState.concat(data[0].key));
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onPressPrint = async () => {
    try {
      const response = await common_post(apis.in_phieu_ket_qua_xn, {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_ID: currentData.PHIEU_IN_CLS_ID,
      });

      if (response.status === "OK") {
        let template = "phieuTraKQXN"
        let dataPrint = response.data.map((item) => {
          item.BENH_VIEN = userProfile.benh_vien;
          if (userProfile?.partner_code == "pkdk5s12") {
            template = "phieuInXN5s"
          }
          if (userProfile?.partner_code == "isgisg123") {
            template = "phieuInIshi"
          }

          return {
            ...item,
            template: template,
          };
        });
        // console.log("template", template);
        refPrint.current.openModalWithData(dataPrint, template);
      }
    } catch (error) {}
  };

  const renderFooterDrawer = () => {
    return (
      <Row gutter={10} justify="end">
        <Col>
          <Button onClick={() => handleCloseModal()} type="primary" ghost>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Col>
        <Col>
          <Button onClick={() => onPressPrint()} type="primary" icon={<PrinterOutlined />}>
            {i18n.t(languageKeys.in_ket_qua)}
          </Button>
        </Col>
      </Row>
    );
  };

  const handleExpandRow = (expand, item) => {
    if (!item.isLoai) return;
    if (expand) {
      setExpandedRowKeys((prevState) => prevState.concat(item.key));
    } else {
      setExpandedRowKeys((prevState) => prevState.filter((value) => value !== item.key));
    }
  };

  const renderExpandIcon = ({ expanded, onExpand, record }) => {
    if (expanded && record.isLoai) {
      return (
        <CaretDownOutlined
          onClick={(e) => {
            e.stopPropagation();
            onExpand(record, e);
          }}
          className={classNames(styles["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
        />
      );
    }
    if (!expanded && record.isLoai) {
      return (
        <CaretRightOutlined
          onClick={(e) => {
            e.stopPropagation();
            onExpand(record, e);
          }}
          className={classNames(styles["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
        />
      );
    }
  };

  const keydownEvent = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (event.key === "Escape") {
      handleCloseModal();
      stopDefaultAction(event);
    }

    if (event.ctrlKey && event.key === "i") {
      stopDefaultAction(event);
    }
  };

  useEventListener("keydown", keydownEvent, window.document, visible && !stopEvent);
  const RenderKetLuan = () => {
    try {
      let resultText;
      if (benhPham && benhPham.length) {
        resultText = benhPham[0].KET_LUAN;
      }
      return <InputAntd.TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={resultText} readOnly />;
    } catch (error) {
      return <></>;
    }
  };

  return (
    <FormDrawer
      keyboard={false}
      visible={visible}
      width="85vw"
      hiddenTopAction
      onCancel={handleCloseModal}
      footer={renderFooterDrawer()}
      title={false}
    >
      <Spin spinning={loading}>
        <div className={styles["container"]}>
          <Row justify={"space-between"} align="middle" className={styles["header"]}>
            <Col>
              <Row gutter={10}>
                <Col>
                  <NewBackIcon onClick={handleCloseModal} style={{ cursor: "pointer" }} />
                </Col>
                <Col>
                  <h3 className={styles["title"]}>{i18n.t(languageKeys.ket_qua_xet_nghiem)}</h3>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row justify="end">
                <Col className={styles["form__top--action"]}>
                  <Row className={styles["patient-title"]} justify="space-evenly">
                    <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ten_benh_nhan)}:</Col>{" "}
                    <Col className="blue-txt" style={{ display: "inline" }}>
                      {currentPatient?.TEN}
                    </Col>
                  </Row>
                  <Row className={styles["patient-title"]} justify="space-evenly">
                    <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_benh_nhan)}: </Col>
                    <Col className="navy-txt">{currentPatient?.MA_BENH_NHAN}</Col>
                  </Row>
                  <Row className={styles["patient-title"]}>
                    <Col style={{ marginRight: "4px" }}>{i18n.t(languageKeys.field_Ma_CK)}: </Col>
                    <Col className="navy-txt">{currentPatient?.MA_CUOC_KHAM}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider style={{ margin: 0 }} />
          <Form layout="vertical" form={formPhieu} className={styles["form-infomation"]}>
            <Row gutter={[10, 10]}>
              <Col sm={4} lg={4}>
                <Form.Item label={"SID"} name="SID">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={8} lg={6}>
                <Form.Item label={i18n.t(languageKeys.ho_va_ten)} name={nameFormPhieu.TEN}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col sm={3} lg={2}>
                <Form.Item label={i18n.t(languageKeys.field_Nam_sinh)} name={nameFormPhieu.NGAY_SINH}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={3} lg={2}>
                <Form.Item label={i18n.t(languageKeys.gioi_tinh)} name={nameFormPhieu.GIOI_TINH}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={4} lg={4}>
                <Form.Item label={i18n.t(languageKeys.field_So_dien_thoai)} name={nameFormPhieu.SO_DIEN_THOAI}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={6} lg={6}>
                <Form.Item label={i18n.t(languageKeys.field_Email)} name={nameFormPhieu.EMAIL}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={4} lg={5}>
                <Form.Item label={i18n.t(languageKeys.nguoi_duyet)} name={nameFormPhieu.TEN_NGUOI_DUYET}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={4} lg={5}>
                <Form.Item label={i18n.t(languageKeys.thoi_gian_duyet)} name={nameFormPhieu.THOI_GIAN_DUYET}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={4} lg={4}>
                <Form.Item label={i18n.t(languageKeys.implementer)} name={nameFormPhieu.TEN_NGUOI_TAO}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col sm={4} lg={4}>
                <Form.Item label={i18n.t(languageKeys.nguoi_chi_dinh)} name={nameFormPhieu.TEN_NGUOI_TAO_PHIEU}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <TableAnt
            className={styles["table"]}
            // title={() => (
            //   <Row justify="space-between">
            //     <Col>
            //       <h4>{i18n.t(languageKeys.thong_tin_ket_qua_xn)}</h4>
            //     </Col>
            //     <Col>
            //       <Button
            //         icon={<LinkOutlined />}
            //         className={styles["button-file"]}
            //         onClick={() => refModalFile.current.onpenModal(currentData.PHIEU_IN_CLS_ID)}
            //       >
            //         {i18n.t(languageKeys.file_dinh_kem)}
            //       </Button>
            //     </Col>
            //   </Row>
            // )}
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            scroll={{ x: "max-content" }}
            expandable={{
              defaultExpandAllRows: false,
              onExpand: handleExpandRow,
              expandIcon: renderExpandIcon,
              expandedRowKeys: expandedRowKeys,
              expandRowByClick: true,
            }}
            rowClassName={(record) => (!record.isLoai ? "child-row" : "child-parent")}
          />
          <Row justify="space-between">
            <Col style={{ marginLeft: 25 }}>
              <Button
                icon={<LinkOutlined />}
                className={styles["button-file"]}
                onClick={() => refModalFile.current.onpenModal(currentData.PHIEU_IN_CLS_ID)}
              >
                {i18n.t(languageKeys.file_dinh_kem)}
              </Button>
            </Col>
          </Row>
          <Divider style={{ margin: "15px 0" }} />
          <Row style={{ padding: "0 25px" }}>
            <span className="bold-txt navy-txt" style={{ fontSize: 15 }}>
              {i18n.t(languageKeys.ket_luan)}
            </span>
            <RenderKetLuan />
          </Row>
        </div>
      </Spin>
      <ModalFileDinhKem ref={refModalFile} setStopEvent={setStopEvent} />
      <ModalPDF ref={refPrint} />
    </FormDrawer>
  );
}

export default React.forwardRef(KetQuaXN);

export const sharedOnCell = (record, index) => {
  if (record.isLoai) {
    return {
      colSpan: 0,
    };
  }
  return {};
};

const renderNote = (record) => {
  let message = "";
  let ket_qua = Number(record.KET_QUA_XN);

  if (!!record && record.KET_QUA_XN) {
    if (ket_qua > record.CAN_TREN) {
      message = record?.GHI_CHU_CAN_TREN;
    } else if (ket_qua < record.CAN_DUOI) {
      message = record?.GHI_CHU_CAN_DUOI;
    } else if (ket_qua >= record.CAN_DUOI && ket_qua <= record.CAN_TREN) {
      message = record?.GHI_CHU_BINH_THUONG;
    } else {
      message = record.GHI_CHU ? record.GHI_CHU : "";
    }
  }
  return message;
};


const fomatData = (arr) => {
  let count = 0;
  return arr.map((item, index_parent) => {
    return {
      LOAI_DV_ID: item.LOAI_DV_ID,
      TEN_LOAI_DV: item.TEN_LOAI_DV,
      KET_LUAN: item.KET_LUAN,
      isLoai: true,
      key: item.LOAI_DV_ID,
      SL_DV_CON: item.SL_DV_CON,
      children: item.data.map((dv, index) => {
      let check_is_parent = !(dv?.PDT_TYPE == "PDT_CON_ID");
      if (check_is_parent) {
        count++;
      }
        return ({
          ...dv,
          key: dv.DICH_VU_ID,
          STT: !check_is_parent ? "-" : count,
        })
      })
    };
  });
};

const tinhSlDichVuDaDuyet = (arr) => {
  return arr.reduce((total, item) => (item.SL_DV_CON ? (total += Number(item.SL_DV_CON)) : total), 0);
};

const nameFormPhieu = {
  BARCODE_XET_NGHIEM: "SID",
  TEN: "TEN",
  NGAY_SINH: "NGAY_SINH",
  GIOI_TINH: "GIOI_TINH",
  SO_DIEN_THOAI: "SO_DIEN_THOAI",
  EMAIL: "EMAIL",
  TEN_NGUOI_TAO: "TEN_NGUOI_TAO",
  TEN_NGUOI_NHAP: "TEN_NGUOI_NHAP",
  TEN_NGUOI_DUYET: "TEN_NGUOI_DUYET",
  THOI_GIAN_DUYET: "THOI_GIAN_DUYET",
  TEN_NGUOI_TAO_PHIEU: "TEN_NGUOI_TAO_PHIEU"
};
