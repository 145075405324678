import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Input, notification } from "antd";
import style from "./style.module.less";
import { PlusCircleFilled } from '@ant-design/icons';
import i18n, { languageKeys } from '../../../../../i18n';
import { ActionButton, ConfirmModal, Table } from "../../../../../components";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { HLog, getFullName } from '../../../../../helpers';
import { apiLichSuKhaiThacKhamBenh } from '../../../apisKhamBenh/apisKhamBenh';
import { apis } from '../../../../../constants';

const KhaiThacThongTinKB = ({ patientInfo }) => {
    const userProfile = useSelector(state => state.auth.user);
    const refDelete = React.useRef();
    const [dataSource, setDataSource] = useState([]);
    const [visible, setVisible] = useState(false);
    const [txt, setTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResult, setTotalResult] = useState(0);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [loading, setLoading] = useState(false);
    const [errorInput, setErrorInput] = useState(false);

    const today = moment().format("YYYYMMDD");
    

    useEffect(() => {
       if (patientInfo) {
        handleGetDataLichSu();
       } else {
        setDataSource([]);
       }
    }, [patientInfo])
    
    const handleGetDataLichSu = async (page = 1, limit = 10) => {
        const payload = {
            BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
            partner_code : userProfile.partner_code,
            BENH_NHAN_ID : patientInfo?.BENH_NHAN_ID,
            page : 1, 
            limit : 10
        }
        setLoading (true);
        try{
            const response = await apiLichSuKhaiThacKhamBenh(apis.lay_ds_ls_khai_thach_kb, payload)
            const { result, current_page, total } = response;
            setDataSource(result);
            setTotalResult(total);
            setCurrentPage(current_page);
            setLoading(false);
            
        }catch(e) {
            HLog("get data lịch sử khám bệnh", e);
        }
    }

    const handleAddNew = async () => {

        const payload = {
            BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
            partner_code : userProfile.partner_code,
            BENH_NHAN_ID : patientInfo?.BENH_NHAN_ID,
            NGUOI_TAO_ID : userProfile.NHANSU_ID,
            NOI_DUNG : txt,
            LICH_KHAM_ID : patientInfo?.LICH_KHAM_ID,
            LUOT_DIEU_TRI_ID : patientInfo?.LUOT_DIEU_TRI_ID
        }
        try{
            const response = await apiLichSuKhaiThacKhamBenh(apis.them_ds_ls_khai_thach_kb, payload);
            handleCloseModal();
            setLoadingBtn(false);
            handleGetDataLichSu();
        }catch(e) {

        }
    }

    const handleDelete = async (item) => { 
        const payload = {
            BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
            partner_code : userProfile.partner_code,
            BENH_NHAN_ID : item.BENH_NHAN_ID,
            ID : item.ID,
        }
        refDelete.current.loading()
        try {
            const response = await apiLichSuKhaiThacKhamBenh(apis.xoa_ds_ls_khai_thach_kb, payload);
            refDelete.current.close()
            handleGetDataLichSu();
        }catch(e){

        }
    }

    const handleEdit = async () => { 
        const payload = {
            BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
            partner_code : userProfile.partner_code,
            BENH_NHAN_ID : patientInfo?.BENH_NHAN_ID,
            ID : selectedRecord.ID,
            NOI_DUNG : txt
        }
        try {
            const response = await apiLichSuKhaiThacKhamBenh(apis.sua_ds_ls_khai_thach_kb, payload);
            handleCloseModal();
            setLoadingBtn(false);
            handleGetDataLichSu();
        }catch(e){

        }

    }

    const onClickAddNew = () => {
        setVisible(true)
    }

    const onClickSave =  () => {
       
        if(!txt) {
            setErrorInput(true);
            notification.error({message : i18n.t(languageKeys.vui_long_nhap), placement : "bottomLeft"});
            return
        }
        setLoadingBtn(true)
        if (selectedRecord) {
            handleEdit()
        }else {
            handleAddNew()
        }
    }

    const onEditRecord = (value) => {
        setSelectedRecord(value);
        setVisible(true);
        setTxt(value?.NOI_DUNG);
    }

    const handleCloseModal = () => {
        setVisible(false);
        selectedRecord && setSelectedRecord()
        setTxt("")
        loadingBtn && setLoadingBtn(false)
    }

    const handleChangeTxt = (e) => {
        errorInput && setErrorInput(false);
        setTxt(e.target.value);
    }



    const columns = [
        {
            title : i18n.t(languageKeys.stt),
            dataIndex : "",
            width : 50,
            render : (_, __, index) => index + 1
        },
        {
            title : i18n.t(languageKeys.thoi_gian),
            dataIndex : "NGAY_TAO",
            width : 170,
            render : (value, item) => {
               
                return `${item.GIO_TAO} - ${moment(item.NGAY_TAO).format("DD/MM/YYYY")}`;
            }
        },
        {
            title : i18n.t(languageKeys.nguoi_tao),
            dataIndex : "TEN_NGUOI_TAO",
            width : 200
        },
        {
            title : i18n.t(languageKeys.common_noi_dung),
            dataIndex : "NOI_DUNG",
           
        },

        {
            title : "",
            dataIndex : "NOI_DUNG",
            width : 100,
            // fixed : "left",
            render : (_, item) => {
                return <ActionButton
                    showDelete={true}
                    showEdit={true}
                    onEdit={() => onEditRecord(item)}
                    onDelete={() => refDelete.current?.open(item)}
                    deleteDisabled = {
                        item.NGUOI_TAO_ID !== userProfile.NHANSU_ID || 
                        item.NGAY_TAO !== today || 
                        item?.LICH_KHAM_ID !== patientInfo?.LICH_KHAM_ID ||
                        item.LUOT_DIEU_TRI_ID !== patientInfo?.LUOT_DIEU_TRI_ID
                    }
                    editDisabled = {
                        item.NGUOI_TAO_ID !== userProfile.NHANSU_ID || 
                        item.NGAY_TAO !== today || 
                        item?.LICH_KHAM_ID !== patientInfo?.LICH_KHAM_ID ||
                        item.LUOT_DIEU_TRI_ID !== patientInfo?.LUOT_DIEU_TRI_ID
                    }
                    tooltipXoaTheoQuyen={true}
                />
            }
        }

    ]

    return (
        <div className={style['container']}>
            <Row justify={"space-between"} align={"middle"} style={{marginBottom: 10}}>
                <Col>
                    <h3>{i18n.t(languageKeys.title_khai_thac_thong_tin_kb)}</h3>
                </Col>
                <Col>
                <Button type='primary' icon={<PlusCircleFilled/>} onClick={onClickAddNew} disabled={!patientInfo}>
                    {i18n.t(languageKeys.common_Them_moi)}
                </Button>
                </Col>
            </Row>

            <Table 
                columns={columns} 
                dataSource={dataSource}
                onClickRow={(value) => onEditRecord(value)}
                totalResult={totalResult}
                currentPage={currentPage}
                onNext={() => handleGetDataLichSu(currentPage + 1)}
                onPrev={() => handleGetDataLichSu(currentPage - 1)}
                scroll = {{ x : true,  }}
                loading = {loading}
            />
            

            <Modal 
                open={visible}
                onCancel={handleCloseModal}
                className={style["modal-add-khai-thac"]}
                footer={false}
                centered
            >
                <h3>{i18n.t(languageKeys.them_moi_thong_tin_khai_thac_kham_benh)}</h3>
                <Row justify={"space-between"} style={{marginBlock : 10}}>
                    <Col>
                        <p className={style["text"]}>{i18n.t(languageKeys.thoi_gian)} : {selectedRecord ? `${selectedRecord.GIO_TAO} - ${moment(selectedRecord.NGAY_TAO).format("DD/MM/YYYY")}` : moment().format("HH:mm DD/MM/YYYY")}</p>
                    </Col>
                    <Col>
                        <p className={style["text"]}>{i18n.t(languageKeys.nguoi_tao)} : {selectedRecord ? selectedRecord.TEN_NGUOI_TAO : getFullName(userProfile)}</p>
                    </Col>
                </Row>

                <h4>{i18n.t(languageKeys.common_noi_dung)} <span style={{ color : "red"}}>*</span></h4>
                <Input.TextArea
                    rows={5}
                    className={style['khai-thac-text-area']}
                    onChange={(e) => handleChangeTxt(e)}
                    value={txt}
                    status= {errorInput && "error"}
                    readOnly={
                        selectedRecord && (
                            selectedRecord?.NGUOI_TAO_ID !== userProfile.NHANSU_ID ||
                            selectedRecord?.NGAY_TAO !== today ||
                            selectedRecord?.LICH_KHAM_ID !== patientInfo?.LICH_KHAM_ID ||
                            selectedRecord?.LUOT_DIEU_TRI_ID !== patientInfo?.LUOT_DIEU_TRI_ID
                        )
                    }
                />
                <Row justify={"end"} style={{marginTop : 10}}>
                    <Button type='primary' style={{marginInline : 10}} danger ghost onClick={handleCloseModal}>{i18n.t(languageKeys.huy)}</Button>
                    <Button 
                        type='primary'
                        onClick={onClickSave}
                        loading={loadingBtn}
                        disabled={
                            selectedRecord && (
                                selectedRecord?.NGUOI_TAO_ID !== userProfile.NHANSU_ID ||
                                selectedRecord?.NGAY_TAO !== today ||
                                selectedRecord?.LICH_KHAM_ID !== patientInfo?.LICH_KHAM_ID ||
                                selectedRecord?.LUOT_DIEU_TRI_ID !== patientInfo?.LUOT_DIEU_TRI_ID
                            )
                        }
                    >{i18n.t(languageKeys.luu)}</Button>
                </Row>
            </Modal>

            <ConfirmModal
                ref ={refDelete}
                content={i18n.t(languageKeys.confirm_xoa_lich_su)}
                danger={true}
                onOk={(value) => handleDelete(value)}
                onCancel={() => {
                    refDelete.current.close();
                }}
            />
        </div>
    )
}

export default React.memo(KhaiThacThongTinKB)