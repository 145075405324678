import { Button, Col, DatePicker, Popover, Radio, Row } from "antd";
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import moment from "moment";

import style from "./sider.module.less";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../../i18n";
import { CaretDownFilled, DownOutlined } from "@ant-design/icons";
import { isNull } from "lodash";

const FilterDate = (
  {
    onFilterTime = () => {}, // hàm lọc thời gian
    onFilter = () => {}, // hàm lọc khi nhấp button áp dụng
    onReset = () => {},
    auto = false,
    showBtn = true,
    showFilterBlock = false,
    showIconToggle = true,
  },
  ref
) => {
  const [date, setDate] = useState(i18n.t(languageKeys.common_Hom_nay));
  const [defaultDate, setDefaultDate] = useState([moment(), moment()]);
  const [currentDate, setCurrentDate] = useState(null);
  const [typeTime, setTypeTime] = useState(false); // false là filter theo Chọn ngày cố định, true là chọn ngày động
  const [showFilter, setShowFilter] = useState(showFilterBlock);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setDate(i18n.t(languageKeys.common_Hom_nay));
      setCurrentDate(null);
      setDefaultDate([moment(), moment()]);
      setTypeTime(false);
    },
  }));

  const handleChangeType = (value) => {
    setTypeTime(!!value);
    if (!value) {
      auto && onFilterTime(defaultDate);
    } else {
      !isNull(currentDate) ? auto && onFilterTime(currentDate) : onFilterTime([moment(), moment()]);
      setCurrentDate([moment(), moment()]);
    }
  };
  // reset Thời gian
  const handleReset = () => {
    onReset([moment(), moment()]);
    setDate(i18n.t(languageKeys.common_Hom_nay));
    setCurrentDate(null);
    setDefaultDate([moment(), moment()]);
    setTypeTime(false);
  };

  //format ngày
  const customFormat = (value) => {
    return i18n.language === languages.tieng_viet ? ` ${value.format("DD/MM/YYYY")}` : value.format("LL");
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  return (
    <div className={style["sider-time"]}>
      <h4 className={style["sider-title"]} onClick={() => showIconToggle && setShowFilter(!showFilter)} style={{ cursor: "pointer", fontSize: 13 }}>
        <span>
          {showIconToggle && (
            <CaretDownFilled
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              style={{ transform: !showFilter && "rotate(-90deg)" }}
            />
          )}
        </span>{" "}
        <span style={{ fontWeight: 700 }}>{i18n.t(languageKeys.field_thoi_gian)}</span>
      </h4>
      <Radio.Group
        style={{ width: "100%", display: showFilter ? "block" : "none" }}
        className={style["time-group"]}
        value={typeTime}
        onChange={(e) => handleChangeType(e.target.value)}
      >
        <Row gutter={5} className={style["sider-line"]}>
          <Col span={4} style={{ display: "flex" }}>
            <Radio className={style["mr-center"]} value={false} />
          </Col>
          <Col span={20}>
            <Popover
              overlayClassName={style["pop-up-time"]}
              className={style["pop-up-sider"]}
              placement="bottom"
              visible={visible}
              onVisibleChange={handleVisibleChange}
              zIndex={5}
              content={
                <div>
                  <div
                    className={style["pop-item"]}
                    onClick={async (e) => {
                      setDate(i18n.t(languageKeys.common_Hom_nay));
                      setVisible(false);
                      setDefaultDate([moment(), moment()]);

                      handleChangeType(false);
                      auto && onFilterTime([moment(), moment()]);
                    }}
                  >
                    {i18n.t(languageKeys.common_Hom_nay)}
                  </div>
                  <div
                    className={style["pop-item"]}
                    onClick={(e) => {
                      setVisible(false);
                      setDate(i18n.t(languageKeys.khambenh_homqua));
                      setDefaultDate([moment().subtract(1, "days"), moment().subtract(1, "days")]);
                      handleChangeType(false);
                      auto && onFilterTime([moment().subtract(1, "days"), moment().subtract(1, "days")]);
                    }}
                  >
                    {i18n.t(languageKeys.common_Hom_qua)}
                  </div>
                  <div
                    className={style["pop-item"]}
                    onClick={(e) => {
                      setVisible(false);
                      setDate(i18n.t(languageKeys.khambenh_7ngay_gan_nhat));
                      setDefaultDate([moment().subtract(7, "days"), moment().subtract(0, "days")]);
                      handleChangeType(false);
                      auto && onFilterTime([moment().subtract(7, "days"), moment().subtract(0, "days")]);
                    }}
                  >
                    7 {i18n.t(languageKeys.txt_ngay_gan_nhat)}
                  </div>
                  <div
                    className={style["pop-item"]}
                    onClick={(e) => {
                      setVisible(false);
                      setDate(i18n.t(languageKeys.khambenh_30ngay_gan_nhat));
                      setDefaultDate([moment().subtract(30, "days"), moment().subtract(0, "days")]);
                      handleChangeType(false);
                      auto && onFilterTime([moment().subtract(30, "days"), moment().subtract(0, "days")]);
                    }}
                  >
                    30 {i18n.t(languageKeys.txt_ngay_gan_nhat)}
                  </div>
                </div>
              }
              trigger="click"
            >
              <div className={style["today__btn--wrap"]}>{date}</div>
            </Popover>
          </Col>
        </Row>
        <Row gutter={5} className={style["sider-line"]}>
          <Col span={4} style={{ display: "flex" }}>
            <Radio className={style["mr-center"]} value={true} />
          </Col>
          <Col span={20}>
            <DatePicker.RangePicker
              className={style["date-pick"]}
              format={customFormat}
              size={"default"}
              value={currentDate}
              placeholder={[i18n.t(languageKeys.field_Tu), i18n.t(languageKeys.field_Den)]}
              onChange={(value) => {
                if (!!value && !isNull(value)) {
                  handleChangeType(true);
                  setCurrentDate([value[0], value[1]]);
                  auto && onFilterTime([value[0], value[1]]);
                } else {
                  handleChangeType(false);
                  setCurrentDate(null);
                  auto && onFilterTime(defaultDate);
                  setTypeTime(false);
                }
              }}
              popupClassName={style["popup-select"]}
            />
          </Col>
        </Row>
        {!auto && showBtn && (
          <Row justify="space-evenly" style={{ marginTop: 12 }}>
            <Col>
              <Button type="primary" ghost style={{ width: 100, fontWeight: 700 }} onClick={() => handleReset()}>
                {i18n.t(languageKeys.button_Cai_dat_lai)}
              </Button>
            </Col>
            <Col>
              <Button
                type={"primary"}
                style={{ width: 100, fontWeight: 700 }}
                onClick={() => (!typeTime ? onFilterTime(defaultDate) : onFilterTime(currentDate))}
              >
                {i18n.t(languageKeys.common_Ap_dung)}
              </Button>
            </Col>
          </Row>
        )}
      </Radio.Group>
    </div>
  );
};

export default forwardRef(FilterDate);
