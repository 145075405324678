import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { Row, Button, Tooltip } from "antd";
import {FormDrawer, Table} from "../../../../components";
import style from "./lskb.module.less"
import i18n, {languageKeys} from "../../../../i18n";
import {common_post, convertDateToValue} from "../../../../helpers";
import LichSuKhamDrawer from "../../../../components/LichSuKhamBenh/LichSuKhamDrawer/LichSuKhamDrawer";
import {apis} from "../../../../constants";
import {useEventListener} from "../../../../hooks";
const ellipsisProps = {
    ellipsis: true,
    render: (data) => {
        return (
            <Tooltip placement="topLeft" title={data} zIndex={1}>
                {data === "null" || data === "undefined" || !data ? "" : data}
            </Tooltip>
        );
    },
};
const DanhSachLichSuKham = React.forwardRef(({patientInfo , userProfile, isVisible}, ref) => {
    const [visible, setVisible] = useState(false);
    const [dsLuotDieuTri, setDsLuotDieuTri] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [visibleDetail, setVisibleDetail] = useState(false)
    const chiTietRef = useRef();


    useImperativeHandle(ref, () => ({
        closeModal() {
            handleCloseModal()
        },
        openModal() {
            handleOpenModal()
        }
    }));
    function handleCloseModal() {
        setVisible(false)

        setDsLuotDieuTri([])
        setCurrentPage(1)
        setTotal(0);
        setLoading(false)
    }

    function handleOpenModal() {
        setVisible(true)

        if(!!patientInfo) getDataLichSu();
    }

    function handleXemChiTietLichKham(record) {
        //console.log("SSS", record)
        chiTietRef.current.openModal(record,patientInfo)
    }

    async  function getDataLichSu(page = currentPage){
        let req = {
            BENH_NHAN_ID : patientInfo.BENH_NHAN_ID,
            partner_code : userProfile.partner_code,
            limit : 20,
            page : page
        }
        try {
            setLoading(true)
            let response = await common_post(apis.khambenh_lay_lich_su_xem_nhanh, req)
            if (response.status === "OK") {
                let {total, result, current_page} = response
                setTotal(total)
                setCurrentPage(current_page)
                setDsLuotDieuTri(result)
            }
            setLoading(false)
        }catch (e) {

        }
    }
    const columns = [
        {
            title: i18n.t(languageKeys.field_Ngay_bat_dau),
            key: "NGAY_BAT_DAU",
            dataIndex: "NGAY_BAT_DAU",
            width: 110,
            render: (NGAY_BAT_DAU, record) =>NGAY_BAT_DAU ? convertDateToValue(NGAY_BAT_DAU) : "-",
        },
        {
            title: i18n.t(languageKeys.field_Ngay_ket_thuc),
            key: "NGAY_KET_THUC",
            dataIndex: "NGAY_KET_THUC",
            width: 110,
            render: (NGAY_KET_THUC, record) => NGAY_KET_THUC ?  convertDateToValue(NGAY_KET_THUC) : "-",
        },
        {
            title: i18n.t(languageKeys.field_Chan_doan_benh_chinh),
            key: "CHAN_DOAN_BENH_CHINH",
            dataIndex: "CHAN_DOAN_BENH_CHINH",
            ...ellipsisProps,
        },
        {
            title: i18n.t(languageKeys.field_Chan_doan_benh_phu_kem_theo),
            key: "CHAN_DOAN_BENH_KEM_THEO",
            dataIndex: "CHAN_DOAN_BENH_KEM_THEO",
            ...ellipsisProps,
            render: (data) => data.length > 0 ? data.join(", ") : "",
        },
        {
            title: i18n.t(languageKeys.field_Chan_doan_xac_dinh),
            key: "CHAN_DOAN_XAC_DINH",
            dataIndex: "CHAN_DOAN_XAC_DINH",
            ...ellipsisProps,
        },
        {
            title: i18n.t(languageKeys.field_Bac_si),
            key: "BAC_SI",
            dataIndex: "BAC_SI",
            width: 140,
        },
        {
            title: i18n.t(languageKeys.field_Phong_thuc_hien),
            key: "PHONG_THUC_HIEN",
            dataIndex: "PHONG_THUC_HIEN",
            width: 150,
        },
        {
            title: "",
            key: "ACTION",
            dataIndex: "ACTION",
            width: 100,
            fixed: "right",
            render: (item, record) => (
                <div>
                    <Button className={style["detailBtn"]} type={"primary"} onClick={() => handleXemChiTietLichKham(record)}>
                        {i18n.t(languageKeys.chi_tiet)}
                    </Button>
                </div>
            ),
        },
    ];

    // hàm xử lý phím tắt
    function keydownEvent(event) {
        const stopDefaultAction = (e) => {
            e.stopPropagation();
            e.preventDefault();
        };
        if (event.key === "Escape") {
            stopDefaultAction(event);
            if (visibleDetail){
                return
            }
            handleCloseModal();
        }
    }

    useEventListener("keydown", keydownEvent, window, visible);
    return(
       <FormDrawer
            title={i18n.t(languageKeys.xem_nhanh_lich_su_kham_benh)}
            visible={visible}
            hiddenTopAction={true}
            onCancel = {() => setVisible(false)}
            width={"calc(100vw - 100px)"}
            footer = {
                    <Row justify="end">
                        <Button type="primary" ghost onClick={() => handleCloseModal()}>
                            {i18n.t(languageKeys.common_Thoat)} (ESC)
                        </Button>
                    </Row>
            }
            keyboard={false}
       >
           <div className={style["danhsach-lichsukham"]}>
               <Table
                    //style = {{ backgroundColor :"green"}}
                    dataSource={dsLuotDieuTri}
                    columns={columns}
                    scroll = {{
                        y : "calc(100vh - 200px)"
                    }}
                    showPagination={true}
                    currentPage={currentPage}
                    totalResult={total}
                    limit={20}
                    onNext={() => getDataLichSu(currentPage + 1)}
                    onPrev={() => getDataLichSu(currentPage - 1)}
               />
           </div>

           <LichSuKhamDrawer ref={chiTietRef} onChangeVisibleDetail = {setVisibleDetail} patientInfo={patientInfo}/>

       </FormDrawer>
    )
})
export default React.memo(DanhSachLichSuKham)
